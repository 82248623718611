import { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"


//i18n
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import { FormSwitch } from "components/Common"

const RESOURCE = ModuleIds.RuleManagement

const CustomRuleTable = ({
  rules,
  onSearch,
  onSort,
  onDeleteHandler,
  onEditHandler,
  onRefresh,
  paging,
  onSelect,
  t,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: ""
  })

  const searchQuery = useSelector(state => state.individual.searchQuery)

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(rules)
  }, [rules])

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, user, index) => {
        return index + 1
      },
    },
    {
      dataField: "ruleName",
      text: t("Rule Name"),
      sort: true
    },
    {
      dataField: "operator",
      text: t("Operator"),
      sort: true
    },
    {
      dataField: "successEvent",
      text: t("Success Event"),
      sort: true,
    },
    // {
    //   dataField: "errorMessage",
    //   text: t("Error Message"),
    //   sort: true,
    // },
    {
      dataField: "expression",
      text: t("Expression"),
      style: { width: 400 },
      sort: true
    },
    // {
    //   dataField: "actions",
    //   text: t("Actions"),
    // },
    {
      dataField: "enable",
      text: t("Enabled"),
      sort: true,
      formatter: (cellContent, item, index) => {
        return <FormSwitch
          label=""
          value={item.enabled}
          disabled
        />
      },
    },
    {
      dataField: "action2",
      text: "action",
      style: { width: 100 },
      formatter: (cellContent, rule, index) => (
        <div className="d-flex flex-row justify-content-around">
          <div className="align-self-center"
            onClick={e => { onEditHandler(rule) }}
          >
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />
          </div>
          <div className="align-self-center"
            onClick={e => { onDeleteHandler(rule) }}
          >
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
          </div>
        </div>
      ),
    },
  ]

  return (
    <div style={{ backgroundColor: 'white' }}>
      <CustomBootstrapTableAsync
        columns={columns}
        isEnableExport={false}
        search
        onSelect={onSelect}
        showSelectRow
        data={data}
        onSort={onSort}
        keyField="id"
        searchText={model.search}
        onSearch={onSearch}
        resource={RESOURCE}
        isScrollable
        onRefresh={onRefresh}
        isHover={false}
        isEnableRefresh
        paging={paging}
        updatedTime={new Date()}
      />
    </div>
  )
}

const mapStateToProps = ({ RuleManagement }) => ({

})

const mapDispatchToProps = dispatch => ({
  onGetTestsSuccess: payload => dispatch(getFullTestSuccess(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message, common, testConfig"])(CustomRuleTable))
