import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import upload_image from "../../assets/images/users/image_upload.png"
const LetterAvatar = ({ name, image }) => {
  const [selectedImage, setSelectedImage] = useState(null)

  useEffect(() => {
    if (image) {
      const url = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/${image.filePath}`
      setSelectedImage(url)
    } else {
      let storedImages  = localStorage.getItem("images")
      if (storedImages ) {
        const parsedImages = JSON.parse(storedImages)
        const url = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/${parsedImages.filePath}`
        setSelectedImage(url)
      }
    }
  }, [image])
  return (
    // <span
    //   className={`avatar-xs avatar-title rounded-circle bg-success bg-soft text-success ${className}`}
    // >
    //   {getFirstLetterOfEachWords(name)}
    // </span>
    <img
      src={!selectedImage ? `${upload_image}` : selectedImage}
      style={{
        borderRadius: "50%",
        width: "32px",
        height: "32px",
        transition: "border-color 0.2s",
        backgroundColor: "#999999",
        border: "1px solid #ccccc",
        objectFit: "inherit",
      }}
      className="picture-src"
      id="wizardPicturePreview"
      title=""
    />
  )
}

LetterAvatar.defaultProps = {
  className: "",
}

LetterAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}
const mapStateToProps = ({ userProfiles }) => ({
  image: userProfiles.image,
})
export default connect(mapStateToProps)(LetterAvatar)
