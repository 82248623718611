import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  getCategoryTestSetting,
  getProfilesTestSetting,
  getSampletypeTestSetting,
  getTestProfilePermissionsByRoleId,
  getTestSamplePermissionByRoleId,
  updateRoleTestProfilePermissions,
  updateRoleTestSamplePermissions,
} from "store/users/role/actions"

import {
  Check,
  CollapsibleModule,
  CollapsibleModuleWrapper,
  CustomButton,
  CustomButtonGroup,
  CustomCheckbox,
  CustomNav,
  CustomNavLink,
} from "components/Common"

import { ModuleIds, permissionType } from "constant"

import { isEmptyArray } from "helpers/utilities"
import { isEmpty } from "lodash"
import { Col, Row, TabContent, TabPane } from "reactstrap"

const RESOURCE = ModuleIds.RolePemisssion

const TabTestSetting = ({
  sampleTypes,
  testSamplePermissions,
  profileTestPermissions,
  categories,
  profilesTestSetting,
  onGetSampleTypes,
  onGetCategoryTestSetting,
  onGetTestSamplePermission,
  onGetTestProfilePermissions,
  onGetProfilesTestSetting,
  roleId,
  onUpdateRoleTestSamplePermissions,
  onUpdateRoleTestProfilePermissions,
  customActiveTab,
  t,
}) => {

  const formEl1 = useRef()
  const formEl2 = useRef()

  const [profilePermissions, setProfilePermissions] = useState([])

  const [selectAllProfilePermissions, setSelectAllProfilePermissions] = useState(false)
  const [isSelectedAllChecked, setIsSelectedAllChecked] = useState(false)
  const [checkedList, setCheckedList] = useState([])
  const [removedCheckedList, setRemovedCheckedList] = useState([])
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    setProfilePermissions(profileTestPermissions)
    setCheckedList([])
  }, [profileTestPermissions])

  const fetchSampleTypes = () => {
    onGetSampleTypes()
  }

  const fetchCategoryTestSetting = () => {
    onGetCategoryTestSetting()
  }

  const fetchTestProfilePermissions = () => {
    onGetTestProfilePermissions({ roleId })
  }
  useEffect(() => {
    setIsloading(false)
  }, [profileTestPermissions, testSamplePermissions])

  const fetchGetTestSamplePermission = () => {
    onGetTestSamplePermission({ roleId })
  }

  const onToggle = id => {
    //setCollapsesOpen(prev => ({ [id]: !prev[id] }))
  }



  const checkProfileAccess = id => {
    return isEmpty(profilePermissions.find(p => p.profileId === id))
      ? false
      : profilePermissions.find(p => p.profileId === id).hasAccess
        ? true
        : false
  }


  const checkSampleAccess = code => {
    const checkEmpty = isEmpty(testSamplePermissions.find(p => p.sampleType === code));
    const result = checkEmpty ? false
      : testSamplePermissions.find(p => p.sampleType === code).hasAccess ? true : false
    return result
  }

  const onSamplePersValidSubmit = (e, values) => {
    const sampleAccess = []
    // convert object to array
    Object.keys(values).filter(_key => values[_key]).map(_key =>
      sampleAccess.push({
        sample: _key + '',
        sampleName: sampleTypes.find(item => item.code === _key).message,
      })
    )
    onUpdateRoleTestSamplePermissions({
      testSamplePermissons: sampleAccess,
      roleId,
      callback: () => onAfterUpdateSample(),
    })
  }

  const onAfterUpdateProfile = () => {
    fetchTestProfilePermissions({ roleId })
  }

  const onAfterUpdateSample = () => {
    fetchGetTestSamplePermission({ roleId })
  }

  const onProfilePersValidSubmit = (e, values) => {
    const profileAccess = []
    // convert object to array
    Object.keys(values)
      .filter(_key => !_key.includes("_resource"))
      .map(_key => {
        const profile = profilesTestSetting.find(e => e.id === parseInt(_key));
        //const category = categories.filter(e => e.subCategory.find(sc => sc.code == profile.category));

        return profileAccess.push({
          profileId: parseInt(_key),
          displayOrder: profile.displayOrder,
          profileName: profile.profileName,
          hasAccess: values[_key]
          //category: category[0]?.code
        })
      }
      )

    onUpdateRoleTestProfilePermissions({
      testProfiePermissons: profileAccess,
      roleId,
      callback: () => onAfterUpdateProfile(),
    })
  }

  const onClickNav = code => {
    setRemovedCheckedList([])
    setCheckedList([])

    const cate = categories.find(item => item.code === code)?.subCategory;
    const subCategories = cate.map(item => item.code)

    onGetProfilesTestSetting({ subCategories: subCategories })
    fetchTestProfilePermissions()

  }

  const handleOnSelectAll = (value, subCategory) => {
    if (value) {
      const list = [...checkedList]
      list.push(subCategory)
      setCheckedList(list)

      const removedList = removedCheckedList.filter(item => item != subCategory)
      setRemovedCheckedList(removedList)

    } else {
      if (checkedList) {
        const list = checkedList.filter(item => item != subCategory)
        setCheckedList(list)

        const removedList = [...removedCheckedList]
        removedList.push(subCategory)
        setRemovedCheckedList(removedList)
      }
    }
    setSelectAllProfilePermissions(!selectAllProfilePermissions)
    if (!isSelectedAllChecked)
      setIsSelectedAllChecked(true)
  }

  const onResetFormEl1Handler = () => {
    formEl1?.current.reset()
  }

  const onResetFormEl2Handler = () => {
    formEl2?.current.reset()
  }

  const onCancelSamplePerForm = () => {
    setIsloading(true)
    onResetFormEl2Handler()
    fetchGetTestSamplePermission()
    setRemovedCheckedList([])
    setCheckedList([])
    //fetchSampleTypes()
  }

  const onCancelProfilePerForm = () => {
    setIsloading(true)
    onResetFormEl1Handler()
    fetchTestProfilePermissions()
    setRemovedCheckedList([])
    setCheckedList([])
    //fetchSampleTypes()
  }

  useEffect(() => {
    if (customActiveTab === "5") {
      fetchSampleTypes()
      fetchGetTestSamplePermission()
      fetchTestProfilePermissions()
      fetchCategoryTestSetting()
    }
  }, [customActiveTab])

  return (
    <>
      <h4>{t("Test Setting")}</h4>

      <CollapsibleModuleWrapper onToggle={onToggle} defaultTab={1}>
        {!isEmptyArray(categories) && <CollapsibleModule
          title={t("Categories")}
          key={1}
          tabId={1 + ""}
        >
          <Row>
            <CustomNav
              vertical
              tabs
              className="vertical-tabs"
              onToggle={onClickNav}
              defaultTab={categories[0]?.code}
              tabContents={activeTab => (
                <Col>
                  <TabContent
                    activeTab={activeTab}
                    className="p-3 text-muted"
                  >
                    {categories.map((cat) => {
                      return (
                        <TabPane key={cat.code} tabId={cat.code}>
                          <AvForm
                            onValidSubmit={(e, values) =>
                              onProfilePersValidSubmit(e, values)
                            }
                            model={cat}
                            ref={formEl1}
                          >
                            {!isloading &&
                              <React.Fragment>
                                {cat.subCategory?.map(
                                  ({ message, code }) => {
                                    return (
                                      <Row
                                        key={code}
                                        className="border border-2 p-2 tab-permissions"
                                      >
                                        <Col sm={3}>
                                          <CustomCheckbox
                                            label={message}
                                            name={code + "_resource"}
                                            direction="left"
                                            onChange={value =>
                                              handleOnSelectAll(value, code)
                                            }
                                            checked={false}
                                          />
                                        </Col>
                                        <Col sm={9}>
                                          <Row>
                                            {profilesTestSetting.filter(_pro => _pro.category == code)?.map((profile) => {
                                              let checked = checkProfileAccess(profile.id);

                                              if (checkedList.includes(profile.category)) {
                                                checked = true
                                              }

                                              if (removedCheckedList.includes(profile.category)) {
                                                checked = false
                                              }
                                              return (
                                                <Col key={profile.id} sm={3}>
                                                  <CustomCheckbox
                                                    label={profile.profileName}
                                                    name={profile.id + ""}
                                                    direction="left"
                                                    checked={checked}
                                                  />
                                                </Col>
                                              )
                                            }
                                            )}
                                          </Row>
                                        </Col>
                                      </Row>
                                    )
                                  }
                                )}
                              </React.Fragment>
                            }
                            {isloading &&
                              <React.Fragment>
                                {cat.subCategory?.map(
                                  ({ message, code }) => {
                                    return (
                                      <Row
                                        key={code}
                                        className="border border-2 p-2 tab-permissions"
                                      >
                                        <Col sm={3}>
                                          <CustomCheckbox
                                            label={message}
                                            name={code + "_resource"}
                                            direction="left"
                                            onChange={value =>
                                              handleOnSelectAll(value, code)
                                            }
                                            checked={false}
                                          />
                                        </Col>
                                        <Col sm={9}>
                                          <Row>
                                            {profilesTestSetting.filter(_pro => _pro.category == code)?.map((profile) => {
                                              let checked = checkProfileAccess(profile.id);

                                              if (checkedList.includes(profile.category)) {
                                                checked = true
                                              }

                                              if (removedCheckedList.includes(profile.category)) {
                                                checked = false
                                              }
                                              return (
                                                <Col key={profile.id} sm={3}>
                                                  <CustomCheckbox
                                                    label={profile.profileName}
                                                    name={profile.id + ""}
                                                    direction="left"
                                                    checked={checked}
                                                  />
                                                </Col>
                                              )
                                            }
                                            )}
                                          </Row>
                                        </Col>
                                      </Row>
                                    )
                                  }
                                )}
                              </React.Fragment>
                            }
                            {!isEmptyArray(cat.subCategory) && (
                              <CustomButtonGroup className="justify-content-end mt-2">
                                <Check
                                  resource={RESOURCE}
                                  permission={permissionType.U}
                                >
                                  <CustomButton
                                    type="button"
                                    data-dismiss="modal"
                                    text={t("common:Cancel")}
                                    isEdit
                                    className="button-width"
                                    onClick={onCancelProfilePerForm}
                                  />{" "}

                                  <CustomButton
                                    type="submit"
                                    color="primary"
                                    className="save-user button-width"
                                    text={t("common:Save")}
                                    isEdit
                                  />
                                </Check>
                              </CustomButtonGroup>
                            )}
                          </AvForm>
                        </TabPane>
                      )
                    }
                    )}
                  </TabContent>
                </Col>
              )}
            >
              {categories?.map(({ id, code, message }) => {
                return (
                  <CustomNavLink key={code} tabId={code}>
                    {message}
                  </CustomNavLink>
                )
              })
              }

            </CustomNav>
          </Row>

        </CollapsibleModule>
        }

        <CollapsibleModule key={2} tabId={2 + ""} title={t("Type of Sample")}>
          <Row>
            <Col sm="12">
              <AvForm onValidSubmit={(e, values) => onSamplePersValidSubmit(e, values)} ref={formEl2}>
                {!isloading &&
                  <Row>
                    {sampleTypes.map(({ message, code }, _idx) => {
                      const checked = checkSampleAccess(code)
                      return (
                        <Col key={_idx} className="col-3">
                          <div className="mb-3">
                            <CustomCheckbox
                              label={message}
                              name={code + ""}
                              checked={checked}
                            />
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                }
                {isloading &&
                  <Row>
                    {sampleTypes.map(({ message, code }, _idx) => {
                      const checked = checkSampleAccess(code)
                      return (
                        <Col key={_idx} className="col-3">
                          <div className="mb-3">
                            <CustomCheckbox
                              label={message}
                              name={code + ""}
                              checked={checked}
                            />
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                }
                {!isEmpty(sampleTypes) && (
                  <CustomButtonGroup className="justify-content-end">
                    <Check resource={RESOURCE}
                      permission={permissionType.U}>
                      <CustomButton type="button" text={t("common:Cancel")} className="button-width" isEdit onClick={onCancelSamplePerForm} />{" "}
                      <CustomButton
                        type="submit"
                        color="primary"
                        className="save-user button-width"
                        text={t("common:Save")}
                        isEdit
                      />
                    </Check>
                  </CustomButtonGroup>
                )}
              </AvForm>
            </Col>
          </Row>
        </CollapsibleModule>


      </CollapsibleModuleWrapper >
    </>
  )
}

TabTestSetting.propTypes = {
  sampleTypes: PropTypes.array,
  categories: PropTypes.array,
  testSamplePermissions: PropTypes.array,
  profileTestPermissions: PropTypes.array,
  profilesTestSetting: PropTypes.array,
  roleProfiles: PropTypes.array,
  onGetSampleTypes: PropTypes.func,
  onGetTestSamplePermission: PropTypes.func,
  onUpdateRoleTestSamplePermissions: PropTypes.func,
  onUpdateRoleTestProfilePermissions: PropTypes.func,

  modules: PropTypes.object,
}

const mapStateToProps = ({ role }) => ({
  sampleTypes: role.sampleTestSetting,
  testSamplePermissions: role.testSamplePermissions,
  profileTestPermissions: role.profileTestPermissions,
  categories: role.categoryTestSetting,
  profilesTestSetting: role.profilesTestSetting,
  roleProfiles: role.roleProfiles,
  modules: role.modules,
})

const mapDispatchToProps = dispatch => ({
  onGetSampleTypes: () => dispatch(getSampletypeTestSetting()),
  onGetCategoryTestSetting: () => dispatch(getCategoryTestSetting()),
  onGetProfilesTestSetting: payload => dispatch(getProfilesTestSetting(payload)),
  onGetTestSamplePermission: payload => dispatch(getTestSamplePermissionByRoleId(payload)),
  onGetTestProfilePermissions: payload => dispatch(getTestProfilePermissionsByRoleId(payload)),
  onUpdateRoleTestSamplePermissions: payload => dispatch(updateRoleTestSamplePermissions(payload)),
  onUpdateRoleTestProfilePermissions: payload => dispatch(updateRoleTestProfilePermissions(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps
)(withRouter(withTranslation(["rolePage", "common"])(TabTestSetting)))
