import React, { Component } from "react"
import PropTypes, { bool } from "prop-types"
import { connect } from "react-redux"

// //Import Scrollbar
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"
import classNames from "classnames"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { insertSpaces, isEmptyValues } from "helpers/utilities"
import { Button } from "reactstrap"
import { CopyrightFooter } from "components/Common"

var clicks = 0;
var timer, timeout = 350;

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
    this.state = {
      itemActive: null,
      reportReady: false
    };
  }

  componentDidMount() {
    // this.itemStateHandler()
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
    if (this.props.menus !== prevProps.menus) {
      this.initMenu()
    }
    if (this.state.reportReady != prevState.reportReady) {
      if (this.state.reportReady == true) {
        if (this.props.location.pathname.startsWith("/Reports/")) {
          let reportId = this.props.location.pathname.replace("/Reports/", "")
          reportId = reportId.split('/')
          let parent = document.querySelector('[reportname="pagecodelv3_Statistic"]')
          parent?.click()
          if (reportId.length > 0) {
            try {
              parent.className = "has-arrow mm-active"
              parent.nextElementSibling.className = "sub-menu mm-collapse mm-show"
              parent.nextElementSibling.style.height = "unset"
            } catch (error) {
              console.log(error);
            }
            document.querySelector(`[reportnamelv4="pagecodelv4Report${reportId[0]}"]`)?.click()
          }
        }
      }
    }
  }

  // Use ComponentDidMount and ComponentDidUpdate method symultaniouslys
  initMenu() {
    const ul = document.getElementById("side-menu")
    if (isEmptyValues(ul)) return
    new MetisMenu("#side-menu")

    let pathName = this.props.location.pathname

    // console.log(this.props.location)
    if (pathName === "/403" || pathName === "/404") {
      pathName = this.props.location.state?.from?.pathname || ""
    }

    const urlIdx = pathName.indexOf("/", 1)
    pathName = pathName.substring(0, urlIdx >= 0 ? urlIdx : pathName.length)

    let matchingMenuItem = null
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (items[i].pathname.length > 1 && pathName === items[i].pathname) {
        if (![...items[i].classList].includes('a-menu-quicklink')) {
          matchingMenuItem = items[i]
          break
        }
      }
    }

    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  renderMenu = () => {
    let menusTMP = this.props.menus || [];
    let quickMenus = menusTMP.filter(x => x.icon.includes('_quick')) || []
    if (quickMenus.length > 0) {
      const quick = {
        "pageCode": "_QuickLink",
        "parent": "_OneLIS",
        "level": 2,
        "hasChildren": true,
        "icon": "bx bxs-paper-plane",
        "color": "#ffc107"
      }
      if (menusTMP.findIndex(x => x.pageCode == quick.pageCode && x.parent == quick.parent) < 0) {
        menusTMP.unshift(quick)
      }
      for (let index = 0; index < quickMenus.length; index++) {
        const element = { ...quickMenus[index] };
        element.parent = quick.pageCode
        element.level = 3
        if (menusTMP.findIndex(x => x.pageCode == element.pageCode && x.parent == element.parent) < 0) {
          menusTMP.push(element)
        }
      }
    }
    return (
      <ul className="metismenu list-unstyled" id="side-menu" style={{ flex: 1 }}>
        {this.renderLevel2(menusTMP.filter(_menu => _menu.level === 2))}

        <li className="menu-title">
          <div className="separator"></div>
        </li>
        <li>
          <a href="http://help.iolis.solutions" target="_blank" rel="noreferrer">
            <i className="bx bx-help-circle"></i>
            <span>{this.props.t("Support Center")}</span>
          </a>
        </li>
      </ul>
    )
  }

  renderLevel2 = menus => {
    const { menus: rawMenus, t } = this.props
    return menus.map((_menu, _idx) => {
      const { pageCode } = _menu
      const pageCodeString = pageCode.substring(1)
      const displayName = insertSpaces(pageCodeString)

      return (
        <React.Fragment key={_idx}>
          <li key={_idx} className="menu-title">{t(displayName)}</li>
          {this.renderLevel3(
            rawMenus.filter(
              _item => _item.parent === pageCode && _item.level === 3
            )
          )}
        </React.Fragment>
      )
    })
  }

  GetIconBadge = (icon) => {
    let icons = icon?.split(" ") || [];
    const item = icons.find(x => !x.includes('__') && x.includes('_'))
    return item || '';
  }
  CheckBadge = (icon) => {
    switch (icon) {
      case "_new":
        return true
      default:
        return false
    }
  }
  GetBadge = (icon, t) => {
    switch (icon) {
      case "_new":
        return t("New")
      default:
        return ""
    }
  }
  setReportReady(pageCode) {
    if (this.state.reportReady == false)
      if (pageCode == "_Statistic") this.setState({ reportReady: true })
  }
  renderLevel3 = menus => {
    const { menus: rawMenus, t } = this.props
    return menus.map((_menu, _idx) => {
      const { pageCode, hasChildren, icon, displayOrder } = _menu
      let pageCodeString = pageCode.substring(1)
      const displayName = insertSpaces(pageCodeString)
      const iconBadge = this.GetIconBadge(icon);
      const checkBadge = this.CheckBadge(iconBadge);
      const badge = this.GetBadge(iconBadge, t);
      let menuLv4 = rawMenus.filter(
        _item => _item.parent === pageCode && _item.level === 4
      )
      if (!icon.includes('_quick') && hasChildren) {
        pageCodeString = '#'
      }
      let linkForce = false;
      if (menuLv4 && menuLv4.length > 0) {
        let oder0 = menuLv4.find(x => x.displayOrder == 0)
        if (oder0) {
          pageCodeString = oder0.pageCode.substring(1)
          linkForce = true
        }
      }
      if (hasChildren)
        return (
          <li key={_idx} className={(displayOrder == -1 || displayOrder == 0) ? 'd-none' : ''}>
            {icon.includes('_quick') ?
              <Link
                to={`/${pageCodeString}`} className={hasChildren ? `has-arrow a-menu-quicklink` : "a-menu-quicklink"}>
                <div className="menu-quicklink">
                  <i style={{ color: '#fff' }} className={icon} />
                  <span>{t(displayName)}</span>
                </div>
              </Link>
              :
              <Link
                //_Statistic
                {...(pageCode == '_Statistic' && { reportname: `pagecodelv3` + pageCode })}
                to={`/#`} className={checkBadge ? "" : "has-arrow"}
                onClick={() => {
                  if (linkForce)
                    this.props.history.push(`/${pageCodeString}`)
                }}
              >
                {checkBadge &&
                  <span className="badge rounded-pill bg-success float-end">{badge}</span>
                }
                <i className={icon} />
                <span>{t(displayName)}</span>
                {this.setReportReady(pageCode)}
              </Link>
            }
            <ul className="sub-menu" aria-expanded="false">
              {this.renderLevel4(menuLv4, pageCode)}
            </ul>
          </li>
        )
      else
        return (
          <li key={_idx} className={(displayOrder == -1 || displayOrder == 0) ? 'd-none' : ''}>
            {icon.includes('_quick') ?
              <Link
                to={`/${pageCodeString}`} className={hasChildren ? `has-arrow a-menu-quicklink` : "a-menu-quicklink"}>
                <div className="menu-quicklink">
                  <i style={{ color: '#fff' }} className={icon} />
                  <span>{t(displayName)}</span>
                </div>
              </Link>
              :
              <Link to={`/${pageCodeString}`} className={hasChildren ? `has-arrow` : ""}>
                <i className={icon} />
                <span>{t(displayName)}</span>
              </Link>
            }
          </li>
        )
    })
  }

  handleItemClick = (pageCodeString) => {
    this.setState({ itemActive: pageCodeString });
  }

  renderLevel4 = (menus, pageParent) => {
    const { t } = this.props
    return menus.map((_menu, _idx) => {
      const { itemActive } = this.state
      const { pageCode, icon, displayOrder } = _menu
      const pageCodeString = pageCode.substring(1)
      let displayName = insertSpaces(pageCodeString)
      const iconBadge = this.GetIconBadge(icon);
      const checkBadge = this.CheckBadge(iconBadge);
      const badge = this.GetBadge(iconBadge, t);
      let linkMenu = `/${pageCodeString}`
      let idReportRender = "";
      let classMenuReport = "";
      if (pageParent == "_Statistic") {
        const idReport = pageCodeString.split(':');
        linkMenu = `/${pageCodeString}`.replace(":", "/")
        if (idReport && idReport.length > 0) {
          idReportRender = idReport[1]
          const reportName = this.props.menuReports.find(x => x.inUse == true && x.id == idReport[1])
          if (reportName?.name) {
            displayName = t(reportName.name)
          } else {
            classMenuReport = "menu-report-none"
          }
        }
      }
      return (
        <li key={_idx} className={((displayOrder == -1 || displayOrder == 0) ? 'd-none' : '') + ' ' + classMenuReport}>
          <Link
            to={linkMenu}
            {...(pageParent == '_Statistic' && { reportnamelv4: (`pagecodelv4Report`) + (idReportRender != "" ? idReportRender : pageCode) })}
            className={classNames(
              { active: itemActive === pageCodeString },
              "d-flex",
              "align-items-center"
            )}
            onClick={() => this.handleItemClick(pageCodeString)}
          >
            {checkBadge &&
              <span className="badge rounded-pill bg-success float-end">{badge}</span>
            }
            {t(displayName)}
          </Link>
        </li>
      )
    })
  }

  doubleClick = (e) => {
    console.log('doubleClick');
  }
  // doubleClick = function (e) {
  //   console.log('doubleClick');
  // }

  tripleClick = () => {
    console.log('tripleClick');
  }
  // tripleClick = function (e) {
  //   console.log('tripleClick');
  // }

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" style={{ maxHeight: "100%" }} ref={this.refDiv}>
          <div id="sidebar-menu" className="h-100 d-flex flex-column">
            {this.renderMenu()}
            {process.env.REACT_APP_ONELIS_VERSION !== undefined &&
              <div className="d-flex align-items-end align-self-center text-center version-release"
                style={{ flex: 1 }}
              >
                <span style={{ color: '#a6b0cf', userSelect: 'none' }} id="branchInfo" onClick={(e) => {
                  console.log(685352);
                  clearTimeout(timer);
                  clicks++;
                  var evt = e;
                  // this.tripleClick
                  timer = setTimeout(function () {
                    if (clicks == 3) {
                      localStorage.clear()
                    }
                    clicks = 0;
                  }, timeout);
                }}>{`${process.env.REACT_APP_ONELIS_VERSION.toUpperCase().replace("/", " ")}`} {process.env.REACT_APP_GIT_SHA}</span>
              </div>
            }
            <div className="d-flex align-items-end align-self-center text-center version-release"
              style={{ flex: 1 }}
            >
              <span style={{ color: 'transparent' }}>&nbsp;</span>
            </div>
            <div style={{ color: "#a6b0cf", alignSelf: 'center' }}>
              © {new Date().getFullYear()} IOLIS Solutions
            </div>
          </div>
        </SimpleBar>
        {/* {this.initMenu()} */}
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  menus: PropTypes.array.isRequired,
}

SidebarContent.defaultProps = {
  menus: [],
  menuReports: [],
}

const mapStateToProps = ({ Authorization, report }) => {
  return {
    menus: Authorization.menus,
    menuReports: report.menuReports,
  }
}

export default connect(
  mapStateToProps,
  {}
)(
  withRouter(withTranslation(["sidebar", "common"])(React.memo(SidebarContent)))
)
