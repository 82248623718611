import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import CustomSID from "../CustomSID";

const CustomSIDHover = ({
  data,
  indexParent,
  emergency = false,
  placement = "right",
  showTooltip = true,
  patientDetail
}) => {

  const [sampleIds, setSampleIds] = useState([])
  const [sampleDistinct, setSampleDistinct] = useState([]);
  useEffect(() => {
    let sids = (data != '' && data != null) ? JSON.parse(data) : []
    setSampleIds(sids)
    const sid = sids.map(x => `${x.SID}${x.SubSID ? ` - ${x.SubSID}` : ''}`.trim())
    const sidDistinct = sid.filter((x, index) => !sid.includes(x, index + 1)) || []
    setSampleDistinct(sidDistinct)
  }, [data])

  return (
    <>
      <div className="floating-text-field">
        {
          sampleDistinct.map((item, index) => {
            let children = sampleIds.filter(x => `${x.SID}${x.SubSID ? ` - ${x.SubSID}` : ''}` == `${item}`)
            return <React.Fragment key={index}>
              <CustomSID
                key={`${index}`}
                keyToolTip={`tooltipsid${indexParent}${index}`}
                isToolTip={true}
                toolTip={children.map((x, indexChild) =>
                  <React.Fragment key={indexChild}>
                    <span key={indexChild}>
                      {`${x.SampleTypeName} - ${x.SampleType}`}
                    </span>
                    <br />
                  </React.Fragment>)}
                sid={item}
                emergency={patientDetail?.emergency}
              />
            </React.Fragment>
          })
        }
      </div>
    </>
  )
}

CustomSIDHover.propTypes = {
  data: PropTypes.string,
  indexParent: PropTypes.number
}

CustomSIDHover.defaultProps = {
  data: '',
  indexParent: 1
}

export default CustomSIDHover
