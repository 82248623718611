import { CustomBootstrapInlineTable } from "components/Common"
import { convertDateFormat, getFirstLetterOfEachWords } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { ModalBody } from "reactstrap"
// import ReactJson from 'react-json-view'

const CustomResultAuditLogTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    loading,
    updatedTime,
    onRowClick,
    module,
    ...rest
}) => {
    const [testResultHistory, setTestResultHistory] = useState([])
    const [dataDisplay, setDataDisplay] = useState('');
    const [isJson, setIsJson] = useState(false);

    useEffect(() => {
        setTestResultHistory(data)
    }, [data])

    const handleParseJSON = (msg) => {
        let tmp = '';
        try {
            tmp = msg ? JSON.parse(msg) : ''
            setIsJson(true)
        } catch (e) {
            tmp = msg || ''
            setIsJson(false)
        }
        // setDataDisplay(tmp)
        return tmp
    }

    useEffect(() => {
        handleParseJSON()
    }, [data])

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            headerStyle: { width: "50px" },
            formatter: (cellContent, user, index) => {
                return index + 1
            },
        },
        {
            dataField: "identifier",
            text: t("Id"),
            style: { width: "100px" },
            formatter: (cellContent, user, index) => {
                return <span>{cellContent.split(':')[2]}</span>
            },
        },
        {
            dataField: "timeStamp",
            text: t("testRequestPage:Timestamp"),
            style: { width: "180px" },
            formatter: (cellContent, code, index) => (
                <span>{convertDateFormat(cellContent, "YYYY-MM-DD HH:mm:ss")}</span>
            ),
        },
        {
            dataField: "userName",
            text: t("common:User"),
            style: { width: "180px" },
            formatter: (cellContent, user, index) => {
                return <Link to={`/User/${user.userId}/view?tab=1`} target="_blank">{cellContent}</Link>
            },
        },
        // {
        //     dataField: "userName",
        //     text: t("User"),
        //     style: { width: "80px" },
        //     formatter: (cellContent, user, index) => {
        //         return <span>{getFirstLetterOfEachWords(cellContent)}</span>
        //     },
        // },
        {
            dataField: "",
            text: t("common:Module"),
            style: { width: "150px" },
            formatter: (cellContent, user, index) => {
                return <span className="d-flex badge-profile">{t(module || 'Request')}</span>
            },
        },
        {
            dataField: "payload",
            text: t("testRequestPage:Message"),
            // headerStyle: { maxWidth: "400px" },
            // style: { maxWidth: 300 },
            formatter: (cellContent, user, index) => (
                <div
                    onClick={e => {
                        onRowClick(e, user)
                    }}
                    style={{
                        // width: "500px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color: "blue",
                    }}
                >{cellContent}</div>
            ),
        },
        {
            dataField: "action",
            text: t("common:Action"),
            headerStyle: { paddingLeft: "25px", width:'100px', minWidth:'100px', maxWidth:'100px', },
            style: { paddingLeft: "25px", width:'100px', minWidth:'100px', maxWidth:'100px', },
            formatter: (cellContent, user, index) => {
                return <span className="d-flex badge-test">{t(`common:${cellContent}`)}</span>
            },
        },
    ]

    return (
        <div className="table-result-auditlog">
            <CustomBootstrapInlineTable
                columns={columns}
                data={testResultHistory}
                isScrollable
                updatedTime={updatedTime}
                isEnableLoadInfo={true}
                searchText={""}
                loading={loading}
                keyField={"id"}
                {...rest}
            />
        </div>
    )
}

export default withTranslation(["testRequestPage", "common"])(CustomResultAuditLogTable)
