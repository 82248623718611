import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import {
  CustomAvInput,
  CustomBootstrapTable,
  InlineAction,
} from "components/Common"
import { convertDateFormat, indexCalculator } from "helpers/utilities"

import FilterForm from "./FilterForm"
import BadgeItem from "../../../components/Common/BadgeItem"
//i18n
import { withTranslation } from "react-i18next"
import { ModuleIds, BadgeParameterType } from "constant"
import Editable from "react-bootstrap-editable"
import { AvForm } from "availity-reactstrap-validation"

const RESOURCE = ModuleIds.Parameter

const ParameterTable = ({
  onSelect,
  onSelectAll,
  parameters,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  loading,
  onLengthChange,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    type: [],
    isSystem: "",
  })

  const searchQuery = useSelector(state => state.parameter.searchQuery)
  const onResetHandler = () => {
    const initModel = {
      search: "",
      type: [],
      isSystem: "",
    }
    let x = document.getElementsByName("type")
    for (let i = 0; i < x.length; i++) {
      x[i].checked = false
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(parameters)
  }, [parameters])

  const columns = [
    {
      dataField: "SEQ",
      text: "#",
      formatter: (cellContent, testRequest, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, user, index) => {
        return cellContent
      },
    },

    {
      dataField: "parameterKey",
      text: t("Code"),
      sort: true,
      formatter: (cellContent, user, index, type) => {
        let badge = BadgeParameterType.filter(p => p.key === user.type)
        if (badge.length > 0) badge = badge[0]
        else badge = {}
        const color = badge?.color
        const classname = badge?.classname
        const key = badge?.key
        return (
          <BadgeItem
            message={cellContent}
            label={key}
            classname={classname}
            color={color}
            Perfix=" "
          />
        )
      },
    },

    {
      dataField: "name",
      text: t("Name"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return <Link to={`/Parameter/${user.id}/view?tab=1`}>{user.name}</Link>
      },
    },
    {
      dataField: "description",
      text: t("Description"),
      sort: true,
    },
    {
      dataField: "defaultValue",
      text: t("Default Value"),
      sort: true,
    },
    {
      dataField: "length",
      text: t("Length"),
      sort: true,
      style: { maxWidth: "100px" },
      align: "-webkit-center",
      headerAlign: "-webkit-center",
      formatter: (cellContent, item, index) => (
        <div
          onClick={e => {
            document.getElementById(`length-${index}`).style.display = ""
            document.getElementById(`length-${index}`).focus()
            document.getElementById(`length-text-${index}`).style.display =
              "none"
          }}
        >
          <a href="#" id={`length-text-${index}`}>
            {cellContent}
          </a>
          <AvForm>
            <CustomAvInput
              style={{ display: "none" }}
              id={`length-${index}`}
              name={`length`}
              value={item.length || ""}
              type="number"
              min={0}
              onBlur={e => {
                if (e.target.value != item.length) {
                  onLengthChange(item, e.target.value)
                }
                document.getElementById(`length-${index}`).style.display =
                  "none"
                document.getElementById(`length-text-${index}`).style.display =
                  ""
              }}
            />
          </AvForm>
        </div>
      ),
    },
    {
      dataField: "isSystem",
      text: t("Is System"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return cellContent ? (
          <i className="bx bx-key font-size-22" style={{ color: "#4463A6" }} />
        ) : (
          ""
        )
      },
    },
    {
      dataField: "createdByName",
      text: t("Created By"),
      sort: true,
      formatter: (cellContent, param, index) => {
        return (
          <Link to={`/User/${param.createdBy}/view?tab=1`} target="_blank">
            {param.createdByName}
          </Link>
        )
      },
    },
    {
      dataField: "createdDate",
      text: t("Created Date"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return convertDateFormat(cellContent)
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, param, index) => (
        <InlineAction
          hiddenDelete={param.isSystem}
          resource={RESOURCE}
          onEdit={e => onEdit(e, param.id)}
          onDelete={e => onDelete(e, param)}
        />
      ),
    },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      filterForm={() => <FilterForm model={model} />}
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
      keyField="id"
    />
  )
}

export default withTranslation(["common"])(ParameterTable)
