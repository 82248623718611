import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_INSTRUMENT_ENDPOINT}/results`
const BASE_API_LA_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/results`

// get all update result
//GET_UPDATERESULTS_LIST
const getAllUpdateResult = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

// update result by id method
//UPDATE_UPDATERESULT
const updateUpdateResultById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

const updateResultSID = req => {
    return put(`${BASE_API_URL}/sid`, req)
}

// delete result by id method
const deleteUpdateResult = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))
    return put(`${BASE_API_URL}/deletes?${ids}`)
}

//UPDATE_RESULTS_SAMPLEID
const updatePublishedResult = payload => {
    let q = spreadSearchQuery(payload)
    return put(`${BASE_API_URL}/events?${q}`)
}


// get all update result Id
const getIdUpdateResult = payload => {
    const { requestDate, status, id } = payload;
    let ids = id.map(_id => `id=${_id}`).join('&');
    // let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/updates?${ids}&status=${status}&requestDate=${requestDate}`)
}


// delete result by id method
const updateStatusUpdateResult = payload => {
    let ids = ""
    const { status } = payload;
    payload.ids.forEach(_id => (ids += `id=${_id}&`))
    return put(`${BASE_API_URL}/updates?${ids}&status=${status}`)
}

const GetCollectionTime = payload => {
    let ids = ""
    const { id } = payload;
    payload.ids.forEach(_id => (ids += `ids=${_id}&`))
    return get(`${BASE_API_LA_URL}/GetCollectionTime/${id}?${ids}`)
}


export {
    getAllUpdateResult,

    updateUpdateResultById,
    deleteUpdateResult,
    updateResultSID,

    updatePublishedResult,
    getIdUpdateResult,
    updateStatusUpdateResult,
    GetCollectionTime,
}
