import {
    ConfirmModal2,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds } from "constant"
import { convertDateFormat, GetSelectedRole, getUrlParamByKey, getUrlParams, insertUrlParam, selectCheckboxHandler } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getWorkLists, getWorkListsExport, getWorkListsSuccess, inValidWorkLists, updateWorkLists, validWorkLists } from "store/actions"
import HeaderButtons from "./HeaderButtons"
import UpdateModal from "./Modal/UpdateModal"
import WorkListTable from "./WorkListTable"
import ReactExport from "react-export-excel"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const RESOURCE = ModuleIds.WorkList

const initModel = {
    dateType: 1,
    testCode: "",
    fromDate: new Date(),
    toDate: new Date(),
    fromSampleId: "",
    toSampleId: "",
}
const WorkList = ({
    workLists,
    paging,
    onGetWorkLists,
    loadingData,
    updateDataTime,
    onUpdateWorkLists,
    onValidWorkLists,
    onInValidWorkLists,
    onEmptyWorkLists,
    onGetWorkListsExport,
    loadingDataExport,
    t,
}) => {
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const [model, setModel] = useState(initModel)
    const [modal, setModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [isValid, setIsValid] = useState(false);
    const [warningModal, setWarningModal] = useState(false)
    const [rows, setRows] = useState([])
    const [dataExport, setDataExport] = useState([])
    useEffect(() => {
        onEmptyWorkLists({ data: [] })
    }, [])
    useEffect(() => {
        setLoadingRefresh(loadingData)
    }, [loadingData])

    const GetWorkLists = (payload, callback) => {
        insertUrlParam({ page: payload.page, size: payload.size })
        onGetWorkLists(payload, callback)
    }

    const fetchWorkLists = () => {
        GetWorkLists({ page: 1, ...model })
    }

    const onSearch = () => {
        GetData(model)
    }

    const onSizePerPageChange = size => {
        GetWorkLists({ page: 1, size, ...model })
    }

    const onPageChange = page => {
        GetWorkLists({ page, ...model })
    }

    const GetData = (model) => {
        if (model.dateType != '' && model.fromDate && model.toDate && model.testCode != '') {
            let params = getUrlParams();
            let paramPage = getUrlParamByKey("page");
            if (paramPage) {
                GetWorkLists({ ...params, ...model })
            } else {
                fetchWorkLists()
            }
        }
    }

    useEffect(() => {
        if (dataExport.length > 0) {
            document.getElementById("btn-export-worklist").click()
        }
    }, [dataExport])

    const GetDataExport = (model) => {
        if (model.dateType != '' && model.fromDate && model.toDate && model.testCode != '') {
            let params = getUrlParams();
            onGetWorkListsExport({ ...params, ...model, page: 1, size: 0 }, (res) => {
                try {
                    let data = res.map((row, index) => ({
                        "STT": index + 1,
                        "SID": row.sid,
                        "Tên Bệnh Nhân": row.fullName,
                        "Xét Nghiệm": row.testName,
                        "Kết Quả": row.result,
                        "CSBT": row.normalRange,
                        "Ghi Chú": row.remark,
                        "Đã Duyệt": row.validatedBy != null ? "TRUE" : "FALSE",
                        "Người Duyệt": convertDateFormat(row.validatedDate, "YYYY-MM-DD"),
                        "Người Tạo": row.createdByName,
                    }))
                    setDataExport(data)
                } catch (error) {
                    console.log(error);
                }
            })
        }
    }

    const afterUpdate = () => {
        setConfirmModal(false)
        setModal(false)
        GetData(model)
    }
    const handleSubmit = (values) => {
        values.fromDate = convertDateFormat(values?.fromDate || new Date(), "YYYY-MM-DD HH:mm")
        values.toDate = convertDateFormat(values?.toDate || new Date(), "YYYY-MM-DD HH:mm")
        if (values.testName)
            delete values.testName
        onUpdateWorkLists({
            filter: values,
            selectedRole: GetSelectedRole(),
            newResult: values?.newResult,
            newResultText: values?.newResultText,
            id: rows.map(x => x.id),
            callback: afterUpdate
        })
    }

    const handleValidSubmit = () => {
        let values = model
        values.fromDate = convertDateFormat(values?.fromDate || new Date(), "YYYY-MM-DD HH:mm")
        values.toDate = convertDateFormat(values?.toDate || new Date(), "YYYY-MM-DD HH:mm")
        if (values.testName)
            delete values.testName
        if (isValid) {
            onValidWorkLists({
                filter: model,
                id: rows.map(x => x.id),
                callback: afterUpdate
            })
        }
        else {
            onInValidWorkLists({
                filter: model,
                id: rows.map(x => x.id),
                callback: afterUpdate
            })
        }
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
    }

    const onExport = () => {
        GetDataExport(model)
    }

    const filterColumns = data => {
        data.map(item => {
            if (rows.find(elem => elem.id == item.id) != null) {
                item.selected = 1
            } else {
                item.selected = ""
            }
        })
        return [
            "STT",
            "SID",
            "Tên Bệnh Nhân",
            "Xét Nghiệm",
            "Kết Quả",
            "CSBT",
            "Ghi Chú",
            "Đã Duyệt",
            "Người Duyệt",
            "T.Gian Duyệt",
            "Người Tạo"
        ]
        // return [
        //     "STT",
        //     "SID",
        //     "Patient Name",
        //     "Test Name",
        //     "Result",
        //     "Normal Range",
        //     "Remark",
        //     "Valid",
        //     "User Valid",
        //     "Valid Time",
        //     "User Input"
        // ]
    }

    return (
        <React.Fragment>
            <div className="d-none">
                <ExcelFile
                    filename={"WorkList"}
                    element={
                        <CustomButton id='btn-export-worklist' color="primary" outline
                            onClick={(e) => {

                            }}
                        >
                            {t("common:Download")}
                        </CustomButton>
                    }
                >
                    <ExcelSheet data={dataExport} name="Test">
                        {dataExport?.length > 0 &&
                            filterColumns(dataExport).map((col, _idx) => {
                                return (
                                    <ExcelColumn key={_idx} label={col} value={col} />
                                )
                            })}
                    </ExcelSheet>
                </ExcelFile>
            </div>
            <TitleAndTable
                table={() => (
                    <WorkListTable
                        loadingDataExport={loadingDataExport}
                        workLists={workLists}
                        onSearch={onSearch}
                        onExport={onExport}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        loading={loadingData}
                        updatedTime={updateDataTime}
                        onChangeModel={(val) => {
                            setModel(val);
                        }}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        buttons={() => (
                            <HeaderButtons
                                rows={rows}
                                resource={RESOURCE}
                                loadingRefresh={loadingRefresh}
                                onRefresh={() => {
                                    GetData(model)
                                }}
                                onClick={() => {
                                    if (rows.length > 0) {
                                        setModal(true)
                                    }
                                    else {
                                        setWarningModal(true)
                                    }
                                }}
                                onValid={() => {
                                    if (rows.length > 0) {
                                        setIsValid(true)
                                        setConfirmModal(true)
                                    }
                                    else {
                                        setWarningModal(true)
                                    }
                                }}
                                onInValid={() => {
                                    if (rows.length > 0) {
                                        setIsValid(false)
                                        setConfirmModal(true)
                                    }
                                    else {
                                        setWarningModal(true)
                                    }
                                }}
                                disabled={!(model.dateType != '' && model.fromDate && model.toDate && model.testCode != '')}
                            />
                        )}
                    />
                )}
                resource={RESOURCE}
                external
            // title={t("WORK LIST")}
            // subtitle={t("WORK LIST")}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            {modal &&
                <UpdateModal
                    resource={RESOURCE}
                    modal={modal}
                    toggle={() => {
                        setModal(prev => !prev)
                    }}
                    data={model}
                    isUpdate={true}
                    onValidSubmit={(values) => {
                        handleSubmit(values)
                    }}
                />
            }
            <ConfirmModal2
                isEdit={true}
                modal={confirmModal}
                title={t(`${isValid ? 'Valid' : 'Invalid'} Result`)}
                message={t(`Confirm ${isValid ? 'Valid' : 'Invalid'} Result`)}
                onToggle={() => {
                    setConfirmModal(prev => !prev)
                }}
                onConfirm={() => {
                    handleValidSubmit()
                }}
                btnConfirmText={t("common:Save")}
            />
        </React.Fragment>
    )
}

const mapStateToProps = ({ workList }) => ({
    workLists: workList.workLists,
    paging: workList.paging,
    loadingData: workList.loadingData,
    updateDataTime: workList.updatedTime,
    loadingDataExport: workList.loadingDataExport
});

const mapDispatchToProps = dispatch => ({
    onGetWorkLists: (payload) => dispatch(getWorkLists(payload)),
    onGetWorkListsExport: (payload, callback) => dispatch(getWorkListsExport(payload, callback)),
    onEmptyWorkLists: payload => dispatch(getWorkListsSuccess(payload)),
    onUpdateWorkLists: payload => dispatch(updateWorkLists(payload)),
    onValidWorkLists: payload => dispatch(validWorkLists(payload)),
    onInValidWorkLists: payload => dispatch(inValidWorkLists(payload)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["workListPage", "message"])(WorkList)));