import {
    CustomAvField,
    CustomButton,
    CustomCheckbox,
    CustomModal,
    CustomSelect
} from "components/Common";
import { Col, ModalBody, ModalFooter, Row } from "reactstrap";

//i18n
import { AvForm } from "availity-reactstrap-validation";
import { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { updateCustomRule } from "store/actions";

const RuleModal = ({
    toggle,
    t,
    fetchRules,
    onUpdateCustomRule,
    data,
    modal
}) => {
    const [ruleDetail, setRuleDetail] = useState({})
    const title = "Edit Rule"
    const formEl = useRef()

    useEffect(() => {
        setRuleDetail(data)
    }, [data])

    const closeModal = () => {
        toggle()
    }

    return (
        <CustomModal modal={modal} title={title} onToggle={closeModal} size="lg">
            <ModalBody className="requestModal">
                <AvForm
                    ref={formEl}
                    id="ruleForm"
                    onValidSubmit={(e, values) => {
                        values.id = data.id
                        onUpdateCustomRule(values, () => {
                            closeModal()
                        })
                    }}
                    model={ruleDetail}
                >
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomAvField
                                label={t("Rule Name")}
                                name="ruleName"
                                value={ruleDetail?.ruleName}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomSelect
                                name="operator"
                                value={ruleDetail?.operator}
                                valueName=""
                                code=""
                                options={[
                                    {
                                        value: "And",
                                        label: "And",
                                    },
                                    {
                                        value: "AndAlso",
                                        label: "AndAlso",
                                    },
                                    {
                                        value: "Or",
                                        label: "Or",
                                    },
                                    {
                                        value: "OrElse",
                                        label: "OrElse",
                                    },
                                ]}
                                label={t("Operator")}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomAvField
                                label={t("Success Event")}
                                name="successEvent"
                                value={ruleDetail?.successEvent}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomAvField
                                label={t("Error Message")}
                                name="errorMessage"
                                value={ruleDetail?.errorMessage}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomAvField
                                label={t("Expression")}
                                name="expression"
                                value={ruleDetail?.expression}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomAvField
                                label={t("Action")}
                                type="textarea"
                                name="actions"
                                value={ruleDetail?.actions}
                                rows="5"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomCheckbox
                                type="checkbox"
                                direction="down"
                                name="enabled"
                                label={`${t("Enabled")}`}
                                checked={ruleDetail?.enabled}
                            />
                        </Col>
                    </Row>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={closeModal}
                    data-dismiss="modal"
                    className="button-width"
                />
                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user button-width"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

RuleModal.propTypes = {
}


const mapStateToProps = ({ RuleManagement }) => ({
    rule: RuleManagement.rule || {},
})

const mapDispatchToProps = dispatch => ({
    onUpdateCustomRule: (payload, callBack) => dispatch(updateCustomRule(payload, callBack)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(["message", "common", "testConfig"])(RuleModal))