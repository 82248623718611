import { StackedColumnChart } from "components/Common"
import { getStatusInstrument } from "helpers/fakebackend_helper"
import React, { Fragment, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

const BodyMidTop = ({ }) => {
  const [statusInstrument, setStatusInstrument] = useState([])
  const [insId, setInsId] = useState([])

  const [activeTabInstrument, setactiveTabInstrument] = useState("1")

  const onFetchStatusInstrument = async () => {
    await getStatusInstrument().then(res => {
      let statusIns = []
      let value = []
      let ins = []
      res.map(item => {
        value = []
        ins = []
        item.data.map(val => {
          value.push(val.value)
          ins.push(val.insId)
        })
        statusIns.push({
          name: item.subTitle,
          data: value,
        })
      })
      setStatusInstrument(statusIns)
      setInsId(ins)
    })
  }

  useEffect(() => {
    onFetchStatusInstrument()
  }, [])

  const toggleTabInstrument = tab => {
    if (activeTabInstrument !== tab) {
      setactiveTabInstrument(tab)
    }
  }
  return (
    <Col lg="12">
      <Card className="mb-2" style={{ height: 'fit-content' }}>
        <CardHeader
          style={{
            textAlign: "center",
            background: "#fff",
          }}
        >
          <div style={{ fontSize: 15, fontWeight: 600 }}>Trạng thái máy</div>
        </CardHeader>
        <CardBody style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Nav
            pills
            className="navtab-bg"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <NavItem style={{ fontSize: 10, height: 20, textAlign: "center" }}>
              <NavLink
                style={{
                  cursor: "pointer",
                  color: activeTabInstrument === "1" ? "#fff" : "#556ee6",
                  width: 70,
                  padding: 9,
                }}
                className={classnames({
                  active: activeTabInstrument === "1",
                })}
                onClick={() => {
                  toggleTabInstrument("1")
                }}
              >
                Tất cả
              </NavLink>
            </NavItem>
            <NavItem style={{ fontSize: 10, height: 20, textAlign: "center" }}>
              <NavLink
                style={{
                  cursor: "pointer",
                  color: activeTabInstrument === "2" ? "#fff" : "#556ee6",
                  width: 70,
                  padding: 9,
                }}
                className={classnames({
                  active: activeTabInstrument === "2",
                })}
                onClick={() => {
                  toggleTabInstrument("2")
                }}
              >
                Sinh hóa
              </NavLink>
            </NavItem>
            <NavItem style={{ fontSize: 10, height: 20, textAlign: "center" }}>
              <NavLink
                style={{
                  cursor: "pointer",
                  color: activeTabInstrument === "3" ? "#fff" : "#556ee6",
                  width: 70,
                  padding: 9,
                }}
                className={classnames({
                  active: activeTabInstrument === "3",
                })}
                onClick={() => {
                  toggleTabInstrument("3")
                }}
              >
                Huyết học
              </NavLink>
            </NavItem>
            <NavItem style={{ fontSize: 10, height: 20, textAlign: "center" }}>
              <NavLink
                style={{
                  cursor: "pointer",
                  color: activeTabInstrument === "4" ? "#fff" : "#556ee6",
                  width: 70,
                  padding: 9,
                }}
                className={classnames({
                  active: activeTabInstrument === "4",
                })}
                onClick={() => {
                  toggleTabInstrument("4")
                }}
              >
                Miễn dịch
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={activeTabInstrument}
            className="p-3 text-muted"
          >
            <TabPane tabId="1">
              <Row>
                <Col sm="12" style={{ padding: 0 }}>
                  <StackedColumnChart
                    periodData={statusInstrument}
                    dataColors={[
                      "--bs-primary",
                      "--bs-warning",
                      "--bs-success",
                    ]}
                    categories={insId}
                    height={237}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12" style={{ padding: 0 }}>
                  <StackedColumnChart
                    periodData={statusInstrument}
                    dataColors={[
                      "--bs-primary",
                      "--bs-warning",
                      "--bs-success",
                    ]}
                    categories={insId}
                    height={237}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12" style={{ padding: 0 }}>
                  <StackedColumnChart
                    periodData={statusInstrument}
                    dataColors={[
                      "--bs-primary",
                      "--bs-warning",
                      "--bs-success",
                    ]}
                    categories={insId}
                    height={237}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="12" style={{ padding: 0 }}>
                  <StackedColumnChart
                    periodData={statusInstrument}
                    dataColors={[
                      "--bs-primary",
                      "--bs-warning",
                      "--bs-success",
                    ]}
                    categories={insId}
                    height={237}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Col>
  )
}

export default BodyMidTop
