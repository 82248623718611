import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import FilterForm from "./FilterForm"
import { CustomBootstrapTable, InlineAction } from "components/Common"
import IconStatus from "components/Common/IconStatus"
import { ModuleIds } from "constant"
import { indexCalculator } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const RESOURCE = ModuleIds.User

const UserTable = ({
  onSelect,
  onSelectAll,
  users,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    departmentId: "",
    role: "",
  })

  const searchQuery = useSelector(state => state.user.searchQuery)

  const onResetHandler = () => {
    const initModel = {
      search: "",
      departmentId: "",
      role: "",
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    let tmp = users || [];
    tmp = tmp.map(x => ({ ...x, roleName: x.role?.map(_r => _r.roleName).join(", ") }))
    setData(tmp)
  }, [users])

  const columns = [
    {
      dataField: "id",
      text: "#",
      style:{width:50},
      formatter: (cellContent, user, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
    },
    {
      dataField: "familyName",
      text: t("common:Full Name"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return (
          <Link to={`/User/${user.id}/view?tab=1`}>
            {cellContent} {user.givenName}
          </Link>
        )
      },
    },
    {
      dataField: "userName",
      text: t("common:User Name"),
      sort: true,
    },
    {
      dataField: "email",
      text: t("common:Email"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return <Link to={`/User/${user.id}/view?tab=1`}>{cellContent}</Link>
      },
    },
    {
      dataField: "departmentName",
      text: t("common:Department"),
      sort: true,
    },
    {
      dataField: "roleName",
      text: t("Roles"),
      sort: false,
      style: { paddingLeft: "0px", paddingRight: "0px", maxWidth: "180px" },
      headerStyle: { maxWidth: "180px", paddingLeft: "0px", paddingRight: "0px" },
    },
    {
      dataField: "status",
      text: t("common:Status"),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (cellContent, user, index) => {
        return <IconStatus
          isRound={true}
          type={cellContent}
        />
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style:{width:50},
      formatter: (cellContent, user, index) => (
        <InlineAction
          resource={RESOURCE}
          onEdit={e => onEdit(e, user.id)}
          onDelete={e => onDelete(e, user)}
        />
      ),
    },
  ]
  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={(key, type) => {
        if (key == 'familyName')
          key = 'familyName,,givenName'
        onSort(key, type)
      }}
      onRefresh={onRefresh}
      filterForm={() => <FilterForm model={model} />}
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
    />
  )
}

export default withTranslation(["userPage","common"])(UserTable)
