import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"
import {
    GET_FILES,
    GET_FILE_DETAIL,
    ADD_NEW_FILE,
    ADD_NEW_FILE_RESULT,
} from "./actionTypes"

import {
    getFilesFail,
    getFilesSuccess,
    getFileDetailFail,
    getFileDetailSuccess,
    addFileSuccess,
    addFileFail,
    setFileSearchQuery,
    getFiles,
} from './actions'

import {
    getFileById,
    getAllFiles,
    createFile,
    updateDeliveryById,
    UpdateDeliveryFileId,
    createFileResult,
} from "helpers/app-backend"
import { ModuleIds } from "constant"
import { showToast } from "components/Common"
import { UpdateResultFileId } from "helpers/app-backend/testResult_backend_helper"
const t = (msg, param) => i18n.t(msg, param)

function* fetchFileDetail({ id }) {
    try {
        const response = yield call(getFileById, id)
        yield put(getFileDetailSuccess(response))
    } catch (error) {
        yield put(getFileDetailFail(error))
    }
}

function* fetchFiles({ payload }) {
    try {
        const searchQuery = yield select(state => state.file.searchQuery)
        payload = { ...searchQuery, ...payload }

        const response = yield call(getAllFiles, payload)
        yield put(getFilesSuccess(response))

        yield put(setFileSearchQuery(payload))
    } catch (error) {
        yield put(getFilesFail(error))
    }
}
function* onAddNewFile({ payload: { data, callback } }) {
    try {
        const response = yield call(createFile, data)
        const id = response?.id
        const deliveryUpdate = {
            id: data.ItemValue,
            FileId: id,
        }
        switch (data.Module) {
            case ModuleIds.SampleDelivery:
                yield call(UpdateDeliveryFileId, deliveryUpdate)
                break;
            case ModuleIds.TestResult:
                yield call(UpdateResultFileId, deliveryUpdate)
                break;
            default:
                break;
        }
        yield put(addFileSuccess(id))
        showToast(
            `${t("message:CreateReportSucceeded", {
                fileId: `${t(
                )} <span class='text-decoration-underline fw-bold'>${id}</span>`,
            })}`
        )
        callback && callback(id)
    } catch (error) {
        console.log(error);
        yield put(addFileFail(error))
    }
}

function* onAddNewFileResult({ payload: { data, callback } }) {
    try {
        const response = yield call(createFileResult, data.data, data.params)
        const id = response?.id
        if (id) {
            const deliveryUpdate = {
                id: data.data.ResultId,
                FileId: id,
            }
            yield call(UpdateResultFileId, deliveryUpdate)
            yield put(addFileSuccess(id))
        }
        showToast(
            `${t("message:CreateReportSucceeded", {
                fileId: `${t(
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )
        callback && callback(id)
    } catch (error) {
        console.log(error);
        yield put(addFileFail(error))
    }
}

function* fileSaga() {
    yield takeEvery(GET_FILE_DETAIL, fetchFileDetail)
    yield takeEvery(GET_FILES, fetchFiles)
    yield takeEvery(ADD_NEW_FILE, onAddNewFile)
    yield takeEvery(ADD_NEW_FILE_RESULT, onAddNewFileResult)
}

export default fileSaga