import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Row, TabContent, TabPane } from "reactstrap"
import TreeViewBar_Insurance from "./TreeViewBar_Insurance"
import { CustomNav, CustomNavLink } from "components/Common"
import InsuranceConfigs from "./Settings"

const Insurance = ({
  t, connectors,
}) => {

  const [currentTab, setCurrentTab] = useState('1')
  const [scale, setScale] = useState(false)
  const scaleScreen = () => {
    setScale(!scale)
  }
  return (
    <React.Fragment>
      <Row style={{ height: "calc(100% - 45px)", overflow: 'hidden', backgroundColor: '#fff', position: 'relative' }}>
        <div className={`col-md-3 col-lg-2 p-0 ${(scale || connectors.length <= 2) ? 'TATSettingLeft' : ''}`} style={{ maxHeight: '100%', overflowY: "auto", position: 'relative' }} >
          <TreeViewBar_Insurance
            onChange={val => {
              //setItemActive(val)
            }}
          />
        </div>
        <div className={`h-100 col-md-9 col-lg-10 ${(scale || connectors.length <= 2) ? 'TATSettingRight' : ''}`}>
          <div className={`resize-button-tat ${(connectors.length <= 0) ? 'd-none' : ''}`}
            onClick={() => scaleScreen()}
          >
            <i className={`fa fa-angle-double-right ${(scale || connectors.length <= 2) ? 'resize-button-tat-icon' : ''}`}></i>
          </div>
          <div className="rule-management h-100 tat-setting-content">
            <CustomNav
              onToggle={e => {
                setCurrentTab(e);
              }}
              defaultTab={currentTab}
              tabs
              className="nav-tabs-custom"
              tabContents={customActiveTab => (
                <TabContent
                  activeTab={customActiveTab}
                  className="py-3 text-muted"
                >
                  <TabPane tabId="1">
                    <InsuranceConfigs t={t} />
                  </TabPane>
                </TabContent>
              )}
            >
              <CustomNavLink tabId="1">
                <span className="">{t("common:Settings")}</span>
              </CustomNavLink>
            </CustomNav>
          </div >
        </div >
      </Row >
    </React.Fragment >
  )
}

const mapStateToProps = ({ insurance }) => ({
  connectors: insurance.connectors || [],
})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["message, common, testConfig"])(Insurance)))