import { del, get, post, put } from "../api_helper2"
import { post as postWithoutError } from "../api_helper_withoutError"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/rules`
const BASE_RULE_API_URL = `${process.env.REACT_APP_RULE_MANAGEMENT_ENDPOINT}/rules`

const updateAdditionalInfo = (payload) => {
    return put(`${BASE_RULE_API_URL}/UpdateListAdditionalInfo`, payload)
}

const getAdditionalInfo = (payload) => {
    return post(`${BASE_RULE_API_URL}/GetListAdditionalInfo`, payload)
}

const updateResultTime = (payload) => {
    return put(`${BASE_RULE_API_URL}/UpdateListResultTime`, payload)
}

const getResultTime = (payload) => {
    return post(`${BASE_RULE_API_URL}/GetListResultTime`, payload)
}

const updateCustomRule = (payload) => {
    return put(`${BASE_RULE_API_URL}/UpdateCustomRule`, payload)
}

const importRule = payload => {
    return post(`${BASE_RULE_API_URL}/ImportRange`, payload)
}

const updateCustomWorkflow = (payload) => {
    return put(`${BASE_RULE_API_URL}/UpdateCustomWorkflow`, payload)
}

const getCustomWorkflowDetail = ({ id }) => {
    return get(`${BASE_RULE_API_URL}/GetCustomWorkflowDetail?id=${id}`)
}

const deleteCustomWorkflow = ({ id }) => {
    return del(`${BASE_RULE_API_URL}/DeleteCustomWorkflow?id=${id}`)
}

const deleteCustomRule = ({ id }) => {
    const data = id.map(item => {
        return `id=${item}`
    })
    return del(`${BASE_RULE_API_URL}/DeleteCustomRule?${data.join('&')}`)
}

const createCustomWorkflow = (payload) => {
    return post(`${BASE_RULE_API_URL}/CreateCustomWorkflow`, payload)
}

const getCustomRuleList = ({ workflowId, search }) => {
    return get(`${BASE_RULE_API_URL}/GetCustomRuleList?workflowId=${workflowId}&search=${search || ''}`)
}

const getCustomWorkflowList = payload => {
    return get(`${BASE_RULE_API_URL}/GetCustomWorkflowList`, payload)
}

const getRuleNormalRange = payload => {
    return post(`${BASE_RULE_API_URL}/GetListNormalRange`, payload)
}

const getRulePatientRange = payload => {
    return post(`${BASE_RULE_API_URL}/GetListPatientRange`, payload)
}

const getRuleInstrumentRange = payload => {
    return post(`${BASE_RULE_API_URL}/GetListInstrumentRange`, payload)
}

const getRuleInstrumentAlert = payload => {
    return get(`${BASE_API_URL}/GetListRule104`)
}

const getRuleRulerBase = payload => {
    return get(`${BASE_API_URL}/GetListRule105`)
}

const UpdateListNormalRange = payload => {
    return put(`${BASE_RULE_API_URL}/UpdateListNormalRange`, payload)
}

const UpdateListPatientRange = payload => {
    return put(`${BASE_RULE_API_URL}/UpdateListPatientRange`, payload)
}

const UpdateListInstrumentRange = payload => {
    return put(`${BASE_RULE_API_URL}/UpdateListInstrumentRange`, payload)
}

const updateRule104 = payload => {
    return put(`${BASE_API_URL}/UpdateListRule104`, payload)
}

const updateRule105 = payload => {
    return put(`${BASE_API_URL}/UpdateListRule105`, payload)
}

const getListRuleAction = payload => {
    return get(`${BASE_API_URL}/GetListRuleAction`)
}

const executeCustomRule = payload => {
    return postWithoutError(`${BASE_RULE_API_URL}/ExecuteCustomRule`, payload)
}

const ExecuteCustomRuleAdditional = payload => {
    return postWithoutError(`${BASE_RULE_API_URL}/ExecuteCustomRuleAdditional`, payload)
}

const ExecuteCustomRuleResultTime = payload => {
    return postWithoutError(`${BASE_RULE_API_URL}/ExecuteCustomRuleResultTime`, payload)
}

const executeRuleCustomWorkflow = payload => {
    return postWithoutError(`${BASE_RULE_API_URL}/ExecuteRuleCustomWorkflow`, payload)
}

const updateTATSettingRule = (payload) => {
    return put(`${BASE_RULE_API_URL}/UpdateTATSetting`, payload)
}
//GetInsuranceSetting
const getTATSetting = payload => {
    return post(`${BASE_RULE_API_URL}/GetTATSetting`, payload)
}

const getInsuranceSetting = payload => {
    return post(`${BASE_RULE_API_URL}/GetInsuranceSetting`, payload)
}

const updateInsuranceSettingRule = (payload) => {
    return put(`${BASE_RULE_API_URL}/UpdateInsuranceSetting`, payload)
}

export { ExecuteCustomRuleAdditional, ExecuteCustomRuleResultTime, UpdateListInstrumentRange, UpdateListNormalRange, UpdateListPatientRange, createCustomWorkflow, deleteCustomRule, deleteCustomWorkflow, executeCustomRule, executeRuleCustomWorkflow, getAdditionalInfo, getCustomRuleList, getCustomWorkflowDetail, getCustomWorkflowList, getInsuranceSetting, getListRuleAction, getResultTime, getRuleInstrumentAlert, getRuleInstrumentRange, getRuleNormalRange, getRulePatientRange, getRuleRulerBase, getTATSetting, importRule, updateAdditionalInfo, updateCustomRule, updateCustomWorkflow, updateInsuranceSettingRule, updateResultTime, updateRule104, updateRule105, updateTATSettingRule }

