import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Authorization from "./auth/authorization/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Login from "./auth/login/reducer"

//Parameters
import parameter from "./setting/parameter/reducer"

import generalSetting from './setting/generalSetting/reducer'
import personalSetting from './setting/personalSetting/reducer'
//Common codes
import common from "./setting/common/reducer"

/**PARTY MANAGEMENT */
//Profiles
import profile from "./party/profile/reducer"
//Parties
import party from "./party/party/reducer"
//Individuals
import individual from "./party/individual/reducer"
//Organizations
import organization from "./party/organization/reducer"
//Physicians
import physician from "./party/physician/reducer"

/**USER MANAGEMENT */
//Users
import user from "./users/user/reducer"
//Company
import company from "./users/company/reducer"
//Department
import department from "./users/department/reducer"
//Role
import role from "./users/role/reducer"
//Resource
import resource from "./users/resource/reducer"
//Test
import test from "./laboratory/tests/reducer"

import fastReport from "./fastreport/reducer"
import report from "./fastreport/reports/reducer"
/**LABORATORY */
//TESTPROFILE
import testProfile from "./laboratory/profile/reducer"; //testconfig
import testconfig from "./laboratory/testconfig/reducer"

import additionalconfig from "./laboratory/additionalconfig/reducer"
import rulebasedconfig from "./laboratory/rulebasedconfig/reducer"
//hisconnector
import hisConnector from "./connector/HISConnector/reducer"

import normalRangeConfig from "./laboratory/normalrangeconfig/reducer"
import resultTimeConfig from "./laboratory/resulttimeconfig/reducer"

//interface
import interFace from "./connector/interfaces/reducer"

//machine
import machine from "./connector/instruments/reducer"
//test request
import testRequest from "./laboratory/testRequest/reducer"

//accessionNumber
import accessionNumber from "./setting/accessionNumber/reducer"
// Delivery
import sampleDelivery from "./laboratory/sampledelivery/reducer"


//analysis-process
import updateResult from "./analysis-process/updateresults/reducer"

import validResult from "./analysis-process/validresults/reducer"

import process from "./analysis-process/processes/reducer"
import workOrder from "./analysis-process/workorders/reducer"
import qcResult from "./analysis-process/qcresult/reducer"

//test result
import testResult from "./laboratory/testResult/reducer"

import File from "./users/file/reducer"

import MsDashboard from './users/ms-dashboard/reducer'

import workList from "./laboratory/workList/reducer"

import RuleManagement from "./laboratory/ruleManagement/reducer"

import sampleTracking from './laboratory/sampletracking/reducer'

import TATSetting from './setting/TATSettings/reducer'
import userProfiles from "./users/user-profiles/reducer"

import insurance from "./connector/Insurance/reducer"
import loginHistory from "./users/login-history/reducer"
import auditLog from "./setting/auditlog/reducer"
import notification from "./users/notification/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  Authorization,
  parameter,
  common,
  profile,
  party,
  individual,
  organization,
  physician,
  user,
  company,
  department,
  role,
  resource,
  fastReport,
  test,
  testProfile,
  hisConnector,
  interFace,
  machine,
  testconfig,
  rulebasedconfig,
  normalRangeConfig,
  resultTimeConfig,
  additionalconfig,
  report,
  testRequest,
  accessionNumber,
  sampleDelivery,
  updateResult,
  validResult,
  workOrder,
  process,
  testResult,
  File,
  workList,
  MsDashboard,
  sampleTracking,
  RuleManagement,
  generalSetting,
  personalSetting,
  TATSetting,
  userProfiles,
  loginHistory,
  insurance,
  auditLog,
  notification,
  qcResult
})
export default rootReducer
