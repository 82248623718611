//search query
export const SET_TESTRESULTS_SEARCH_QUERY = "SET_TESTRESULTS_SEARCH_QUERY"
//reset search query
export const RESET_TESTRESULTS_SEARCH_QUERY = "RESET_TESTRESULTS_SEARCH_QUERY"
export const RESET_TESTRESULTS_SEARCH_QUERY_CALLBACK = "RESET_TESTRESULTS_SEARCH_QUERY_CALLBACK"

//get test results
export const GET_RESULTS = "GET_RESULTS"
export const GET_RESULTS_SUCCESS = "GET_RESULTS_SUCCESS"
export const GET_RESULTS_FAIL = "GET_RESULTS_FAIL"

export const GET_RESULTS_WITHOUT_LOADING = "GET_RESULTS_WITHOUT_LOADING"
export const GET_RESULTS_WITHOUT_LOADING_SUCCESS = "GET_RESULTS_WITHOUT_LOADING_SUCCESS"
export const GET_RESULTS_WITHOUT_LOADING_FAIL = "GET_RESULTS_WITHOUT_LOADING_FAIL"

//update result
export const UPDATE_TESTRESULTS = "UPDATE_TESTRESULTS"
export const UPDATE_TESTRESULTS_SUCCESS = "UPDATE_TESTRESULTS_SUCCESS"
export const UPDATE_TESTRESULTS_FAIL = "UPDATE_TESTRESULTS_FAIL"

//get result detail
export const GET_TESTRESULT_DETAIL = "GET_TESTRESULT_DETAIL"
export const GET_TESTRESULT_DETAIL_SUCCESS = "GET_TESTRESULT_DETAIL_SUCCESS"
export const GET_TESTRESULT_DETAIL_FAIL = "GET_TESTRESULT_DETAIL_FAIL"

//get result detail history
export const GET_TESTRESULT_HISTORY = "GET_TESTRESULT_HISTORY";
export const GET_TESTRESULT_HISTORY_SUCCESS = "GET_TESTRESULT_HISTORY_SUCCESS";
export const GET_TESTRESULT_HISTORY_FAIL = "GET_TESTRESULT_HISTORY_FAIL";

//get comment
export const GET_TESTRESULT_COMMENT = "GET_TESTRESULT_COMMMENT";
export const GET_TESTRESULT_COMMENT_SUCCESS = "GET_TESTRESULT_COMMMENT_SUCCESS";
export const GET_TESTRESULT_COMMENT_FAIL = "GET_TESTRESULT_COMMMENT_FAIL";

//get result detail
export const GET_TESTRESULT_PROFILE_DETAIL = "GET_TESTRESULT_PROFILE_DETAIL"
export const GET_TESTRESULT_PROFILE_DETAIL_SUCCESS = "GET_TESTRESULT_PROFILE_DETAIL_SUCCESS"
export const GET_TESTRESULT_PROFILE_DETAIL_FAIL = "GET_TESTRESULT_PROFILE_DETAIL_FAIL"

export const GET_TESTRESULT_TEST_DETAIL = "GET_TESTRESULT_TEST_DETAIL"
export const GET_TESTRESULT_TEST_DETAIL_SUCCESS = "GET_TESTRESULT_TEST_DETAIL_SUCCESS"
export const GET_TESTRESULT_TEST_DETAIL_FAIL = "GET_TESTRESULT_TEST_DETAIL_FAIL"

export const GET_TESTRESULT_RESULTS = "GET_TESTRESULT_RESULTS";
export const GET_TESTRESULT_RESULTS_SUCCESS = "GET_TESTRESULT_RESULTS_SUCCESS";
export const GET_TESTRESULT_RESULTS_FAIL = "GET_TESTRESULT_RESULTS_FAIL";

export const GET_TESTRESULT_TESTS_LOADING_COMPLETE = "GET_TESTRESULT_TESTS_LOADING_COMPLETE"

//UPDATE COMMENT
export const UPDATE_TESTRESULT_COMMENT = "UPDATE_TESTRESULT_COMMENT";
export const UPDATE_TESTRESULT_COMMENT_SUCCESS = "UPDATE_TESTRESULT_COMMENT_SUCCESS";
export const UPDATE_TESTRESULT_COMMENT_FAIL = "UPDATE_TESTRESULT_COMMENT_FAIL";

export const UPDATE_TESTRESULT_RESULTS = "UPDATE_TESTRESULT_RESULTS";
export const UPDATE_TESTRESULT_RESULTS_SUCCESS = "UPDATE_TESTRESULT_RESULTS_SUCCESS";
export const UPDATE_TESTRESULT_RESULTS_FAIL = "UPDATE_TESTRESULT_RESULTS_FAIL";

export const CONFIRM_TESTRESULT_RESULTS = "CONFIRM_TESTRESULT_RESULTS";
export const CONFIRM_TESTRESULT_RESULTS_SUCCESS = "CONFIRM_TESTRESULT_RESULTS_SUCCESS";
export const CONFIRM_TESTRESULT_RESULTS_FAIL = "CONFIRM_TESTRESULT_RESULTS_FAIL";

export const UNCONFIRM_TESTRESULT_RESULTS = "UNCONFIRM_TESTRESULT_RESULTS";
export const UNCONFIRM_TESTRESULT_RESULTS_SUCCESS = "UNCONFIRM_TESTRESULT_RESULTS_SUCCESS";
export const UNCONFIRM_TESTRESULT_RESULTS_FAIL = "UNCONFIRM_TESTRESULT_RESULTS_FAIL";

export const VALID_TESTRESULT_RESULTS = "VALID_TESTRESULT_RESULTS";
export const VALID_TESTRESULT_RESULTS_SUCCESS = "VALID_TESTRESULT_RESULTS_SUCCESS";
export const VALID_TESTRESULT_RESULTS_FAIL = "VALID_TESTRESULT_RESULTS_FAIL";

export const INVALID_TESTRESULT_RESULTS = "INVALID_TESTRESULT_RESULTS";
export const INVALID_TESTRESULT_RESULTS_SUCCESS = "INVALID_TESTRESULT_RESULTS_SUCCESS";
export const INVALID_TESTRESULT_RESULTS_FAIL = "INVALID_TESTRESULT_RESULTS_FAIL";

//DELETE RESULT DETAIL
export const DELETE_TESTRESULT_RESULTS = "DELETE_TESTRESULT_RESULTS";
export const DELETE_TESTRESULT_RESULTS_SUCCESS = "DELETE_TESTRESULT_RESULTS_SUCCESS";
export const DELETE_TESTRESULT_RESULTS_FAIL = "DELETE_TESTRESULT_RESULTS_FAIL";

//DELETE RESULT

export const DELETE_RESULTS = "DELETE_RESULTS"
export const DELETE_RESULTS_SUCCESS = "DELETE_RESULTS_SUCCESS"
export const DELETE_RESULTS_FAIL = "DELETE_RESULTS_FAIL"

//get result-columns settings
export const GET_RESULT_COLUMNS_CURRENT_SETTINGS = "GET_RESULT_COLUMNS_CURRENT_SETTINGS"
export const GET_RESULT_COLUMNS_CURRENT_SETTINGS_SUCCESS = "GET_RESULT_COLUMNS_CURRENT_SETTINGS_SUCCESS"
export const GET_RESULT_COLUMNS_CURRENT_SETTINGS_FAIL = "GET_RESULT_COLUMNS_CURRENT_SETTINGS_FAIL"

//get parameter result-columns settings
export const GET_PARAMETER_RESULT_COLUMNS_SETTINGS = "GET_PARAMETER_RESULT_COLUMNS_SETTINGS"
export const GET_PARAMETER_RESULT_COLUMNS_SETTINGS_SUCCESS = "GET_PARAMETER_RESULT_COLUMNS_SETTINGS_SUCCESS"
export const GET_PARAMETER_RESULT_COLUMNS_SETTINGS_FAIL = "GET_PARAMETER_RESULT_COLUMNS_SETTINGS_FAIL"

//update result-columns settings
export const UPDATE_RESULT_COLUMNS_SETTINGS = "UPDATE_RESULT_COLUMNS_SETTINGS"
export const UPDATE_RESULT_COLUMNS_SETTINGS_SUCCESS = "UPDATE_RESULT_COLUMNS_SETTINGS_SUCCESS"
export const UPDATE_RESULT_COLUMNS_SETTINGS_FAIL = "UPDATE_RESULT_COLUMNS_SETTINGS_FAIL"


export const GET_TESTPERFORM_RESULTS = "GET_TESTPERFORM_RESULTS";
export const GET_TESTPERFORM_RESULTS_SUCCESS = "GET_TESTPERFORM_RESULTS_SUCCESS";
export const GET_TESTPERFORM_RESULTS_FAIL = "GET_TESTPERFORM_RESULTS_FAIL";

export const GET_UPDATABLE_RESULTFIELD = "GET_UPDATABLE_RESULTFIELD";
export const GET_UPDATABLE_RESULTFIELD_SUCCESS = "GET_UPDATABLE_RESULTFIELD_SUCCESS";
export const GET_UPDATABLE_RESULTFIELD_FAIL = "GET_UPDATABLE_RESULTFIELD_FAIL";

export const RERUN_TESTRESULT_RESULTS = "RERUN_TESTRESULT_RESULTS";
export const RERUN_TESTRESULT_RESULTS_SUCCESS = "RERUN_TESTRESULT_RESULTS_SUCCESS";
export const RERUN_TESTRESULT_RESULTS_FAIL = "RERUN_TESTRESULT_RESULTS_FAIL";

export const GET_RESULT_COMMENT = "GET_RESULT_COMMENT";
export const GET_RESULT_COMMENT_SUCCESS = "GET_RESULT_COMMENT_SUCCESS";
export const GET_RESULT_COMMENT_FAIL = "GET_RESULT_COMMENT_FAIL";

export const GET_CATEGORY_COMMENT = "GET_CATEGORY_COMMENT";
export const GET_CATEGORY_COMMENT_SUCCESS = "GET_CATEGORY_COMMENT_SUCCESS";
export const GET_CATEGORY_COMMENT_FAIL = "GET_CATEGORY_COMMENT_FAIL";

export const UPDATE_RESULT_COMMENT = "UPDATE_RESULT_COMMENT";
export const UPDATE_RESULT_COMMENT_SUCCESS = "UPDATE_RESULT_COMMENT_SUCCESS";
export const UPDATE_RESULT_COMMENT_FAIL = "UPDATE_RESULT_COMMENT_FAIL";

export const UPDATE_CATEGORY_COMMENT = "UPDATE_CATEGORY_COMMENT";
export const UPDATE_CATEGORY_COMMENT_SUCCESS = "UPDATE_CATEGORY_COMMENT_SUCCESS";
export const UPDATE_CATEGORY_COMMENT_FAIL = "UPDATE_CATEGORY_COMMENT_FAIL";

export const RUNRULE_TESTRESULT_RESULTS = "RUNRULE_TESTRESULT_RESULTS";
export const RUNRULE_TESTRESULT_RESULTS_SUCCESS = "RUNRULE_TESTRESULT_RESULTS_SUCCESS";
export const RUNRULE_TESTRESULT_RESULTS_FAIL = "RUNRULE_TESTRESULT_RESULTS_FAIL";

export const GET_GENERAL_HISTORY_RESULT = "GET_GENERAL_HISTORY_RESULT"
export const GET_GENERAL_HISTORY_RESULT_SUCCESS = "GET_GENERAL_HISTORY_RESULT_SUCCESS"
export const GET_GENERAL_HISTORY_RESULT_FAIL = "GET_GENERAL_HISTORY_RESULT_FAIL"

export const GET_PATIENT_RESULT = "GET_PATIENT_RESULT"
export const GET_PATIENT_RESULT_SUCCESS = "GET_PATIENT_RESULT_SUCCESS"
export const GET_PATIENT_RESULT_FAIL = "GET_PATIENT_RESULT_FAIL"
export const SET_ROW_COUNT_SUCCESS = "SET_ROW_COUNT_SUCCESS"

export const GET_TESTRESULT_RESULT_AUDITLOG = "GET_TESTRESULT_RESULT_AUDITLOG"
export const GET_TESTRESULT_RESULT_AUDITLOG_SUCCESS = "GET_TESTRESULT_RESULT_AUDITLOG_SUCCESS"
export const GET_TESTRESULT_RESULT_AUDITLOG_FAIL = "GET_TESTRESULT_RESULT_AUDITLOG_FAIL"

export const GET_PARAMETER_GROUP_BY_CODE_SUCCESS = "GET_PARAMETER_GROUP_BY_CODE_SUCCESS"

export const GET_RESULT_NOTIFY = "GET_RESULT_NOTIFY"
export const GET_RESULT_NOTIFY_SUCCESS = "GET_RESULT_NOTIFY_SUCCESS"
export const GET_RESULT_NOTIFY_FAIL = "GET_RESULT_NOTIFY_FAIL"

export const CREATE_RESULT_NOTIFY = "CREATE_RESULT_NOTIFY"
export const CREATE_RESULT_NOTIFY_SUCCESS = "CREATE_RESULT_NOTIFY_SUCCESS"
export const CREATE_RESULT_NOTIFY_FAIL = "CREATE_RESULT_NOTIFY_FAIL"

export const WARNING_RULE_TESTRESULT_RESULTS = "WARNING_RULE_TESTRESULT_RESULTS";
export const WARNING_RULE_TESTRESULT_RESULTS_SUCCESS = "WARNING_RULE_TESTRESULT_RESULTS_SUCCESS";
export const WARNING_RULE_TESTRESULT_RESULTS_FAIL = "WARNING_RULE_TESTRESULT_RESULTS_FAIL";

export const GET_TEST_REQUEST_AUDITLOG = "GET_TEST_REQUEST_AUDITLOG"
export const CLEAR_TESTRESULT_DETAIL = "CLEAR_TESTRESULT_DETAIL"

export const SET_LOADING_CHANGE_RESULT_DETAIL = "SET_LOADING_CHANGE_RESULT_DETAIL"
export const SET_SCROLL_RESULT_DETAIL = "SET_SCROLL_RESULT_DETAIL"

export const APPROVED_VALID_AND_PUBLISH_RESULT = "APPROVED_VALID_AND_PUBLISH_RESULT";
export const APPROVED_VALID_AND_PUBLISH_RESULT_SUCCESS = "APPROVED_VALID_AND_PUBLISH_RESULT_SUCCESS";
export const APPROVED_VALID_AND_PUBLISH_RESULT_FAIL = "APPROVED_VALID_AND_PUBLISH_RESULT_FAIL";

export const SET_FAST_VALID = "SET_FAST_VALID"

export const GET_PATHOLOGY_RESULT = "GET_PATHOLOGY_RESULT"
export const GET_PATHOLOGY_RESULT_SUCCESS = "GET_PATHOLOGY_RESULT_SUCCESS"
export const GET_PATHOLOGY_RESULT_FAIL = "GET_PATHOLOGY_RESULT_FAIL"

export const CREATE_PATHOLOGY_RESULT = "CREATE_PATHOLOGY_RESULT"
export const CREATE_PATHOLOGY_RESULT_SUCCESS = "CREATE_PATHOLOGY_RESULT_SUCCESS"
export const CREATE_PATHOLOGY_RESULT_FAIL = "CREATE_PATHOLOGY_RESULT_FAIL"

export const UPDATE_PATHOLOGY_RESULT = "UPDATE_PATHOLOGY_RESULT"
export const UPDATE_PATHOLOGY_RESULT_SUCCESS = "UPDATE_PATHOLOGY_RESULT_SUCCESS"
export const UPDATE_PATHOLOGY_RESULT_FAIL = "UPDATE_PATHOLOGY_RESULT_FAIL"

export const UPDATE_RESULT_TEST_VENDOR = "UPDATE_RESULT_TEST_VENDOR"
export const UPDATE_RESULT_TEST_VENDOR_SUCCESS = "UPDATE_RESULT_TEST_VENDOR_SUCCESS"
export const UPDATE_RESULT_TEST_VENDOR_FAIL = "UPDATE_RESULT_TEST_VENDOR_FAIL"

export const UPDATE_RESULT_PROFILE_VENDOR = "UPDATE_RESULT_PROFILE_VENDOR"
export const UPDATE_RESULT_PROFILE_VENDOR_SUCCESS = "UPDATE_RESULT_PROFILE_VENDOR_SUCCESS"
export const UPDATE_RESULT_PROFILE_VENDOR_FAIL = "UPDATE_RESULT_PROFILE_VENDOR_FAIL"

export const SIGN_TESTRESULT_RESULTS = "SIGN_TESTRESULT_RESULTS";
export const SIGN_TESTRESULT_RESULTS_SUCCESS = "SIGN_TESTRESULT_RESULTS_SUCCESS";
export const SIGN_TESTRESULT_RESULTS_FAIL = "SIGN_TESTRESULT_RESULTS_FAIL";

export const UNSIGN_TESTRESULT_RESULTS = "UNSIGN_TESTRESULT_RESULTS";
export const UNSIGN_TESTRESULT_RESULTS_SUCCESS = "UNSIGN_TESTRESULT_RESULTS_SUCCESS";
export const UNSIGN_TESTRESULT_RESULTS_FAIL = "UNSIGN_TESTRESULT_RESULTS_FAIL";