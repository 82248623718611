//search query
export const SET_TESTREQUESTS_SEARCH_QUERY = "SET_TESTREQUESTS_SEARCH_QUERY"

//reset search query
export const RESET_TESTREQUESTS_SEARCH_QUERY = "RESET_TESTREQUESTS_SEARCH_QUERY"

//get TEST REQUEST
export const GET_REQUESTS = "GET_REQUESTS"
export const GET_REQUESTS_SUCCESS = "GET_REQUESTS_SUCCESS"
export const GET_REQUESTS_FAIL = "GET_REQUESTS_FAIL"

export const CREATE_NEW_REQUEST = "CREATE_NEW_REQUEST"
export const CREATE_NEW_REQUEST_SUCCESS = "CREATE_NEW_REQUEST_SUCCESS"
export const CREATE_NEW_REQUEST_FAIL = "CREATE_NEW_REQUEST_FAIL"

export const UPDATE_TESTREQUESTS = "UPDATE_TESTREQUESTS"
export const UPDATE_TESTREQUESTS_SUCCESS = "UPDATE_TESTREQUESTS_SUCCESS"
export const UPDATE_TESTREQUESTS_FAIL = "UPDATE_TESTREQUESTS_FAIL"

export const DELETE_REQUESTS = "DELETE_REQUESTS"
export const DELETE_REQUESTS_SUCCESS = "DELETE_REQUESTS_SUCCESS"
export const DELETE_REQUESTS_FAIL = "DELETE_REQUESTS_FAIL"

export const CANCEL_TEST_REQUEST = "CANCEL_TEST_REQUEST"
export const CANCEL_TEST_REQUEST_SUCCESS = "CANCEL_TEST_REQUEST_SUCCESS"
export const CANCEL_TEST_REQUEST_FAIL = "CANCEL_TEST_REQUEST_FAIL"

//test request general
export const GET_TESTREQUEST_DETAIL = "GET_TESTREQUEST_DETAIL"
export const GET_TESTREQUEST_DETAIL_SUCCESS = "GET_TESTREQUEST_DETAIL_SUCCESS"
export const GET_TESTREQUEST_DETAIL_FAIL = "GET_TESTREQUEST_DETAIL_FAIL"
export const EMPTY_TESTREQUEST_DETAIL = "EMPTY_TESTREQUEST_DETAIL"

//patient visit general
export const GET_TESTREQUEST_PATIENT_VISIT_DETAIL = "GET_TESTREQUEST_PATIENT_VISIT_DETAIL"
export const GET_TESTREQUEST_PATIENT_VISIT_DETAIL_SUCCESS = "GET_TESTREQUEST_PATIENT_VISIT_DETAIL_SUCCESS"
export const GET_TESTREQUEST_PATIENT_VISIT_DETAIL_FAIL = "GET_TESTREQUEST_PATIENT_VISIT_DETAIL_FAIL"

//test request test
export const DELETE_TESTREQUEST_TESTS = "DELETE_TESTREQUEST_TESTS"
export const DELETE_TESTREQUEST_TESTS_SUCCESS = "DELETE_TESTREQUEST_TESTS_SUCCESS"
export const DELETE_TESTREQUEST_TESTS_FAIL = "DELETE_TESTREQUEST_TESTS_FAIL"
export const ADD_TESTREQUEST_TEST = "ADD_TESTREQUEST_TEST"
export const ADD_TESTREQUEST_TEST_SUCCESS = "ADD_TESTREQUEST_TEST_SUCCESS"
export const ADD_TESTREQUEST_TEST_FAIL = "ADD_TESTREQUEST_TEST_FAIL"

//test request samplte
export const DELETE_TESTREQUEST_SAMPLE = "DELETE_TESTREQUEST_SAMPLE"
export const DELETE_TESTREQUEST_SAMPLE_SUCCESS = "DELETE_TESTREQUEST_SAMPLE_SUCCESS"
export const DELETE_TESTREQUEST_SAMPLE_FAIL = "DELETE_TESTREQUEST_SAMPLE_FAIL"
export const ADD_TESTREQUEST_SAMPLE = "ADD_TESTREQUEST_SAMPLE"
export const ADD_TESTREQUEST_SAMPLE_SUCCESS = "ADD_TESTREQUEST_SAMPLE_SUCCESS"
export const ADD_TESTREQUEST_SAMPLE_FAIL = "ADD_TESTREQUEST_SAMPLE_FAIL"

//get test info
export const GET_TESTREQUEST_TEST_DETAIL = "GET_TESTREQUEST_TEST_DETAIL"
export const GET_TESTREQUEST_TEST_DETAIL_SUCCESS = "GET_TESTREQUEST_TEST_DETAIL_SUCCESS"
export const GET_TESTREQUEST_TEST_DETAIL_FAIL = "GET_TESTREQUEST_TEST_DETAIL_FAIL"
export const DELETE_TESTREQUEST_TEST = "DELETE_TESTREQUEST_TEST"
export const DELETE_TESTREQUEST_TEST_SUCCESS = "DELETE_TESTREQUEST_TEST_SUCCESS"
export const DELETE_TESTREQUEST_TEST_FAIL = "DELETE_TESTREQUEST_TEST_FAIL"
export const EDIT_TESTREQUEST_TEST_SAMPLETYPE = "EDIT_TESTREQUEST_TEST_SAMPLETYPE"
export const EDIT_TESTREQUEST_TEST_SAMPLETYPE_SUCCESS = "EDIT_TESTREQUEST_TEST_SAMPLETYPE_SUCCESS"
export const EDIT_TESTREQUEST_TEST_SAMPLETYPE_FAIL = "EDIT_TESTREQUEST_TEST_SAMPLETYPE_FAIL"
export const GET_TESTREQUEST_PROFILE_DETAIL = "GET_TESTREQUEST_PROFILE_DETAIL"
export const GET_TESTREQUEST_PROFILE_DETAIL_SUCCESS = "GET_TESTREQUEST_PROFILE_DETAIL_SUCCESS"
export const GET_TESTREQUEST_PROFILE_DETAIL_FAIL = "GET_TESTREQUEST_PROFILE_DETAIL_FAIL"

export const GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL = "GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL"
export const GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_SUCCESS = "GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_SUCCESS"
export const GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_FAIL = "GET_TESTREQUEST_PATIENT_VISIT_PROFILE_DETAIL_FAIL"
//e
export const GET_TESTREQUEST_SAMPLE = "GET_TESTREQUEST_SAMPLE";
export const GET_TESTREQUEST_SAMPLE_SUCCESS = "GET_TESTREQUEST_SAMPLE_SUCCESS";
export const GET_TESTREQUEST_SAMPLE_FAIL = "GET_TESTREQUEST_SAMPLE_FAIL";

//billingTypes
export const GET_BILLING_TYPES = "GET_BILLING_TYPES"
export const GET_BILLING_TYPES_SUCCESS = "GET_BILLING_TYPES_SUCCESS"
export const GET_BILLING_TYPES_FAIL = "GET_BILLING_TYPES_FAIL"

//sample
export const SET_REQUEST_SAMPLE_COLLECTOR = "SET_REQUEST_SAMPLE_COLLECTOR"
export const SET_REQUEST_SAMPLE_SID = "SET_REQUEST_SAMPLE_SID"
export const SET_REQUEST_SAMPLE_NUMB_OF_LABELS = "SET_REQUEST_SAMPLE_NUMB_OF_LABELS"
export const SET_REQUEST_SAMPLE_COLLECT_TIME = "SET_REQUEST_SAMPLE_COLLECT_TIME"
export const SET_REQUEST_SAMPLE_QUALITY = "SET_REQUEST_SAMPLE_QUALITY"
export const SET_REQUEST_SAMPLE_RECEIVER = "SET_REQUEST_SAMPLE_RECEIVER"
export const SET_REQUEST_SAMPLE_RECEIVED_TIME = "SET_REQUEST_SAMPLE_RECEIVED_TIME"
export const SET_REQUEST_SAMPLE_CLEAR = "SET_REQUEST_SAMPLE_CLEAR"

//detail tab
export const UPDATE_REQUEST_GENERAL = "UPDATE_REQUEST_GENERAL"
export const UPDATE_REQUEST_GENERAL_SUCCESS = "UPDATE_REQUEST_GENERAL_SUCCESS"
export const UPDATE_REQUEST_GENERAL_FAIL = "UPDATE_REQUEST_GENERAL_FAIL"

//update SID
export const UPDATE_REQUEST_SID = "UPDATE_REQUEST_SID"
export const UPDATE_REQUEST_SID_SUCCESS = "UPDATE_REQUEST_SID_SUCCESS"
export const UPDATE_REQUEST_SID_FAIL = "UPDATE_REQUEST_SID_FAIL"

//test tabs
export const UPDATE_REQUEST_TESTS = "UPDATE_REQUEST_TESTS"
export const UPDATE_REQUEST_TESTS_SUCCESS = "UPDATE_REQUEST_TESTS_SUCCESS"
export const UPDATE_REQUEST_TESTS_FAIL = "UPDATE_REQUEST_TESTS_FAIL"
//update requests sample
export const UPDATE_REQUESTSSAMPLE = "UPDATE_REQUESTSSAMPLE"
export const UPDATE_REQUESTSSAMPLE_SUCCESS = "UPDATE_REQUESTSSAMPLE_SUCCESS"
export const UPDATE_REQUESTSSAMPLE_FAIL = "UPDATE_REQUESTSSAMPLE_FAIL"


//GET REQUEST DETAILS
export const GET_TESTREQUEST_GENERAL_DETAIL = "GET_TESTREQUEST_GENERAL_DETAIL";
export const GET_TESTREQUEST_GENERAL_DETAIL_SUCCESS = "GET_TESTREQUEST_GENERAL_DETAIL_SUCCESS";
export const GET_TESTREQUEST_GENERAL_DETAIL_FAIL = "GET_TESTREQUEST_GENERAL_DETAIL_FAIL";

export const GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL = "GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL";
export const GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_SUCCESS = "GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_SUCCESS";
export const GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_FAIL = "GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL_FAIL";

export const GET_TESTREQUEST_TESTS_DETAIL = "GET_TESTREQUEST_TESTS_DETAIL";
export const GET_TESTREQUEST_TESTS_DETAIL_SUCCESS = "GET_TESTREQUEST_TESTS_DETAIL_SUCCESS";
export const GET_TESTREQUEST_TESTS_DETAIL_FAIL = "GET_TESTREQUEST_TESTS_DETAIL_FAIL";

export const GET_TESTREQUEST_SAMPLES_DETAIL = "GET_TESTREQUEST_SAMPLES_DETAIL";
export const GET_TESTREQUEST_SAMPLES_DETAIL_SUCCESS = "GET_TESTREQUEST_SAMPLES_DETAIL_SUCCESS";
export const GET_TESTREQUEST_SAMPLES_DETAIL_FAIL = "GET_TESTREQUEST_SAMPLES_DETAIL_FAIL";

export const GET_TESTREQUEST_TESTS_LOADING_COMPLETE = "GET_TESTREQUEST_TESTS_LOADING_COMPLETE";


//END GET REQUEST DETAILS
export const CHANGE_REQUEST_SAMPLE_TABLE = "CHANGE_REQUEST_SAMPLE_TABLE"
export const CHANGE_REQUEST_SAMPLE_TABLE_SUCCESS = "CHANGE_REQUEST_SAMPLE_TABLE_SUCCESS"
export const CHANGE_REQUEST_SAMPLE_TABLE_FAIL = "CHANGE_REQUEST_SAMPLE_TABLE_FAIL"

// reload state
export const RELOAD_STATE_TESTREQUEST_DETAIL = "RELOAD_STATE_TESTREQUEST_DETAIL"
export const RELOAD_STATE_TESTREQUEST_DETAIL_SUCCESS = "RELOAD_STATE_TESTREQUEST_DETAIL_SUCCESS"
export const RELOAD_STATE_TESTREQUEST_DETAIL_FAIL = "RELOAD_STATE_TESTREQUEST_DETAIL_FAIL"

export const UPDATE_SAMPLE_NUMBOFLABELS = "UPDATE_SAMPLE_NUMBOFLABELS"
export const UPDATE_SAMPLE_NUMBOFLABELS_SUCCESS = "UPDATE_SAMPLE_NUMBOFLABELS_SUCCESS"
export const UPDATE_SAMPLE_NUMBOFLABELS_FAIL = "UPDATE_SAMPLE_NUMBOFLABELS_FAIL"

export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST"

export const EDIT_TESTREQUEST_SAMPLEID = "EDIT_TESTREQUEST_SAMPLEID"
export const EDIT_TESTREQUEST_SAMPLEID_SUCCESS = "EDIT_TESTREQUEST_SAMPLEID_SUCCESS"
export const EDIT_TESTREQUEST_SAMPLEID_FAIL = "EDIT_TESTREQUEST_SAMPLEID_FAIL"

export const EDIT_TESTREQUEST_NUMBER_OF_LABELS = "EDIT_TESTREQUEST_NUMBER_OF_LABELS"
export const EDIT_TESTREQUEST_NUMBER_OF_LABELS_SUCCESS = "EDIT_TESTREQUEST_NUMBER_OF_LABELS_SUCCESS"
export const EDIT_TESTREQUEST_NUMBER_OF_LABELS_FAIL = "EDIT_TESTREQUEST_NUMBER_OF_LABELS_FAIL"

export const SET_REQUEST_SAMPLE_SID_SUCCESS = "SET_REQUEST_SAMPLE_SID_SUCCESS"
export const SET_REQUEST_SAMPLE_SID_FAIL = "SET_REQUEST_SAMPLE_SID_FAIL"

export const SET_REQUEST_SAMPLE_NUMB_OF_LABELS_SUCCESS = "SET_REQUEST_SAMPLE_NUMB_OF_LABELS_SUCCESS"
export const SET_REQUEST_SAMPLE_NUMB_OF_LABELS_FAIL = "SET_REQUEST_SAMPLE_NUMB_OF_LABELS_FAIL"

export const TEST_REQUEST_AUDIT = 'TEST_REQUEST_AUDIT'
export const GET_PATIENT_VISIT_SUCCESS = "GET_PATIENT_VISIT_SUCCESS";
export const CLEAR_DETAIL_INFO_PATIENT_VISIT = "CLEAR_DETAIL_INFO_PATIENT_VISIT";

export const COUNT_PATIENT_AND_TEST = 'COUNT_PATIENT_AND_TEST'
export const COUNT_PATIENT_AND_TEST_SUCCESS = "COUNT_PATIENT_AND_TEST_SUCCESS";
export const COUNT_PATIENT_AND_TEST_FAIL = "COUNT_PATIENT_AND_TEST_FAIL";

// collector area
export const CREATE_NEW_COLLECTOR_AREA = "CREATE_NEW_COLLECTOR_AREA"
export const CREATE_NEW_COLLECTOR_AREA_SUCCESS = "CREATE_NEW_COLLECTOR_AREA_SUCCESS"
export const CREATE_NEW_COLLECTOR_AREA_FAIL = "CREATE_NEW_COLLECTOR_AREA_FAIL"

export const GET_COLLECTOR_AREA = "GET_COLLECTOR_AREA"
export const GET_COLLECTOR_AREA_SUCCESS = "GET_COLLECTOR_AREA_SUCCESS"
export const GET_COLLECTOR_AREA_FAIL = "GET_COLLECTOR_AREA_FAIL"

export const PRINT_SAMPLE_LIST = "PRINT_SAMPLE_LIST"
export const LOADING_PRINT_SAMPLE_LIST = "LOADING_PRINT_SAMPLE_LIST"