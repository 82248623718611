import {
  convertDateFormat,
  getUrlParamByKey,
  getUrlParams,
  insertUrlParam,
  onActionToggle
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import TestTable from "./WorkOrderTable"

import {
  OkCancelModal,
  TitleAndTable,
  WarningModal
} from "components/Common"
import { selectCheckboxHandler } from "helpers/utilities"

import {
  getWorkOders,
  resetSearchQuery,
  updateWorkOrderMultiple,
} from "store/analysis-process/workorders/actions"

import { ModuleIds, WorkOrderAction } from "constant"
import { withTranslation } from "react-i18next"
import HeaderButtons from "./HeaderButtons"

const RESOURCE = ModuleIds.AnalyticalProcess

const WorkOrders = ({
  history,
  tests,
  paging,
  searchQuery,
  onGetWorkOrders,
  onResetWorkOrderSearchQuery,
  onUpdateWorkOrders,
  loadingTests,
  updatedTestTime,
  t,
}) => {
  const [modal, setModal] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [row, setRow] = useState(false)
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [warningModal, setWarningModal] = useState(false)

  const [confirmReloadDownloadModal, setConfirmReloadDownloadModal] =
    useState(false)
  const [confirmSuspendDownloadModal, setConfirmSuspendDownloadModal] =
    useState(false)

  const formEl = useRef(null)
  const popStateChange = event => {
    let params = getUrlParams()
    let paramPage = getUrlParamByKey("page")
    if (paramPage) {
      onGetTestList(params)
    } else {
      fetchTests()
    }
  }
  const resetState = () => {
    setRow({})
    setRows([])
  }
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const onGetTestList = payload => {
    insertUrlParam(payload)
    onGetWorkOrders(payload)
  }

  const fetchTests = () => {
    let requestDate = convertDateFormat(new Date(), "YYYY-MM-DD")
    if (searchQuery.requestDate) {
      requestDate = searchQuery.requestDate
    }
    onGetTestList({ page: 1, requestDate: requestDate, sort: "requestDate:desc,createdDate:desc" })
  }

  const fetchTest = () => {
    let requestDate = convertDateFormat(new Date(), "YYYY-MM-DD")
    if (searchQuery.requestDate) {
      requestDate = searchQuery.requestDate
    }
    onGetTestList({requestDate: requestDate, sort: "requestDate:desc,createdDate:desc" })
  }

  const onRefreshHandler = () => {
    resetState()
    fetchTests()
  }

  const onSearch = searchText => {
    onGetTestList({ page: 1, search: searchText })
  }

  const onSizePerPageChange = size => {
    onGetTestList({ page: 1, size })
  }

  const onPageChange = page => {
    onGetTestList({ page })
  }

  const onSubmitFilter = values => {
    onGetTestList({ page: 1, ...values })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetTestList({ page: 1, sort: sortString })
  }

  const onReloadDownloadToggleHandler = (e, param) => {
    if (row.id) {
      onActionToggle({
        rows,
        row: param || rowDelete,
        setConfirmUpdateModal: setConfirmReloadDownloadModal,
        setWarningModal,
        isNotCheckEmpty: true,
      })
    } else {
      setConfirmSuspendDownloadModal(false);
      setWarningModal(true)
    }
  }

  const onSuspendDownloadToggleHandler = (e, param) => {
    if (row.id) {
      onActionToggle({
        rows,
        row: param || rowDelete,
        setConfirmUpdateModal: setConfirmSuspendDownloadModal,
        setWarningModal,
        isNotCheckEmpty: true,
      })
    } else {
      setConfirmSuspendDownloadModal(false);
      setWarningModal(true)
    }

  }

  const onReloadDownloadHandler = () => {
    const workOrderIds = rows.map(el => {
      return el.inRequestId
    })

    onUpdateWorkOrders({
      ids: workOrderIds,
      action: WorkOrderAction.RELOAD,
      callback: afterUpdate,
    })
  }

  const onSuspendDownloadHandler = () => {
    const workOrderIds = rows.map(e => {
      return e.inRequestId
    })

    onUpdateWorkOrders({
      ids: workOrderIds,
      action: WorkOrderAction.SUSPEND,
      callback: afterUpdate,
    })
  }

  const afterUpdate = () => {
    setConfirmReloadDownloadModal(false)
    setConfirmSuspendDownloadModal(false)
    resetState()
    fetchTest()
    setIsSelected(true)
  }

  /** ---------CYCLE-------- **/
  useEffect(() => {
    onResetWorkOrderSearchQuery()
  }, [])

  useEffect(() => {
    fetchTests()
  }, [])

  return (
    <React.Fragment>
      <TitleAndTable
        table={() => (
          <TestTable
            tests={tests}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            isSelected={isSelected}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            onEdit={() => { }}
            onDelete={() => { }}
            loading={loadingTests}
            updatedTime={updatedTestTime}
          />
        )}
        buttons={() => (
          <HeaderButtons
            resource={RESOURCE}
            onReloadClick={onReloadDownloadToggleHandler}
            onSuspendClick={onSuspendDownloadToggleHandler}
            model={{}}
          />
        )}
        external
        resource={RESOURCE}
        onEdit={() => { }}
        onDelete={() => { }}
        onClone={() => { }}
        title={t("Analytical Process")}
        subtitle={t("Work Order List")}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <OkCancelModal
        modal={confirmReloadDownloadModal}
        title={`${t("Reload")}}`}
        message={t("Clear status downloaded")}
        onToggle={onReloadDownloadToggleHandler}
        onAction={onReloadDownloadHandler}
      />

      <OkCancelModal
        modal={confirmSuspendDownloadModal}
        title={`${t("Suspend")}}`}
        message={t("Reject download")}
        onToggle={onSuspendDownloadToggleHandler}
        onAction={onSuspendDownloadHandler}
      />
    </React.Fragment>
  )
}

WorkOrders.propTypes = {
  tests: PropTypes.array,
  paging: PropTypes.object,
  onGetWorkOrders: PropTypes.func,
  //   onAddNewTest: PropTypes.func,
  //   onUpdateTest: PropTypes.func,
  //   onDeleteTests: PropTypes.func,
  onResetWorkOrderSearchQuery: PropTypes.func,
  onGetTestDetail: PropTypes.func,
  test: PropTypes.object,
  loadingTests: PropTypes.bool,
  updatedTestTime: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = ({ workOrder }) => ({
  tests: workOrder.tests,
  test: workOrder.test,
  paging: workOrder.paging,
  loadingTests: workOrder.loadingTests,
  updatedTestTime: workOrder.updatedTime,
  searchQuery: workOrder.searchQuery,
})

const mapDispatchToProps = dispatch => ({
  onGetWorkOrders: payload => dispatch(getWorkOders(payload)),
  //   onAddNewTest: (test, history) => dispatch(addNewTest(test, history)),
  //   onUpdateTest: test => dispatch(updateTest(test)),
  //   onDeleteTests: test => dispatch(deleteTests(test)),
  //   onGetTestDetail: testId => dispatch(getTestDetail(testId)),
  onUpdateWorkOrders: payload => dispatch(updateWorkOrderMultiple(payload)),
  onResetWorkOrderSearchQuery: () => dispatch(resetSearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["workOrderPage", "message"])(WorkOrders)))
