import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delWithUpdateCommon } from "../api_helper2"
import { postWithErrorMessage } from "../api_helper_withoutError"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/requests`
const BASE_BFF_API_URL = `${process.env.REACT_APP_BFF_ENDPOINT}/requests`
const BASE_API_PATIENT_VISIT_WAITING = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/requests/waiting/patientVisit`
const BASE_API_PATIENT_VISIT_COLLECTED = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/requests/collected/patientVisit`
const BASE_API_URL_IOLIS = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/iolis`
// Get All Test Requests
const getAllTestRequests = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const getAllFullRequests = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)//chuyển từ bff sang
}

// Get Patient Visit Waiting List
const getPatientVisitWaitingList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_PATIENT_VISIT_WAITING}?${q}`)
}

// Get Patient Visit Collected List
const getPatientVisitCollectedList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_PATIENT_VISIT_COLLECTED}?${q}`)
}

// Get Test Requests By Id
const getTestRequestById = id => {
    return get(`${BASE_API_URL}/${id}`)
}

const getFullRequestPatientVisitById = id => {
    return get(`${BASE_BFF_API_URL}/${id}/patientVisit`)
}

const getFullRequestById = id => {
    return get(`${BASE_API_URL}/${id}`)
}

// Add test request
const addNewRequest = req => {
    return post(`${BASE_API_URL}`, req)
}

const addNewRequestWithoutError = req => {
    return postWithErrorMessage(`${BASE_API_URL}`, req)
}

const addRequestGroupWithoutError = req => {
    return postWithErrorMessage(`${BASE_API_URL}/CreateRequestGroup`, req)
}

// Update test request By Id Method
const updateTestRequestById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

// Cancel test request
const cancelTestRequests = (id) => {
    return put(`${BASE_API_URL}/cancelRequest`, id)
}

// Delete request By Id Method
const deleteRequestsById = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))
    return delWithUpdateCommon(`${BASE_API_URL}?${ids}`)
}
// Update request sample by RequestId
const updateRequestSamples = testRequest => {
    const { id, samples } = testRequest;
    return put(`${BASE_API_URL}/${id}/samples`, testRequest)
}

// Update test request tests By Id
const updateRequestTestsById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}/tests`, req)
}

// Update test request general By Id
const updateRequestGeneralById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}/general`, req)
}

// Update SID
const updateRequestSID = req => {
    return put(`${BASE_API_URL}/sid`, req)
}

// GET_TESTREQUEST_GENERAL_DETAIL
const getTestrequestGeneralDetailById = id => {
    return get(`${BASE_API_URL}/${id}/general`)
}

const getFullTestrequestGeneralDetailById = id => {
    return get(`${BASE_API_URL}/${id}/general`)
}

// GET_TESTREQUEST_TESTS_DETAIL
const getTestrequestTestsDetailById = id => {
    return get(`${BASE_API_URL}/${id}/tests`)
}

// GET_TESTREQUEST_TESTS_DETAIL
const getTestrequestProfilesDetailById = id => {
    return get(`${BASE_API_URL}/${id}/profiles`)
}

// GET_TESTREQUEST_SAMPLES_DETAIL
const getTestrequestSamplesDetailById = id => {
    return get(`${BASE_API_URL}/${id}/samples`)
}

const getFullTestrequestSamplesDetailById = id => {
    return get(`${BASE_API_URL}/${id}/samples`)
}

const getMassTestrequestSamplesDetailById = id => {
    let ids = ""
    id.forEach(x => (ids += `id=${x}&`))
    return get(`${BASE_API_URL}/mass/samples?${ids}`)
}

const getRequestSampleList = (data) => {
    const q = spreadSearchQuery(data)
    return get(`${BASE_API_URL}/sampleList?${q}`)
}

const GetConfigSample = (companyId, requestSamples) => {
    return put(`${BASE_API_URL}/sample-config?companyId=${companyId}`, requestSamples)
}

const UpdateRequestPatient = (id, patientRequestId, data) => {
    return put(`${BASE_API_URL}/${id}/patient/${patientRequestId}?`, data)
}

const CreateRequestPatient = (id, data) => {
    return post(`${BASE_API_URL}/${id}/patient`, data)
}
const getSampleRequest = (data) => {
    const q = spreadSearchQuery(data)
    return get(`${BASE_API_URL}/patient?${q}`)
}
const updateNumbOfLabels = request => {
    const { requestId, samples } = request
    return put(`${BASE_API_URL}/${requestId}/numOfLabel`, request)
}
const countPatientAndTestCompany = id => {
    return get(`${BASE_API_URL}/countTest/${id}`, id)
}

const LisGetRequestOrder = (alternateId, requestDate, hisCode, sid = '', autoCollected = false, collectorUserId = '') => {
    let data = {
        alternateId: alternateId,
        requestDate: requestDate,
        hisCode: hisCode,
        sid: sid,
        autoCollected: autoCollected,
        collectorUserId: collectorUserId
    }
    return post(`${BASE_API_URL}/LisGetRequestOrder`, data)
}

// collector area
const addNewCollectorArea = req => {
    return post(`${BASE_API_URL}/create-collector`, req)
}

const getCollectorArea = req => {
    let { areaCode } = req
    return get(`${BASE_API_URL}/collector/${areaCode}`)
}

const downloadImportPatientGroupTemplate = () => {
    return post(`${BASE_API_URL}/DownloadImportPatientGroupTemplate`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=template.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

const exportRequestList = (payload) => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/export-request-list?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=ExportRequestList.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

export {
    getAllTestRequests,
    getAllFullRequests,
    addNewRequest,
    updateTestRequestById,
    deleteRequestsById,
    getTestRequestById,
    getFullRequestPatientVisitById,
    getFullRequestById,
    updateRequestTestsById,
    updateRequestGeneralById,
    updateRequestSID,
    updateRequestSamples,
    getTestrequestGeneralDetailById,
    getTestrequestTestsDetailById,
    getTestrequestProfilesDetailById,
    getTestrequestSamplesDetailById,
    getFullTestrequestGeneralDetailById,
    getRequestSampleList,
    GetConfigSample,
    UpdateRequestPatient,
    CreateRequestPatient,
    getSampleRequest,
    updateNumbOfLabels,
    addNewRequestWithoutError,
    cancelTestRequests,
    getPatientVisitWaitingList,
    getPatientVisitCollectedList,
    countPatientAndTestCompany,
    LisGetRequestOrder,
    addRequestGroupWithoutError,
    getFullTestrequestSamplesDetailById,
    addNewCollectorArea,
    getCollectorArea,
    getMassTestrequestSamplesDetailById,
    downloadImportPatientGroupTemplate,
    exportRequestList
}
