import { CustomBootstrapInlineTable, FormSwitch } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { DateAgeText, GenderSelectText, ModuleIds, parameterCode } from "constant"
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getFullTestSuccess, getPositionScroll } from "store/actions"
import RulePopover from "./RulePopover"
import { convertStringForSearch } from "helpers/utilities"

const RESOURCE = ModuleIds.RuleManagement

let expandGlobal = []
let itemActiveGlobal = {}
let pageGlobal = 0
let dataGlobal = []
const RuleManagementTable = forwardRef(
  ({
    data,
    onSort,
    onSelect,
    rows,
    onSelectAll,
    onGetTestsSuccess,
    onSearch,
    onRefresh,
    paging,
    itemActive,
    onChangeData,
    updatedTime,
    loadingTests,
    onGetPositionScroll,
    t,
  }, ref) => {
    const [model, setModel] = useState({
      search: "",
    })
    const size = 15;
    const [columnDisplay, setColumnDisplay] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [columnExpand, setColumnExpand] = useState([])
    const [isExpand, setIsExpand] = useState(false)
    const [page, setPage] = useState(0)

    const columnAdd = {
      dataField: "add",
      text: <div className="text-success text-center" style={{ width: 50 }}>
        <i className="fas fa-plus text-success"></i>
      </div>,
      isDummyField: true,
      classes: "add-rule-col",
      headerClasses: "add-rule-col-headder",
      style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50 },
      headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50 },
      formatter: (cellContent, item, index) => (
        <div className="text-center"
          onClick={(e) => {
            onSelect(item, true, true)
            const parentVal = {
              unit: item.unit || '',
              normalRange: item.normalRange || '',
              expression: item.expression || '',
              defaultResult: item.defaultResult || '',
              lowerLimit: item.lowerLimit || '',
              upperLimit: item.upperLimit || '',
              lowerAlert: item.lowerAlert || '',
              upperAlert: item.upperAlert || '',
              enable: true,
            }
            if (itemActiveGlobal.Key == 2 && itemActiveGlobal.parent.ID_Group == "1") {
              if (item.children && item.children.length > 0 && expandGlobal.findIndex(x => x == item.id) >= 0) {
                e.stopPropagation()
              }
              else {
                let el = document.getElementsByClassName('expand-cell')[index]
                setTimeout(() => {
                  if (el) {
                    let checkExpand = el.getElementsByClassName("fa-angle-up")
                    if (checkExpand.length == 0) {
                      el.click()
                    }
                  }
                }, 200);
              }
              let tmpData = dataGlobal
              if (!tmpData[index].children)
                tmpData[index].children = [{ age: '', gender: '', ageType: '', testCode: item.testCode, parentId: item.id, id: new Date().getTime(), ...parentVal }]
              else {
                // if (tmpData[index].children.findIndex(x => x.age == '' && x.gender == '') < 0)
                tmpData[index].children.push({
                  age: '', gender: '', ageType: '', parentId: item.id, testCode: item.testCode, id: new Date().getTime(), ...parentVal
                })
              }
              setDataSource([...tmpData])
            }
            else if (itemActiveGlobal.Key == 3 && itemActiveGlobal.parent.ID_Group == "1") {
              if (item.childrenInstrument && item.childrenInstrument.length > 0 && expandGlobal.findIndex(x => x == item.id) >= 0)
                e.stopPropagation()
              else {
                let el = document.getElementsByClassName('expand-cell')[index]
                setTimeout(() => {
                  if (el) {
                    let checkExpand = el.getElementsByClassName("fa-angle-up")
                    if (checkExpand.length == 0) {
                      el.click()
                    }
                  }
                }, 200);
              }
              let tmpData = dataGlobal
              if (!tmpData[index].childrenInstrument) {
                tmpData[index].childrenInstrument = [{
                  age: '', gender: '', ageType: '',
                  testCode: item.testCode, parentId: item.id, id: new Date().getTime(), ...parentVal
                }]
              }
              else {
                tmpData[index].childrenInstrument.push({
                  age: '', gender: '', ageType: '',
                  testCode: item.testCode, parentId: item.id, id: new Date().getTime(), ...parentVal
                })
              }
              setDataSource([...tmpData])
            } else if (itemActiveGlobal.Key === 5 && itemActiveGlobal.parent.ID_Group == "1") {
              if (item.childrenResultTime && item.childrenResultTime.length > 0 && expandGlobal.findIndex(x => x == item.id) >= 0)
                e.stopPropagation()
              else {
                let el = document.getElementsByClassName('expand-cell')[index]
                setTimeout(() => {
                  if (el) {
                    let checkExpand = el.getElementsByClassName("fa-angle-up")
                    if (checkExpand.length == 0) {
                      el.click()
                    }
                  }
                }, 200);
              }
              let tmpData = dataGlobal
              if (!tmpData[index].childrenResultTime) {
                tmpData[index].childrenResultTime = [{
                  resultTime: '', timeCollection: '', test: '', profileCode: '', serviceType: '', inPatient: '', subCategory: item.subCategory,
                  testCode: '', subCategoryName: item.subCategoryName, parentId: item.id, id: new Date().getTime()
                }]
              }
              else {
                tmpData[index].childrenResultTime.push({
                  resultTime: '', timeCollection: '', test: '', profileCode: '', serviceType: '', inPatient: '',
                  subCategory: item.subCategory, subCategoryName: item.subCategoryName,
                  testCode: '', testName: item.testName, parentId: item.id, id: new Date().getTime()
                })
              }
              setDataSource([...tmpData])
            } else if (itemActiveGlobal.Key === 4 && itemActiveGlobal.parent.ID_Group == "1") {
              if (item.childrenAdditionalInfo && item.childrenAdditionalInfo.length > 0 && expandGlobal.findIndex(x => x == item.id) >= 0)
                e.stopPropagation()
              else {
                let el = document.getElementsByClassName('expand-cell')[index]
                setTimeout(() => {
                  if (el) {
                    let checkExpand = el.getElementsByClassName("fa-angle-up")
                    if (checkExpand.length == 0) {
                      el.click()
                    }
                  }
                }, 200);
              }
              let tmpData = dataGlobal
              if (!tmpData[index].childrenAdditionalInfo) {
                tmpData[index].childrenAdditionalInfo = [{
                  subCategory: '', testCode: '', subId: '', color: '', labels: '', sampleType: item.sampleType,
                  sampleTypeName: item.sampleTypeName, parentId: item.id, id: new Date().getTime()
                }]
              }
              else {
                tmpData[index].childrenAdditionalInfo.push({
                  subCategory: '', testCode: '', subId: '', color: '', labels: '', sampleType: item.sampleType,
                  sampleTypeName: item.sampleTypeName, parentId: item.id, id: new Date().getTime()
                })
              }
              setDataSource([...tmpData])
            }
          }}
        ><i className="fas fa-plus text-success"></i></div>
      ),
    }

    useImperativeHandle(ref, () => ({
      onGetData: () => {
        return dataSource || []
      },
    }))

    useEffect(() => {
      setDataSource(data)
    }, [data])

    useEffect(() => {
      if (isExpand == false) {
        if (!(itemActive.Key == 2 && itemActive.parent.ID_Group == "1")) {
          let el = document.querySelector(".testName-col")
          el && el.click()
        }
      }
    }, [isExpand])

    useEffect(() => {
      expandGlobal = columnExpand
    }, [columnExpand])

    useEffect(() => {
      if (
        (itemActive.Key == 2 && itemActive.parent.ID_Group == "1") ||
        (itemActive.Key == 3 && itemActive.parent.ID_Group == "1") ||
        (itemActive.Key == 4 && itemActive.parent.ID_Group == "1") ||
        (itemActive.Key == 5 && itemActive.parent.ID_Group == "1")
      ) {
        setIsExpand(true)
        let tmp = [...columns];
        tmp.push(columnAdd)
        setColumnDisplay([...tmp])
      }
      else {
        setIsExpand(false)
        setColumnDisplay([...columns])
        setColumnExpand([])
      }
    }, [dataSource, itemActive.Name, rows])

    const checkAndTab = (e, idHTML, key) => {
      if (e.key === 'Enter' || e.key === "Tab") {
        const fields =
          Array.from(document.getElementsByClassName(key)) ||
          []
        const position = fields.findIndex(x => x.id == `${idHTML}`)
        if (position >= 0) {
          setTimeout(() => {
            let el = fields[position + 1]
            if (el) {
              const tmpClick = $(`#${el.id} form a`)
              if (tmpClick && tmpClick.length > 0)
                tmpClick[0].click()
            }
          }, 0);

        }
      }
    }

    const isInstrument = itemActive?.Key !== 3 && itemActiveGlobal?.parent?.ID_Group == "1" && itemActive?.Key !== 2

    let columns = [
      {
        dataField: "",
        text: "#",
        style: { borderLeft: '1px solid #F0F2F7' },
        headerStyle: { borderLeft: '1px solid #F0F2F7' },
        classes: 'testName-col',
        headerClasses: 'testName-col-headder',
        formatter: (cellContent, item, index) => {
          return index + 1
        }
      },
      ...((itemActiveGlobal.Key === 4) ? [
        {
          dataField: "sampleType",
          text: t("testConfig:Sample Type"),
          style: { minWidth: 200, maxWidth: 200, width: 200, borderRight: '1px solid #F0F2F7' },
          headerStyle: { minWidth: 200, maxWidth: 200, width: 200 },
          sort: true,
          classes: 'testName-col',
          headerClasses: 'testName-col-headder',
          formatter: (cellContent, item, index) => {
            if (item.id == -1)
              return <span id={`watch_end_of_document`}>End of document</span>
            else
              return <span style={item?.childrenAdditionalInfo?.length > 0 && itemActiveGlobal.Key !== 1 ? { color: 'blue' } : {}} >{item?.sampleType} - {item?.sampleTypeName}</span>
          }
        },
        {
          dataField: "subCategory",
          text: t("testConfig:Category"),
          style: { minWidth: 100, maxWidth: 100 },
        },
        {
          dataField: "testCode",
          text: t("testConfig:Test Code"),
          style: { minWidth: 100, maxWidth: 100, borderRight: '1px solid #F0F2F7' },
        },
        {
          dataField: "subId",
          text: t("testConfig:Sub Id"),
          style: { minWidth: 100, maxWidth: 100 },
        },
        {
          dataField: "color",
          text: t("testConfig:Color"),
          style: { minWidth: 100, maxWidth: 100 },

        },
        {
          dataField: "labels",
          text: t("testConfig:Labels"),
          style: { minWidth: 100, maxWidth: 100 },
        }] :
        ((itemActiveGlobal.Key === 5) ? [
          {
            dataField: "subCategory",
            text: t("testConfig:Category"),
            style: { minWidth: 200, maxWidth: 200, width: 200, borderRight: '1px solid #F0F2F7' },
            headerStyle: { minWidth: 200, maxWidth: 200, width: 200 },
            sort: true,
            classes: "testName-col",
            headerClasses: "testName-col-headder",
            formatter: (cellContent, item, index) => {
              if (item.id == -1)
                return <span id={`watch_end_of_document`}>End of document</span>
              else
                return <span style={item?.childrenResultTime?.length > 0 && itemActiveGlobal.Key !== 1 ? { color: 'blue' } : {}} >{item?.subCategory} - {item?.subCategoryName}</span>
            }
          },
          {
            dataField: "serviceType",
            text: t("testConfig:Service Type"),
            style: { minWidth: 100, maxWidth: 100 },
          },
          {
            dataField: "profileCode",
            text: t("testConfig:Profile Code"),
            style: { minWidth: 100, maxWidth: 100 },
          },

          {
            dataField: "test",
            text: t("testConfig:Test Code"),
            style: { minWidth: 100, maxWidth: 100 },
          },
          {
            dataField: "inPatient",
            text: t("testConfig:In Patient"),
            style: { minWidth: 100, maxWidth: 100 },
          },
          {
            dataField: "timeCollection",
            text: t("testConfig:Time Collection"),
            style: { minWidth: 100, maxWidth: 100, borderRight: '1px solid #F0F2F7' },
          },
          {
            dataField: "resultTime",
            text: t("testConfig:Result Time"),
            style: { minWidth: 100, maxWidth: 100 },
          }] :
          (itemActiveGlobal.Key == 1 && itemActiveGlobal.parent.ID_Group == "1") ?
            [{
              dataField: "testName",
              text: t("testConfig:Test"),
              style: { minWidth: 200, maxWidth: 200 },
              headerStyle: { minWidth: 200, maxWidth: 200 },
              sort: true,
              classes: "testName-col",
              headerClasses: "testName-col-headder",
              formatter: (cellContent, item, index) => {
                if (item.id == -1)
                  return <span id={`watch_end_of_document`}>End of document</span>
                else
                  return <span style={item?.childrenInstrument?.length > 0 && itemActiveGlobal.Key !== 1 ? { color: 'blue' } : {}} >{item?.testCode} - {cellContent}</span>
              }
            }]
            :
            (
              (itemActiveGlobal.Key == 3 && itemActiveGlobal.parent.ID_Group == "1") ?
                [
                  {
                    dataField: "testName",
                    text: t("testConfig:Test"),
                    style: { minWidth: 200, maxWidth: 200, width: 200 },
                    headerStyle: { minWidth: 200, maxWidth: 200, width: 200 },
                    sort: true,
                    classes: "testName-col",
                    headerClasses: "testName-col-headder",
                    formatter: (cellContent, item, index) => {
                      if (item.id == -1)
                        return <span id={`watch_end_of_document`}>End of document</span>
                      else
                        return <span style={item?.childrenInstrument?.length > 0 && itemActiveGlobal.Key !== 1 ? { color: 'blue' } : {}} >{item?.testCode} - {cellContent}</span>
                    }
                  },
                  {
                    dataField: "instrument",
                    text: t("testConfig:InstrumentName"),
                    style: { minWidth: 150, maxWidth: 150, width: 150 },
                    headerStyle: { minWidth: 150, maxWidth: 150, width: 150 },
                    formatter: (cellContent, item, index) => {
                      return itemActive ? itemActive.Name : '';
                    }
                  },
                  {
                    dataField: "ageType",
                    text: t("testConfig:Age Type"),
                    style: { minWidth: 100, maxWidth: 100 },
                  },
                  {
                    dataField: "age",
                    text: t("testConfig:Age"),
                    style: { minWidth: 100, maxWidth: 100 },
                  },
                  {
                    dataField: "gender",
                    text: t("testConfig:Gender"),
                    // classes: "testName-col",
                    // headerClasses: "testName-col-headder",
                    style: { minWidth: 100, maxWidth: 100, borderRight: '1px solid #F0F2F7' },
                  }]
                :
                (
                  (itemActiveGlobal.Key == 2 && itemActiveGlobal.parent.ID_Group == "1") ?
                    [{
                      dataField: "testName",
                      text: t("testConfig:Test"),
                      style: { minWidth: 202, maxWidth: 200 },
                      headerStyle: { minWidth: 202, maxWidth: 200 },
                      sort: true,
                      classes: "testName-col",
                      headerClasses: "testName-col-headder",
                      formatter: (cellContent, item, index) => {
                        if (item.id == -1)
                          return <span id={`watch_end_of_document`}>End of document</span>
                        else
                          return <span style={item?.children?.length > 0 && itemActiveGlobal.Key !== 1 ? { color: 'blue' } : {}} >{item?.testCode} - {cellContent}</span>
                      }
                    },
                    {
                      dataField: "ageType",
                      text: t("testConfig:Age Type"),
                      style: { minWidth: 100, maxWidth: 100 },
                    },
                    {
                      dataField: "age",
                      text: t("testConfig:Age"),
                      style: { minWidth: 100, maxWidth: 100 },
                    },
                    {
                      dataField: "gender",
                      text: t("testConfig:Gender"),
                      // classes: "testName-col",
                      // headerClasses: "testName-col-headder",
                      style: { minWidth: 100, maxWidth: 100, borderRight: '1px solid #F0F2F7' },
                    }]
                    : [{
                      dataField: "testName",
                      text: t("testConfig:Test"),
                      style: { minWidth: 200, maxWidth: 200, width: 200 },
                      headerStyle: { minWidth: 200, maxWidth: 200, width: 200 },
                      sort: true,
                      classes: "testName-col",
                      headerClasses: "testName-col-headder",
                      formatter: (cellContent, item, index) => {
                        if (item.id == -1)
                          return <span id={`watch_end_of_document`}>End of document</span>
                        else
                          return <span style={item?.childrenInstrument?.length > 0 && itemActiveGlobal.Key !== 1 ? { color: 'blue' } : {}} >{item?.testCode} - {cellContent}</span>
                      }
                    },
                    {
                      dataField: "instrument",
                      text: t("testConfig:InstrumentName"),
                      style: { minWidth: 150, maxWidth: 150, width: 150 },
                      headerStyle: { minWidth: 150, maxWidth: 150, width: 150 },
                      formatter: (cellContent, item, index) => {
                        return itemActive ? itemActive.Name : '';
                      }
                    },
                    {
                      dataField: "age",
                      text: t("testConfig:Age"),
                      style: { minWidth: 100, maxWidth: 100 },
                    },
                    {
                      dataField: "gender",
                      text: t("testConfig:Gender"),
                      // classes: "testName-col",
                      // headerClasses: "testName-col-headder",
                      style: { minWidth: 100, maxWidth: 100, borderRight: '1px solid #F0F2F7' },
                    }]
                )))),
      ...((itemActiveGlobal.Key !== 5 && itemActiveGlobal.Key !== 4) ?
        [
          {
            dataField: "unit",
            text: t("testConfig:Unit"),
            style: { minWidth: 100, maxWidth: 100 },
            // events: {
            //   onClick: () => { },
            // },
            // formatter: (cellContent, item, index) => {
            //   const idHTML = `resultBlurItemFocusParent${item.id + item?.testCode?.replace('.', '')}unit`
            //   return rows.find(elem => elem.id == item.id) && (itemActiveGlobal.Key == 1 && itemActiveGlobal.parent.ID_Group == "1") ? (
            //     <React.Fragment>
            //       <div
            //         className={"resultBlurItemFocusParent"}
            //         id={idHTML}
            //         onKeyDown={e => {
            //           checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
            //         }}
            //         style={{ width: 90, margin: "0 auto" }}
            //         onClick={e => {
            //           e.stopPropagation()
            //         }}
            //         onBlur={() => {
            //           $(`#${item.id}-unit`).click()
            //         }}
            //       >
            //         <Editable
            //           alwaysEditing={false}
            //           disabled={false}
            //           editText={item.unit || "---"}
            //           initialValue={item.unit || ""}
            //           id={item.unit}
            //           isValueClickable={false}
            //           mode="inline"
            //           placement="top"
            //           showText={false}
            //           type="textfield"
            //           renderConfirmElement={confirmElement(`${item.id}-unit`)}
            //           renderCancelElement={cancelElement}
            //           onSubmit={value => {
            //             onSubmitRule(item, value, "unit")
            //           }}
            //         />
            //       </div>
            //     </React.Fragment>
            //   ) : (
            //     <>{isInstrument && cellContent}</>
            //   )
            // },
          },
          {
            dataField: "normalRange",
            text: t("testConfig:Normal Range"),
            style: { minWidth: 130, maxWidth: 130 },
            sort: true,
            events: {
              onClick: () => { },
            },
            formatter: (cellContent, item, index) => {
              const idHTML = `resultBlurItemFocusParent${item.id + item?.testCode?.replace('.', '')}normalRange`
              return rows.find(elem => elem.id == item.id) && (itemActiveGlobal.Key == 1 && itemActiveGlobal.parent.ID_Group == "1") ? (
                <React.Fragment>
                  <div
                    className={"resultBlurItemFocusParent"}
                    id={idHTML}
                    onKeyDown={e => {
                      checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
                    }}
                    style={{ width: 120, margin: "0 auto" }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    onBlur={() => {
                      $(`#${item.id}-normalRange`).click()
                    }}
                  >
                    <Editable
                      alwaysEditing={false}
                      disabled={false}
                      editText={item.normalRange || "---"}
                      initialValue={item.normalRange || ""}
                      id={item.normalRange}
                      isValueClickable={false}
                      mode="inline"
                      placement="top"
                      showText={false}
                      type="textfield"
                      renderConfirmElement={confirmElement(
                        `${item.id}-normalRange`
                      )}
                      renderCancelElement={cancelElement}
                      onSubmit={value => {
                        onSubmitRule(item, value, "normalRange")
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <>{isInstrument && cellContent}</>
              )
            },
          },
          // //expression
          // ...(itemActiveGlobal.Key == 2) ?
          //   [{
          //     dataField: "expression",
          //     text: "Expression",
          //     style: { minWidth: 140, maxWidth: 140 },
          //     events: {
          //       onClick: () => { },
          //     },
          //     formatter: (cellContent, item, index) => {
          //       const idHTML = `resultBlurItemFocusParent${item.id + item?.testCode?.replace('.', '')}expression`
          //       return rows.find(elem => elem.id == item.id) && (itemActiveGlobal.Key == 2 && itemActiveGlobal.parent.ID_Group == "1") ? (
          //         <React.Fragment>
          //           <div
          //             className={"resultBlurItemFocusParent"}
          //             id={idHTML}
          //             onKeyDown={e => {
          //               checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
          //             }}
          //             style={{ width: 130, margin: "0 auto" }}
          //             onClick={e => {
          //               e.stopPropagation()
          //             }}
          //             onBlur={() => {
          //               $(`#${item.id}-expression`).click()
          //             }}
          //           >
          //             <Editable
          //               alwaysEditing={false}
          //               disabled={false}
          //               editText={item.expression || "---"}
          //               initialValue={item.expression || ""}
          //               id={item.expression}
          //               isValueClickable={false}
          //               mode="inline"
          //               placement="top"
          //               showText={false}
          //               type="textfield"
          //               renderConfirmElement={confirmElement(
          //                 `${item.id}-expression`
          //               )}
          //               renderCancelElement={cancelElement}
          //               onSubmit={value => {
          //                 onSubmitRule(item, value, "expression")
          //               }}
          //             />
          //           </div>
          //         </React.Fragment>
          //       ) : (
          //         <>{isInstrument && cellContent}</>
          //       )
          //     },
          //   }] : [],
          // //end expression
          {
            dataField: "defaultResult",
            text: t("testConfig:Default Result"),
            style: { minWidth: 130, maxWidth: 130 },
            events: {
              onClick: () => { },
            },
            formatter: (cellContent, item, index) => {
              const idHTML = `resultBlurItemFocusParent${item.id + item?.testCode?.replace('.', '')}defaultResult`
              return rows.find(elem => elem.id == item.id) && (itemActiveGlobal.Key == 1 && itemActiveGlobal.parent.ID_Group == "1") ? (
                <React.Fragment>
                  <div
                    className={"resultBlurItemFocusParent"}
                    id={idHTML}
                    onKeyDown={e => {
                      checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
                    }}
                    style={{ width: 120, margin: "0 auto" }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    onBlur={() => {
                      $(`#${item.id}-defaultResult`).click()
                    }}
                  >
                    <Editable
                      alwaysEditing={false}
                      disabled={false}
                      editText={item.defaultResult || "---"}
                      initialValue={item.defaultResult || ""}
                      id={item.defaultResult}
                      isValueClickable={false}
                      mode="inline"
                      placement="top"
                      showText={false}
                      type="textfield"
                      renderConfirmElement={confirmElement(
                        `${item.id}-defaultResult`
                      )}
                      renderCancelElement={cancelElement}
                      onSubmit={value => {
                        onSubmitRule(item, value, "defaultResult")
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <>{isInstrument && cellContent}</>
              )
            },
          },
          {
            dataField: "lowerLimit",
            text: t("testConfig:Lower Limit"),
            style: { minWidth: 130, maxWidth: 130 },
            events: {
              onClick: () => { },
            },
            formatter: (cellContent, item, index) => {
              const idHTML = `resultBlurItemFocusParent${item.id + item?.testCode?.replace('.', '')}lowerLimit`
              return rows.find(elem => elem.id == item.id) && (itemActiveGlobal.Key == 1 && itemActiveGlobal.parent.ID_Group == "1") ? (
                <React.Fragment>
                  <div
                    className={"resultBlurItemFocusParent"}
                    id={idHTML}
                    onKeyDown={e => {
                      checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
                    }}
                    style={{ width: 120, margin: "0 auto" }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    onBlur={() => {
                      $(`#${item.id}-lowerLimit`).click()
                    }}
                  >
                    <Editable
                      alwaysEditing={false}
                      disabled={false}
                      editText={item.lowerLimit || "---"}
                      initialValue={item.lowerLimit || ""}
                      id={item.lowerLimit}
                      isValueClickable={false}
                      mode="inline"
                      placement="top"
                      showText={false}
                      type="textfield"
                      renderConfirmElement={confirmElement(`${item.id}-lowerLimit`)}
                      renderCancelElement={cancelElement}
                      onSubmit={value => {
                        onSubmitRule(item, value, "lowerLimit")
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <>{isInstrument && cellContent}</>
              )
            },
          },
          {
            dataField: "upperLimit",
            text: t("testConfig:Upper Limit"),
            style: { minWidth: 130, maxWidth: 130 },
            events: {
              onClick: () => { },
            },
            formatter: (cellContent, item, index) => {
              const idHTML = `resultBlurItemFocusParent${item.id + item?.testCode?.replace('.', '')}upperLimit`
              return rows.find(elem => elem.id == item.id) && (itemActiveGlobal.Key == 1 && itemActiveGlobal.parent.ID_Group == "1") ? (
                <React.Fragment>
                  <div
                    className={"resultBlurItemFocusParent"}
                    id={idHTML}
                    onKeyDown={e => {
                      checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
                    }}
                    style={{ width: 120, margin: "0 auto" }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    onBlur={() => {
                      $(`#${item.id}-upperLimit`).click()
                    }}
                  >
                    <Editable
                      alwaysEditing={false}
                      disabled={false}
                      editText={item.upperLimit || "---"}
                      initialValue={item.upperLimit || ""}
                      id={item.upperLimit}
                      isValueClickable={false}
                      mode="inline"
                      placement="top"
                      showText={false}
                      type="textfield"
                      renderConfirmElement={confirmElement(`${item.id}-upperLimit`)}
                      renderCancelElement={cancelElement}
                      onSubmit={value => {
                        onSubmitRule(item, value, "upperLimit")
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <>{isInstrument && cellContent}</>
              )
            },
          },
          {
            dataField: "lowerAlert",
            text: t("testConfig:Lower Alert"),
            style: { minWidth: 130, maxWidth: 130 },
            events: {
              onClick: () => { },
            },
            formatter: (cellContent, item, index) => {
              const idHTML = `resultBlurItemFocusParent${item.id + item?.testCode?.replace('.', '')}lowerAlert`
              return rows.find(elem => elem.id == item.id) && (itemActiveGlobal.Key == 1 && itemActiveGlobal.parent.ID_Group == "1") ? (
                <React.Fragment>
                  <div
                    className={"resultBlurItemFocusParent"}
                    id={idHTML}
                    onKeyDown={e => {
                      checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
                    }}
                    style={{ width: 120, margin: "0 auto" }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    onBlur={() => {
                      $(`#${item.id}-lowerAlert`).click()
                    }}
                  >
                    <Editable
                      alwaysEditing={false}
                      disabled={false}
                      editText={item.lowerAlert || "---"}
                      initialValue={item.lowerAlert || ""}
                      id={item.lowerAlert}
                      isValueClickable={false}
                      mode="inline"
                      placement="top"
                      showText={false}
                      type="textfield"
                      renderConfirmElement={confirmElement(`${item.id}-lowerAlert`)}
                      renderCancelElement={cancelElement}
                      onSubmit={value => {
                        onSubmitRule(item, value, "lowerAlert")
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <>{isInstrument && cellContent}</>
              )
            },
          },
          {
            dataField: "upperAlert",
            text: t("testConfig:Upper Alert"),
            style: { minWidth: 130, maxWidth: 130 },
            events: {
              onClick: () => { },
            },
            formatter: (cellContent, item, index) => {
              const idHTML = `resultBlurItemFocusParent${item.id + item?.testCode?.replace('.', '')}upperAlert`
              return rows.find(elem => elem.id == item.id) && (itemActiveGlobal.Key == 1 && itemActiveGlobal.parent.ID_Group == "1") ? (
                <React.Fragment>
                  <div
                    className={"resultBlurItemFocusParent"}
                    id={idHTML}
                    onKeyDown={e => {
                      checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
                    }}
                    style={{ width: 120, margin: "0 auto" }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    onBlur={() => {
                      $(`#${item.id}-upperAlert`).click()
                    }}
                  >
                    <Editable
                      alwaysEditing={false}
                      disabled={false}
                      editText={item.upperAlert || "---"}
                      initialValue={item.upperAlert || ""}
                      id={item.upperAlert}
                      isValueClickable={false}
                      mode="inline"
                      placement="top"
                      showText={false}
                      type="textfield"
                      renderConfirmElement={confirmElement(`${item.id}-upperAlert`)}
                      renderCancelElement={cancelElement}
                      onSubmit={value => {
                        onSubmitRule(item, value, "upperAlert")
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : <>{isInstrument && cellContent}</>
            },
          },
        ] : []),
      {
        dataField: "enable",
        text: t("testConfig:Enable"),
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocusParent${item.id + item?.testCode?.replace('.', '')}enable`
          return rows.find(elem => elem.id == item.id) && (itemActiveGlobal?.Key == 1 && itemActiveGlobal?.parent?.ID_Group == "1") ? (
            <React.Fragment>
              <div
                className={"resultBlurItemFocusParent"}
                id={idHTML}
                onKeyDown={e => {
                  checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
                }}
                style={{ width: 120, margin: "0 auto" }}
                onClick={e => {
                  e.stopPropagation()
                }}
                onBlur={() => {
                  $(`#${item.id}-enable`).click()
                }}
              >
                <FormSwitch
                  label=""
                  value={item.enable}
                  onChangeSwitch={(value) => {
                    onSubmitRule(item, value, "enable")
                  }}
                />
              </div>
            </React.Fragment>
          ) : (
            isInstrument ? <FormSwitch
              label=""
              value={item.enable}
              disabled
            /> : ''
          )
        },
      }
    ]

    const columnChild = [
      {
        dataField: "expand-cell",
        text: "",
        style: { minWidth: 32, width: 32, padding: 0 },
      },
      {
        dataField: "selection-cell",
        text: "",
        style: { minWidth: 54 },
        formatter: (cellContent, item, index) => {
          return <React.Fragment>
            <div className="tooltip-custom mx-2">
              <span onClick={() => {
                let tmpData = dataGlobal
                const indexTestCode = tmpData.findIndex(x => x.testCode === item.testCode)
                const newItem = JSON.parse(JSON.stringify(item))
                newItem.id = new Date().getTime()
                tmpData[indexTestCode].children?.push(newItem)
                setDataSource([...tmpData])
              }}>
                <i className="fas fa-copy" style={{ color: "#74788d" }}></i>
              </span>
            </div>
          </React.Fragment>
        }
      },
      {
        dataField: "",
        text: "#",
        style: { minWidth: 39 },
      },
      {
        dataField: "testName",
        text: t("testConfig:Test"),
        style: { minWidth: 200, maxWidth: 200, width: 200 },
        headerStyle: { minWidth: 200, maxWidth: 200, width: 200 }, style: { minWidth: 200 },
        sort: true,
        classes: "testName-col",
        headerClasses: "testName-col-headder"
      },
      {
        dataField: "ageType",
        text: t("testConfig:Age Type"),
        style: { minWidth: 100, maxWidth: 100 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}ageType`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-ageType`).click()
              }}
            >
              <RulePopover
                code={parameterCode.TIME_TYPE}
                title={t(DateAgeText[item.ageType]) || ""}
                onChange={(value) => {
                  onSubmitRuleChild(item, value.code, "ageType")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "age",
        text: t("testConfig:Age"),
        style: { minWidth: 100, maxWidth: 100 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}age`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-age`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.age || "---"}
                initialValue={item.age || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-age`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "age")
                }}
              />
            </div>
          </React.Fragment>
        },
      },

      {
        dataField: "gender",
        text: t("testConfig:Gender"),
        // classes: "testName-col",
        // headerClasses: "testName-col-headder",
        style: { minWidth: 100, maxWidth: 100, borderRight: '1px solid #F0F2F7' },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}gender`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-gender`).click()
              }}
            >
              <RulePopover
                code={parameterCode.GENDER}
                title={t(GenderSelectText[item.gender]) || ""}
                onChange={(value) => {
                  onSubmitRuleChild(item, value.code, "gender")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "unit",
        text: "",
        style: { minWidth: 100, maxWidth: 100 },
        // events: {
        //   onClick: () => { },
        // },
        // formatter: (cellContent, item, index) => {
        //   const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}unit`
        //   return <React.Fragment>
        //     <div
        //       className={"resultBlurItemFocus"}
        //       style={{ width: 90, margin: "0 auto" }}
        //       onClick={e => {
        //         e.stopPropagation()
        //       }}
        //       id={idHTML}
        //       onKeyDown={e => {
        //         checkAndTab(e, idHTML, 'resultBlurItemFocus')
        //       }}
        //       onBlur={() => {
        //         $(`#patient${item.id}-unit`).click()
        //       }}
        //     >
        //       <Editable
        //         alwaysEditing={false}
        //         disabled={false}
        //         editText={item.unit || "---"}
        //         initialValue={item.unit || ""}
        //         id={item.unit}
        //         isValueClickable={false}
        //         mode="inline"
        //         placement="top"
        //         showText={false}
        //         type="textfield"
        //         renderConfirmElement={confirmElement(`patient${item.id}-unit`)}
        //         renderCancelElement={cancelElement}
        //         onSubmit={value => {
        //           onSubmitRuleChild(item, value, "unit")
        //         }}
        //       />
        //     </div>
        //   </React.Fragment>
        // },
      },
      {
        dataField: "normalRange",
        text: "Normal Range",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}normalRange`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-normalRange`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.normalRange || "---"}
                initialValue={item.normalRange || ""}
                id={item.normalRange}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-normalRange`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "normalRange")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "defaultResult",
        text: "Default Result",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}defaultResult`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-defaultResult`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.defaultResult || "---"}
                initialValue={item.defaultResult || ""}
                id={item.defaultResult}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-defaultResult`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "defaultResult")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "lowerLimit",
        text: "Lower Limit",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}lowerLimit`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-lowerLimit`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.lowerLimit || "---"}
                initialValue={item.lowerLimit || ""}
                id={item.lowerLimit}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-lowerLimit`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "lowerLimit")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "upperLimit",
        text: "Upper Limit",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}upperLimit`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-upperLimit`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.upperLimit || "---"}
                initialValue={item.upperLimit || ""}
                id={item.upperLimit}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-upperLimit`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "upperLimit")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "lowerAlert",
        text: "Lower Alert",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}lowerAlert`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-lowerAlert`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.lowerAlert || "---"}
                initialValue={item.lowerAlert || ""}
                id={item.lowerAlert}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-lowerAlert`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "lowerAlert")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "upperAlert",
        text: "Upper Alert",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}upperAlert`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-upperAlert`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.upperAlert || "---"}
                initialValue={item.upperAlert || ""}
                id={item.upperAlert}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-upperAlert`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "upperAlert")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "enable",
        text: "Enable",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocusParent${item.id + item?.testCode?.replace('.', '')}enable`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocusParent"}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
              }}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              onBlur={() => {
                $(`#${item.id}-enable`).click()
              }}
            >
              <FormSwitch
                label=""
                value={item.enable}
                onChangeSwitch={(value) => {
                  onSubmitRuleChild(item, value, "enable")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "action",
        text: "",
        isDummyField: true,
        style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        formatter: (cellContent, item, index) => (
          <span onClick={() => {
            onDeleteRuleChild(item)
          }}>
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
          </span>
        ),
      },
    ]

    const columnChildAdditionalInfo = [
      {
        dataField: "expand-cell",
        text: "",
        style: { minWidth: 32, width: 32, padding: 0 },
      },
      {
        dataField: "selection-cell",
        text: "",
        style: { minWidth: 106 },
        formatter: (cellContent, item, index) => {
          return <React.Fragment>
            <div className="tooltip-custom mx-2">
              <span onClick={() => {
                let tmpData = dataGlobal
                const indexTestCode = tmpData.findIndex(x => x.sampleType === item.sampleType)
                const newItem = JSON.parse(JSON.stringify(item))
                newItem.id = new Date().getTime()
                tmpData[indexTestCode].childrenAdditionalInfo?.push(newItem)
                setDataSource([...tmpData])
              }}>
                <i className="fas fa-copy" style={{ color: "#74788d" }}></i>
              </span>
            </div>
          </React.Fragment>
        }
      },
      {
        dataField: "",
        text: "#",
        style: { minWidth: 53 },
      },
      {
        dataField: "",
        text: "Sample Type",
        style: { minWidth: 83, maxWidth: 56, borderRight: '1px solid #F0F2F7' },
        sort: true,
      },
      {
        dataField: "subCategory",
        text: t("testConfig:Category"),
        style: { minWidth: 140, maxWidth: 140 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.sampleType?.replace('.', '')}subCategory`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-subCategory`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.subCategory || "---"}
                initialValue={item.subCategory || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-subCategory`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "subCategory", "childrenAdditionalInfo")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "testCode",
        text: t("testConfig:Test Code"),
        style: { minWidth: 130, maxWidth: 125, borderRight: '1px solid #F0F2F7' },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.sampleType?.replace('.', '')}testCode`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-testCode`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.testCode || "---"}
                initialValue={item.testCode || ""}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-testCode`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "testCode", "childrenAdditionalInfo")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "subId",
        text: "testConfig:Sub Id",
        style: { minWidth: 122, maxWidth: 122 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.sampleType?.replace('.', '')}subId`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-subId`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.subId || "---"}
                initialValue={item.subId || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-subId`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "subId", "childrenAdditionalInfo")
                }}
              />
            </div>
          </React.Fragment >
        },
      },
      {
        dataField: "color",
        text: "Color",
        style: { minWidth: 123, maxWidth: 123 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.sampleType?.replace('.', '')}color`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-color`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.color || "---"}
                initialValue={item.color || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-color`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "color", "childrenAdditionalInfo")
                }}
              />
            </div>
          </React.Fragment >
        },
      },
      {
        dataField: "labels",
        text: "Labels",
        events: {
          onClick: () => { },
        },
        style: { minWidth: 122, maxWidth: 122 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.sampleType?.replace('.', '')}labels`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              style={{ width: 130, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-labels`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.labels || "---"}
                initialValue={item.labels || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-labels`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "labels", "childrenAdditionalInfo")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "enable",
        text: "Enable",
        style: { minWidth: 200, maxWidth: 200 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocusParent${item.id + item?.sampleType?.replace('.', '')}enable`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocusParent"}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
              }}
              style={{ width: 120, margin: "0 auto", marginLeft: '33px' }}
              onClick={e => {
                e.stopPropagation()
              }}
              onBlur={() => {
                $(`#${item.id}-enable`).click()
              }}
            >
              <FormSwitch
                label=""
                value={item.enable}
                onChangeSwitch={(value) => {
                  onSubmitRuleChild(item, value, "enable", "childrenAdditionalInfo")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "action",
        text: "",
        isDummyField: true,
        style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        formatter: (cellContent, item, index) => (
          <span onClick={() => {
            onDeleteRuleChild(item, 'childrenAdditionalInfo')
          }}>
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
          </span>
        ),
      },
    ]

    const columnChildResultTime = [
      {
        dataField: "expand-cell",
        text: "",
        style: { minWidth: 32, width: 32, padding: 0 },
      },
      {
        dataField: "selection-cell",
        text: "",
        style: { minWidth: 44 },
      },
      {
        dataField: "",
        text: "#",
        style: { minWidth: 48 },
      },
      {
        dataField: "",
        text: "Category",
        style: { minWidth: 200, maxWidth: 200, width: 200 },
        headerStyle: { minWidth: 200, maxWidth: 200, width: 200 },
        style: { minWidth: 200, borderRight: '1px solid #F0F2F7' },
        sort: true,
        // classes: "testName-col",
      },
      {
        dataField: "serviceType",
        text: "Service Type",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.subCategory?.replace('.', '')}serviceType`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-serviceType`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.serviceType || "---"}
                initialValue={item.serviceType || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-serviceType`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "serviceType", "childrenResultTime")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "profileCode",
        text: "Profile Code",
        style: { minWidth: 115, maxWidth: 115 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.subCategory?.replace('.', '')}profileCode`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              style={{ width: 90, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-profileCode`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.profileCode || "---"}
                initialValue={item.profileCode || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-profileCode`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "profileCode", "childrenResultTime")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "testCode",
        text: t("testConfig:Test Code"),
        style: { minWidth: 119, maxWidth: 119 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.subCategory?.replace('.', '')}testCode`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-testCode`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.testCode || "---"}
                initialValue={item.testCode || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-testCode`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "testCode", "childrenResultTime")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "inPatient",
        text: "In Patient",
        style: { minWidth: 140, maxWidth: 140 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.subCategory?.replace('.', '')}inPatient`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              style={{ width: 130, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-inPatient`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.inPatient || "---"}
                initialValue={item.inPatient || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-inPatient`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "inPatient", "childrenResultTime")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "timeCollection",
        text: t("testConfig:Time Collection"),
        style: { minWidth: 100, maxWidth: 100, borderRight: '1px solid #F0F2F7' },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.subCategory?.replace('.', '')}timeCollection`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-timeCollection`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.timeCollection || "---"}
                initialValue={item.timeCollection || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-timeCollection`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "timeCollection", "childrenResultTime")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "resultTime",
        text: "Result Time",
        style: { minWidth: 100, maxWidth: 100 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.subCategory?.replace('.', '')}resultTime`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#patient${item.id}-resultTime`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.resultTime || "---"}
                initialValue={item.resultTime || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-resultTime`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "resultTime", "childrenResultTime")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "enable",
        text: "Enable",
        style: { minWidth: 170, maxWidth: 170 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocusParent${item.id + item?.subCategory?.replace('.', '')}enable`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocusParent"}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
              }}
              style={{ width: 120, margin: "0 auto", paddingLeft: '7px' }}
              onClick={e => {
                e.stopPropagation()
              }}
              onBlur={() => {
                $(`#${item.id}-enable`).click()
              }}
            >
              <FormSwitch
                label=""
                value={item.enable}
                onChangeSwitch={(value) => {
                  onSubmitRuleChild(item, value, "enable", "childrenResultTime")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "action",
        text: "",
        isDummyField: true,
        style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        formatter: (cellContent, item, index) => (
          <span onClick={() => {
            onDeleteRuleChild(item, 'childrenResultTime')
          }}>
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
          </span>
        ),
      },
    ]

    const columnChildInstrument = [
      {
        dataField: "expand-cell",
        text: "",
        style: { minWidth: 32, width: 32, padding: 0 },
      },
      {
        dataField: "selection-cell",
        text: "",
        style: { minWidth: 52 },
        formatter: (cellContent, item, index) => {
          return <React.Fragment>
            <div className="tooltip-custom mx-2">
              <span onClick={() => {
                let tmpData = dataGlobal
                const indexTestCode = tmpData.findIndex(x => x.testCode === item.testCode)
                const newItem = JSON.parse(JSON.stringify(item))
                newItem.id = new Date().getTime()
                tmpData[indexTestCode].childrenInstrument?.push(newItem)
                setDataSource([...tmpData])
              }}>
                <i className="fas fa-copy" style={{ color: "#74788d", zIndex: 10 }}></i>
              </span>
            </div>
          </React.Fragment>
        }
      },
      {
        dataField: "",
        text: "#",
        style: { minWidth: 39 },
      },
      {
        dataField: "testName",
        text: "testConfig:Test",
        style: { minWidth: 200, maxWidth: 200, width: 200 },
        headerStyle: { minWidth: 200, maxWidth: 200, width: 200 },
        sort: true,
        classes: "testName-col",
        headerClasses: "testName-col-headder"
      },
      {
        dataField: "instrument",
        text: t("testConfig:InstrumentName"),
        style: { minWidth: 150, maxWidth: 150, width: 150 },
        headerStyle: { minWidth: 150, maxWidth: 150, width: 150 },

      },
      {
        dataField: "ageType",
        text: t("testConfig:Age Type"),
        style: { minWidth: 100, maxWidth: 100 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}ageType`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-ageType`).click()
              }}
            >
              <RulePopover
                code={parameterCode.TIME_TYPE}
                title={t(DateAgeText[item.ageType]) || ""}
                onChange={(value) => {
                  onSubmitRuleChild(item, value.code, "ageType", "childrenInstrument")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "age",
        text: t("testConfig:Age"),
        style: { minWidth: 100, maxWidth: 100 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}age`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-age`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.age || "---"}
                initialValue={item.age || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-age`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "age", "childrenInstrument")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "gender",
        text: t("testConfig:Gender"),
        // classes: "testName-col",
        // headerClasses: "testName-col-headder",
        style: { minWidth: 100, maxWidth: 100, borderRight: '1px solid #F0F2F7' },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}gender`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-gender`).click()
              }}
            >
              <RulePopover
                code={parameterCode.GENDER}
                title={t(GenderSelectText[item.gender]) || ""}
                onChange={(value) => {
                  onSubmitRuleChild(item, value.code, "gender", "childrenInstrument")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "unit",
        text: "",
        style: { minWidth: 100, maxWidth: 100 },
        // events: {
        //   onClick: () => { },
        // },
        // formatter: (cellContent, item, index) => {
        //   const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}unit`
        //   return <React.Fragment>
        //     <div
        //       id={idHTML}
        //       className={"resultBlurItemFocus"}
        //       onKeyDown={e => {
        //         checkAndTab(e, idHTML, 'resultBlurItemFocus')
        //       }}
        //       style={{ width: 90, margin: "0 auto" }}
        //       onClick={e => {
        //         e.stopPropagation()
        //       }}
        //       onBlur={() => {
        //         $(`#patient${item.id}-unit`).click()
        //       }}
        //     >
        //       <Editable
        //         alwaysEditing={false}
        //         disabled={false}
        //         editText={item.unit || "---"}
        //         initialValue={item.unit || ""}
        //         id={item.unit}
        //         isValueClickable={false}
        //         mode="inline"
        //         placement="top"
        //         showText={false}
        //         type="textfield"
        //         renderConfirmElement={confirmElement(`patient${item.id}-unit`)}
        //         renderCancelElement={cancelElement}
        //         onSubmit={value => {
        //           onSubmitRuleChild(item, value, "unit", "childrenInstrument")
        //         }}
        //       />
        //     </div>
        //   </React.Fragment>
        // },
      },
      {
        dataField: "normalRange",
        text: "Normal Range",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}normalRange`
          return <React.Fragment>
            <div
              id={idHTML}
              className={"resultBlurItemFocus"}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              onBlur={() => {
                $(`#patient${item.id}-normalRange`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.normalRange || "---"}
                initialValue={item.normalRange || ""}
                id={item.normalRange}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-normalRange`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "normalRange", "childrenInstrument")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "defaultResult",
        text: "Default Result",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}defaultResult`
          return <React.Fragment>
            <div
              id={idHTML}
              className={"resultBlurItemFocus"}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              onBlur={() => {
                $(`#patient${item.id}-defaultResult`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.defaultResult || "---"}
                initialValue={item.defaultResult || ""}
                id={item.defaultResult}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-defaultResult`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "defaultResult", "childrenInstrument")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "lowerLimit",
        text: "Lower Limit",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}lowerLimit`
          return <React.Fragment>
            <div
              id={idHTML}
              className={"resultBlurItemFocus"}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              onBlur={() => {
                $(`#patient${item.id}-lowerLimit`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.lowerLimit || "---"}
                initialValue={item.lowerLimit || ""}
                id={item.lowerLimit}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-lowerLimit`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "lowerLimit", "childrenInstrument")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "upperLimit",
        text: "Upper Limit",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}upperLimit`
          return <React.Fragment>
            <div
              id={idHTML}
              className={"resultBlurItemFocus"}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              onBlur={() => {
                $(`#patient${item.id}-upperLimit`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.upperLimit || "---"}
                initialValue={item.upperLimit || ""}
                id={item.upperLimit}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-upperLimit`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "upperLimit", "childrenInstrument")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "lowerAlert",
        text: "Lower Alert",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}lowerAlert`
          return <React.Fragment>
            <div
              id={idHTML}
              className={"resultBlurItemFocus"}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              onBlur={() => {
                $(`#patient${item.id}-lowerAlert`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.lowerAlert || "---"}
                initialValue={item.lowerAlert || ""}
                id={item.lowerAlert}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-lowerAlert`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "lowerAlert", "childrenInstrument")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "upperAlert",
        text: "Upper Alert",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + item?.testCode?.replace('.', '')}upperAlert`
          return <React.Fragment>
            <div
              id={idHTML}
              className={"resultBlurItemFocus"}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              onBlur={() => {
                $(`#patient${item.id}-upperAlert`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.upperAlert || "---"}
                initialValue={item.upperAlert || ""}
                id={item.upperAlert}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`patient${item.id}-upperAlert`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "upperAlert", "childrenInstrument")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "enable",
        text: "Enable",
        style: { minWidth: 130, maxWidth: 130 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocusParent${item.id + item?.testCode?.replace('.', '')}enable`
          return <div
            id={idHTML}
            className={"resultBlurItemFocus"}
            onKeyDown={e => {
              checkAndTab(e, idHTML, 'resultBlurItemFocus')
            }}
            style={{ width: 120, margin: "0 auto" }}
            onClick={e => {
              e.stopPropagation()
            }}
            onBlur={() => {
              $(`#patient${item.id}-upperAlert`).click()
            }}
          >
            <FormSwitch
              label=""
              value={item.enable}
              onChangeSwitch={(value) => {
                onSubmitRuleChild(item, value, "enable", "childrenInstrument")
              }}
            />
          </div>
        },
      },
      {
        dataField: "action",
        text: "",
        isDummyField: true,
        style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        formatter: (cellContent, item, index) => (
          <span onClick={() => {
            onDeleteRuleChild(item, "childrenInstrument")
          }}>
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
          </span>
        ),
      },
    ]

    useEffect(() => {
      const search = {
        search: ''
      }
      setModel({ ...search })

    }, [itemActive.Key])

    const confirmElement = id => (
      <button
        id={id}
        type="submit"
        className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
      >
        <i className="mdi mdi-check"></i>
      </button>
    )

    /** Cancel button */
    const cancelElement = (
      <button
        type="button"
        className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none"
      >
        <i className="mdi mdi-close"></i>
      </button>
    )

    const onSubmitRule = (item, value, name) => {
      item[name] = value
      const dataReplace = data.find(obj => obj.id === item.id)
      Object.assign(dataReplace, item)
      onGetTestsSuccess(data)
      setDataSource([...dataSource])
    }

    useEffect(() => {
      onChangeData([...dataSource])
      dataGlobal = [...dataSource]
    }, [dataSource])

    const onSubmitRuleChild = (item, value, name, key = 'children') => {
      let children = dataSource.find(x => x.id == item.parentId)
      if (children) {
        const dataReplace = children[key].find(obj => obj.id === item.id)
        if (name === 'normalRange') {
          const normalRangeSplit = value.split("-")
          if (normalRangeSplit.length > 1) {
            dataReplace['lowerLimit'] = normalRangeSplit[0]
            dataReplace['upperLimit'] = normalRangeSplit[1]
          }
        }
        dataReplace[name] = value
        setDataSource([...dataSource])
      }
    }

    const onDeleteRuleChild = (item, key = 'children') => {
      let children = dataSource.find(x => x.id == item.parentId)
      if (children) {
        if (children[key].length == 1) {
          setColumnExpand(expandGlobal.filter(x => x != item.parentId))
        }
        children[key] = children[key].filter(obj => obj.id !== item.id)
        setDataSource([...dataSource])
      }
    }

    useEffect(() => {
      let tmp = columnDisplay;
      if (
        (itemActive.Key == 2 && itemActive.parent.ID_Group == "1") ||
        (itemActive.Key == 3 && itemActive.parent.ID_Group == "1")
      ) {
        setIsExpand(true)
        if (itemActiveGlobal.Key != itemActive.Key || itemActiveGlobal.parent.ID_Group != itemActive.parent.ID_Group) {
          setColumnExpand([])
        }
        if (columnDisplay.findIndex(x => x.dataField == "add") < 0) {
          tmp.push(columnAdd)
        }
      }
      else {
        setColumnExpand([])
        setIsExpand(false)
        if (columnDisplay.findIndex(x => x.dataField == "add") >= 0) {
          tmp = tmp.filter(x => x.dataField != 'add')
          setColumnDisplay([...tmp])
        }
      }
      setColumnDisplay([...tmp])
      itemActiveGlobal = itemActive
      //reset table
      let el = document.getElementById("resizeMeAsync")
      if (el) {
        el.scrollIntoView(true)
        setTimeout(() => {
          setPage(0)
          pageGlobal = 0
        }, 100);
      }
    }, [itemActive])

    const UpdateExpandColumn = (code, row) => {
      let key = 'children'
      if (itemActiveGlobal.Key == 2 && itemActiveGlobal.parent.ID_Group == "1") {
        key = 'children'
      }
      else if (itemActiveGlobal.Key == 3 && itemActiveGlobal.parent.ID_Group == "1") {
        key = 'childrenInstrument'
      } else if (itemActiveGlobal.Key == 5 && itemActiveGlobal.parent.ID_Group == "1") {
        key = 'childrenResultTime'
      } else if (itemActiveGlobal.Key == 4 && itemActiveGlobal.parent.ID_Group == "1") {
        key = 'childrenAdditionalInfo'
      }
      let children = row[key] || []
      if (children.length == 0)
        return;
      let result = columnExpand
      let ind = result.findIndex(x => x == code)
      if (ind < 0) {
        result.push(code)
      }
      else {
        result = result.filter(x => x != code)
      }
      setColumnExpand([...result])
    }

    const getNonExpand = () => {
      let res = []
      if (itemActiveGlobal.Key == 2 && itemActiveGlobal.parent.ID_Group == "1") {
        res = dataSource.filter(x => !x.children || x.children.length == 0).map(x => x.id)
      }
      else if (itemActiveGlobal.Key == 3 && itemActiveGlobal.parent.ID_Group == "1") {
        res = dataSource.filter(x => !x.childrenInstrument || x.childrenInstrument.length == 0).map(x => x.id)
      } else if (itemActiveGlobal.Key == 5 && itemActiveGlobal.parent.ID_Group == "1") {
        res = dataSource.filter(x => !x.childrenResultTime || x.childrenResultTime.length == 0).map(x => x.id)
      } else if (itemActiveGlobal.Key == 4 && itemActiveGlobal.parent.ID_Group == "1") {
        res = dataSource.filter(x => !x.childrenAdditionalInfo || x.childrenAdditionalInfo.length == 0).map(x => x.id)
      }
      res.push(-1)
      return res
    }

    const expandRow = {
      renderer: (row, rowIndex) => {
        return (<React.Fragment>
          {(itemActiveGlobal.Key === 2 && itemActiveGlobal.parent.ID_Group == "1") ?
            (
              row.children ?
                <div className="table-expand-custom-header tableinline-scrollable table-expand-custom-child">
                  <CustomBootstrapInlineTable
                    columns={columnChild}
                    data={[...row.children] || []}
                    isScrollable
                    searchText={""}
                    tableHeaderClass={"col-hidden"}
                    keyField={"id"}
                    isHover={false}
                  />
                </div>
                : null
            )
            :
            ((itemActiveGlobal.Key === 5 && itemActiveGlobal.parent.ID_Group == "1") ?
              (row.childrenResultTime ? <div className="table-expand-custom-header tableinline-scrollable table-expand-custom-child">
                <CustomBootstrapInlineTable
                  columns={columnChildResultTime}
                  data={[...row.childrenResultTime] || []}
                  isScrollable
                  searchText={""}
                  tableHeaderClass={"col-hidden"}
                  keyField={"id"}
                  isHover={false}
                />
              </div> : null)
              : ((itemActiveGlobal.Key === 4 && itemActiveGlobal.parent.ID_Group == "1") ?
                (row.childrenAdditionalInfo ? <div className="table-expand-custom-header tableinline-scrollable table-expand-custom-child">
                  <CustomBootstrapInlineTable
                    columns={columnChildAdditionalInfo}
                    data={[...row.childrenAdditionalInfo] || []}
                    isScrollable
                    searchText={""}
                    tableHeaderClass={"col-hidden"}
                    keyField={"id"}
                    isHover={false}
                  />
                </div> : null)
                :
                (
                  row.childrenInstrument ?
                    <div className="table-expand-custom-header tableinline-scrollable table-expand-custom-child">
                      <CustomBootstrapInlineTable
                        columns={columnChildInstrument}
                        data={[...row.childrenInstrument] || []}
                        isScrollable
                        searchText={""}
                        tableHeaderClass={"col-hidden"}
                        keyField={"id"}
                        isHover={false}
                      />
                    </div>
                    : null
                )))
          }
        </React.Fragment>)
      },
      parentClassName: (isExpanded, row, rowIndex) => {
        return columnExpand.findIndex(x => x == row.id) >= 0 ? 'row-expanded' : 'row-noneexpanded'
      },
      // expanded: columnExpand,
      nonExpandable: getNonExpand(),
      showExpandColumn: !(itemActiveGlobal.Key == 1 && itemActiveGlobal.parent.ID_Group == "1"),
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <b style={{ color: '#556ee6' }}><i className="fas fa-angle-up"></i></b>;
        }
        return <b style={{ color: '#556ee6' }}><i className="fas fa-angle-down"></i></b>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <b style={{ color: '#556ee6' }}><i className="fas fa-angle-up"></i></b>
          );
        }
        return (
          <b style={{ color: '#556ee6' }}><i className="fas fa-angle-down"></i></b>
        );
      }
    };

    const io = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }
        pageGlobal++;
        setPage(pageGlobal)
      });
    });

    let elEnd = document.getElementById(`watch_end_of_document`)
    useEffect(() => {
      if (elEnd) {
        io.observe(elEnd);
      }
    }, [elEnd])

    const getDataDisplay = () => {
      if (dataSource?.length > 0) {
        let res = [...dataSource].slice(0, size * (page + 1))
        res.push({ id: -1, testCode: '' })
        return res
      }
    }

    return (
      <div style={{ backgroundColor: 'white' }} className={`table-expand-custom ${dataSource.length === 0 ? '' : 'table-rule-management'}`}>
        {loadingTests &&
          <div className="position-absolute" style={{ width: '98%', height: '93%', zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
            <div className="w-100 h-100"><div className="text-center">
              <div className="spinner-border text-primary" role="status" style={{ marginTop: 150 }}>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            </div>
          </div>
        }
        {columnDisplay.length > 0 &&
          <CustomBootstrapTableAsync
            updatedTime={updatedTime}
            columns={[...columnDisplay]}
            search
            data={getDataDisplay()}
            onSort={onSort}
            keyField="id"
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            selectedRows={rows?.map(r => r.id)}
            searchText={model.search}
            onSearch={onSearch}
            resource={RESOURCE}
            onRefresh={onRefresh}
            striped={true}
            isScrollable={true}
            showSelectRow={true}
            isHover={false}
            expandRow={expandRow}
            isEnableExport={false}
            onScroll={(e) => {
              onGetPositionScroll({
                left: e.target.scrollLeft,
                top: e.target.scrollTop
              })
            }}
            paging={paging}
            onRowClick={(e, row, rowIndex) => {
              if (isExpand == false)
                setColumnExpand([])
              else
                UpdateExpandColumn(row.id, row);
            }}
          />
        }
      </div>
    )
  })

const mapStateToProps = ({ RuleManagement }) => ({
  loadingTests: RuleManagement.loadingTests
})

const mapDispatchToProps = dispatch => ({
  onGetTestsSuccess: payload => dispatch(getFullTestSuccess(payload)),
  onGetPositionScroll: payload => dispatch(getPositionScroll(payload))
})

RuleManagementTable.displayName = 'RuleManagementTable';
export default withTranslation(["testConfig", "common"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(RuleManagementTable))
