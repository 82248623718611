import i18n from "i18next";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
//setting redux states
import {
    CONFIRM_TESTRESULT_RESULTS,
    DELETE_TESTRESULT_RESULTS,
    GET_TESTPERFORM_RESULTS,
    GET_TESTRESULT_RESULTS, INVALID_TESTRESULT_RESULTS, UNCONFIRM_TESTRESULT_RESULTS, UPDATE_TESTRESULT_RESULTS, VALID_TESTRESULT_RESULTS,
    GET_UPDATABLE_RESULTFIELD,
    RERUN_TESTRESULT_RESULTS,
    RUNRULE_TESTRESULT_RESULTS,
    GET_GENERAL_HISTORY_RESULT,
    GET_PATIENT_RESULT,
    GET_TESTRESULT_RESULT_AUDITLOG,
    WARNING_RULE_TESTRESULT_RESULTS,
    GET_TEST_REQUEST_AUDITLOG,
    RESET_TESTRESULTS_SEARCH_QUERY,
    RESET_TESTRESULTS_SEARCH_QUERY_CALLBACK,
    APPROVED_VALID_AND_PUBLISH_RESULT,
    GET_PATHOLOGY_RESULT,
    CREATE_PATHOLOGY_RESULT,
    UPDATE_PATHOLOGY_RESULT,
    UNSIGN_TESTRESULT_RESULTS,
    SIGN_TESTRESULT_RESULTS,
} from "./actionTypes";

import { TestRequest_Test_Type } from "constant";

import {
    getTestById
} from "helpers/app-backend/tests_backend_helper";

import { showToast } from "components/Common";
import {
    getFullTestProfileById
} from "helpers/app-backend/testProfiles_backend_helper";
import {
    confirmResultsById, getTestPerformResult, getTestResultResultProfileByResultId, getTestResultResultTestByResultId,
    inValidResultsById, unConfirmResultsById, updateResultResultTestById, validResultsById, getUpdatableResultField,
    rerunResultsById,
    runRuleResultsById,
    deleteResultDetailById,
    getGeneralHistoryResult,
    getPatientResult,
    getTestResultResultAuditLog,
    warningRuleResultsById,
    approvedValidAndPublishResult,
    UpdateResultFileId,
    getPathologyResult,
    updatePathologyResult,
    unsignFileResult,
    signFileResult,
} from "helpers/app-backend/testResult_backend_helper";
import {
    ConfirmTestResultResultsFail, getTestResultResultsFail, getTestResultResultsSuccess, InValidTestResultResultsFail,
    UnConfirmTestResultResultsFail, UpdateTestResultResultsFail, UpdateTestResultResultsSuccess, ValidTestResultResultsFail,
    getUpdatableResultFieldSuccess, getUpdatableResultFieldFail,
    rerunTestResultResultsFail, rerunTestResultResultsSuccess, runRuleTestResultResultsSuccess, runRuleTestResultResultsFail, getGeneralHistoryResultFail,
    getGeneralHistoryResultSuccess, getPatientResultSuccess, getPatientResultFail, setRowCountSuccess, getTestResultResultAuditLogSuccess, getTestResultResultAuditLogFail, getParameterGroupByCodes, warningRuleTestResultResultsFail, resetSearchQuery, getPathologyResultSuccess, getPathologyResultFail, createPathologyResultSuccess, createPathologyResultFail, updatePathologyResultSuccess, updatePathologyResultFail,
    SignTestResultResultsFail,
    SignTestResultResultsSuccess,
    UnsignTestResultResultsSuccess,
    UnsignTestResultResultsFail
} from "./actions";
import { GetActionFromShortTypeAuditLog, GetSelectedRole, GetTypeFromShortTypeAuditLog, ifNull, spreadSearchQuery } from "helpers/utilities";
import { getAllCompanies, getAllDepartments, getAllParameterGroupByCodes, getAllUsers } from "helpers/app-backend";
import { isEmpty, take } from "lodash";
import { addFileFail, addFileSuccess } from "store/actions";

const t = (msg, param) => i18n.t(msg, param)
let numGlobal = 1;
function* fetchTestResultResuts({ payload }) {
    try {
        const id = payload.id;
        let { tests, profiles, fields } = yield all({
            tests: call(getTestResultResultTestByResultId, id),
            profiles: call(getTestResultResultProfileByResultId, id),
            fields: call(getUpdatableResultField),
        })
        const arrCategory = tests.filter(x => x.testCategory).map(x => x.testCategory) || [];
        let resCategory = []
        if (arrCategory.length > 0) {
            let tmp = yield call(getAllParameterGroupByCodes, arrCategory)
            if (tmp && tmp.length > 0) {
                resCategory = [...(tmp.map(x => ({ label: x.groupName, value: x.code })))]
            }
        }
        const arrUserCreate = tests.filter(x => x.confirmedBy).map(x => x.confirmedBy) || [];
        const arrUserValid = tests.filter(x => x.validatedBy).map(x => x.validatedBy) || [];
        const arrUser = [...arrUserCreate, ...arrUserValid]
        const arrUserIdDistinct = arrUser.filter((x, index) => !arrUser.includes(x, index + 1)) || []
        let resUser = [];
        if (arrUserIdDistinct.length > 0)
            resUser = yield call(getAllUsers, { id: arrUserIdDistinct })
        if (resUser.data?.length > 0) {
            tests.forEach((element, _index) => {
                const itemUserCreate = resUser.data?.find(x => x.id == element.confirmedBy)
                const itemUserValid = resUser.data?.find(x => x.id == element.validatedBy)
                if (itemUserCreate)
                    element.confirmedBy = "".concat(ifNull(itemUserCreate.familyName), ifNull(" " + itemUserCreate.givenName))
                if (itemUserValid)
                    element.validatedBy = "".concat(ifNull(itemUserValid.familyName), ifNull(" " + itemUserValid.givenName))
            })
        }

        //department
        let arrDepartment = tests.map(x => isNaN(x.departmentId) ? 0 : x.departmentId) || [];
        let resDepartment = [];
        arrDepartment = arrDepartment.filter(x => !isEmpty(`${x}`))
        if (arrDepartment.length > 0) {
            const deptValues = yield call(getAllDepartments, { id: arrDepartment, size: 0 })
            resDepartment = deptValues.data || []
        }
        //company
        const arrCompanyId = tests.map(x => isNaN(x.companyId) ? 0 : x.companyId) || [];
        const arrCompanyIdDistinct = arrCompanyId.filter((x, index) => !arrCompanyId.includes(x, index + 1)) || []
        let resCompany = [];
        if (arrCompanyIdDistinct.length > 0)
            resCompany = yield call(getAllCompanies, { id: arrCompanyIdDistinct, size: 0 })
        let treeData = [];
        if (profiles) {
            for (let item of profiles) {
                const data = yield fetchTestRequestProfileById(item.profileCode)
                data.displayOrder = item.displayOrder
                if (data)
                    treeData = CheckTreeDataProfile(treeData, data, item)
            }
        }
        let result = treeData || [];
        if (result && result.length > 0) {
            result = result.map(item => {
                if (item.type == TestRequest_Test_Type.PROFILE_GROUP) {
                    item.children = item.children.filter(x =>
                        (x.type === TestRequest_Test_Type.PROFILE
                            && (profiles.filter(e => e.profileCode === x.code).length > 0))
                        || (x.type === TestRequest_Test_Type.TEST
                            && (tests.filter(y => y.testCode === x.code).length > 0))
                    )
                    item.children = item.children.map(child => {
                        if (child.type == TestRequest_Test_Type.PROFILE) {

                            child.children = child.children.filter(x =>
                            (x.type === TestRequest_Test_Type.TEST
                                && (tests.filter(y => y.testCode === x.code).length > 0))
                            )

                            child.children = child.children.map(test => {
                                let testTemp = tests.find(t => t.testCode == test.code);
                                test = { ...testTemp, ...test }
                                test.sampleType = testTemp.sampleType;
                                test.sampleTypeName = testTemp.sampleTypeName;
                                test.sid = testTemp?.sid;
                                test.result = testTemp?.result;
                                test.oldResult = testTemp?.oldResult
                                test.resultTestId = testTemp?.resultTestId;
                                test.resultTestFinalId = testTemp?.resultTestFinalId;
                                test.state = testTemp?.state;
                                test.validatedDate = testTemp?.validatedDate;
                                test.expression = testTemp?.expression;
                                test.lowerLimit = testTemp?.lowerLimit;
                                test.lowerWarning = testTemp?.lowerWarning;
                                test.higherLimit = testTemp?.higherLimit;
                                test.higherWarning = testTemp?.higherWarning;
                                test.machineName = testTemp?.machineName;
                                test.normalRangeWarning = testTemp?.normalRangeWarning;
                                test.normalRange = testTemp?.normalRange;
                                test.download = testTemp?.download;
                                return test;
                            })
                        }
                        if (child.type == TestRequest_Test_Type.TEST) {
                            let testTemp = tests.find(t => t.testCode == child.code);
                            child = { ...testTemp, ...child }
                            child.companyName = resCompany.data?.find(x => x.id == child.companyId)?.shortName || ''
                            child.departmentName = resDepartment?.find(x => `${x.id}` == `${child.departmentId}`)?.name || ''
                            child.resultTestId = testTemp?.resultTestId;
                            child.resultTestFinalId = testTemp?.resultTestFinalId;
                            child.sampleType = testTemp.sampleType;
                            child.sampleTypeName = testTemp.sampleTypeName;
                            child.sid = testTemp?.sid;
                            child.result = testTemp?.result;
                            child.oldResult = testTemp?.oldResult
                            child.state = testTemp?.state;
                            child.validatedDate = testTemp?.validatedDate;
                            child.expression = testTemp?.expression;

                            child.lowerLimit = testTemp?.lowerLimit;
                            child.lowerWarning = testTemp?.lowerWarning;
                            child.higherLimit = testTemp?.higherLimit;
                            child.higherWarning = testTemp?.higherWarning;
                            child.machineName = testTemp?.machineName;
                            child.normalRangeWarning = testTemp?.normalRangeWarning;
                            child.normalRange = testTemp?.normalRange;
                            child.download = testTemp?.download;
                        }

                        return child
                    })
                }

                if (item.type == TestRequest_Test_Type.PROFILE) {
                    item.children = item.children.filter(x =>
                    (x.type === TestRequest_Test_Type.TEST
                        && (tests.filter(y => y.testCode === x.code).length > 0))
                    );
                    item.children = item.children.map(test => {
                        let testTemp = tests.find(t => t.testCode == test.code);
                        test = { ...testTemp, ...test }
                        test.companyName = resCompany.data?.find(x => x.id == test.companyId)?.shortName || ''
                        test.departmentName = resDepartment?.find(x => `${x.id}` == `${test.departmentId}`)?.name || ''
                        test.sampleType = testTemp.sampleType;
                        test.sampleTypeName = testTemp.sampleTypeName;
                        test.sid = testTemp?.sid;
                        test.result = testTemp?.result;
                        test.oldResult = testTemp?.oldResult
                        test.resultTestId = testTemp?.resultTestId;
                        test.resultTestFinalId = testTemp?.resultTestFinalId;
                        test.state = testTemp?.state;
                        test.validatedDate = testTemp?.validatedDate;
                        test.expression = testTemp?.expression;
                        test.lowerLimit = testTemp?.lowerLimit;
                        test.lowerWarning = testTemp?.lowerWarning;
                        test.higherLimit = testTemp?.higherLimit;
                        test.higherWarning = testTemp?.higherWarning;
                        test.machineName = testTemp?.machineName;
                        test.normalRangeWarning = testTemp?.normalRangeWarning;
                        test.normalRange = testTemp?.normalRange;
                        test.download = testTemp?.download;
                        return test;
                    })
                }

                return item;
            })
            result.forEach(item => {
                if (item.type === TestRequest_Test_Type.PROFILE) {
                    result.filter(g => g.type === TestRequest_Test_Type.PROFILE_GROUP).forEach(gr => {
                        if (gr.children.find(x => x.code === item.code)) {
                            result = result.filter(pr => pr.code != item.code);
                        }
                    });
                }
            })
        }
        if (tests) {
            for (let item of tests.filter(test => !test.profileCode)) {
                const response = yield fetchTestRequestTestById(item.testCode)
                result = CheckTreeDataTest(result, item, response, resCompany, resDepartment)
            }
        }
        result = result.sort((a, b) => a.displayOrder - b.displayOrder);
        numGlobal = 1;
        if (result && result.length > 0) {
            result = InsertNo(result)
        }

        yield put(getParameterGroupByCodes(resCategory))
        yield put(setRowCountSuccess(numGlobal))
        yield put(getTestResultResultsSuccess(result))

        payload.callback && payload.callback()
    } catch (error) {
        console.log(error);
        payload.callback && payload.callback()
        yield put(getTestResultResultsFail(error))
    }
}


function InsertNo(data, parentNo = '') {
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const currentNo = parentNo === '' ? `${numGlobal++}` : `${parentNo}.${index + 1}`;
        element.no = currentNo;
        element.isPrint = false;
        if (element.children && element.children.length > 0) {
            InsertNo(element.children, currentNo);
        }
    }
    return data;
}

function* fetchTestRequestProfileById(code) {
    const response = yield call(getFullTestProfileById, code)
    return response;
}

function* fetchTestRequestTestById(code) {
    const response = yield call(getTestById, code)
    return response;
}

function CheckTreeDataProfile(treeData, response, itemResult) {
    let result = [...treeData]
    if (response.type === TestRequest_Test_Type.PROFILE_GROUP) {
        if (!result.find(x => x.type === TestRequest_Test_Type.PROFILE_GROUP && x.code === response.code)) {//chưa có profile_group nào trong list
            response.resultTestId = itemResult?.resultTestId;
            response.resultTestFinalId = itemResult?.resultTestFinalId;
            response.result = itemResult?.result;
            response.resultProfileId = itemResult?.resultProfileId;
            response.vendorCode = itemResult?.vendorCode;
            response.sentTime = itemResult?.sentTime;
            response.sentUser = itemResult?.sentUser;
            response.sentUserId = itemResult?.sentUserId;
            result.push(response)
        }
    }
    if (response.type === TestRequest_Test_Type.PROFILE) {
        let parents = result.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);

        if (!parents.length) {
            response.resultTestId = itemResult?.resultTestId;
            response.resultTestFinalId = itemResult?.resultTestFinalId;
            response.result = itemResult?.result;
            response.resultProfileId = itemResult?.resultProfileId;
            response.vendorCode = itemResult?.vendorCode;
            response.sentTime = itemResult?.sentTime;
            response.sentUser = itemResult?.sentUser;
            response.sentUserId = itemResult?.sentUserId;
            result.push(response)
        } else {
            parents.forEach(parent => {
                let item = parent?.children.find(x => x.type === TestRequest_Test_Type.PROFILE
                    && x.code === response.code)
                if (!item) {
                    response.resultTestId = itemResult?.resultTestId;
                    response.resultTestFinalId = itemResult?.resultTestFinalId;
                    response.result = itemResult?.result;
                    response.resultProfileId = itemResult?.resultProfileId;
                    response.vendorCode = itemResult?.vendorCode;
                    response.sentTime = itemResult?.sentTime;
                    response.sentUser = itemResult?.sentUser;
                    response.sentUserId = itemResult?.sentUserId;
                    result.push(response)
                } else {
                    item.resultTestId = itemResult?.resultTestId;
                    item.resultTestFinalId = itemResult?.resultTestFinalId;
                    item.result = itemResult?.result;
                    item.resultProfileId = itemResult?.resultProfileId;
                    item.vendorCode = itemResult?.vendorCode;
                    item.sentTime = itemResult?.sentTime;
                    item.sentUser = itemResult?.sentUser;
                    item.sentUserId = itemResult?.sentUserId;
                }
            })
        }
    }

    if (response.type === TestRequest_Test_Type.TEST) {
        // tìm bên ngoài
        let isExist = false;
        let tests = result.filter(x => x.type === TestRequest_Test_Type.TEST && x.code == response.code);
        if (tests.length) isExist = true;

        // tìm trong profile
        let profiles = result.filter(x => x.type === TestRequest_Test_Type.PROFILE);
        if (profiles.length) {
            profiles.forEach(pro => {
                let tst = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.code);
                if (tst) {
                    isExist = true;
                }
            })
        }
        // tìm trong group
        let groups = result.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);
        if (groups.length) {
            groups.forEach(x => {
                let tst = x.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.code);
                if (tst) {
                    isExist = true;
                }
                //tìm trong profile của group
                let profs = x.children.filter(x => x.type === TestRequest_Test_Type.PROFILE);
                if (profs.length) {
                    profs.forEach(pro => {
                        let ts = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                            && x.code == response.code);
                        if (ts) {
                            isExist = true;
                        }
                    })
                }
            })
        }

        if (!isExist) {
            response.id = itemResult?.id;
            response.result = itemResult?.result;
            result.push(response)
        }

    }
    return result;
}

function CheckTreeDataTest(treeData, test, response, resCompany, resDepartment) {
    let result = [...treeData]
    if (result.length > 0) {
        // tìm bên ngoài
        let isExist = false;
        let tests = result.filter(x => x.type === TestRequest_Test_Type.TEST && x.code == response.testCode);
        if (tests.length) isExist = true;

        // tìm trong profile
        let profiles = result.filter(x => x.type === TestRequest_Test_Type.PROFILE);
        if (profiles.length) {
            profiles.forEach(pro => {
                let tst = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.testCode);
                if (tst) {
                    isExist = true;
                }
            })
        }
        // tìm trong group
        let groups = result.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);
        if (groups.length) {
            groups.forEach(x => {
                let tst = x.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.testCode);
                if (tst) {
                    isExist = true;
                }
                //tìm trong profile của group
                let profs = x.children.filter(x => x.type === TestRequest_Test_Type.PROFILE);
                if (profs.length) {
                    profs.forEach(pro => {
                        let ts = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                            && x.code == response.testCode);
                        if (ts) {
                            isExist = true;
                        }
                    })
                }
            })
        }

        if (!isExist) {
            let res = {
                ...test,
                resultTestId: test?.resultTestId,
                resultTestFinalId: test?.resultTestFinalId,
                sid: test?.sid,
                name: response.testName,
                code: response.testCode,
                category: response.category,
                categoryName: response.categoryName.split('>')[1].trim(),
                sampleType: test?.sampleType || response.sampleType,
                sampleTypeName: test?.sampleTypeName || response.sampleTypeName,
                type: TestRequest_Test_Type.TEST,
                result: test?.result,
                oldResult: test?.oldResult,
                state: test?.state,
            }
            res.companyName = resCompany.data?.find(x => x.id == test.companyId)?.shortName || ''
            res.departmentName = resDepartment?.find(x => `${x.id}` == `${test.departmentId}`)?.name || ''
            result.push(res)
        }
    }
    else {
        let res = {
            ...test,
            resultTestId: test?.resultTestId,
            resultTestFinalId: test?.resultTestFinalId,
            sid: test?.sid,
            name: response.testName,
            code: response.testCode,
            sampleType: test?.sampleType || response.sampleType,
            sampleTypeName: test?.sampleTypeName || response.sampleTypeName,
            category: response.category,
            categoryName: response.categoryName.split('>')[1].trim(),
            type: TestRequest_Test_Type.TEST,
            result: test?.result,
            oldResult: test?.oldResult,
            state: test?.state,
        }
        res.companyName = resCompany.data?.find(x => x.id == test.companyId)?.shortName || ''
        res.departmentName = resDepartment?.find(x => `${x.id}` == `${test.departmentId}`)?.name || ''
        result = [{ ...res }]
    }
    return result;
}

function* onUpdateTestResultResults({ payload }) {
    try {
        const testResult = yield select(state => {
            return state.testResult.testResult
        })
        const { data, result, item, id } = payload;
        let response = {};
        if (item.type == TestRequest_Test_Type.TEST) {
            response = yield call(updateResultResultTestById, {
                resultTestId: item.resultTestId,
                selectedRole: GetSelectedRole(),
                resultManual: item,
                ResultId: testResult.id,
                name: item.name
            })
        }
        yield put(UpdateTestResultResultsSuccess([...data]))
        if (payload.callback)
            payload.callback()
    } catch (error) {
        console.log(error);
        yield put(UpdateTestResultResultsFail(error))
    }
}

function* onConfirmTestResultResults({ payload: { ids, id, sid, callback } }) {
    try {
        const result = yield call(confirmResultsById, ids, id)
        showToast(
            `${t("message:ConfirmResultSuccessed", {
                field: sid,
            })}`
        )
        callback && callback()
        // yield put(ConfirmTestResultResultsSuccess(results))
    } catch (error) {
        console.log(error)
        yield put(ConfirmTestResultResultsFail(error))
    }
}

function* onUnConfirmTestResultResults({ payload: { ids, id, sid, callback } }) {
    try {
        const result = yield call(unConfirmResultsById, ids, id)
        showToast(
            `${t("message:UnConfirmResultSuccessed", {
                field: sid,
            })}`
        )
        callback && callback()
        // yield put(ConfirmTestResultResultsSuccess(results))
    } catch (error) {
        console.log(error)
        yield put(UnConfirmTestResultResultsFail(error))
    }
}

function* onValidTestResultResults({ payload: { ids, id, sid, callback, validWithoutDelivery } }) {
    try {
        const result = yield call(validResultsById, ids, id, validWithoutDelivery)

        showToast(
            `${t("message:ValidResultSuccessed", {
                field: sid,
            })}`
        )
        callback && callback()
        // yield put(ConfirmTestResultResultsSuccess(results))
    } catch (error) {
        console.log(error)
        yield put(ValidTestResultResultsFail(error))
    }
}

function* onApprovedValidAndPublishResult({ payload: { data, callback } }) {
    try {
        const response = yield call(approvedValidAndPublishResult, data.data, data.params)
        const id = response?.id
        const deliveryUpdate = {
            id: data.data.ResultId,
            FileId: id,
        }
        yield call(UpdateResultFileId, deliveryUpdate)
        yield put(addFileSuccess(id))
        // showToast(
        //     `${t("message:CreateReportSucceeded", {
        //         fileId: `${t(
        //         )} <span class='text-decoration-underline fw-bold'>${id}</span>`,
        //     })}`
        // )
        callback && callback(id)
    } catch (error) {
        console.log(error);
        yield put(addFileFail(error))
    }
}

function* onInValidTestResultResults({ payload: { ids, id, sid, callback } }) {
    try {
        const result = yield call(inValidResultsById, ids, id)
        showToast(
            `${t("message:CancelValidResultSuccessed", {
                field: sid,
            })}`
        )
        callback && callback()
        // yield put(ConfirmTestResultResultsSuccess(results))
    } catch (error) {
        console.log(error)
        yield put(InValidTestResultResultsFail(error))
    }
}

//delete result detail
function* onDeleteTestResultResults({ payload: { ids, id, callback } }) {
    try {
        const result = yield call(deleteResultDetailById, ids, id)
        showToast(
            `${t("message:DeletedMessage", {
                field: t('Result'),
            })}`
        )
        callback && callback()
        // yield put(ConfirmTestResultResultsSuccess(results))
    } catch (error) {
        console.log(error)
        yield put(DeleteTestResultResultsFail(error))
    }
}

function* fetchTestPerformResuts({ payload: { id, callback } }) {
    try {
        const result = yield call(getTestPerformResult, id)
        showToast(
            `${t("Test Perform")} ${t("message:Succeeded")}`
        )
        callback && callback()
        // yield put(getTestResultResultsSuccess(result))
    } catch (error) {
        console.log(error);
        //tắt loading
        callback && callback()
        // yield put(getTestResultResultsFail(error))
    }
}

function* fetchResultFields() {
    try {
        const resultFields = yield call(getUpdatableResultField)
        var resultFieldsFinal = resultFields.map(str => {
            return str.charAt(0).toLowerCase() + str.slice(1)
        });
        yield put(getUpdatableResultFieldSuccess([...resultFieldsFinal]))

    } catch (error) {
        console.log(error);
        yield put(getUpdatableResultFieldFail(error))
    }
}

function* onReRunTestResultResults({ payload: { ids, id, sid, callback } }) {
    try {
        const result = yield call(rerunResultsById, ids, id)

        showToast(
            `${t("message:ReRunSuccess", {
                field: sid,
            })}`
        )
        callback && callback()
        yield put(rerunTestResultResultsSuccess(result))
    } catch (error) {
        console.log(error)
        yield put(rerunTestResultResultsFail(error))
    }
}

function* onRunRuleTestResultResults({ payload: { ids, id, sid, callback } }) {
    try {
        const result = yield call(runRuleResultsById, ids, id, GetSelectedRole())
        showToast(
            `${t("message:RunRuleSuccess", {
                field: sid,
            })}`
        )
        callback && callback()
        yield put(runRuleTestResultResultsSuccess(result))
    } catch (error) {
        console.log(error)
        yield put(runRuleTestResultResultsFail(error))
    }
}

function* onGetGeneralHistoryResult({ payload: { patientId, fromDate, toDate, callback } }) {
    try {
        const result = yield call(getGeneralHistoryResult, patientId, fromDate, toDate)
        yield put(getGeneralHistoryResultSuccess(result))
        callback && callback()
    }
    catch (error) {
        console.log(error)
        yield put(getGeneralHistoryResultFail(error))
    }
}


function* onGetPatientResult({ payload }) {
    try {
        const result = yield call(getPatientResult, payload)
        yield put(getPatientResultSuccess(result))
    }
    catch (error) {
        console.log(error)
        yield put(getPatientResultFail(error))
    }
}

function* fetchTestResultResultAudiLogs({ payload }) {
    try {
        let res = yield call(getTestResultResultAuditLog, payload)

        if (res) {
            let tmp = res.data
            tmp.forEach(element => {
                element.action = GetActionFromShortTypeAuditLog(element.shortType || "")
                element.type = GetTypeFromShortTypeAuditLog(element.shortType, element.action)
            });
        }
        yield put(getTestResultResultAuditLogSuccess(res.data))

    } catch (error) {
        console.log(error);
        yield put(getTestResultResultAuditLogFail(error))
    }
}

function* fetchTestRequestAudiLogs({ payload }) {
    try {
        const res = yield all(payload.map(item => call(getTestResultResultAuditLog, item)));
        const data = yield res.map((item) => {

            let tmp = item.data
            tmp.forEach(element => {
                element.action = GetActionFromShortTypeAuditLog(element.shortType || "")
                element.type = GetTypeFromShortTypeAuditLog(element.shortType, element.action)
            });
            return tmp;
        })
        let result = []
        data.forEach((item) => {
            result = [...result, ...item]
        })
        yield put(getTestResultResultAuditLogSuccess(result.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp))))
    } catch (error) {
        console.log(error);
        yield put(getTestResultResultAuditLogFail(error))
    }
}

function* onWarningTestResultResults({ payload: { ids, id, sid, callback } }) {
    try {
        const result = yield call(warningRuleResultsById, ids, id)
        showToast(
            `${t("message:WarningResultSuccessed", {
                field: sid,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(warningRuleTestResultResultsFail(error))
    }
}

function* onResetTestResultSearchQueryCallBack({ callback }) {
    try {
        yield put(resetSearchQuery())
        callback && callback()
    } catch (error) {
        console.log(error);
    }
}

function* fetchPathologyResult({ payload, callback }) {
    try {
        let res = yield call(getPathologyResult, payload)
        yield put(getPathologyResultSuccess(res))
        if (callback) {
            callback(res)
        }
    } catch (error) {
        console.log(error);
        yield put(getPathologyResultFail(error))
    }
}

function* onUpdatePathologyResult({ payload, callback }) {
    try {
        let res = yield call(updatePathologyResult, payload)
        yield put(updatePathologyResultSuccess(res))
        showToast(
            `${t("message:UpdatedMessage", { field: t('testResultPage:Result Text') })}`
        )
        if (callback) {
            callback()
        }
    } catch (error) {
        yield put(updatePathologyResultFail(error))
    }
}

function* onUnsignFileResult({ payload, callback }) {
    try {
        let data = payload;
        const response = yield call(unsignFileResult, data.id, data)
        yield put(UnsignTestResultResultsSuccess())
        callback && callback(response)
    } catch (error) {
        console.log(error);
        yield put(UnsignTestResultResultsFail(error))
    }
}

function* onSignFileResult({ payload, callback }) {
    try {
        let data = payload
        const response = yield call(signFileResult, data.id, data)
        yield put(SignTestResultResultsSuccess())
        callback && callback(response)
    } catch (error) {
        console.log(error);
        yield put(SignTestResultResultsFail(error))
    }
}

function* updateTestResultSaga() {
    yield takeEvery(GET_TESTRESULT_RESULTS, fetchTestResultResuts)
    yield takeEvery(UPDATE_TESTRESULT_RESULTS, onUpdateTestResultResults)
    yield takeEvery(UNCONFIRM_TESTRESULT_RESULTS, onUnConfirmTestResultResults)
    yield takeEvery(VALID_TESTRESULT_RESULTS, onValidTestResultResults)
    yield takeEvery(INVALID_TESTRESULT_RESULTS, onInValidTestResultResults)
    yield takeEvery(RESET_TESTRESULTS_SEARCH_QUERY_CALLBACK, onResetTestResultSearchQueryCallBack)

    yield takeEvery(RERUN_TESTRESULT_RESULTS, onReRunTestResultResults)
    yield takeEvery(RUNRULE_TESTRESULT_RESULTS, onRunRuleTestResultResults)

    yield takeEvery(DELETE_TESTRESULT_RESULTS, onDeleteTestResultResults)
    yield takeEvery(GET_TESTPERFORM_RESULTS, fetchTestPerformResuts)
    yield takeEvery(CONFIRM_TESTRESULT_RESULTS, onConfirmTestResultResults)

    yield takeEvery(GET_UPDATABLE_RESULTFIELD, fetchResultFields)
    yield takeEvery(GET_GENERAL_HISTORY_RESULT, onGetGeneralHistoryResult)
    yield takeEvery(GET_PATIENT_RESULT, onGetPatientResult)
    yield takeEvery(WARNING_RULE_TESTRESULT_RESULTS, onWarningTestResultResults)

    yield takeEvery(GET_TESTRESULT_RESULT_AUDITLOG, fetchTestResultResultAudiLogs)
    yield takeEvery(GET_TEST_REQUEST_AUDITLOG, fetchTestRequestAudiLogs)
    yield takeEvery(APPROVED_VALID_AND_PUBLISH_RESULT, onApprovedValidAndPublishResult)

    yield takeEvery(GET_PATHOLOGY_RESULT, fetchPathologyResult)
    // yield takeEvery(CREATE_PATHOLOGY_RESULT, onCreatePathologyResult)
    yield takeEvery(UPDATE_PATHOLOGY_RESULT, onUpdatePathologyResult)

    yield takeEvery(UNSIGN_TESTRESULT_RESULTS, onUnsignFileResult)
    yield takeEvery(SIGN_TESTRESULT_RESULTS, onSignFileResult)
}

export default updateTestResultSaga