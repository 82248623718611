import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_INSTRUMENT_ENDPOINT}/work-orders`
const BASE_MS_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/global`
// TESTS
// Get All Tests Method
const getAllRequests = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}?${q}`)
}

// Get All Tests Method
const getHistoryResults = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_MS_API_URL}/auditlog/GetHistoryLogs?${q}`)
}


// Get Test By Id Method
// const getTestById = id => {
//     return get(`${BASE_API_URL}/${id}`)
// }

// // Delete Test By Id Method
// const deleteTestByIds = tests => {
//     let ids = ""
//     tests.forEach(_test => (ids += `id=${_test.id}&`))

//     return del(`${BASE_API_URL}?${ids}`)
// }

// Update Test By Id Method
const updateWorkOrderMultiple = req => {
  return put(`${BASE_API_URL}`, req)
}

// // Create Test By Id Method
// const createTest = req => {
//     return post(`${BASE_API_URL}`, req)
// }

export { getAllRequests, getHistoryResults, updateWorkOrderMultiple }
