import { InlineEditType, TestRequest_State, parameterCode } from "constant"
import PropTypes from "prop-types"
import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react"
//i18n
import { AvForm } from "availity-reactstrap-validation"
import { CustomButton, CustomDatePicker, CustomSelectAsync, FormSwitch } from "components/Common"
import { getCodesByParameterId } from "helpers/app-backend"
import { getI18nextLng } from "helpers/utilities"
import { isEmpty } from "lodash"
import * as moment from "moment"
import { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Col, Row } from "reactstrap"
import {
    ChangeRequestSampleTable, getTestRequestSample
} from "store/laboratory/testRequest/actions"
import SampleTabTable from "./SampleTabTable"

let lang = getI18nextLng()
const SampleTab = forwardRef(({
    t,
    testRequestSample,
    onGetTestRequestSample,
    activeTab,
    onChangeRequestSampleTableSuccess,
    onChangeCollect,
    onChange,
    testRequest,
    generalSetting,
    onSaveSample,
    config
}, ref) => {
    const [isCollected, setIsCollected] = useState(false);
    const [isReceived, setIsReceived] = useState(false);
    const [dataCollect, setDataCollect] = useState([]);
    const [dataReceive, setDataReceive] = useState([]);
    const userInfo = localStorage.getItem("userInfo")
    const userCollectedAndReceived = JSON.parse(localStorage.getItem("userInSesstion"));
    const selectedArea = JSON.parse(localStorage.getItem("selectedArea"));
    const defaultCollector = useMemo(() => JSON.parse(localStorage.getItem("defaultCollector")), [])
    const { family_name, given_name, name, sub } = JSON.parse(userInfo)
    const [userCollect, setUserCollect] = useState(0)
    const [userReceive, setUserReceive] = useState(0)
    const [receiveDate, setReceiveDate] = useState(new Date())
    const [collectDate, setCollectDate] = useState(new Date())
    const [userCollectName, setUserCollectName] = useState('');
    const [userReceiveName, setUserReceiveName] = useState('');
    const [lstQuanlity, setLstQuanlity] = useState([])

    useEffect(() => {
        onChangeCollect && onChangeCollect(isCollected)
    }, [isCollected])
    const isCollectedAndClear = testRequestSample.every(x => !x.isCollected && !x.isClear);
    useEffect(() => {
        if (testRequestSample.length > 0) {
            const isCollect = testRequestSample.findIndex(x => x.collectorUserId != undefined && x.collectorUserId != null && x.collectorUserId != '')
            setIsCollected(isCollect >= 0)
            const isReceiver = testRequestSample.findIndex(x => x.receiverUserId != undefined && x.receiverUserId != null && x.receiverUserId != '')
            setIsReceived(isReceiver >= 0)
            if (!isCollectedAndClear) {
                setIsCollected(true);
            } else {
                setIsCollected(false);
            }
        }
        else {
            setIsCollected(false);
            setIsReceived(false);
        }
    }, [testRequestSample])
    useEffect(() => {
        if (activeTab == 3) {
            onGetTestRequestSample();
        }
    }, [activeTab])
    useImperativeHandle(ref, () => ({
        GetUserData: () => {
            let res = {}
            let displayName = '';
            let userid = 0
            if (userInfo) {
                displayName = family_name && given_name ? `${family_name} ${given_name}` : name
                userid = defaultCollector ? parseInt(defaultCollector?.collectorId) : Number(sub)
            }
            if (isCollected) {
                if (!isEmpty(userCollectName)) {
                    res.userCollectName = userCollectName
                    res.userCollect = userCollect
                    res.isCollected = true
                    res.collectionTime = collectDate
                }
                else {
                    res.userCollectName = defaultCollector ? defaultCollector?.collectorName : displayName
                    res.userCollect = userid
                    res.isCollected = true
                    res.collectionTime = collectDate
                }
            }
            else {
                res.userCollectName = ''
                res.userCollect = 0
                res.isCollected = false
            }
            if (isReceived) {
                if (!isEmpty(userReceiveName)) {
                    res.userReceiveName = userReceiveName
                    res.userReceive = userReceive
                    res.isReceived = true
                    res.receivedTime = receiveDate
                }
                else {
                    res.userCollectName = defaultCollector ? defaultCollector?.collectorName : displayName
                    res.userCollect = userid
                    res.isReceived = true
                    res.receivedTime = receiveDate
                }
            }
            else {
                res.userReceiveName = ''
                res.userReceive = 0
                res.isReceived = false
            }
            return res;
        },
    }));

    useEffect(() => {
        if (defaultCollector) {
            const displayName = family_name && given_name ? `${family_name} ${given_name}` : name
            setUserCollect(Object.keys(defaultCollector).length !== 0 ? parseInt(defaultCollector?.collectorId) : parseInt(sub))
            setUserCollectName(Object.keys(defaultCollector).length !== 0 ? defaultCollector?.collectorName : displayName)
        }
    }, [testRequest, defaultCollector])

    useEffect(() => {
        if (userInfo) {
            const displayName = family_name && given_name ? `${family_name} ${given_name}` : name
            const res = [{ value: defaultCollector ? parseInt(defaultCollector?.collectorId) : Number(sub), label: displayName }]
            setUserReceive(Number(sub))
            setUserReceiveName(displayName)
            setDataCollect(res)
            setDataReceive(res)
        }
        getQuanlity();
    }, [])

    const getQuanlity = async () => {
        const query = { lang }
        const res = await getCodesByParameterId(parameterCode.TESTREQUEST_SAMPLE_QUALITY, query)
        res?.map(_item => {
            _item.value = _item.code
            _item.label = _item.message
            return _item
        })
        setLstQuanlity(res)
    }
    const afterSubmit = () => {
        onChange && onChange()
    }

    const displayCollected = generalSetting.find(item => item.fieldName === 'Collected')?.fieldValue === 'True';
    return (
        <React.Fragment>
            {displayCollected && <AvForm id="generalForm" >
                <Row className="px-2 mt-2">
                    <Col md={12}>
                        <div className="row">
                            <div className="my-1" style={{ alignSelf: 'center', width: '130px' }}>
                                <FormSwitch label={t("testRequestPage:Collected?")}
                                    disabled={testRequestSample?.some(sample => sample.state > TestRequest_State.Collected) && testRequest.state != TestRequest_State.Onhold}
                                    value={isCollected}
                                    onChangeSwitch={(e) => {
                                        if (isCollected) {
                                            setIsReceived(false)
                                            onChangeRequestSampleTableSuccess([
                                                { value: !isCollected, key: 'isCollected' },
                                                { value: null, key: 'collectionTime' },
                                                { value: null, key: 'collectorUserId' },
                                                { value: '', key: 'collectorName' },
                                                { value: false, key: 'isReceived' },
                                                { value: null, key: 'receivedTime' },
                                                { value: null, key: 'receiverUserId' },
                                                { value: '', key: 'receiverName' },
                                                { value: ' ', key: 'quality' },
                                                { value: '', key: 'qualityName' },
                                                { value: false, key: 'isClear' },
                                            ], afterSubmit)
                                        }
                                        else {
                                            let collectedDateString = moment(receiveDate).format("YYYY-MM-DDTHH:mm");
                                            onChangeRequestSampleTableSuccess([
                                                { value: !isCollected, key: 'isCollected' },
                                                { value: collectedDateString, key: 'collectionTime' },
                                                { value: userCollect, key: 'collectorUserId' },
                                                { value: userCollectName, key: 'collectorName' },
                                                { value: 'G', key: 'quality' },
                                                { value: true, key: 'isClear' },
                                                { value: lstQuanlity.find(x => x.value == "G")?.label, key: 'qualityName' },
                                            ], afterSubmit)
                                        }
                                        setIsCollected(prev => !prev)
                                    }}
                                />
                            </div>
                            <div className="col-md-3 my-1">
                                <CustomDatePicker
                                    name="collectDate"
                                    // label={''}
                                    placeholder={"DD/MM/YYYY"}
                                    format={"DD/MM/YYYY"}
                                    value={collectDate || new Date()}
                                    disabled={!isCollected || testRequest.state > TestRequest_State.Collected && testRequest.state != TestRequest_State.Onhold}
                                    enableTime={true}
                                    dateFormat={'Y-m-d H:i'}
                                    onChangeHandler={(e) => {
                                        let value = moment(e.time[0]).format("YYYY-MM-DDTHH:mm");
                                        setCollectDate(value)
                                        onChangeRequestSampleTableSuccess([{ value: value, key: 'collectionTime' }], afterSubmit)
                                    }}
                                />
                            </div>
                            <div className="col-md-3 my-1">
                                <CustomSelectAsync
                                    isClearable={false}
                                    label={""}
                                    name="collectName"
                                    readOnly={!isCollected || testRequest.state > TestRequest_State.Collected && testRequest.state != TestRequest_State.Onhold}
                                    options={dataCollect}
                                    value={userCollect || ""}
                                    valueName={userCollectName || ""}
                                    code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                                    onChange={(e, values, name) => {
                                        setUserCollect(values[0] || null)
                                        setUserCollectName(name || '')
                                        onChangeRequestSampleTableSuccess([{ value: values[0], key: 'collectorUserId' }, { value: name, key: 'collectorName' }], afterSubmit)
                                    }}
                                />
                            </div>
                            {/* <div style={{
                                width: '38%',
                                textAlign: 'right'
                            }}>
                                <CustomButton
                                    text={t("common:Save")}
                                    type="submit"
                                    isEdit
                                    color="success"
                                    className="save-user button-width"
                                    disabled={testRequest === null}
                                    style={{ marginTop: '6px' }}
                                    onClick={() => {
                                        onSaveSample()
                                    }}
                                >
                                    {t("common:Save")}
                                </CustomButton>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </AvForm>}
            <div className="sampleTabTable px-2">
                <SampleTabTable
                    data={testRequestSample || []}
                    onSelect={() => { }}
                    onSelectAll={() => { }}
                    onDelete={() => { }}
                    userCollect={userCollect}
                    userCollectName={userCollectName}
                    isCollected={isCollected}
                    lstQuanlity={lstQuanlity}
                    onChange={() => {
                        onChange && onChange()
                    }}
                />
            </div>
        </React.Fragment>
    )
})

SampleTab.propTypes = {
    t: PropTypes.any,
    onGetTestRequestSample: PropTypes.func,
    testRequestSample: PropTypes.array,
    config: PropTypes.any,
    onSaveSample: PropTypes.func
}

SampleTab.defaultProps = {}

const mapStateToProps = ({ testRequest, generalSetting }) => ({
    testRequestSample: testRequest.testRequest.samples || [],
    testRequestTests: testRequest.testRequest.tests || [],
    generalSetting: generalSetting.generalSetting
})

const mapDispatchToProps = dispatch => ({
    onGetTestRequestSample: (payload, callback) => dispatch(getTestRequestSample(payload, callback)),
    onChangeRequestSampleTableSuccess: (payload, callback) => dispatch(ChangeRequestSampleTable(payload, callback)),
})
SampleTab.displayName = 'SampleTab';
export default withTranslation(["message", "common", "testRequestPage"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(SampleTab))
