import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  UserChangeTreeViewItem,
  addNewUser,
  deleteUsers,
  getUserDetail,
  getUsers,
  resetUserSearchQuery,
  updateUser,
} from "store/users/user/actions"

import {
  getCommonCompanies,
  getCommonDepartments,
} from "store/setting/common/actions"

import { Col, Row } from "reactstrap"

// import common components
import {
  ConfirmModal,
  TitleAndTable,
  TreeViewBar,
  WarningModal,
} from "components/Common"

import UserTable from "./UserTable"
// import UserModal from "./Modal/UserModal"

import {
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import UserModal from "./Modal/UserModal"

import { ModuleIds } from "constant"
import { postWithFormData } from "helpers/api_helper2"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.User

const Users = ({
  history,
  users,
  paging,
  onGetUsers,
  onAddNewUser,
  onUpdateUser,
  onDeleteUser,
  onResetUserSearchQuery,
  onGetUserDetail,
  user,
  loadingUsers,
  updatedTime,
  t,
  onGetCommonCompanies,
  onGetCommonDepartments,
  onChangeTreeViewItem,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const formEl = useRef(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const handleImageChange = async (e) => {
    const file = e.target.files[0]
    setSelectedImage(file)
  }
  const handleImageDelete = async () => {
    if (selectedImage) {
      const formData = new FormData()
      formData.append("signature", null)
      try {
        await postWithFormData(
          `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users/signature`,
          formData
        )
      } catch (error) {
      }
    }
  }
  const toggle = () => {
    setModal(prev => !prev)
    setSelectedImage(null)
  }

  const addUserClicks = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
  }

  const onValidSubmit = (e, values) => {
    values.givenName = '';
    if (isEdit && !isClone) {
      values.status = values.statuss
      values.email = values.emaill
      values.password = values.passwordd

      delete values.statuss
      delete values.emaill
      delete values.passwordd
      onUpdateUser({ user: values, callback: (userId) =>  afterUpdate(userId, values) })
    } else {
      const newUser = {
        ...values,
        status: values.statuss,
        email: values.emaill,
        password: values.passwordd,
      }
      delete newUser.statuss
      delete newUser.emaill
      delete newUser.passwordd

      onAddNewUser({ user: newUser, history, callback: (userId) => {
        submitUploadSignature(userId, newUser)    
      } })
      
    }
  }
   const submitUploadSignature = async (userId, values) => {
    if (selectedImage) {
      const formData = new FormData()
      formData.append("signature", selectedImage)
      formData.append("userId", userId)
      formData.append("signatureUID", values.signatureUID)
      try {
        await postWithFormData(
          `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users/signature`,
          formData)
      } catch (error) {
      }
    }
   }
  const afterUpdate = (userId, values) => {
    onGetUsers()
    toggle()
    submitUploadSignature(userId,values)    
  }

  const onCloneHandler = () => {
    const id = row?.id
    if (id) {
      onGetUserDetail(id)
      setIsEdit(false)
      setIsClone(true)
      toggle()
    } else setWarningModal(true)
  }

  const onEditHandler = (e, userId) => {
    const id = userId || row?.id
    if (id) {
      onGetUserDetail(id)
      setIsClone(false)
      setIsEdit(true)
      toggle()
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, user) => {
    onDeleteToggle({
      rows,
      row: user || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteUser({ users: rowsState, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteUser({
      users: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteUserHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }
  const onGetUserList = (payload) => {
    insertUrlParam(payload)
    onGetUsers(payload)
  }
  const fetchUsers = () => {
    onGetUserList({ page: 1 , sort: "createdDate:desc"})
  }

  /** Table methods */
  const onRefreshHandler = () => {
    resetState()
    fetchUsers()
  }

  const onSearch = searchText => {
    onGetUserList({ page: 1, search: searchText })
  }

  const onSizePerPageChange = size => {
    onGetUserList({ page: 1, size })
  }

  const onPageChange = page => {
    onGetUserList({ page })
  }

  const onSubmitFilter = values => {
    onGetUserList({ page: 1, ...values })
  }

  const onSortHandler = (field, order) => {
    let sortString = ``
    if (field.indexOf(',,') >= 0) {
      const arr = field.split(',,')
      sortString = `${arr[0]}:${order},${arr[1]}:${order}`
    }
    else {
      sortString = `${field}:${order}`
    }
    onGetUsers({ page: 1, sort: sortString })
  }

  // Common
  const fetchCommonCompanies = () => {
    onGetCommonCompanies({ size: 0 })
  }

  const fetchCommonDepartments = () => {
    onGetCommonDepartments({ size: 0, countUser: true })
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetUserSearchQuery()
  }, [])

  useEffect(() => {
    let params = getUrlParams();
    let paramPage = getUrlParamByKey("page");
    if (paramPage) {
      onGetUserList(params)
    } else {
      fetchUsers()
    }
  }, [])

  useEffect(() => {
    fetchCommonCompanies()
  }, [])

  useEffect(() => {
    fetchCommonDepartments()
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col lg="3">
          <TreeViewBar
            buttonText={t("Add User")}
            onChangeTreeViewItem={onChangeTreeViewItem}
            onAddNew={addUserClicks}
            resource={RESOURCE}
          />
        </Col>
        <Col lg="9">
          <TitleAndTable
            table={() => (
              <UserTable
                users={users}
                onSelect={onSelectCheckbox}
                onSelectAll={onSelectAllCheckbox}
                onSearch={onSearch}
                onSort={onSortHandler}
                onRefresh={onRefreshHandler}
                onPageChange={onPageChange}
                paging={paging}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                loading={loadingUsers}
                updatedTime={updatedTime}
              />
            )}
            resource={RESOURCE}
            buttons
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            onClone={onCloneHandler}
            title={t("Users")}
            subtitle={t("User List")}
          />
        </Col>
      </Row>

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("User")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteUserHandler}
      />
      <UserModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={onValidSubmit}
        handleImageChange= {handleImageChange}
        handleImageDelete={handleImageDelete}
        toggle={toggle}
        isClone={isClone}
        selectedImage={selectedImage}
        data={!isEdit ? (isClone ? user : {}) : user}
      />
    </React.Fragment>
  )
}
Users.propTypes = {
  users: PropTypes.array,
  onGetUserDetail: PropTypes.func,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onResetUserSearchQuery: PropTypes.func,
  paging: PropTypes.object,
  loadingUsers: PropTypes.bool,
  updatedTime: PropTypes.any,
  t: PropTypes.any,
  onGetCommonCompanies: PropTypes.func,
  onGetCommonDepartments: PropTypes.func,
  onChangeTreeViewItem: PropTypes.func,
}
const mapStateToProps = ({ user }) => ({
  users: user.users,
  user: user.user,
  paging: user.paging,
  loadingUsers: user.loadingUsers,
  updatedTime: user.updatedTime,
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: payload => dispatch(getUsers(payload)),
  onAddNewUser: (user, history, callback) => dispatch(addNewUser(user, history, callback)),
  onUpdateUser: (user,callback) => dispatch(updateUser(user,callback)),
  onDeleteUser: user => dispatch(deleteUsers(user)),
  onGetUserDetail: userId => dispatch(getUserDetail(userId)),
  onGetCommonCompanies: payload => dispatch(getCommonCompanies(payload)),
  onGetCommonDepartments: payload => dispatch(getCommonDepartments(payload)),
  onChangeTreeViewItem: item => dispatch(UserChangeTreeViewItem(item)),
  onResetUserSearchQuery: () => dispatch(resetUserSearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["userPage", "message", "common"])(Users)))
