import ReactTooltip from "react-tooltip"
import CustomButton from "./CustomButton"
import "../../../assets/scss/custom/pages/_party.scss"
const TooltipButton = ({ id, color, iconClass, onClick, tooltipText }) => (
  <>
    <CustomButton id={id} color={color} onClick={onClick}>
      <i className={iconClass} data-tip data-for={id} />
    </CustomButton>
    <ReactTooltip placement="top" id={id} effect="solid">
      {tooltipText}
    </ReactTooltip>
  </>
)
const CustomTooltipButton = ({
  index,
  component,
  onClone,
  onEdit,
  onDelete,
  isId,
  t,
}) => (
  <div className="d-flex toolbar button-items text-end">
    <TooltipButton
      id={`copy-${index}`}
      color="btn btn-outline-primary btn-soft-copy"
      iconClass="bx bx-copy-alt"
      onClick={() => {
        onClone(isId ? component.id : component)
      }}
      tooltipText={t("common:Copy")}
    />
    <TooltipButton
      id={`edit-${index}`}
      color="btn btn-outline-info btn-soft-info"
      iconClass="mdi mdi-pencil-outline"
      onClick={e => {
        onEdit(e, component.id, component)
      }}
      tooltipText={t("common:Edit")}
    />
    <TooltipButton
      id={`delete-${index}`}
      color="btn btn-outline-danger btn-soft-danger"
      iconClass="mdi mdi-delete-outline"
      onClick={e => onDelete(e, component)}
      tooltipText={t("common:Delete")}
    />
  </div>
)

export default CustomTooltipButton
