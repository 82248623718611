import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  getProfilesForRoleSetting,
  getRoleProfileByRoleId,
  updateRolePartyProfile,
} from "store/users/role/actions"

import {
  Check,
  CollapsibleModule,
  CollapsibleModuleWrapper,
  CustomButton,
  CustomButtonGroup,
  CustomCheckbox,
} from "components/Common"

import { ModuleIds, permissionType } from "constant"

import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"

const RESOURCE = ModuleIds.RolePemisssion

const TabPartySetting = ({
  profiles,
  roleProfiles,
  onGetProfiles,
  onGetRoleProfiles,
  roleId,
  onUpdateRoleProfiles,
  customActiveTab,
  t,
}) => {
  const [collapsesOpen, setCollapsesOpen] = useState({})
  const [isloading, setIsloading] = useState(false)

  const fetchProfiles = () => {
    const query = {
      status: true,
      isSystem: false,
      size: 100,
    }
    onGetProfiles({ query })
  }

  const fetchRoleProfiles = () => {
    onGetRoleProfiles({ roleId })
  }

  const onToggle = id => {
    setCollapsesOpen(prev => ({ [id]: !prev[id] }))
  }

  const onAfterUpdate = () => { }

  const checkAccess = id => {
    return isEmpty(roleProfiles.find(p => p.profileId === id))
      ? false
      : roleProfiles.find(p => p.profileId === id).hasAccess
        ? true
        : false
  }

  const onValidSubmit = (e, values) => {
    const profileAccess = []
    // convert object to array
    Object.keys(values)
      .filter(_key => values[_key])
      .map(_key =>
        profileAccess.push({
          profileId: parseInt(_key),
          profileName: profiles.find(e => e.id === parseInt(_key)).name,
        })
      )

    onUpdateRoleProfiles({
      roleProfiles: profileAccess,
      roleId,
      callback: () => onAfterUpdate(profileAccess),
    })
  }
  useEffect(() => {
    setIsloading(false)
  }, [profiles])

  const onCancel = () => {
    setIsloading(true)
    fetchProfiles()
    fetchRoleProfiles()
  }

  useEffect(() => {
    if (customActiveTab === "4") {
      fetchProfiles()
      fetchRoleProfiles()
    }
  }, [customActiveTab])

  return (
    <>
      <h4>{t("Party Profile Setting")}</h4>
      <CollapsibleModuleWrapper onToggle={onToggle} defaultTab={1}>
        <CollapsibleModule key={1} tabId={1 + ""} title={t("Party Profiles")}>
          <Row>
            <Col sm="12">
              <AvForm onValidSubmit={(e, values) => onValidSubmit(e, values)}>
                {!isloading &&
                  <Row>
                    {profiles.map(({ name: label, id: id }, _idx) => {
                      const checked = checkAccess(id)
                      return (
                        <Col key={_idx} className="col-3">
                          <div className="mb-3">
                            <CustomCheckbox
                              label={label}
                              name={id + ""}
                              checked={checked}
                            />
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                }
                {isloading &&
                  <Row>
                    {profiles.map(({ name: label, id: id }, _idx) => {
                      const checked = checkAccess(id)
                      return (
                        <Col key={_idx} className="col-3">
                          <div className="mb-3">
                            <CustomCheckbox
                              label={label}
                              name={id + ""}
                              checked={checked}
                            />
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                }
                {!isEmpty(profiles) && (
                  <CustomButtonGroup className="justify-content-end">
                    <Check resource={RESOURCE} permission={permissionType.U}>
                      <CustomButton
                        type="button"
                        text={t("common:Cancel")}
                        isEdit
                        onClick={onCancel}
                        className="button-width"
                      />{" "}
                      <CustomButton
                        type="submit"
                        color="primary"
                        className="save-user button-width"
                        text={t("common:Save")}
                        isEdit
                      />
                    </Check>
                  </CustomButtonGroup>
                )}
              </AvForm>
            </Col>
          </Row>
        </CollapsibleModule>
      </CollapsibleModuleWrapper>
    </>
  )
}

TabPartySetting.propTypes = {
  profiles: PropTypes.array,
  roleProfiles: PropTypes.array,
  onGetProfiles: PropTypes.func,
  onGetRoleProfiles: PropTypes.func,
  onUpdateRoleProfiles: PropTypes.func,
}

const mapStateToProps = ({ role }) => ({
  profiles: role.profileSetting,
  roleProfiles: role.roleProfiles,
})

const mapDispatchToProps = dispatch => ({
  onGetProfiles: payload => dispatch(getProfilesForRoleSetting(payload)),
  onGetRoleProfiles: payload => dispatch(getRoleProfileByRoleId(payload)),
  onUpdateRoleProfiles: payload => dispatch(updateRolePartyProfile(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)
(withRouter(withTranslation(["rolePage", "common"])(TabPartySetting)))
