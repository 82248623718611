import { AvForm } from "availity-reactstrap-validation"
import {
    Accordion,
    AccordionBody,
    AccordionWrapper,
    CustomCheckbox
} from "components/Common"
import BadgeLabelAndValue from "components/Common/BadgeLabelAndValue"
import CustomSIDHover from "components/Common/Input/CustomSIDHover"
import { RESULT_STATE } from "constant"
import { getFileById, getFileByIdWithName, getViewFileByIdWithName } from "helpers/app-backend"
import { getListFileByItemValue } from "helpers/app-backend/testResult_backend_helper"
import { convertDateFormat, convertDateFormat_VN, getAgeFromDOB } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
const SummaryInfo = ({
    data,
    t,
    updatedByName,
    createdByName,
    generalSetting,
    isPatientGroup
}) => {
    const [resultDetail, setResultDetail] = useState({})
    const [collection, setCollection] = useState({ user: '', time: '' })
    const [received, setReceived] = useState({ user: '', time: '' })
    const [open, setOpen] = useState(false)
    const [inPatient, setInPatient] = useState(false)
    const [files, setFiles] = useState([])

    useEffect(() => {
        setResultDetail(data)
        let sids = (data.sampleIds != '' && data.sampleIds != null) ? JSON.parse(data.sampleIds) : []
        getTimeSample(sids)
        if (data.id) {
            getFileNames(data.id)
        }
    }, [data])

    const getFileNames = async (id) => {
        let res = await getListFileByItemValue(id)
        if (res) {
            setFiles(res)
        }
    }

    useEffect(() => {
        if (generalSetting && generalSetting.length > 0) {
            setInPatient(generalSetting.find(x => x.fieldName == "InPatient")?.fieldValue || false)
        }
    }, [generalSetting])

    const getFile = async (id) => {
        // await getFileById(id)
        await getFileByIdWithName(id);
    }

    const viewFile = async (id) => {
        // await getFileById(id)
        await getViewFileByIdWithName(id);
    }

    const getTimeSample = (samples) => {
        let collector = samples.filter(x => x.CollectionTime && x.CollectorUserName && `${x.CollectorUserName || ''}`.trim().length > 0)
        let receiver = samples.filter(x => x.ReceivedTime && x.ReceiverUserName && `${x.ReceiverUserName || ''}`.trim().length > 0)

        if (collector.length > 0) {
            const maxDate = new Date(
                Math.max(
                    ...collector.map(element => {
                        return new Date(element.CollectionTime);
                    }),
                ),
            );
            const user = collector.find(x => convertDateFormat(x.CollectionTime) == convertDateFormat(maxDate))?.CollectorUserName
            setCollection({
                user: user,
                time: convertDateFormat(maxDate)
            })
        }
        else {
            setCollection({})
        }
        if (receiver.length > 0) {
            const maxDate = new Date(
                Math.max(
                    ...receiver.map(element => {
                        return new Date(element.ReceivedTime);
                    }),
                ),
            );
            const user = receiver.find(x => convertDateFormat(x.ReceivedTime) == convertDateFormat(maxDate))?.ReceiverUserName
            setReceived({
                user: user,
                time: convertDateFormat(maxDate)
            })
        } else {
            setReceived({})
        }
    }
    return (
        <AccordionWrapper>
            <Accordion tabId={"1"} iconPosition='right'

                onChange={(e, val) => {
                    setOpen(val)
                }}
                customTitle={() => <React.Fragment>
                    {open == true ?
                        <React.Fragment>
                            <div>{t("testResultPage:Administrative Information")}</div>
                            <div className="flex-1" style={{ textAlign: 'right' }}>
                                <label className="header-result-detail-label">{t("common:Created By")}:
                                    <span style={{ color: '#4775C5' }} className="header-result-detail-value">{createdByName},</span>
                                    <span className="header-result-detail-value">{convertDateFormat(resultDetail.createdDate, "YYYY-MM-DD HH:mm")}</span>
                                </label>
                                <label style={{ marginLeft: 5, marginRight: 5 }}>-</label>
                                <label className="header-result-detail-label">{t("common:Updated By")}:
                                    <span style={{ color: '#4775C5' }} className="header-result-detail-value">{updatedByName ? `${updatedByName},` : ''}</span>
                                    <span className="header-result-detail-value">{convertDateFormat(resultDetail.updatedDate, "YYYY-MM-DD HH:mm")}</span>
                                </label>
                                {resultDetail.emergency == true &&
                                    <label style={{ marginLeft: 5 }}>
                                        <div className="">
                                            <BadgeLabelAndValue
                                                label=''
                                                type={'danger'}
                                                value={t(`testResultPage:CC`)}
                                                className={"fw-500"}
                                            />
                                        </div>
                                    </label>
                                }
                                {resultDetail.requestState &&
                                    <label style={{ marginLeft: 5 }}>
                                        <div className="state-result-header-detail">
                                            <BadgeLabelAndValue
                                                label=''
                                                type={RESULT_STATE[resultDetail.requestState]}
                                                value={t(`testResultPage:${RESULT_STATE[resultDetail.requestState]}`)}

                                                className={"fw-500"}
                                            />
                                        </div>
                                    </label>
                                }
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="header-result-detail-content d-flex header-result-detail-content-summary">
                                {!isPatientGroup &&
                                    <div>
                                        <label className="header-result-detail-label">{t("SID")}: </label>
                                        <label className="header-result-detail-value">
                                            <CustomSIDHover data={resultDetail.sampleIds} indexParent={'header-result'} showTooltip={false} />
                                        </label>
                                    </div>}
                                <div>
                                    <label style={{ color: '#4775C5' }} className="header-result-detail-value">
                                        <Link to={`/Patient/${resultDetail.patientId}/view?tab=1`} target="_blank">{resultDetail.patientName}</Link>
                                    </label>
                                </div>
                                <div>
                                    <label className="header-result-detail-label">{t("Birthday")}: </label>
                                    <label className="header-result-detail-value">{convertDateFormat_VN(resultDetail.dob)}</label>
                                </div>
                                <div>
                                    <label className="header-result-detail-label">{t("Gender")}: </label>
                                    <label className="header-result-detail-value">{resultDetail.genderName}</label>
                                </div>
                                <div>
                                    <label className="header-result-detail-label">{t("testRequestPage:Age")}: </label>
                                    <label className="header-result-detail-value">{resultDetail.dob ? getAgeFromDOB(convertDateFormat(resultDetail.dob, "YYYY-MM-DD")) : ''}</label>
                                </div>
                                <div>
                                    <label className="header-result-detail-label">{t("common:ServiceType")}: </label>
                                    <label className="header-result-detail-value">{resultDetail.serviceTypeName}</label>
                                </div>
                                <div>
                                    <label className="header-result-detail-label">{t("Insurance")}: </label>
                                    <label className="header-result-detail-value">{resultDetail.insuranceNumber}</label>
                                </div>
                            </div>
                            <div className="flex-1" style={{ textAlign: 'right' }}>
                                {resultDetail.emergency == true &&
                                    <label style={{ marginLeft: 5 }}>
                                        <div className="">
                                            <BadgeLabelAndValue
                                                label=''
                                                type={'danger'}
                                                value={t(`testResultPage:CC`)}
                                                className={"fw-500"}
                                            />
                                        </div>
                                    </label>
                                }
                                {resultDetail.requestState &&
                                    <label style={{ marginLeft: 5 }}>
                                        <div className="state-result-header-detail">
                                            <BadgeLabelAndValue
                                                label=''
                                                type={RESULT_STATE[resultDetail.requestState]}
                                                value={t(`testResultPage:${RESULT_STATE[resultDetail.requestState]}`)}
                                                className={"fw-500"}
                                            />
                                        </div>
                                    </label>
                                }
                            </div>
                        </React.Fragment>
                    }
                </React.Fragment>}
            >
                <AccordionBody>
                    <div className="row">
                        <div className="col-lg-3 mb-2 header-result-detail-content">
                            <div>
                                <label className="header-result-detail-label">{t("Full Name")}: </label>
                                <label style={{ color: '#4775C5' }} className="header-result-detail-value">
                                    <Link to={`/Patient/${resultDetail.patientId}/view?tab=1`} target="_blank">{resultDetail.patientName}</Link>
                                </label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("Birthday")}: </label>
                                <label className="header-result-detail-value">{convertDateFormat_VN(resultDetail.dob)}</label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("Gender")}: </label>
                                <label className="header-result-detail-value">{resultDetail.genderName}</label>
                                <label style={{ marginLeft: '10px' }} className="header-result-detail-label">{t("testRequestPage:Age")}: </label>
                                <label className="header-result-detail-value">{resultDetail.dob ? getAgeFromDOB(convertDateFormat(resultDetail.dob, "YYYY-MM-DD")) : ''}</label>
                            </div>
                            {/* <div>
                                    <label className="header-result-detail-label">{t("common:Phone")}: </label>
                                    <label className="header-result-detail-value">{resultDetail.phone}</label>
                                </div> */}
                            <div>
                                <label className="header-result-detail-label">{t("common:PIN")}: </label>
                                <label className="header-result-detail-value">{resultDetail.pin}</label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("common:Address")}:
                                    <span className="header-result-detail-value">{resultDetail.address}</span>
                                </label>

                            </div>
                        </div>
                        <div className="col-lg-3 mb-2 header-result-detail-content">
                            <div>
                                <label className="header-result-detail-label">{t("PID")}: </label>
                                <label className="header-result-detail-value">{resultDetail.patientId}</label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("SID")}: </label>
                                <label className="header-result-detail-value">
                                    <CustomSIDHover data={resultDetail.sampleIds} showTooltip={false} indexParent={'header-result'} />
                                </label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("Alternate ID")}: </label>
                                <label className="header-result-detail-value">{resultDetail.alternateId}</label>
                            </div>
                            {/* <div>
                                    <label className="header-result-detail-label">{t("Medical ID")}: </label>
                                    <label className="header-result-detail-value">{resultDetail.medicalId}</label>
                                </div>*/}
                            <div>
                                <label className="header-result-detail-label">{t("common:ServiceType")}: </label>
                                <label className="header-result-detail-value">{resultDetail.serviceTypeName}</label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("Insurance Number")}: </label>
                                <label className="header-result-detail-value">{resultDetail.insuranceNumber}</label>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-2 header-result-detail-content">
                            <div>
                                <label className="header-result-detail-label">{t("testResultPage:Collector Time")}: </label>
                                <label className="header-result-detail-value">{collection?.time}</label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("testResultPage:Collector Name")}: </label>
                                <label className="header-result-detail-value">{collection?.user}</label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("testResultPage:Received Time")}: </label>
                                <label className="header-result-detail-value">{received?.time}</label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("testResultPage:Received Name")}: </label>
                                <label className="header-result-detail-value">{received?.user}</label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("testResultPage:Medical ID")}: </label>
                                <label className="header-result-detail-value">{resultDetail?.medicalId}</label>
                            </div>
                            {(inPatient == "True" || inPatient == true) &&
                                <div className="d-flex">
                                    <label className="header-result-detail-label">{t("testRequestPage:In Patient")}: </label>
                                    <label className="header-result-detail-value">
                                        <AvForm>
                                            <div className="form-check form-switch">
                                                <CustomCheckbox
                                                    type="checkbox"
                                                    direction={"right"}
                                                    name="isSystem"
                                                    checked={resultDetail.inPatient}
                                                    label={''}
                                                    disabled={true}
                                                />
                                            </div>
                                        </AvForm></label>
                                </div>
                            }
                        </div>
                        <div className="col-lg-3 mb-2 header-result-detail-content">
                            <div>
                                <label className="header-result-detail-label">{t("common:Department")}: </label>
                                <label className="header-result-detail-value">{resultDetail.departmentName}</label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("common:Phycisian")}: <span className="header-result-detail-value">{resultDetail.physicianName}</span></label>
                            </div>
                            <div>
                                <label className="header-result-detail-label">{t("common:Diagnosis")}: <span className="header-result-detail-value">{resultDetail.diagnosisName}</span></label>
                            </div>
                            <div>
                                {files.map((row, index) => <div key={index} className="d-flex"
                                >
                                    {/* <div style={{ paddingRight: 5, cursor: 'pointer' }} onClick={() => {
                                        getFile(row.id)
                                    }}>
                                        <i className="fa fa-download"></i>
                                    </div> */}
                                    {(row.updateHis == true || row.sendHis == true) &&
                                        <>
                                            <div className="tooltip-custom">
                                                <i className="fa fa-check-circle" style={{ color: '#34c38f' }}></i>
                                                <span className="tooltiptext-custom" style={{ width: 'unset', maxWidth: '300px', height: 'unset', padding: 5 }}>
                                                    <div style={{ fontSize: 12, textAlign: 'left' }}>
                                                        <div className="d-flex">
                                                            <div className="result-status-send-file">1</div>
                                                            {row.updateHis == true ? (`${convertDateFormat(row.updatedDate, 'DD-MM-YYYY HH:mm')} ` + t("Đã gửi bản tin text sang HIS ")) : ""}
                                                        </div>
                                                        <div className="d-flex" style={{ wordWrap: 'break-word' }}>
                                                            <div className="result-status-send-file">2</div>
                                                            <div style={{ wordWrap: 'break-word', maxWidth: '280px' }}>
                                                                {row.sendHis == true ? (`${convertDateFormat(row.updatedDate, 'DD-MM-YYYY HH:mm')} ` + t("Đã gửi file sang HIS") + " : " + row.hisMessage) : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </>
                                    }
                                    <div style={{ marginLeft: 3 }} onClick={() => {
                                        viewFile(row.id)
                                    }}>
                                        <a href="javascript:void(0)">{row.fileName}</a>
                                    </div>

                                </div>)}
                            </div>
                        </div>
                    </div>
                </AccordionBody>
            </Accordion>
        </AccordionWrapper>
    )
}

SummaryInfo.propTypes = {
    onEdit: PropTypes.func,
    onClone: PropTypes.func,
    data: PropTypes.object,
    t: PropTypes.any
}

const mapStateToProps = ({ testRequest, testResult }) => ({
    testsLoadingComplete: testRequest.testsLoadingComplete,
    requestTestSamplesCount: testRequest.requestTestSamplesCount,
    requestTestTestsCount: testRequest.requestTestTestsCount,
    testResult: testResult.testResult,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testRequestPage", "testResultPage", "message", "common"])(SummaryInfo))