import { spreadSearchQuery } from "helpers/utilities"
import { get, put } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/worklists`

// Get All Test Requests
const getTestWorkLists = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const updateWorkLists = req => {
    return put(`${BASE_API_URL}/update-result`, req)
}

const validWorkLists = req => {
    return put(`${BASE_API_URL}/valid-result`, req)
}

const inValidWorkLists = req => {
    return put(`${BASE_API_URL}/invalid-result`, req)
}

export {
    getTestWorkLists,
    updateWorkLists,
    validWorkLists,
    inValidWorkLists
}

