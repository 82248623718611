import {
    CustomAvField,
    CustomButton,
    CustomCheckbox,
    CustomModal,
    CustomSelect
} from "components/Common";
import { Col, ModalBody, ModalFooter, Row } from "reactstrap";

//i18n
import { AvForm } from "availity-reactstrap-validation";
import { parameterCode } from "constant";
import { getInvalidMessageI18n } from "helpers/utilities";
import { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addCustomWorkflowManagement, getWorkflowCustom, getWorkflowCustomDetail, updateCustomWorkflow } from "store/actions";

const WorkflowModal = ({
    toggle,
    isEdit,
    t,
    addCustomWorkflowManagement,
    getCustomWorkflowList,
    getWorkflowCustomDetail,
    updateCustomWorkflow,
    workflowId,
    workflow,
    modal
}) => {
    const [workflowDetail, setWorkflowDetail] = useState({})
    const title = isEdit ? "Edit Workflow" : "New Workflow"
    const formEl = useRef()
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))

    useEffect(() => {
        if (isEdit && workflowId) {
            getWorkflowCustomDetail({ id: workflowId })
            setWorkflowDetail(workflow)
        } else {
            workflowDetail.companyId = userInfo.company
            setWorkflowDetail({ ...workflowDetail })
        }
    }, [isEdit, workflowId])
    useEffect(() => {
        setWorkflowDetail(workflow)
    }, [workflow])

    const closeModal = () => {
        toggle()
        getCustomWorkflowList()
        setWorkflowDetail({})
    }

    return (
        <CustomModal modal={modal} title={title} onToggle={closeModal} size="lg">
            <ModalBody className="requestModal">
                <AvForm
                    ref={formEl}
                    id="workflowForm"
                    onValidSubmit={(e, values) => {
                        if (isEdit) {
                            values.id = workflowId
                            updateCustomWorkflow(values, () => {
                                closeModal()
                            })
                        } else {
                            addCustomWorkflowManagement(values, () => {
                                closeModal()
                            })
                        }

                    }}
                    model={workflowDetail}
                >
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomSelect
                                name="companyId"
                                code={parameterCode.COMPANIES}
                                label={t("Company")}
                                value={userInfo?.company}
                                required
                                readOnly
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomAvField
                                label={t("Workflow Name")}
                                name="workflowName"
                                value={workflowDetail?.workflowName}
                                errorMessage={getInvalidMessageI18n(t, "Workflow Name")}
                                validate={{
                                    required: { value: true },
                                    maxLength: { value: 200 }
                                }}
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomAvField
                                label={t("Type")}
                                name="ruleType"
                                value='C'
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomCheckbox
                                type="checkbox"
                                direction="down"
                                name="isSystem"
                                label={`${t("common:System")} ?`}
                                checked={workflowDetail?.isSystem}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="pb-2">
                            <CustomAvField
                                label={t("Description")}
                                type="textarea"
                                name="description"
                                value={workflowDetail?.description}
                                validate={{
                                    maxLength: { value: 200 }
                                }}
                            />
                        </Col>
                    </Row>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={closeModal}
                    data-dismiss="modal"
                    className="button-width"
                />
                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user button-width"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

WorkflowModal.propTypes = {
}


const mapStateToProps = ({ RuleManagement }) => ({
    workflow: RuleManagement.workflow || {},
})

const mapDispatchToProps = dispatch => ({
    getCustomWorkflowList: payload => dispatch(getWorkflowCustom(payload)),
    addCustomWorkflowManagement: (payload, callBack) => dispatch(addCustomWorkflowManagement(payload, callBack)),
    getWorkflowCustomDetail: payload => dispatch(getWorkflowCustomDetail(payload)),
    updateCustomWorkflow: (payload, callBack) => dispatch(updateCustomWorkflow(payload, callBack)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(["common", "testConfig"])(WorkflowModal))