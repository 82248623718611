import { Check, CustomBootstrapInlineTable, FilterButton, Now } from "components/Common"
import BadgeLabel from "components/Common/BadgeLabel"
import IconStatus from "components/Common/IconStatus"
import { ModuleIds, RESULT_STATE, RESULT_STATE_Text, TestRequest_Test_Type, parameterCode, permissionType } from "constant"
import { getAllOrganizations } from "helpers/app-backend"
import { getPatientTestHistory } from "helpers/app-backend/testResult_backend_helper"
import { CalculateResultText, CheckRoleValid, ExecuteRuleWarningColor, ExecuteRuleWarningColorCompare, ExecuteRuleWarningColorText, convertDateFormat } from "helpers/utilities"
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import { Button, Row, UncontrolledCollapse } from "reactstrap"
import { UpdateTestResultResults, setFastValidate, setScrollResultDetail } from "store/laboratory/testResult/actions"
import ContentChildColumn from "./ContentChildColumn"
import CustomSelectField from "./CustomSelectField"
import FilterForm from "./FilterForm"
import HistoryModal from "./Modal/HistoryModal"
import PathologyResultModal from "./Modal/PathologyResultModal"
const RESOURCE = ModuleIds.TestResult
let testResultTestGlobal = [];
let isAllPrint = true
let filterGlobal = {}
let testResultResultsGlobal = []
let dataFilterGlobal = [];
let isFilter = false;
let filterSearch = false;
let isCheckCheckbox = false;
let isCheckAll = false;
let isChangeResult = false;
const DetailTestsTabTable = forwardRef(
    ({
        onSelect,
        onSelectAll,
        data,
        onEdit,
        onDelete,
        t,
        loading,
        updatedTime,
        onEditSampleType,
        onGetTestRequestSample,
        onUpdateTestResultResults,
        dataColumn,
        userPermissions,
        testResult,
        testResultFields,
        parameterFields,
        ChangeTestPerform,
        testResultResults,
        resultList,
        rowCountTable,
        onChange,
        groups,
        currentTab,
        onSetScrollResultDetail,
        onSetFastValidate,
        onRefresh,
        resultId,
        ...rest
    }, ref) => {
        const [testResultTests, setTestResultTests] = useState([])
        const [dataFilter, setDataFilter] = useState([]);
        const [arrcolumns, setArrColumns] = useState([]);
        const [columnDisplay, setColumnDisplay] = useState([]);
        const [editableColumns, setEditableColumns] = useState([]);
        const [historyData, setHistoryData] = useState([])
        const [historyModal, setHistoryModal] = useState(false)
        const [pathologyResultModal, setPathologyResultModal] = useState(false)
        const [isTestPerform, setIsTestPerform] = useState(false)
        const [columnExpand, setColumnExpand] = useState([]);
        const userInfo = localStorage.getItem("userInfo")
        const { sub } = JSON.parse(userInfo)
        const [fieldAuto, setFieldAuto] = useState([]);
        const [widthTable, setWidthTable] = useState(1000);
        const [organizations, setOrganizations] = useState([])
        const [keySearch, setKeySearch] = useState("")

        useEffect(() => {
            if (organizations.length === 0) {
                getOrganizations()
            }
        }, [])

        const getOrganizations = async () => {
            const res = await getAllOrganizations({ page: 1, size: 0 })
            setOrganizations(res.data)
        }

        useEffect(() => {
            testResultResultsGlobal = testResultResults
        }, [testResultResults])
        useEffect(() => {
            dataFilterGlobal = dataFilter
        }, [dataFilter])

        const isCancel = (code, paddingLeft) => {
            try {
                const isCanceled = parseInt(code) === RESULT_STATE_Text.Canceled || parseInt(code) === RESULT_STATE_Text.RDS
                if (isCanceled) {
                    return { ...{ textDecoration: 'line-through' }, paddingLeft: `${paddingLeft}px` };
                } else {
                    return { paddingLeft: `${paddingLeft}px` };
                }
            }
            catch (error) {
                console.log(error);
                return {}
            }
        };
        const modelDefault = {
            search: '',
            groups: groups.map(x => x.value) || [],
            // isPrinted: false,
            // isFullresult: false,
            // isValid: false,
            // isGroup: true,
            // isStatus: true,
            notPrinted: false,
            fullResult: false,
            valid: false,
            selectedGroup: [],
        }
        const [model, setModel] = useState(modelDefault)
        useEffect(() => {
            if (resultId) {
                setModel(modelDefault)
            }
        }, [resultId])

        useEffect(() => {
            filterGlobal = modelDefault
        }, [])
        const hasChildrenWithDownloadTrue = (data) => {
            // Check if the data item has download set to true
            if (data.download === true) {
                return true;
            }

            // If it has children, check them
            if (data.hasChildren) {
                for (const child of data.children) {
                    if (child.download === true) {
                        return true;
                    }
                }
            }

            // If no child with download true is found, return false
            return false;
        }
        const checkChildrenStates = data => {
            if (data.state) {
                return data.state
            }
            let commonState = null;

            if (data.hasChildren) {
                for (const child of data.children) {
                    if (!commonState) {
                        commonState = child.state
                    } else if (commonState !== child.state) {
                        const priorityStates = [
                            RESULT_STATE_Text.InProcess,
                            RESULT_STATE_Text.Completed,
                            RESULT_STATE_Text.Confirmed,
                            RESULT_STATE_Text.Validated,
                            RESULT_STATE_Text.Signed,
                            RESULT_STATE_Text.Released
                        ];
                        for (const priorityState of priorityStates) {
                            const stateFound = data.children.find(
                                child => child.state === priorityState
                            )
                            if (stateFound) {
                                return priorityState
                            }
                        }
                    }
                }
                if (commonState !== null) {
                    return commonState;
                }
            }
            return 0;
        }
        useEffect(() => {
            setModel({ ...model, groups: groups.map(x => x.value) || [], group: [...(groups.map(x => x.value)), ''] })
        }, [groups])

        useEffect(() => {
            if (isCheckCheckbox == false) {
                setDataFilter([...testResultTests])
            }
        }, [testResultTests])

        useEffect(() => {
            setTimeout(() => {
                fieldAuto.forEach(element => {
                    ChangeWidthAuto(element)
                });
            }, 500);
        }, [fieldAuto])

        useEffect(() => {
            if (testResultTests.length > 0) {
                onChange && onChange(testResultTests)
            }
        }, [testResultTests])


        const ChangeWidthAuto = (field) => {
            let lstCol = document.getElementsByClassName(field)
            let max = 0;
            for (let index = 0; index < lstCol.length; index++) {
                let element = lstCol[index];
                if (max < element.clientWidth)
                    max = element.clientWidth
            }
            for (let index = 0; index < lstCol.length; index++) {
                let element = lstCol[index];
                element.style.width = `${max}px`
                element.style.maxWidth = `${max}px`
                element.style.minWidth = `${max}px`
            }
            let width = document.getElementById("testTable")?.offsetWidth
            if (width) {
                if (width != widthTable)
                    setWidthTable(width)
            }
        }

        useEffect(() => {
            let result = [];
            AddColumnExpand(testResultTests, result)
            setColumnExpand(result);
            setTimeout(() => {
                fieldAuto.forEach(element => {
                    ChangeWidthAuto(element)
                });
            }, 500);
        }, [testResultTests])

        const AddColumnExpand = (source, result) => {
            for (let index = 0; index < source.length; index++) {
                const element = source[index];
                if (element.children && element.children.length > 0) {
                    result.push(element.code)
                    AddColumnExpand(element.children, result)
                }
            }
        }

        const UpdateExpandColumn = (code, isExpand, children) => {
            if (children.length == 0)
                return;
            let result = columnExpand
            if (isExpand) {
                result.push(code)
            }
            else {
                result = result.filter(x => x != code)
            }
            setColumnExpand(result)
        }

        useEffect(() => {
            checkExistReleaseValid()
        }, [resultList])

        useEffect(() => {
            setArrColumns(dataColumn)
        }, [dataColumn])
        useEffect(() => {
            ChangeTestPerform && ChangeTestPerform(isTestPerform)
        }, [isTestPerform])
        useEffect(() => {
            generateEditableColumns()
            if (testResultFields && testResultFields.length > 0 && arrcolumns.length > 0)
                SetColumnDisplay()
        }, [arrcolumns])

        useEffect(() => {
            SetColumnDisplay()
        }, [editableColumns])

        const GetColumnDefault = (dataField = ' ', label) => {
            let field = dataField.charAt(0).toLowerCase() + dataField.slice(1)
            return {
                dataField: `${field}`,
                text: t(`${label}`),
                style: {},
                headerStyle: { width: "100px" },
            }
        }
        const arrColumnDefault = "id,isValid,idx,name,result".split(',')
        const arrColumnDefaultExtra = "oldResult,isPrint,download,machineName,warning,unit,normalRange,resultText".split(',')
        const minWidthArr = [
            { key: 'resultText', value: '146px' },
            { key: 'warning', value: '50px' },
            { key: 'isPrint', value: '50px' },
            { key: 'comment', value: '200px' },
            { key: 'state', value: '138px' },
        ]
        const getMinWidth = (fieldName) => {
            const ind = minWidthArr.findIndex(x => `${x.key}`.toLowerCase() == fieldName)
            if (ind >= 0)
                return minWidthArr[ind].value;
            return '100px';
        }
        const SetColumnDisplay = () => {
            let temColumns = [...columns, ...editableColumns]
            let temColumnChild = [...columnChild, ...editableColumns]
            let newData = []
            let newDataChild = []
            arrColumnDefault.forEach(element => {
                newData.push(temColumns.find(x => x.dataField == element))
                newDataChild.push(temColumnChild.find(x => x.dataField == element))
            });

            let arrColumnNew = [...arrcolumns]
            arrColumnDefaultExtra.forEach(element => {
                let indexExists = arrcolumns.findIndex(x => `${x.fieldName}`.toLowerCase() == `${element}`.toLowerCase())
                if (indexExists < 0) {
                    arrColumnNew.unshift({
                        "id": new Date(),
                        "displayOrder": 0,
                        "displayLength": 0,
                        "fieldName": element,
                        "updatedBy": null,
                        "message": element
                    })
                }
            })

            let fAuto = []
            arrColumnNew.forEach(row => {
                let item = temColumns.find(x => `${x.dataField}`.toLowerCase() == `${row.fieldName}`.toLowerCase())
                if (item) {
                    if (!isNaN(row.displayLength) && item.headerStyle) {
                        try {
                            let width = (row.displayLength != undefined && Number(row.displayLength) == 0) ? 'auto' : row.displayLength
                            let minWidth = getMinWidth(`${row.fieldName}`.toLowerCase())
                            item.headerStyle.width = width
                            item.headerStyle.minWidth = minWidth
                            item.headerStyle.maxWidth = width
                            if (width == 'auto' && row.fieldName !== 'resultText') {
                                item.headerStyle.minWidth = minWidth
                                item.headerStyle.whiteSpace = 'nowrap'
                                item.headerClasses = `autowidth${row.fieldName}`
                                item.classes = `autowidth${row.fieldName}`
                                if (fAuto.findIndex(x => x == `autowidth${row.fieldName}`) < 0) {
                                    fAuto.push(`autowidth${row.fieldName}`)
                                }
                            }
                            if (row.fieldName == 'normalRange' || row.fieldName == 'unit') {
                                item.classes = (item.classes ? item.classes : '') + " result-detail-" + row.fieldName
                                item.headerClasses = (item.headerClasses ? item.headerClasses : '') + " result-detail-header" + row.fieldName
                            }
                            if (row.fieldName === 'State') {
                                item.classes = (item.classes ? item.classes : '') + " result-detail-state"
                                item.headerClasses = (item.headerClasses ? item.headerClasses : '') + " result-detail-state-header"
                            }
                        } catch (error) {

                        }

                    }
                    newData.push(item)
                }
                else {
                    let defaultColumn = GetColumnDefault(row.fieldName, row.message)
                    let width = (row.displayLength != undefined && Number(row.displayLength) == 0) ? 'auto' : row.displayLength
                    let minWidth = getMinWidth(`${row.fieldName}`.toLowerCase())
                    try {
                        defaultColumn.headerStyle.width = width
                        defaultColumn.headerStyle.minWidth = width
                        defaultColumn.headerStyle.maxWidth = width
                        defaultColumn.style.width = width
                        defaultColumn.style.minWidth = minWidth
                        defaultColumn.style.maxWidth = width
                        if (width == 'auto' && row.fieldName !== 'resultText') {
                            defaultColumn.style.minWidth = minWidth
                            defaultColumn.headerStyle.minWidth = minWidth
                            defaultColumn.headerStyle.whiteSpace = 'nowrap'
                            defaultColumn.style.whiteSpace = 'nowrap'
                            defaultColumn.classes = `autowidth${row.fieldName}`
                            defaultColumn.headerClasses = `autowidth${row.fieldName}`
                            if (fAuto.findIndex(x => x == `autowidth${row.fieldName}`) < 0) {
                                fAuto.push(`autowidth${row.fieldName}`)
                            }
                        }
                        if (row.fieldName == 'normalRange' || row.fieldName == 'unit') {
                            item.classes = (item.classes ? item.classes : '') + " result-detail-" + row.fieldName
                            item.headerClasses = (item.headerClasses ? item.headerClasses : '') + " result-detail-header" + row.fieldName
                        }
                        if (row.fieldName === 'State') {
                            defaultColumn.classes = (defaultColumn.classes ? defaultColumn.classes : '') + " result-detail-state"
                            defaultColumn.headerClasses = (defaultColumn.headerClasses ? defaultColumn.headerClasses : '') + " result-detail-state-header"
                        }
                    } catch (error) {

                    }
                    newData.push(defaultColumn)
                    newDataChild.push(defaultColumn)
                }
                let itemChild = temColumnChild.find(x => `${x.dataField}`.toLowerCase() == `${row.fieldName}`.toLowerCase())
                if (itemChild) {
                    if (!isNaN(row.displayLength) && itemChild.headerStyle) {
                        let width = (row.displayLength != undefined && Number(row.displayLength) == 0) ? 'auto' : row.displayLength
                        let minWidth = getMinWidth(`${row.fieldName}`.toLowerCase())
                        try {
                            itemChild.headerStyle.width = width
                            itemChild.headerStyle.minWidth = minWidth
                            itemChild.headerStyle.maxWidth = width
                            if (width == 'auto' && row.fieldName !== 'resultText') {
                                itemChild.headerStyle.minWidth = minWidth
                                itemChild.headerStyle.whiteSpace = 'nowrap'
                                itemChild.classes = `autowidth${row.fieldName}`
                                itemChild.headerClasses = `autowidth${row.fieldName}`
                                if (fAuto.findIndex(x => x == `autowidth${row.fieldName}`) < 0) {
                                    fAuto.push(`autowidth${row.fieldName}`)
                                }
                            }
                            else {
                                itemChild.style.width = width
                                itemChild.style.minWidth = minWidth
                                itemChild.style.maxWidth = width
                            }
                            if (row.fieldName == 'normalRange' || row.fieldName == 'unit') {
                                item.classes = (item.classes ? item.classes : '') + " result-detail-" + row.fieldName
                                item.headerClasses = (item.headerClasses ? item.headerClasses : '') + " result-detail-header" + row.fieldName
                            }
                            if (row.fieldName === 'State') {
                                itemChild.classes = (itemChild.classes ? itemChild.classes : '') + " result-detail-state"
                                itemChild.headerClasses = (itemChild.headerClasses ? itemChild.headerClasses : '') + " result-detail-state-header"
                            }
                        } catch (error) {

                        }
                    }
                    newDataChild.push(itemChild)
                }

            });
            setFieldAuto(fAuto)
            setColumnDisplay(newData)
        }

        useImperativeHandle(ref, () => ({
            GetConfirmIds: () => {
                return GetIds('isValid');
            },
            GetCancelConfirmIds: () => {
                return GetIds('isValid');
            },
            GetValidIds: () => {
                return GetIds('isValid');
            },
            GetRerunIds: () => {
                return GetIds('isValid');
            },
            GetCancelValidIds: () => {
                return GetIds('isValid');
            },
            GetDeleteIds: () => {
                return GetIds('isValid');
            },
            GetIsPrintIds: () => {
                return GetIds('isValid');
            },
            GetReportType: () => {
                return GetReportType();
            },
            GetAllReportType: () => {
                return GetAllReportType();
            },
            GetAllIds: () => {
                return GetAllIds();
            },
            GetAllIdsHasResult: () => {
                return GetAllIdsHasResult();
            },
            GetTestSelected: () => {
                return GetTestSelected()
            },
            GetTestSendSelected: () => {
                return GetTestSendSelected()
            },
            ResetColumnWidth: () => {
                setTimeout(() => {
                    fieldAuto.forEach(element => {
                        ChangeWidthAuto(element)
                    });
                }, 500);
            },
        }));
        useEffect(() => {
            setTestResultTests(data)
        }, [data])

        useEffect(() => {
            testResultTestGlobal = testResultTests || [];

        }, [testResultTests])
        /** Confirm button */
        const confirmElement = (
            <button type="button"
                className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1"
            ><i className="mdi mdi-check"></i></button>
        );

        /** Cancel button */
        const cancelElement = (
            <button type="button"
                className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none">
                <i className="mdi mdi-close"></i></button>
        );
        const getNewStateLocalAfterEditResult = (result, resultText, currentState) => {
            if (currentState != RESULT_STATE_Text.InProcess) {
                return currentState
            }
            if (result != undefined && result != null && result != "") {
                return RESULT_STATE_Text.Completed
            }
            if (resultText != undefined && resultText != null && resultText != "") {
                return RESULT_STATE_Text.Completed
            }
            return currentState
        }
        const executeRuleWarningColor = (result, resultText, resultTest) => {
            try {
                if ((result == '' || result == null) && (resultText == '' || resultText == null)) {
                    return ''
                }
                let isCompare = false;
                result = result?.replaceAll(",", ".");
                let isResultNumber = !isNaN(result) && result != ''
                let resultNumber = 0;
                if (isResultNumber) {
                    resultNumber = Number(result)
                }
                let resultFraction = CalculateResultText(result);
                isCompare = result?.includes('>') || result?.includes('<');
                if (!(resultFraction == null || resultFraction == '')) {
                    resultNumber = Number(resultFraction);
                    isResultNumber = true;
                }
                let color = '';
                if (isResultNumber && !(resultText == null || resultText == '')) {
                    color = ExecuteRuleWarningColor(resultNumber, resultTest);
                    //color = ExecuteRuleWarningColorBoth(resultNumber, resultText, resultTest);
                }
                else if (isResultNumber) {
                    color = ExecuteRuleWarningColor(resultNumber, resultTest);
                }
                else if (isCompare) {
                    color = ExecuteRuleWarningColorCompare(result, resultTest);
                }
                else {
                    color = ExecuteRuleWarningColorText(resultText, resultTest);
                }
                return color;
            }
            catch {
                return ''
            }
        }
        const onEditableChange = (value, item, field, isUpdateServer = false, isReset = false) => {
            let newData = [...testResultTestGlobal];
            let itemNew;
            if (item.type === TestRequest_Test_Type.TEST) {
                if (item.groupCode) {
                    let parentParent = newData.find(x => x.code === item.groupCode && x.type === TestRequest_Test_Type.PROFILE_GROUP)
                    let itemParent = parentParent.children.find(x => x.code === item.parentCode && x.type === TestRequest_Test_Type.PROFILE)
                    itemNew = itemParent.children.find(x => x.id === item.id && x.type === TestRequest_Test_Type.TEST)
                    itemNew[field] = value;
                    itemNew.updatedBy = sub;
                    itemNew.isManual = true;
                }
                else if (item.parentCode && item.parentCode != '') {
                    let itemParent = newData.find(x => x.code === item.parentCode)
                    itemNew = itemParent.children.find(x => x.code === item.code && x.type === TestRequest_Test_Type.TEST)
                    itemNew[field] = value;
                    itemNew.updatedBy = sub;
                    itemNew.isManual = true;
                } else {
                    itemNew = newData.find(x => x.code === item.code);
                    itemNew[field] = value;
                    itemNew.updatedBy = sub;
                    itemNew.isManual = true;
                }
                if (field == 'result' || field == 'resultText') {
                    itemNew.color = executeRuleWarningColor(itemNew.result, itemNew.resultText, itemNew)
                    itemNew.state = getNewStateLocalAfterEditResult(itemNew.result, itemNew.resultText, itemNew.state)
                }
            } else if (item.type === TestRequest_Test_Type.PROFILE) {
                if (item.parentCode && item.parentCode != '') {
                    let itemParent = newData.find(x => x.code === item.parentCode && x.type === TestRequest_Test_Type.PROFILE_GROUP)
                    itemNew = itemParent.children.find(x => x.code === item.code && x.type === TestRequest_Test_Type.PROFILE)
                    itemNew[field] = value;
                    itemNew.updatedBy = sub;
                    itemNew.isManual = true;
                }
                else {
                    itemNew = newData.find(x => x.code === item.code && x.type === TestRequest_Test_Type.PROFILE)
                    itemNew[field] = value;
                    itemNew.updatedBy = sub;
                    itemNew.isManual = true;
                }
            }
            else {
                itemNew = newData.find(x => x.code === item.code && x.type === TestRequest_Test_Type.PROFILE_GROUP);
                itemNew[field] = value;
                itemNew.updatedBy = sub;
                itemNew.isManual = true;
            }
            setTestResultTests([...newData])
            isUpdateServer && onUpdateTestResultResults({ data: [...newData], item: itemNew, result: value, id: testResult.id });
            if (isReset) {
                isChangeResult = true
            }
        }
        useEffect(() => {
            if (isCheckAll || isChangeResult) {
                isCheckAll = false
                isChangeResult = false
                if (filterSearch)
                    onFilterSearchOnly(filterGlobal, false)
                else
                    onFilter(filterGlobal, false)
            }
        }, [testResultTests])

        const onCheckAllValid = (field, val) => {
            let tmp = isFilter ? dataFilterGlobal : testResultTestGlobal
            tmp.forEach((item, index) => {
                onEditableChange(val, item, field)
                if (item.children && item.children.length > 0)
                    onEditableSubChange(val, item, field)
                if (index == tmp.length - 1 && isFilter) {
                    isCheckAll = true
                }
            });
        }
        const checkExistReleaseValid = () => {
            for (let index = 0; index < testResultTestGlobal.length; index++) {
                const item = testResultTestGlobal[index];
                if (item.isValid == true && Number(item.state) >= RESULT_STATE_Text.Validated) {
                    setIsTestPerform(true)
                    return;
                }
                if (item.children && item.children.length > 0) {
                    for (let index1 = 0; index1 < item.children.length; index1++) {
                        const parent = item.children[index1];
                        if (parent.isValid == true && Number(parent.state) >= RESULT_STATE_Text.Validated) {
                            setIsTestPerform(true)
                            return;
                        }
                        if (parent.children && parent.children.length > 0) {
                            for (let index2 = 0; index2 < parent.children.length; index2++) {
                                const child = parent.children[index2];
                                if (child.isValid == true && Number(child.state) >= RESULT_STATE_Text.Validated) {
                                    setIsTestPerform(true)
                                    return;
                                }
                            }
                        }
                    }
                }

            }
            setIsTestPerform(false)
        }
        const onEditableSubChange = (value, item, field) => {
            let newData = [...testResultTestGlobal];
            let newItem = newData.find(x => x.type === item.type && x.code === item.code)
            if (newItem) {
                if (newItem.children && newItem.children.length > 0) {
                    newItem.children.forEach(element => {
                        element[field] = value;
                        if (element.children && element.children.length > 0) {
                            element.children.forEach(child => {
                                child[field] = value;
                            });
                        }
                    });
                    setTestResultTests([...newData])
                }
            }
        }

        const onEditableSubChildChange = (value, item, field) => {
            let newData = [...testResultTestGlobal];
            let newItem = newData.find(x => x.code === item.parentCode)
            if (newItem) {
                if (newItem.children && newItem.children.length > 0) {
                    let childItem = newItem.children.find(x => x.type === item.type && x.code === item.code)
                    if (childItem.children && childItem.children.length > 0) {
                        childItem.children.forEach(element => {
                            element[field] = value;
                        });
                        setTestResultTests([...newData])
                    }
                }
            }
        }

        const getHistory = async (patientId, testCode) => {
            setHistoryModal(true)
            const res = await getPatientTestHistory(patientId, testCode)
            setHistoryData(res)
        }

        const GetIds = (field, fieldValue = 'resultTestId') => {
            let ids = [];
            const dt = testResultTestGlobal;
            dt.forEach(row => {
                if (row.type == TestRequest_Test_Type.TEST && row[fieldValue] && row[field] == true && row?.state !== RESULT_STATE_Text.Canceled) {
                    ids.push(row[fieldValue])
                } else {
                    if (row.children && row.children.length > 0) {
                        row.children.forEach(sub => {
                            if (sub.type == TestRequest_Test_Type.TEST && sub[fieldValue] && sub[field] == true && sub.state !== RESULT_STATE_Text.Canceled) {
                                ids.push(sub[fieldValue])
                            }
                            if (sub.children && sub.children.length > 0) {
                                sub.children.forEach(child => {
                                    if (child.type == TestRequest_Test_Type.TEST && child[fieldValue] && child[field] == true && sub.state !== RESULT_STATE_Text.Canceled)
                                        ids.push(child[fieldValue])
                                });
                            }
                        });
                    }
                }
            });
            return ids;
        }

        const GetAllIds = (field, fieldValue = 'resultTestId') => {
            let ids = [];
            const dt = testResultTestGlobal;
            dt.forEach(row => {
                if (row.type == TestRequest_Test_Type.TEST && row[fieldValue] && row?.state !== RESULT_STATE_Text.Canceled) {
                    ids.push(row[fieldValue])
                } else {
                    if (row.children && row.children.length > 0) {
                        row.children.forEach(sub => {
                            if (sub.type == TestRequest_Test_Type.TEST && sub[fieldValue] && sub.state !== RESULT_STATE_Text.Canceled)
                                ids.push(sub[fieldValue])
                            if (sub.children && sub.children.length > 0) {
                                sub.children.forEach(child => {
                                    if (child.type == TestRequest_Test_Type.TEST && child[fieldValue] && sub.state !== RESULT_STATE_Text.Canceled && child[field] == true)
                                        ids.push(child[fieldValue])
                                });
                            }
                        });
                    }
                }

            });
            return ids;
        };

        const GetAllIdsHasResult = (field, fieldValue = 'resultTestId') => {
            let ids = [];
            const dt = testResultTestGlobal;
            dt.forEach(row => {
                if ((row.type == TestRequest_Test_Type.TEST && row[fieldValue] && row?.state !== RESULT_STATE_Text.Canceled) && (row.result || row.resultText)) {
                    ids.push(row[fieldValue])
                } else {
                    if (row.children && row.children.length > 0) {
                        row.children.forEach(sub => {
                            if ((sub.type == TestRequest_Test_Type.TEST && sub[fieldValue] && sub.state !== RESULT_STATE_Text.Canceled) && (sub.result || sub.resultText)) {
                                ids.push(sub[fieldValue])
                            }
                            if (sub.children && sub.children.length > 0) {
                                sub.children.forEach(child => {
                                    if ((child.type == TestRequest_Test_Type.TEST && child[fieldValue] && sub.state !== RESULT_STATE_Text.Canceled && child[field] == true) && (child.result || child.resultText))
                                        ids.push(child[fieldValue])
                                });
                            }
                        });
                    }
                }

            });
            return ids;
        };

        const GetReportType = () => {
            let res = [];
            let reportTypeNames = [];
            const dt = testResultTestGlobal;
            dt.forEach(row => {
                if (row.type === TestRequest_Test_Type.TEST && row.isValid === true) {
                    let reportTypeName = row.reportTypeName
                    if (reportTypeName !== "" && !reportTypeNames.includes(reportTypeName)) {
                        res.push(reportTypeName);
                        reportTypeNames.push(reportTypeName);
                    }
                }
                if (row.children && row.children.length > 0) {
                    row.children.forEach(sub => {
                        if (sub.type === TestRequest_Test_Type.TEST && sub.isValid === true) {
                            let reportTypeName = sub.reportTypeName
                            if (reportTypeName !== "" && !reportTypeNames.includes(reportTypeName)) {
                                res.push(reportTypeName);
                                reportTypeNames.push(reportTypeName);
                            }
                        }
                        if (sub.children && sub.children.length > 0) {
                            sub.children.forEach(child => {
                                if (child.type === TestRequest_Test_Type.TEST && child.isValid === true) {
                                    let reportTypeName = child.reportTypeName
                                    if (reportTypeName !== "" && !reportTypeNames.includes(reportTypeName)) {
                                        res.push(reportTypeName);
                                        reportTypeNames.push(reportTypeName);
                                    }
                                }
                            });
                        }
                    });
                }
            });
            return res;
        }

        const GetAllReportType = () => {
            let res = [];
            let reportTypeNames = [];
            const dt = testResultTestGlobal;
            dt.forEach(row => {
                if (row.type === TestRequest_Test_Type.TEST) {
                    let reportTypeName = row.reportTypeName
                    if (reportTypeName !== "" && !reportTypeNames.includes(reportTypeName) && row?.state !== RESULT_STATE_Text.Canceled) {
                        res.push(reportTypeName);
                        reportTypeNames.push(reportTypeName);
                    }
                }
                if (row.children && row.children.length > 0) {
                    row.children.forEach(sub => {
                        if (sub.type === TestRequest_Test_Type.TEST) {
                            let reportTypeName = sub.reportTypeName
                            if (reportTypeName !== "" && !reportTypeNames.includes(reportTypeName) && sub?.state !== RESULT_STATE_Text.Canceled) {
                                res.push(reportTypeName);
                                reportTypeNames.push(reportTypeName);
                            }
                        }
                        if (sub.children && sub.children.length > 0) {
                            sub.children.forEach(child => {
                                if (child.type === TestRequest_Test_Type.TEST) {
                                    let reportTypeName = child.reportTypeName
                                    if (reportTypeName !== "" && !reportTypeNames.includes(reportTypeName) && child?.state !== RESULT_STATE_Text.Canceled) {
                                        res.push(reportTypeName);
                                        reportTypeNames.push(reportTypeName);
                                    }
                                }
                            });
                        }
                    });
                }
            });
            return res;
        }


        const GetTestSelected = () => {
            let res = [];
            const dt = testResultTestGlobal;
            dt.forEach(row => {
                if (row.type == TestRequest_Test_Type.TEST && row['isValid'] == true)
                    res.push(row)
                if (row.children && row.children.length > 0) {
                    row.children.forEach(sub => {
                        if (sub.type == TestRequest_Test_Type.TEST && sub['isValid'] == true)
                            res.push(sub)
                        if (sub.children && sub.children.length > 0) {
                            sub.children.forEach(child => {
                                if (child.type == TestRequest_Test_Type.TEST && child['isValid'] == true)
                                    res.push(child)
                            });
                        }
                    });
                }
            });
            return res;
        }

        const GetTestSendSelected = () => {
            let res = [];
            const dt = testResultTestGlobal;
            dt.forEach(row => {
                if (row.type == TestRequest_Test_Type.TEST && row['isValid'] == true)
                    res.push(row)
                if (row.children && row.children.length > 0) {
                    const testCodeSelect = row.children.find(item => item.isValid)
                    if (!!testCodeSelect) {
                        res.push(row)
                    }
                }
            })
            return res;
        }

        const CheckRoleEdit = (state, fieldName) => {
            if (['comment', 'machineName', 'resultText'].includes(fieldName)) {
                if (Number(state) == Number(RESULT_STATE_Text.Completed)) {
                    return false
                }
            }
            if (Number(state) >= Number(RESULT_STATE_Text.Confirmed)) {
                return true
            }
            const val = CheckRoleValid(permissionType.Validate, RESOURCE, userPermissions)
            const upd = CheckRoleValid(permissionType.U, RESOURCE, userPermissions)
            if (upd == true) {
                return false;
            }
            else if (Number(state) < Number(RESULT_STATE_Text.Confirmed)) {
                return false;
            }
            else if (Number(state) < Number(RESULT_STATE_Text.Validated)) {
                return val ? false : true
            }
            else {
                return true;
            }
        }

        const generateEditableColumns = () => {
            var editableColumns = testResultFields.map(fieldName =>
                generateEditableColumn(fieldName)
            )
            setEditableColumns(editableColumns)
        }
        const checkVisiable = (fieldName, state) => {
            if (fieldName == 'resultText' || fieldName == 'result') {
                if (state == RESULT_STATE_Text.RDS || state == RESULT_STATE_Text.Canceled) {
                    return false;
                }
            }
            return true;
        }
        const generateEditableColumn = (fieldName = "") => {
            let num = fieldName.split('').map(x => x.charCodeAt(0)).reduce((a, b) => a + b, 0) * 10;
            var column = {
                dataField: fieldName,
                text: parameterFields.find(z => `${z.code}`.toLocaleLowerCase() == `${fieldName}`.toLocaleLowerCase())?.message || fieldName,
                headerStyle: {
                    minWidth: 150, maxWidth: 150, width: 150, textAlign: 'center',
                    position: fieldName === 'resultText' && 'sticky', left: fieldName === 'resultText' && 520, zIndex: fieldName === 'resultText' ? 6 : 5, //height: '40px'
                },
                style: {
                    minWidth: 150, maxWidth: 150, width: 150, textAlign: 'center', alignContent: 'center',
                    left: fieldName === 'resultText' && 520, position: fieldName === 'resultText' && 'sticky', zIndex: fieldName === 'resultText' && 0,
                    padding: 0//, height: '45px'
                },
                classes: `item-fixed-result-table-detail-content item-fixed-result-table-detail-content-${fieldName} ${fieldName === 'resultText' ? ' item-fixed-result-table-detail-content-resultText' : ''}`,
                headerClasses: ` item-fixed-result-table-detail-content-header-${fieldName} ${fieldName === 'resultText' ? ' item-fixed-result-table-detail-content-header-resultText' : ''}`,
                formatter: (cellContent, item, index) => {
                    const idHTML = `resultBlur${fieldName}${item.code}${item.parentId || ''}`.replace(' ', '')
                    const code = fieldName === 'resultText' ? parameterCode.RESULT_TEXT : parameterCode.INSTRUMENT
                    return (<React.Fragment>
                        {checkVisiable(fieldName, item.state) ?
                            <>
                                {fieldName === 'resultText' && <ContentChildColumn
                                    isActive={false}
                                    width={138}
                                    content={() => <div
                                        style={{ width: 138, maxWidth: 138, minWidth: 138 }}
                                    >
                                        <div className={`d-flex align-items-center text-result-color ${getColorResult(item.color)}`}>
                                            {CheckRoleEdit(item.state, fieldName) ?
                                                <div className="d-flex">
                                                    <span style={{ width: '115px' }} onClick={() => {
                                                        if ((item?.category === 'GPB' || item?.testCategory === 'GPB') && fieldName === 'resultText') {
                                                            setPathologyResultModal(item)
                                                        }
                                                    }} className={fieldName == 'resultText' ? getClassResultText(item.color) : ''}>{cellContent || (item.type == TestRequest_Test_Type.TEST ? '' : '')}</span>
                                                    {item.type == TestRequest_Test_Type.TEST && fieldName === 'resultText' &&
                                                        <div className="d-flex" style={{ alignSelf: 'center' }} >
                                                            {/* <div style={{ width: 20, marginLeft: 5 }}><i style={{ color: '#A6A7B5' }} className="fas fa-pen"></i></div> */}
                                                            <Check permission={permissionType.R} resource={RESOURCE}>
                                                                {item.testCount > 0 ?
                                                                    <div style={{ width: 20, cursor: 'pointer', marginLeft: 5, textAlign: 'center' }}
                                                                        onClick={() => {
                                                                            getHistory(getPatientId(item.patientId), item.testCode)
                                                                        }}
                                                                    ><i style={{ color: '#556ee6' }} className="fas fa-history"></i></div>
                                                                    :
                                                                    <div style={{ width: 20, marginLeft: 5, textAlign: 'center' }}><i style={{ color: '#A6A7B5' }} className="fas fa-history"></i></div>
                                                                }
                                                            </Check>

                                                        </div>
                                                    }
                                                </div> :
                                                <React.Fragment>
                                                    <div className="d-flex">
                                                        <div id={idHTML + '_'} className={item.type == TestRequest_Test_Type.TEST ? 'resultBlurItemFocus' + fieldName : ''} tabIndex={9999 + num}
                                                            onKeyDown={e => {
                                                                if (e.key === 'Enter' || e.key === "Tab") {
                                                                    const fields =
                                                                        Array.from(document.getElementsByClassName('resultBlurItemFocus' + fieldName)) ||
                                                                        []
                                                                    const position = fields.findIndex(x => x.id == `${idHTML}_`)
                                                                    if (position >= 0) {
                                                                        setTimeout(() => {
                                                                            let el = fields[position + 1]
                                                                            if (el) {
                                                                                const parent = el.firstElementChild
                                                                                if (parent) {
                                                                                    parent.firstElementChild && parent.firstElementChild.click()
                                                                                }
                                                                            }
                                                                            el = document.getElementById(`${idHTML}`)
                                                                            el && el.click()
                                                                        }, 100);

                                                                    }
                                                                }
                                                            }}
                                                            onFocus={() => {
                                                                let el = document.getElementById(`${idHTML + '_'}`)
                                                                if (el) {
                                                                    const parent = el.firstElementChild
                                                                    if (parent) {
                                                                        try {
                                                                            parent && parent.click()
                                                                        } catch (error) {
                                                                            console.log(error);
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                let el = document.getElementById(`${idHTML}`)
                                                                el && el.click()
                                                            }}
                                                            style={{ width: fieldName.toLocaleLowerCase() == 'comment' ? 200 : 115, paddingLeft: '4px' }}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                e.preventDefault()
                                                                if ((item?.category === 'PATHOLOGY' || item?.testCategory === 'GPB') && fieldName === 'resultText') {
                                                                    setPathologyResultModal(item)
                                                                }
                                                            }}>
                                                            <Editable
                                                                className={fieldName == 'resultText' ? getClassResultText(item.color, item) : ''}
                                                                alwaysEditing={false}
                                                                disabled={false}
                                                                editText={(item.resultTestId != 0 && item.type == TestRequest_Test_Type.TEST) ? `${(item[fieldName] == null || item[fieldName] == '') ? '---' : item[fieldName]}` : ''}
                                                                initialValue={(item.resultTestId != 0 && item.type == TestRequest_Test_Type.TEST) ? `${(item[fieldName] == null || item[fieldName] == '') ? '' : item[fieldName]}` : ''}
                                                                id={item.code}
                                                                isValueClickable={false}
                                                                mode="inline"
                                                                placement="top"
                                                                showText={false}
                                                                type="textfield"
                                                                renderConfirmElement={<button type="button" id={idHTML}
                                                                    className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
                                                                ><i className="mdi mdi-check"></i></button>}
                                                                renderCancelElement={cancelElement}
                                                                onSubmit={(value) => {
                                                                    if (item[fieldName] != value && !(item[fieldName] == null && value == ''))
                                                                        onEditableChange(value, item, fieldName, true, true)
                                                                }}
                                                            />
                                                        </div>

                                                        {item.type == TestRequest_Test_Type.TEST && fieldName === 'resultText' &&
                                                            <div className="d-flex" style={{ alignSelf: 'center' }}>
                                                                {/* <div style={{ width: 20, marginLeft: 5 }}><i style={{ color: '#A6A7B5' }} className="fas fa-pen"></i></div> */}
                                                                <Check permission={permissionType.R} resource={RESOURCE}>
                                                                    {item.testCount > 0 ?
                                                                        <div style={{ width: 20, cursor: 'pointer', marginLeft: 5, textAlign: 'center' }}
                                                                            onClick={() => {
                                                                                getHistory(getPatientId(item.patientId), item.testCode)
                                                                            }}
                                                                        ><i style={{ color: '#556ee6' }} className="fas fa-history"></i></div>
                                                                        :
                                                                        <div style={{ width: 20, marginLeft: 5, textAlign: 'center' }}><i style={{ color: '#A6A7B5' }} className="fas fa-history"></i></div>
                                                                    }
                                                                </Check>

                                                            </div>
                                                        }
                                                    </div>
                                                </React.Fragment>}
                                        </div>

                                    </div>} />}

                                {fieldName !== 'resultText' && <>
                                    {CheckRoleEdit(item.state, fieldName) ?
                                        <div className="d-flex">
                                            <span style={{ width: '120px' }} className={fieldName == 'resultText' ? getClassResultText(item.color) : ''}>{cellContent || (item.type == TestRequest_Test_Type.TEST ? '' : '')}</span>
                                            {item.type == TestRequest_Test_Type.TEST && fieldName === 'resultText' &&
                                                <div className="d-flex" style={{ alignSelf: 'center' }} >
                                                    {/* <div style={{ width: 20, marginLeft: 5 }}><i style={{ color: '#A6A7B5' }} className="fas fa-pen"></i></div> */}
                                                    <Check permission={permissionType.R} resource={RESOURCE}>
                                                        {item.testCount > 0 ?
                                                            <div style={{ width: 20, cursor: 'pointer', marginLeft: 5, textAlign: 'center' }}
                                                                onClick={() => {
                                                                    getHistory(getPatientId(item.patientId), item.testCode)
                                                                }}
                                                            ><i style={{ color: '#556ee6' }} className="fas fa-history"></i></div>
                                                            :
                                                            <div style={{ width: 20, marginLeft: 5, textAlign: 'center' }}><i style={{ color: '#A6A7B5' }} className="fas fa-history"></i></div>
                                                        }
                                                    </Check>

                                                </div>
                                            }
                                        </div> :
                                        <React.Fragment>
                                            <div className="d-flex">
                                                {!(fieldName === 'machineName') ?
                                                    <div id={idHTML + '_'} className={item.type == TestRequest_Test_Type.TEST ? 'resultBlurItemFocus' + fieldName : ''} tabIndex={9999 + num}
                                                        onKeyDown={e => {
                                                            if (e.key === 'Enter' || e.key === "Tab") {
                                                                const fields =
                                                                    Array.from(document.getElementsByClassName('resultBlurItemFocus' + fieldName)) ||
                                                                    []
                                                                const position = fields.findIndex(x => x.id == `${idHTML}_`)
                                                                if (position >= 0) {
                                                                    setTimeout(() => {
                                                                        let el = fields[position + 1]
                                                                        if (el) {
                                                                            const parent = el.firstElementChild
                                                                            if (parent) {
                                                                                parent.firstElementChild && parent.firstElementChild.click()
                                                                            }
                                                                        }
                                                                        el = document.getElementById(`${idHTML}`)
                                                                        el && el.click()
                                                                    }, 100);

                                                                }
                                                            }
                                                        }}
                                                        onFocus={() => {
                                                            let el = document.getElementById(`${idHTML + '_'}`)
                                                            if (el) {
                                                                const parent = el.firstElementChild
                                                                if (parent) {
                                                                    try {
                                                                        parent && parent.click()
                                                                    } catch (error) {
                                                                        console.log(error);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        onBlur={() => {
                                                            let el = document.getElementById(`${idHTML}`)
                                                            el && el.click()
                                                        }}
                                                        style={{ width: fieldName.toLocaleLowerCase() == 'comment' ? 200 : 120 }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            e.preventDefault()
                                                        }}>
                                                        <Editable
                                                            className={fieldName == 'resultText' ? getClassResultText(item.color, item) : ''}
                                                            alwaysEditing={false}
                                                            disabled={false}
                                                            editText={(item.resultTestId != 0 && item.type == TestRequest_Test_Type.TEST) ? `${(item[fieldName] == null || item[fieldName] == '') ? '---' : item[fieldName]}` : ''}
                                                            initialValue={(item.resultTestId != 0 && item.type == TestRequest_Test_Type.TEST) ? `${(item[fieldName] == null || item[fieldName] == '') ? '' : item[fieldName]}` : ''}
                                                            id={item.code}
                                                            isValueClickable={false}
                                                            mode="inline"
                                                            placement="top"
                                                            showText={false}
                                                            type="textfield"
                                                            renderConfirmElement={<button type="button" id={idHTML}
                                                                className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
                                                            ><i className="mdi mdi-check"></i></button>}
                                                            renderCancelElement={cancelElement}
                                                            onSubmit={(value) => {
                                                                if (item[fieldName] != value && !(item[fieldName] == null && value == ''))
                                                                    onEditableChange(value, item, fieldName, true, true)
                                                            }}
                                                        />
                                                    </div>
                                                    :
                                                    item.state !== RESULT_STATE_Text.Canceled &&
                                                    <div className="flex-1" onClick={e => e.stopPropagation()}>
                                                        <CustomSelectField
                                                            autoFocus={true}
                                                            value={(item.resultTestId != 0 && item.type == TestRequest_Test_Type.TEST) ? `${(item[fieldName] == null || item[fieldName] == '') ? [] : item[fieldName]}` : []}
                                                            code={code} fieldName={fieldName} item={item} idHTML={idHTML}
                                                            onEditableChange={(a, b, c, d) => onEditableChange(a, b, c, d, true)}
                                                            index={index}
                                                        />
                                                    </div>

                                                }
                                            </div>
                                        </React.Fragment>}
                                </>}

                            </>
                            : <></>
                        }
                    </React.Fragment>)
                },
            }

            return column
        }

        const getPatientId = (patientid) => {
            if (patientid && patientid != 'undefined') {
                return patientid
            }
            return testResult.patientId
        }

        const getClassText = (item) => {
            if (item.resultTestId != 0 && item.type == TestRequest_Test_Type.TEST) {
                if (item['result'] == null || item['result'] == '')
                    return "result-color-1 text-center"
            }
        }

        const getColorResult = (color) => {
            if (color == '1') {
                return "result-color-1"
            }
            else if (color == '2') {
                return "result-color-2"
            }
            else if (color == '3') {
                return "result-color-3"
            }
            else if (color == '4') {
                return "result-color-4"
            }
            return "result-color-0"

        }

        const getAlignResult = (color) => {
            if (color == '1' || color == '3') {
                return { textAlign: 'left' }
            }
            else if (color == '2' || color == '4') {
                return { textAlign: 'right' }
            }
            return { textAlign: 'center' }
        }

        const getClassResultText = (color, item) => {
            if (item?.resultTestId != 0 && item?.type == TestRequest_Test_Type.TEST) {
                if (item['resultText'] == null || item['resultText'] == '')
                    return "result-color-1 text-center"
            }
            if (color == '2') {
                return "result-text-color-2 text-end"
            }
            return "result-text-color-0 text-center"
        }
        const onChangeValidAll = (val) => {
            let isCheck = document.getElementById("isValidAllResultDetail")
            if (isCheck) {
                isCheck.checked = val
            }
        }
        const columns = [
            {
                dataField: "id",
                text: "id",
                hidden: true,
                searchable: false,
            },
            {
                dataField: "isValid",
                text: <div className="text-center" style={{ marginLeft: '-12px' }}>
                    <input type={'checkbox'} id="isValidAllResultDetail" onChange={e => {
                        // onChangeValidAll(e.target.checked)
                        onCheckAllValid('isValid', e.target.checked)
                    }} />
                </div>,
                style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, position: 'sticky', left: 0, zIndex: 7 },
                headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, position: 'sticky', left: 0, zIndex: 9 },
                classes: "border-left-organ-2px",
                formatter: (cellContent, item, index, level) => {
                    return <div className="text-center" style={{ margin: 0 }} onClick={e => e.stopPropagation()} >
                        {parseInt(item.state) === RESULT_STATE_Text.Canceled ?
                            <input tabIndex={-1} type="checkbox" checked={false} disabled />
                            :
                            <input tabIndex={-1} type="checkbox" checked={item.isValid == true} onChange={(e) => {
                                isCheckCheckbox = true
                                onEditableChange(e.target.checked, item, 'isValid')
                                if (item.children && item.children.length > 0) {
                                    if (level == 0) {
                                        onEditableSubChange(e.target.checked, item, 'isValid')
                                    }
                                    else if (level == 1) {
                                        onEditableSubChildChange(e.target.checked, item, 'isValid')
                                    }
                                }
                                if (isFilter)
                                    setTimeout(() => {
                                        if (filterSearch)
                                            onFilterSearchOnly(filterGlobal, false)
                                        else
                                            onFilter(filterGlobal, false)
                                    }, 100);
                                setTimeout(() => {
                                    isCheckCheckbox = false
                                }, 500);
                            }} />
                        }
                    </div>
                },
            },
            {
                dataField: "idx",
                text: t("testResultPage:No"),
                searchable: false,
                headerStyle: { width: "50px", maxWidth: 50, minWidth: 50, position: 'sticky', left: 50, zIndex: 9 },
                style: { width: "50px", maxWidth: 50, minWidth: 50, position: 'sticky', left: 50, zIndex: 7 },
                align: 'center',
                headerAlign: 'center',
                formatter: (cellContent, item, index) => {
                    return <span>{item.no}</span>
                },
            },
            {
                dataField: "name",
                text: t("Test Name"),
                headerStyle: { width: "350px", maxWidth: 320, minWidth: 320, position: 'sticky', left: 100, zIndex: 9 },
                style: { width: "350px", maxWidth: 320, minWidth: 320, position: 'sticky', left: 100, zIndex: 7 },
                classes: '',
                formatter: (cellContent, code, index, level, keyCollapse) => {
                    return (
                        <div className="d-flex" id={keyCollapse || index}>
                            {code?.vendorCode && <div>
                                <i data-tip data-for={`tooltip-pin-${index}`} style={{
                                    fontSize: '13px',
                                    color: '#a90909'
                                }} className="mdi mdi-pin"
                                ></i>
                                <ReactTooltip
                                    id={`tooltip-pin-${index}`}
                                    place="right"
                                    effect="solid"
                                >
                                    <Row style={{ display: 'inline-block', width: '210px' }}>
                                        <div style={{ fontSize: '10px', width: '210px !important' }}>
                                            {t('testPage:User Send')}:  {code?.sentUser}
                                        </div>
                                        <div style={{ fontSize: '10px' }}>
                                            {t('testPage:Sent Time')}:  {code?.sentTime}
                                        </div>
                                        <div style={{ fontSize: '10px' }}>
                                            {t('testPage:Partner Send')}: {organizations.find(x => x.organizationCode === code?.vendorCode)?.name}
                                        </div>
                                    </Row>
                                </ReactTooltip>
                            </div>}
                            <div
                                className={checkVisiable('result', code.state) ? '' : 'text-decoration-line-through'}
                                style={{ width: 300, maxWidth: 300, minWidth: 300, paddingLeft: level * 15 }}
                            >
                                {
                                    (code.type == TestRequest_Test_Type.PROFILE_GROUP) &&
                                    <div style={isCancel(code?.state)}>
                                        <i className="fas fa-arrow-right"
                                            style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}}></i>
                                        <span className="px-2" style={{ fontWeight: "bold" }}>{code.name}</span>
                                    </div>
                                }
                                {
                                    (code.type == TestRequest_Test_Type.PROFILE) &&
                                    <div style={(code.children && code.children.length > 0) ? isCancel(code.children[0].state) : {}}>
                                        <i className="fas fa-arrow-right"
                                            style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}}></i>
                                        <span className="px-2" style={{ fontWeight: "bold" }}>{code.name}</span>
                                    </div>
                                }
                                {
                                    (code.type == TestRequest_Test_Type.TEST) &&
                                    <div style={isCancel(code?.state)}>
                                        <span>-&nbsp;</span>
                                        <span className="px-2">{code.name}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }
            },
            {
                dataField: "result",
                text: t("Result"),
                headerAlign: 'center',
                align: 'center',
                headerStyle: { width: "100px", maxWidth: 100, minWidth: 100, position: 'sticky', left: 420, zIndex: 9, textAlign: 'center' },
                style: { width: "100px", maxWidth: 100, minWidth: 100, position: 'sticky', zIndex: 6, left: 420, alignContent: 'center' },
                classes: '',
                formatter: (cellContent, item, index) => {
                    const idHTML = `resultBlur${item.code}${item.parentId || ''}`.replace(' ', '')
                    return (<React.Fragment>
                        {checkVisiable('result', item.state) ?
                            <ContentChildColumn
                                isActive={false}
                                width={90}
                                content={() => <div
                                    style={{ width: 90, maxWidth: 90, minWidth: 90 }}
                                >

                                    <div className={`d-flex align-items-center text-result-color ${getColorResult(item.color)}`}>
                                        {CheckRoleEdit(item.state, item) ?
                                            <span className="flex-1" style={{}}>{cellContent || (item.type == TestRequest_Test_Type.TEST ? '' : '')}</span>
                                            :
                                            <div id={idHTML + '_'} className={item.type == TestRequest_Test_Type.TEST ? 'resultBlurItemFocus' : ''} tabIndex={9999}
                                                onKeyDown={e => {
                                                    if (e.key === 'Enter' || e.key === "Tab") {
                                                        const fields =
                                                            Array.from(document.getElementsByClassName('resultBlurItemFocus')) ||
                                                            []
                                                        const position = fields.findIndex(x => x.id == `${idHTML}_`)
                                                        if (position >= 0) {
                                                            setTimeout(() => {
                                                                let el = fields[position + 1]
                                                                if (el) {
                                                                    const parent = el.firstElementChild
                                                                    if (parent) {
                                                                        parent.firstElementChild && parent.firstElementChild.click()
                                                                    }
                                                                }
                                                                el = document.getElementById(`${idHTML}`)
                                                                el && el.click()
                                                            }, 100);

                                                        }
                                                    }
                                                }}
                                                onFocus={() => {
                                                    let el = document.getElementById(`${idHTML + '_'}`)
                                                    if (el) {
                                                        const parent = el.firstElementChild
                                                        if (parent) {
                                                            try {
                                                                parent && parent.click()
                                                            } catch (error) {
                                                                console.log(error);
                                                            }
                                                        }
                                                    }
                                                }}
                                                onBlur={() => {
                                                    let el = document.getElementById(`${idHTML}`)
                                                    el && el.click()
                                                }}
                                                style={{ width: 80 }} onClick={(e) => {
                                                    e.stopPropagation();
                                                }}>
                                                <Editable
                                                    className={getClassText(item)}
                                                    alwaysEditing={false}
                                                    disabled={false}
                                                    editText={(item.resultTestId != 0 && item.type == TestRequest_Test_Type.TEST) ? `${(item.result == null || item.result == '') ? '---' : item.result}` : ''}
                                                    initialValue={(item.resultTestId != 0 && item.type == TestRequest_Test_Type.TEST) ? `${(item.result == null || item.result == '') ? '' : item.result}` : ''}
                                                    id={item.code}
                                                    isValueClickable={false}
                                                    mode="inline"
                                                    placement="top"
                                                    showText={false}
                                                    type="textfield"
                                                    renderConfirmElement={<button type="button" id={idHTML}
                                                        className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
                                                    ><i className="mdi mdi-check"></i></button>}
                                                    renderCancelElement={cancelElement}
                                                    onSubmit={(value) => {
                                                        if (item['result'] != value && !(item['result'] == null && value == ''))
                                                            onEditableChange(value, item, 'result', true, true)
                                                    }}

                                                />
                                            </div>}
                                    </div>
                                </div>} />
                            :
                            <></>
                        }
                    </React.Fragment>
                    )
                },
            },
            {
                dataField: "isPrint",
                text: <div className="text-center" style={{ marginLeft: '-12px' }}><i className="fas fa-print"></i></div>,
                headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", minWidth: 50, maxWidth: 50 },
                style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", minWidth: 50, maxWidth: 50 },
                headerFormatter: (column, colIndex) => (
                    <>
                        <div>
                            <ReactTooltip
                                id={`tooltip-print${colIndex}`}
                                place="bottom"
                                effect="solid"
                                className="item-fixed-result-table-detail"
                                style={{ postion: 'sticky', zIndex: 100 }}
                            >
                                {t("Printed")}
                            </ReactTooltip>
                            <div style={{ textAlign: 'center' }}>
                                <i data-tip data-for={`tooltip-print${colIndex}`} className="fas fa-print"></i>
                            </div>
                        </div>
                    </>
                ),
                headerStyle: { width: "50px", paddingLeft: "0px", paddingRight: "0px", minWidth: 50, maxWidth: 50 },
                formatter: (cellContent, item, index) => {
                    return <div className="text-center" onClick={e => e.stopPropagation()} >
                        {item.printTime &&
                            <IconStatus
                                isRound={true}
                                type={item.printTime ? "Active" : "Inactive"}
                            />
                        }
                    </div>
                },
            },
            {
                dataField: "warning",
                text: <div className="text-warning text-center" style={{ marginLeft: '-12px' }}><i className="fas fa-exclamation-triangle"></i></div>,
                headerStyle: { minWidth: 50, maxWidth: 50, width: 50 },
                headerAlign: 'center',
                style: { minWidth: 50, maxWidth: 50, width: 50 },
                formatter: (cellContent, user, index) => {
                    return <div className="text-center">

                    </div>
                },
            },
            {
                dataField: "download",
                text: t("Download"),
                style: { minWidth: "120px", maxWidth: "120px", width: 120, paddingLeft: "0px", paddingRight: "0px" },
                headerAlign: 'center',
                headerStyle: { minWidth: "120px", maxWidth: "120px", width: 120, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return <div className="text-center" onClick={e => e.stopPropagation()} >
                        {item.type == TestRequest_Test_Type.TEST || item.type == TestRequest_Test_Type.PROFILE ?
                            <IconStatus
                                isRound={true}
                                type={hasChildrenWithDownloadTrue(item) ? "Active" : "Inactive"}
                            />
                            : <></>
                        }
                    </div>
                },
            },
            {
                dataField: "runrule",
                text: t("Run Rule"),
                style: { minWidth: "120px", maxWidth: "120px", width: 120, paddingLeft: "0px", paddingRight: "0px" },
                headerAlign: 'center',
                headerStyle: { minWidth: "120px", maxWidth: "120px", width: 120, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return <div className="text-center" onClick={e => e.stopPropagation()} >
                        {item.type == TestRequest_Test_Type.TEST ?
                            <IconStatus
                                isRound={true}
                                type={(item.runRule == true) ? "Active" : "Inactive"}
                            />
                            : <></>
                        }
                    </div>
                },
            },
            {
                dataField: "state",
                text: t(""),
                headerAlign: 'center',
                align: 'center',
                style: { paddingLeft: "0px", paddingRight: "0px", minWidth: 90, maxWidth: 90, width: 90 },
                headerStyle: { minWidth: 90, maxWidth: 90, width: 90, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return (
                        <>
                            {item.type == TestRequest_Test_Type.TEST || item.type == TestRequest_Test_Type.PROFILE ? (
                                <BadgeLabel
                                    value={t(
                                        `${RESULT_STATE[checkChildrenStates(item) || 0]}`
                                    )}
                                    type={RESULT_STATE[checkChildrenStates(item) || 0]}
                                    className="badge-width"
                                />
                            ) : null}
                        </>
                    )
                },
            },
            {
                dataField: "printed",
                text: t("Printed"),
                headerAlign: 'center',
                align: 'center',
                style: { paddingLeft: "0px", paddingRight: "0px", minWidth: "80px", maxWidth: 80, width: 80 },
                headerStyle: { minWidth: "80px", maxWidth: 80, width: 80, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return <div className="text-center" onClick={e => e.stopPropagation()} >
                        {item.printed == true &&
                            <IconStatus
                                isRound={false}
                                type={"Active"}
                            />
                        }
                    </div>
                },
            },
            {
                dataField: "exported",
                text: t("Exported"),
                headerAlign: 'center',
                align: 'center',
                style: { paddingLeft: "0px", paddingRight: "0px", minWidth: "80px", maxWidth: 80, width: 80 },
                headerStyle: { minWidth: "80px", maxWidth: 80, width: 80, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return <div className="text-center" onClick={e => e.stopPropagation()} >
                        {item.exported == true &&
                            <IconStatus
                                isRound={false}
                                type={"Active"}
                            />
                        }
                    </div>
                },
            },
            {
                dataField: "validatedDate",
                text: t("Valid Time"),
                headerAlign: 'center',
                align: 'center',
                style: { width: "150px", maxWidth: 150, minWidth: 150, paddingLeft: "0px", paddingRight: "0px" },
                headerStyle: { width: "150px", maxWidth: 150, minWidth: 150, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return <span>{convertDateFormat(item.validatedDate)}</span>
                },
            },
            {
                dataField: "remark",
                text: t("common:Remark"),
                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
            },
            {
                dataField: "printTime",
                text: t("Print Time"),
                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                formatter: (cellContent, item, index) => convertDateFormat(cellContent),
            },
            {
                dataField: "oldResult",
                text: t("Old Result"),
                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 110, minWidth: 110 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
            },
            {
                dataField: "lowerLimit",
                text: t("Lower Limit"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },

            },
            {
                dataField: "lowerWarning",
                text: t("Lower Warning"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },

            },
            {
                dataField: "higherLimit",
                text: t("Higher Limit"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },

            },
            {
                dataField: "higherWarning",
                text: t("Higher Warning"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },

            },
            {
                dataField: "NormalRangeWarning",
                text: t("Normal Range Warning"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },

            },
            {
                dataField: "expression",
                text: t("Expression"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },

            },
            {
                dataField: "signedUser",
                text: t("Signed User"),
                headerStyle: { width: "150px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 150, minWidth: 150 },
                style: { width: "150px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 150, minWidth: 150 },
            },
            {
                dataField: "signedDate",
                text: t("Signed Date"),
                headerStyle: { width: "150px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 150, minWidth: 150 },
                style: { width: "150px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 150, minWidth: 150 },
                formatter: (cellContent, item, index) => convertDateFormat(cellContent),
            },
        ]
        const columnChild = [
            {
                dataField: "id",
                text: "id",
                hidden: true,
                headerAttrs: {
                    hidden: true
                }
            },
            {
                dataField: "isValid",
                text: 'isValid',
                style: {
                    width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50
                },
                headerStyle: {
                    width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50
                },
                classes: 'testName-col test-result-detail-title-table-check',
                formatter: (cellContent, item, index) => {
                    return <ContentChildColumn
                        isActive={false}
                        content={() => <div className="text-center" style={{ display: 'inherit' }} onClick={e => e.stopPropagation()} >
                            {parseInt(item.state) === RESULT_STATE_Text.Canceled ?
                                <input tabIndex={-1} type="checkbox" checked={false} disabled />
                                :
                                <input type="checkbox" tabIndex={-1} checked={item.isValid == true} onChange={(e) => {
                                    onEditableChange(e.target.checked, item, 'isValid')
                                    if (item.children && item.children.length > 0)
                                        onEditableSubChildChange(e.target.checked, item, 'isValid')
                                    if (isFilter)
                                        setTimeout(() => {
                                            if (filterSearch)
                                                onFilterSearchOnly(filterGlobal, false)
                                            else
                                                onFilter(filterGlobal, false)
                                        }, 100);
                                }} />
                            }
                        </div>}
                    />
                },
            },
            {
                dataField: "idx",
                text: "#",
                headerStyle: { width: "50px", maxWidth: 50, minWidth: 50 },
                style: { width: "50px", maxWidth: 50, minWidth: 50, padding: 0 },
                align: 'center',
                headerAlign: 'center',
                classes: 'testName-col test-result-detail-title-table-no',
                headerClasses: 'testName-col-headder',
                formatter: (cellContent, item, index) => {
                    return <ContentChildColumn
                        isActive={false}
                        content={() => <div style={{ width: 50 }} className="text-center">{item.no}</div>}
                    />
                },
            },
            {
                dataField: "name",
                text: t("testName"),
                headerStyle: { width: "350px", maxWidth: 350, minWidth: 350, padding: 0 },
                style: { width: "350px", maxWidth: 350, minWidth: 350, padding: 0 },
                classes: "item-fixed-result-table-detail-content ",
                formatter: (cellContent, code, index) => {
                    return <div className={checkVisiable('result', code.state) ? '' : 'text-decoration-line-through'}>
                        <ContentChildColumn
                            isActive={false}
                            width={350}
                            text={code.name}
                            content={() => <div
                                style={{ width: 350, maxWidth: 350, minWidth: 350 }}
                            >
                                <div className="d-flex" style={{ paddingLeft: '10px' }}>
                                    {code?.vendorCode && <div>
                                        <i data-tip data-for={`tooltip-pin-${index}`} style={{
                                            fontSize: '13px',
                                            color: '#a90909'
                                        }} className="mdi mdi-pin"
                                        ></i>
                                        <ReactTooltip
                                            id={`tooltip-pin-${index}`}
                                            place="right"
                                            effect="solid"
                                        >
                                            <Row style={{ display: 'block' }}>
                                                <div style={{ fontSize: '10px' }}>
                                                    {t('testPage:User Send')}:  {code?.sentUser}
                                                </div>
                                                <div style={{ fontSize: '10px' }}>
                                                    {t('testPage:Sent Time')}:  {code?.sentTime}
                                                </div>
                                                <div style={{ fontSize: '10px' }}>
                                                    {t('testPage:Partner Send')}: {organizations.find(x => x.organizationCode === code?.vendorCode)?.name}
                                                </div>
                                            </Row>
                                        </ReactTooltip>
                                    </div>}
                                    {
                                        (code.type == TestRequest_Test_Type.PROFILE) &&
                                        <div className="mx-3" style={isCancel(code.children[0].state)}>
                                            <i className={`fas fa-arrow-right ${code.code}-${code.id}`}
                                                style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}} >
                                            </i>
                                            <span className="px-2">{code.name}</span>
                                        </div>
                                    }
                                    {
                                        (code.type == TestRequest_Test_Type.TEST && !code.groupCode) &&
                                        <div style={isCancel(code.state, 20)}>
                                            <span>-&nbsp;</span>
                                            <span className="px-2">{code.name}</span>
                                        </div>
                                    }
                                    {
                                        (code.type == TestRequest_Test_Type.TEST && code.groupCode) &&
                                        <div style={isCancel(code.state, 40)}>
                                            <span>-&nbsp;</span>
                                            <span className="px-2">{code.name}</span>
                                        </div>
                                    }
                                </div>
                            </div>}
                        />
                    </div>
                },
            },
            {
                dataField: "result",
                text: t("Result"),
                headerStyle: { width: "100px", maxWidth: 100, minWidth: 100, padding: 0 },
                style: { width: "100px", maxWidth: 100, minWidth: 100, padding: 0 },
                classes: "item-fixed-result-table-detail-content ",
                // headerStyle: { minWidth: 100, maxWidth: 100, width: 100, textAlign: 'center', },
                // style: { minWidth: 100, maxWidth: 100, width: 100, textAlign: 'center', },
                formatter: (cellContent, item, index) => {
                    const idHTML = `resultBlur${item.code}${item.parentId || ''}`.replace(' ', '')
                    return (<React.Fragment>
                        {checkVisiable('result', item.state) ?
                            <ContentChildColumn
                                isActive={false}
                                width={100}
                                // text={item.name}
                                content={() => <div
                                    style={{ width: 100, maxWidth: 100, minWidth: 100 }}
                                >
                                    <>
                                        <div className={`d-flex align-items-center text-result-color ${getColorResult(item.color)}`} style={getAlignResult(item.color)}>
                                            {CheckRoleEdit(item.state) ?
                                                <span className="flex-1">{cellContent || (item.type == TestRequest_Test_Type.TEST ? '' : '')}</span>
                                                :
                                                <div id={idHTML + '_'} className={item.type == TestRequest_Test_Type.TEST ? 'resultBlurItemFocus' : ''} tabIndex={9999}
                                                    onKeyDown={e => {

                                                        if (e.key === 'Enter' || e.key === "Tab") {
                                                            e.preventDefault()
                                                            const fields =
                                                                Array.from(document.getElementsByClassName('resultBlurItemFocus')) ||
                                                                []
                                                            const position = fields.findIndex(x => x.id == `${idHTML}_`)
                                                            if (position >= 0) {
                                                                setTimeout(() => {
                                                                    let el = fields[position + 1]
                                                                    if (el) {
                                                                        const parent = el.firstElementChild
                                                                        if (parent) {
                                                                            parent.firstElementChild && parent.firstElementChild.click()
                                                                        }
                                                                    }
                                                                    el = document.getElementById(`${idHTML}`)
                                                                    el && el.click()
                                                                }, 100);

                                                            }
                                                        }
                                                        e.stopPropagation()
                                                    }}
                                                    onFocus={() => {
                                                        let el = document.getElementById(`${idHTML + '_'}`)
                                                        if (el) {
                                                            const parent = el.firstElementChild
                                                            if (parent) {
                                                                try {
                                                                    parent && parent.click()
                                                                } catch (error) {
                                                                    console.log(error);
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    onBlur={() => {
                                                        let el = document.getElementById(`${idHTML}`)
                                                        el && el.click()
                                                    }}
                                                    style={{ width: 80 }} onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <Editable
                                                        className={getClassText(item)}
                                                        key={idHTML}
                                                        alwaysEditing={false}
                                                        disabled={false}
                                                        editText={(item.resultTestId != 0 && item.type == TestRequest_Test_Type.TEST) ? `${(item.result == null || item.result == '') ? '---' : item.result}` : ''}
                                                        initialValue={(item.resultTestId != 0 && item.type == TestRequest_Test_Type.TEST) ? `${(item.result == null || item.result == '') ? '' : item.result}` : ''}
                                                        id={item.code}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        showText={false}
                                                        type="textfield"
                                                        renderConfirmElement={<button type="button" id={idHTML}
                                                            className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
                                                        ><i className="mdi mdi-check"></i></button>}
                                                        renderCancelElement={cancelElement}
                                                        onSubmit={(value) => {
                                                            if (item['result'] != value && !(item['result'] == null && value == ''))
                                                                onEditableChange(value, item, 'result', true)
                                                        }}
                                                    />
                                                </div>
                                            }

                                        </div>
                                    </>
                                </div>}
                            />
                            :
                            <></>
                        }
                    </React.Fragment>
                    )
                },
            },
            {
                dataField: "isPrint",
                text: 'isPrint',
                headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", minWidth: 50, maxWidth: 50 },
                style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", textAlign: 'text-center', minWidth: 50, maxWidth: 50 },
                formatter: (cellContent, item, index) => {
                    return <div className="text-center" onClick={e => e.stopPropagation()} >
                        {item.printTime &&
                            <IconStatus
                                isRound={true}
                                type={item.printTime ? "Active" : "Inactive"}
                            />
                        }
                    </div>
                },
            },
            {
                dataField: "warning",
                text: 'warning',
                headerStyle: { minWidth: 50, maxWidth: 50, width: 50 },
                // headerAlign: 'center',
                style: { minWidth: 50, maxWidth: 50, width: 50 },
                formatter: (cellContent, user, index) => {
                    return <div className="text-center">
                    </div>
                },

            },
            {
                dataField: "download",
                text: t("download"),
                style: { minWidth: "120px", maxWidth: "120px", width: 120, paddingLeft: "0px", paddingRight: "0px" },
                headerAlign: 'center',
                headerStyle: { minWidth: "120px", maxWidth: "120px", width: 120, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return <div className="text-center" onClick={e => e.stopPropagation()} >
                        {item.type == TestRequest_Test_Type.TEST ?
                            <IconStatus
                                isRound={true}
                                type={item.download ? "Active" : "Inactive"}
                            />
                            : <></>
                        }
                    </div>
                },

            },
            {
                dataField: "runrule",
                text: t("Run Rule"),
                style: { minWidth: "120px", maxWidth: "120px", width: 120, paddingLeft: "0px", paddingRight: "0px" },
                headerAlign: 'center',
                headerStyle: { minWidth: "120px", maxWidth: "120px", width: 120, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return <div className="text-center" onClick={e => e.stopPropagation()} >
                        {item.type == TestRequest_Test_Type.TEST ?
                            <IconStatus
                                isRound={true}
                                type={(item.runRule == true) ? "Active" : "Inactive"}
                            />
                            : <></>
                        }
                    </div>
                },
            },
            {
                dataField: "state",
                text: t("State"),
                align: 'center',
                style: { paddingLeft: "0px", paddingRight: "0px", minWidth: 90, maxWidth: 90, width: 90 },
                headerStyle: { minWidth: 90, maxWidth: 90, width: 90, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return <>
                        {item.type == TestRequest_Test_Type.TEST ?
                            <BadgeLabel
                                value={t(`${RESULT_STATE[item.state || 0]}`)}
                                type={RESULT_STATE[item.state || 0]}
                                className="badge-width"
                            /> : null
                        }
                    </>
                },
            },
            {
                dataField: "printed",
                text: t("Printed"),
                style: { paddingLeft: "0px", paddingRight: "0px", minWidth: "80px", maxWidth: 80, width: 80 },
                headerStyle: { minWidth: "80px", maxWidth: 80, width: 80, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return <div className="text-center" onClick={e => e.stopPropagation()} >
                        {item.printed == true &&
                            <IconStatus
                                isRound={false}
                                type={"Active"}
                            />
                        }
                    </div>
                },
            },
            {
                dataField: "exported",
                text: t("Exported"),
                style: { paddingLeft: "0px", paddingRight: "0px", minWidth: "80px", maxWidth: 80, width: 80 },
                headerStyle: { minWidth: "80px", maxWidth: 80, width: 80, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return <div className="text-center" onClick={e => e.stopPropagation()} >
                        {item.exported == true &&
                            <IconStatus
                                isRound={false}
                                type={"Active"}
                            />
                        }
                    </div>
                },
            },
            {
                dataField: "validatedDate",
                text: t("Valid Time"),
                style: { width: "150px", maxWidth: 150, minWidth: 150, paddingLeft: "0px", paddingRight: "0px" },
                headerStyle: { width: "150px", maxWidth: 150, minWidth: 150, paddingLeft: "0px", paddingRight: "0px" },
                formatter: (cellContent, item, index) => {
                    return <span>{convertDateFormat(item.validatedDate)}</span>
                },
            },
            {
                dataField: "remark",
                text: t("Remark"),
                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
            },
            {
                dataField: "printTime",
                text: t("Print Time"),
                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                formatter: (cellContent, item, index) => convertDateFormat(cellContent),
            },
            {
                dataField: "lowerLimit",
                text: t("Lower Limit"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },

            },
            {
                dataField: "lowerWarning",
                text: t("Lower Warning"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },

            },
            {
                dataField: "higherLimit",
                text: t("Higher Limit"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },

            },
            {
                dataField: "higherWarning",
                text: t("Higher Warning"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
            },
            {
                dataField: "NormalRangeWarning",
                text: t("Normal Range Warning"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
            },
            {
                dataField: "expression",
                text: t("Expression"),

                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
            },
            {
                dataField: "oldResult",
                text: t("Old Result"),
                headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 110, minWidth: 110 },
                style: { width: "100px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 100, minWidth: 100 },
            },
            {
                dataField: "signedUser",
                text: t("Signed User"),
                headerStyle: { width: "150px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 150, minWidth: 150 },
                style: { width: "150px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 150, minWidth: 150 },
            },
            {
                dataField: "signedDate",
                text: t("Signed Date"),
                headerStyle: { width: "150px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 150, minWidth: 150 },
                style: { width: "150px", paddingLeft: "0px", paddingRight: "0px", maxWidth: 150, minWidth: 150 },
                formatter: (cellContent, item, index) => convertDateFormat(cellContent),
            },
        ]


        const refForm = useRef();
        const onFilterSearchOnly = (vals, isCollapse = true) => {
            filterGlobal = vals;
            let tmp = JSON.parse(JSON.stringify(testResultResultsGlobal))
            tmp = tmp.filter(x => x.type != TestRequest_Test_Type.TEST || (
                x.type == TestRequest_Test_Type.TEST
                && (x.name.toLocaleLowerCase().includes(vals.search) || x.code.toLocaleLowerCase().includes(vals.search))
            ))
            for (let index = 0; index < tmp.length; index++) {
                const parent = tmp[index];
                if (parent.children && parent.children.length > 0) {
                    let children = parent.children
                    children = children.filter(x => x.type != TestRequest_Test_Type.TEST || (
                        x.type == TestRequest_Test_Type.TEST
                        && (x.name.toLocaleLowerCase().includes(vals.search) || x.code.toLocaleLowerCase().includes(vals.search))
                    ))
                    parent.children = [...children]
                    if (children && children.length > 0) {
                        children.forEach(row => {
                            if (row.children && row.children.length > 0) {
                                row.children = row.children.filter(x => x.type != TestRequest_Test_Type.TEST || (
                                    x.type == TestRequest_Test_Type.TEST
                                    && (x.name.toLocaleLowerCase().includes(vals.search) || x.code.toLocaleLowerCase().includes(vals.search))
                                ))
                            }
                        });
                    }


                }
            }
            //filter profile trống
            tmp.forEach(element => {
                if (element.children && element.children.length > 0) {
                    element.children = element.children.filter(x => x.type == TestRequest_Test_Type.TEST || x.children.length > 0)
                }
            });
            tmp = tmp.filter(x => x.type == TestRequest_Test_Type.TEST || x.children.length > 0)
            setDataFilter([...tmp])
            if (isCollapse) {
                setTimeout(() => {
                    ResetColumnWidth()
                }, 100);
            }
        }
        const onFilter = (vals, isCollapse = true) => {
            if (vals.search == undefined || vals.search == null) {
                vals.search = ''
            }
            filterGlobal = vals;
            let tmp = JSON.parse(JSON.stringify(testResultResultsGlobal))
            //filter theo test
            tmp = tmp.filter(x => {
                return x.type != TestRequest_Test_Type.TEST || (
                    x.type == TestRequest_Test_Type.TEST
                    && (vals.isStatus == false || (vals.valid == false || vals.valid == (Number(x.state) >= RESULT_STATE_Text.Validated)))
                    // && (vals.isStatus == false || (vals.printed == false || vals.printed == x.isPrint))
                    && (vals.isStatus == false || (vals.notPrinted == false || vals.notPrinted == !x.printTime))
                    && (vals.isStatus == false || ((vals.fullResult == true && ((x.result && x.result != '') || (x.resultText && x.resultText != '')))
                        || (vals.fullResult == false
                            // && !((x.result && x.result != '') || (x.resultText && x.resultText != ''))
                        )))
                    && (vals.isGroup == false || (vals.group == undefined || (vals.group.findIndex(z => z == x.category?.trim()) >= 0 || vals.group.findIndex(z => z == '') >= 0)))
                    && (x.name.toLocaleLowerCase().includes(vals.search) || x.code.toLocaleLowerCase().includes(vals.search))
                )
            })
            for (let index = 0; index < tmp.length; index++) {
                const parent = tmp[index];
                if (parent.children && parent.children.length > 0) {
                    let children = parent.children
                    children = children.filter(x => x.type != TestRequest_Test_Type.TEST || (
                        x.type == TestRequest_Test_Type.TEST
                        && (vals.isStatus == false || (vals.valid == false || vals.valid == (x.state >= RESULT_STATE_Text.Validated)))
                        // && (vals.isStatus == false || (vals.printed == false || vals.printed == x.isPrint))
                        && (vals.isStatus == false || (vals.notPrinted == false || vals.notPrinted == !x.printTime))
                        && (vals.isStatus == false || ((vals.fullResult == true && ((x.result && x.result != '') || (x.resultText && x.resultText != '')))
                            || (vals.fullResult == false
                                // && !((x.result && x.result != '') || (x.resultText && x.resultText != ''))
                            )))
                        && (vals.isGroup == false || (vals.group == undefined || (vals.group.findIndex(z => z == x.category?.trim()) >= 0 || vals.group.findIndex(z => z == '')) >= 0))
                        && (x.name.toLocaleLowerCase().includes(vals.search) || x.code.toLocaleLowerCase().includes(vals.search))
                    ))
                    parent.children = [...children]
                    if (children && children.length > 0) {
                        children.forEach(row => {
                            if (row.children && row.children.length > 0) {
                                row.children = row.children.filter(x => x.type != TestRequest_Test_Type.TEST || (
                                    x.type == TestRequest_Test_Type.TEST
                                    && (vals.isStatus == false || (vals.valid == false || vals.valid == (x.state >= RESULT_STATE_Text.Validated)))
                                    // && (vals.isStatus == false || (vals.printed == false || vals.printed == x.isPrint))
                                    && (vals.isStatus == false || (vals.notPrinted == false || vals.notPrinted == !x.printTime))
                                    && (vals.isStatus == false || ((vals.fullResult == true && ((x.result && x.result != '') || (x.resultText && x.resultText != '')))
                                        || (vals.fullResult == false
                                            // && !((x.result && x.result != '') || (x.resultText && x.resultText != ''))
                                        )))
                                    && (vals.isGroup == false || (vals.group == undefined || (vals.group.findIndex(z => z == x.category?.trim()) >= 0 || vals.group.findIndex(z => z == '') >= 0)))
                                    && (x.name.toLocaleLowerCase().includes(vals.search) || x.code.toLocaleLowerCase().includes(vals.search))
                                ))
                            }
                        });
                    }


                }
            }
            //filter profile trống
            tmp.forEach(element => {
                if (element.children && element.children.length > 0) {
                    element.children = element.children.filter(x => x.type == TestRequest_Test_Type.TEST || x.children.length > 0)
                }
            });
            tmp = tmp.filter(x => x.type == TestRequest_Test_Type.TEST || x.children.length > 0)

            setDataFilter([...tmp])
            if (isCollapse) {
                setTimeout(() => {
                    ResetColumnWidth()
                }, 100);
            }
        }
        const ResetColumnWidth = () => {
            const tmp = [...columnExpand]
            setColumnExpand([])
            setTimeout(() => {
                setColumnExpand(tmp)
                fieldAuto.forEach(element => {
                    ChangeWidthAuto(element)
                });
            }, 300);
        }
        useEffect(() => {
            if (currentTab == '1') {
                ResetColumnWidth()
            }
        }, [currentTab])
        useEffect(() => {
            onSetScrollResultDetail({})

            return () => {
                onSetScrollResultDetail({})
            }
        }, [])

        const renderFormatter = (col, row, index, level, keyCollapse) => {
            if (col.formatter) {
                return col.formatter(row[col.dataField], row, index, level, keyCollapse)
            }
            else {
                return <span>{row[col.dataField]}</span>
            }
        }

        return (
            <React.Fragment>
                <div className="d-flex justify-content-end">
                    <div className={`align-self-center flex-1`}>
                        <span className="font-size-12">
                            {" "}
                            {t("table:totalRow2", {
                                size: rowCountTable
                            })}{" "}
                            &bull; {t("common:Updated")} <Now date={updatedTime} />
                        </span>
                    </div>
                    {/* <div style={{ alignContent: 'center', marginRight: '10px' }}>
                        <AvForm>
                            <div className="d-flex gap-3">
                                <Label className="form-check-label"
                                    style={{ paddingTop: '1px', color: '#556ee6', fontSize: '14px' }} >
                                    {t('Fast Validate')}
                                </Label>
                                <div className="form-check form-switch">
                                    <CustomAvField
                                        type="checkbox"
                                        direction="down"
                                        name='oldSid'
                                        detected={false}
                                        style={{
                                            fontSize: 'larger'
                                        }}
                                        onChange={(value) => {
                                            onSetFastValidate(value)
                                        }}
                                    />
                                </div>
                            </div>
                        </AvForm>
                    </div> */}
                    <div className={`d-flex gap-1`}>
                        <div className="search-box me-2 mb-0" {...rest}>
                            <div className="position-relative">
                                <label
                                    htmlFor="search-bar-testTable"
                                    className="search-label"
                                >
                                    <span id="search-bar-testTable-label" className="sr-only">
                                        Search this table
                                    </span>
                                    <input
                                        id="search-bar-testTable"
                                        type="text"
                                        aria-labelledby="search-bar-testTable-label"
                                        className="form-control table-search-input"
                                        placeholder={t("Search")}
                                        value={keySearch}
                                        onChange={e => {
                                            const keySearch = e.target.value
                                            setKeySearch(keySearch)
                                        }}
                                        onKeyDown={e => {
                                            if (e.key == "Enter") {
                                                filterSearch = false
                                                isFilter = true
                                                let vals = model
                                                vals.search = keySearch
                                                onFilter(vals);
                                                setModel(vals)

                                            }
                                        }}
                                    />
                                    {keySearch?.length > 0 && (
                                        <button
                                            className="search-times-button"
                                            type="reset"
                                            onClick={() => {
                                                setKeySearch("")
                                                filterSearch = false
                                                isFilter = true
                                                let vals = model
                                                vals.search = ''
                                                onFilter(vals);
                                                setModel(vals)
                                            }}
                                        >
                                            &times;
                                        </button>
                                    )}
                                </label>
                                <i className="mdi mdi-magnify search-icon" style={{ fontSize: 20 }}></i>
                            </div>
                        </div>
                    </div>
                    <div className="ms-0">
                        <div className="toolbar button-items text-end">
                            <Check resource={RESOURCE} permission={permissionType.R}>
                                <FilterButton
                                    onReset={() => {
                                        isFilter = false
                                        filterGlobal = modelDefault
                                        setModel(modelDefault)
                                        setDataFilter([...testResultResults])
                                        setTimeout(() => {
                                            ResetColumnWidth()
                                        }, 100);
                                        refForm.current && refForm.current.onRefresh()
                                    }}
                                    onSubmit={(e, vals) => {
                                        filterSearch = false
                                        isFilter = true
                                        setModel(vals)
                                        onFilter(vals);
                                    }}
                                >
                                    <FilterForm
                                        groups={groups} model={model} ref={refForm}
                                        onChangeSelectedGroup={(val) => setModel({ ...model, selectedGroup: val })}
                                    />
                                </FilterButton>
                            </Check>
                        </div>
                    </div>
                </div>
                <div className="table-result-detail">
                    {columnDisplay.length > 0 &&
                        <div className="table-expand-custom table-expand-custom-result-detail">
                            <table className="table table-bordered table-hover">
                                <thead className="sticky-top">
                                    <tr>
                                        {columnDisplay.map((row, index) => <th key={index}
                                            className={`${row.headerClasses || ''} ${row.hidden == true ? 'd-none' : ''}`}
                                            style={row.headerStyle || {}}
                                        >
                                            <div style={row.style || {}} className={row.classes || ''}>
                                                {row.text}
                                            </div>
                                        </th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataFilter.map((row, index) => {
                                        let classCollectedOrReceived = "";
                                        if (row.isCollectedOrReceived == false) {
                                            classCollectedOrReceived = "collected-or-received"
                                        }
                                        if (row.children && row.children.length > 0) {
                                            let count = row.children.filter(x => x.isCollectedOrReceived == true)?.length;
                                            if (row.children.length > count) {
                                                classCollectedOrReceived = "collected-or-received"
                                            }
                                        }
                                        let parent = <tr key={index}
                                            className={`table-result-expanded ${classCollectedOrReceived}`}>
                                            {columnDisplay.map((col, colIndex) => <td key={colIndex}
                                                className={`${col.classes || ''} ${col.hidden == true ? 'd-none' : ''}`}
                                                style={col.style || {}}
                                            >
                                                {renderFormatter(col, row, index, 0, `collapseResultDetail${index}`)}
                                            </td>)}
                                        </tr>
                                        let children = <></>
                                        if (row.children && row.children.length > 0) {
                                            {
                                                children = <tr>
                                                    <td
                                                        className="padding-0" colSpan={columnDisplay.filter(x => x.hidden != true)?.length || 1}>
                                                        <UncontrolledCollapse className="collapseResultDetail" toggler={`#collapseResultDetail${index}`}
                                                            defaultOpen={true}
                                                        >
                                                            {row.children.map((child, indexChild) => {


                                                                let dataLv3 = <></>
                                                                if (child.children && child.children.length > 0) {
                                                                    {
                                                                        dataLv3 = <tr>
                                                                            <td
                                                                                className="padding-0" colSpan={columnDisplay.filter(x => x.hidden != true)?.length || 1}>
                                                                                <UncontrolledCollapse className="collapseResultDetailChild" toggler={`#collapseResultDetailChild${indexChild}`}
                                                                                    defaultOpen={true}
                                                                                >
                                                                                    {child.children.map((el, rowIndex) => {
                                                                                        return <tr key={rowIndex}>
                                                                                            {columnDisplay.map((col, colIndex) => <td key={colIndex}
                                                                                                className={`${col.classes || ''} ${col.hidden == true ? 'd-none' : ''}`}
                                                                                                style={col.style || {}}
                                                                                            >
                                                                                                {renderFormatter(col, el, rowIndex, 2)}
                                                                                            </td>)}
                                                                                        </tr>
                                                                                    }
                                                                                    )}
                                                                                </UncontrolledCollapse>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                }

                                                                let classCollectedOrReceivedChild = "";
                                                                if (classCollectedOrReceived == '') {
                                                                    if (child.isCollectedOrReceived == false) {
                                                                        classCollectedOrReceivedChild = "collected-or-received"
                                                                    }
                                                                    if (child.children && child.children.length > 0) {
                                                                        let count = child.children.filter(x => x.isCollectedOrReceived == true)?.length;
                                                                        if (child.children.length > count) {
                                                                            classCollectedOrReceivedChild = "collected-or-received"
                                                                        }
                                                                    }
                                                                }
                                                                let res = <tr key={indexChild}
                                                                    className={`table-result-expanded-child ${classCollectedOrReceivedChild}`}>
                                                                    {columnDisplay.map((col, colIndex) => <td key={colIndex}
                                                                        className={`${col.classes || ''} ${col.hidden == true ? 'd-none' : ''}`}
                                                                        style={col.style || {}}
                                                                    >
                                                                        {renderFormatter(col, child, indexChild, 1, `collapseResultDetailChild${indexChild}`)}
                                                                    </td>)}
                                                                </tr>

                                                                return <>{res}{dataLv3}</>



                                                            }
                                                            )}
                                                        </UncontrolledCollapse>
                                                    </td>
                                                </tr>
                                            }
                                        }
                                        return <>
                                            {parent}{children}
                                        </>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <HistoryModal
                    modal={historyModal}
                    data={historyData}
                    toggle={() => setHistoryModal(prev => !prev)}
                    openPathology={setPathologyResultModal}
                />
                {
                    pathologyResultModal && <PathologyResultModal
                        modal={pathologyResultModal}
                        data={pathologyResultModal}
                        toggle={() => {
                            setPathologyResultModal(null)
                            onRefresh()
                        }}
                    />
                }
            </React.Fragment >
        )
    })

const mapStateToProps = ({ Authorization, testResult }) => ({
    userPermissions: Authorization.permissions || [],
    testResult: testResult.testResult,
    rowCountTable: testResult.rowCountTable,
    testResultResults: testResult.testResultResults || [],
    resultList: testResult.testResult.result || [],
    groups: testResult.groups || [],
})

const mapDispatchToProps = dispatch => ({
    onUpdateTestResultResults: payload => dispatch(UpdateTestResultResults(payload)),
    onSetScrollResultDetail: (payload) => dispatch(setScrollResultDetail(payload)),
    onSetFastValidate: (payload) => dispatch(setFastValidate(payload))
})
DetailTestsTabTable.displayName = 'DetailTestsTabTable';
export default withTranslation(["testResultPage", "common", "testPage"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(DetailTestsTabTable))
