import { AvForm } from "availity-reactstrap-validation"
import { CustomDatePicker, CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { convertDateFormat } from "helpers/utilities"
import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const HistoryTableButtons = ({ model, onChange, onSubmit, t }) => {
  return (
    <React.Fragment>
      <AvForm>
        <div className="d-flex" style={{ height: '45px' }} >
          <div style={{ width: "200px", marginLeft: "5px" }}>
            <CustomSelect
              label={""}
              placeholder={t("User")}
              portal
              name="userId"
              value={model?.userId || ""}
              code={parameterCode.USERS}
              onChange={(name, value, label, item) => {
                onChange({ userId: value[0] || null })
                setTimeout(() => {
                  onSubmit({ ...model, userId: value[0] || null })
                }, 100)
              }}
            />
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  )
}


HistoryTableButtons.displayName = "HistoryTableButtons"
export default withTranslation(["common", "button"], {
  withRef: true,
})(
  connect(null, null, null, { forwardRef: true })(
    HistoryTableButtons
  )
)
