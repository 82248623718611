import { CustomAvInput, CustomBootstrapTable, CustomSelect } from "components/Common"
import { indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import FilterForm from "./FilterForm"
//i18n
import { AvForm } from "availity-reactstrap-validation"
import { CustomTooltipButton } from "components/Common/Button"
import CustomButtonIcon from "components/Common/Button/CustomButtonIcon"
import IconStatus from "components/Common/IconStatus"
import { ModuleIds, TestProfileType, parameterCode, statusTypes } from "constant"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import FormSwitchForm from "components/Common/FormSwitchForm"

const RESOURCE = ModuleIds.Test
const ProfileTable = ({
    onSelect,
    onSelectAll,
    testProfiles,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    onEdit,
    onDelete,
    onClone,
    loading,
    t,
    updatedTime,
    onAddTestList,
    onDisplayOrderChange,
    onAddProfileSend,
    onDeleteVendor,
    onInfoProfieleChange
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
        type: [],
        isSystem: ""
    })
    const searchQuery = useSelector(state => state.testProfile.searchQuery)
    const onResetHandler = () => {
        const initModel = {
            ...model,
            search: "",
            type: [],
            sampleType: "",
            category: "",
            // status: ""
        }
        let x = document.getElementsByName("type")
        for (let i = 0; i < x.length; i++) {
            x[i].checked = false
        }
        setModel(initModel)
    }
    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    useEffect(() => {
        setData(testProfiles)
    }, [testProfiles])

    const columns = [
        {
            dataField: "id",
            text: "#",
            style: { width: 50 },
            formatter: (cellContent, testProfile, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, testProfile, index) => {
                return cellContent
            },
        },
        // {
        //     dataField: "",
        //     text: "",
        //     formatter: (cellContent, user, index) => {
        //         return (
        //             <div className="d-flex toolbar button-items text-end">
        //                 <CustomButtonIcon
        //                     id={`test-list-${index}`}
        //                     color="btn btn-outline-success btn-soft-success"
        //                     data-tip
        //                     data-for={`test-list-${index}`}
        //                     onClick={() => { onAddTestList(user) }}>
        //                     <i className="bx bx-add-to-queue" />
        //                 </CustomButtonIcon>
        //                 <ReactTooltip place="top" id={`test-list-${index}`} effect="solid">
        //                     {t("testProfilePage:QuickAdd")}
        //                 </ReactTooltip>
        //             </div>)
        //     },
        // },
        {
            dataField: "code",
            text: t("common:Code"),
            sort: true,
            formatter: (cellContent) => {
                return <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
            }
        },
        {
            dataField: "profileName",
            text: t("common:Name"),
            style: { width: '420px' },
            sort: true,
            formatter: (cellContent, testProfile, index) => {
                return <Link to={`/TestProfile/${testProfile.id}/view?tab=1`}>{testProfile.profileName}</Link>
            },
        },
        {
            dataField: "type",
            text: t("Profile Type"),
            sort: true,
            formatter: (cellContent, user, index) => {
                return (
                    <>
                        {TestProfileType[cellContent] === "Profile" && (
                            <div className="px-2 d-flex badge-profile">
                                <span>Profile</span>
                            </div>
                        )}
                        {TestProfileType[cellContent] === "Group" && (
                            <div className="px-2 d-flex badge-group">
                                <span>Group</span>
                            </div>
                        )}
                    </>
                )
            },
        },
        {
            dataField: "category",
            text: t("common:Category"),
            sort: true,
            formatter: (cellContent, item, index) => {
                return item.categoryName
            },
        },
        // {
        //     dataField: "testCount",
        //     text: t("testProfilePage:TestCount"),
        //     sort: true,
        //     align: 'center',
        //     headerAlign: 'center',
        //     formatter: (cellContent) => {
        //         return <span className="badge badge-submitted">{cellContent}</span>
        //     }
        // },
        {
            dataField: "sampleTypeName",
            text: t("common:SampleType"),
            sort: true,
            style: { width: "140px" },
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, item, index) => {
                return (
                    <div
                        onClick={e => {
                            document.getElementById(`sampleTypeName-${index}`).style.display = ""
                            document.getElementById(`sampleTypeName-${index}`).focus()
                            document.getElementById(`sampleTypeName-text-${index}`).style.display = "none"
                        }}>
                        <a
                            href="#"
                            id={`sampleTypeName-text-${index}`}
                        >{cellContent || '---'}</a>
                        <div id={`sampleTypeName-${index}`}
                            style={{ display: 'none' }}
                            onBlur={() => {
                                document.getElementById(`sampleTypeName-${index}`).style.display = "none"
                                document.getElementById(`sampleTypeName-text-${index}`).style.display = ""
                            }}
                        >
                            <AvForm>
                                <CustomSelect
                                    isClearable={false}
                                    name="sampleTypeName"
                                    value={item.sampleType || ''}
                                    code={parameterCode.SAMPLE_TYPES}
                                    onChange={(name, value) => {
                                        if (value[0] != item.sampleType) {
                                            item.sampleType = value[0]
                                            onInfoProfieleChange(item)
                                        }
                                        document.getElementById(`sampleTypeName-${index}`).style.display = "none"
                                        document.getElementById(`sampleTypeName-text-${index}`).style.display = ""
                                    }}
                                />
                            </AvForm>
                        </div>
                    </div>
                )
            },
        },
        {
            dataField: "displayOrder",
            text: t("Display Order"),
            sort: true,
            style: { maxWidth: "100px" },
            align: '-webkit-center',
            headerAlign: '-webkit-center',
            formatter: (cellContent, item, index) => (
                <div
                    onClick={e => {
                        document.getElementById(`displayOrder-${index}`).style.display = ""
                        document.getElementById(`displayOrder-${index}`).focus()
                        document.getElementById(`displayOrder-text-${index}`).style.display = "none"
                    }}>
                    <a
                        href="#"
                        id={`displayOrder-text-${index}`}
                    >{cellContent}</a>
                    <AvForm>
                        <CustomAvInput
                            style={{ display: 'none' }}
                            id={`displayOrder-${index}`}
                            name={`displayOrder`}
                            value={item.displayOrder || ''}
                            type='number'
                            min={0}
                            onBlur={(e) => {
                                if (e.target.value != item.displayOrder) {
                                    onDisplayOrderChange(item, e.target.value)
                                }
                                document.getElementById(`displayOrder-${index}`).style.display = "none"
                                document.getElementById(`displayOrder-text-${index}`).style.display = ""
                            }}
                        />
                    </AvForm>
                </div>
            ),
        },
        {
            dataField: "vendorCode",
            text: t("testProfilePage:Vendor Code"),
            formatter: (cellContent, test, index) => {
                return <AvForm>
                    <FormSwitchForm
                        name="vendorCode"
                        label=''
                        value={!!cellContent}
                        disabled={!cellContent}
                        onChange={() => { onDeleteVendor(test.id) }}
                    />
                </AvForm>
            },
        },
        {
            dataField: "status",
            text: t("common:Status"),
            sort: true,
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, user, index) => {
                return <IconStatus
                    isRound={true}
                    type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
                />
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            // style: { width: 50 },
            formatter: (cellContent, param, index) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <div className="d-flex toolbar button-items text-end" style={{ marginRight: '8px' }}>
                            <CustomButtonIcon
                                id={`profile-send-${index}`}
                                color="btn btn-outline-warning btn-soft-warning"
                                disabled={param?.vendorCode || TestProfileType[param.type] === 'Group'}
                                data-tip
                                data-for={`profile-send-${index}`}
                                onClick={() => { onAddProfileSend(param) }}>
                                <i className="mdi mdi-plus" />
                            </CustomButtonIcon>
                            <ReactTooltip place="top" id={`profile-send-${index}`} effect="solid">
                                {t("profilePage:Add profile send")}
                            </ReactTooltip>
                        </div>
                        <div className="d-flex toolbar button-items text-end" style={{ marginRight: '8px' }}>
                            <CustomButtonIcon
                                id={`test-list-${index}`}
                                color="btn btn-outline-success btn-soft-success"
                                data-tip
                                data-for={`test-list-${index}`}
                                onClick={() => { onAddTestList(param) }}>
                                <i className="bx bx-add-to-queue" />
                            </CustomButtonIcon>
                            <ReactTooltip place="top" id={`test-list-${index}`} effect="solid">
                                {t("testProfilePage:QuickAdd")}
                            </ReactTooltip>
                        </div>
                        <CustomTooltipButton
                            index={index}
                            component={param}
                            onClone={onClone}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            t={t}
                        />
                    </div>
                )
            },
        },
    ]
    return (
        <CustomBootstrapTable
            columns={columns}
            search
            data={data}
            paging={paging}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            onSearch={onSearch}
            onSort={onSort}
            onRefresh={onRefresh}
            filterForm={() => <FilterForm model={model} />}
            onReset={onResetHandler}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            searchText={model.search}
            loading={loading}
            updatedTime={updatedTime}
            resource={RESOURCE}
        />
    )
}
export default withTranslation(["testProfilePage", "common", "profilePage"])(ProfileTable)