import {
    Check,
    CustomButton,
    TitleAndInformation
} from "components/Common"
import { ModuleIds, RESULT_STATE_Text, ReportAction, TestRequest_Test_Type, permissionType } from "constant"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import SettingModal from "../PatientVisitNew/Modal/SettingModal"
import GroupRequestModal from "../PatientVisitNew/GroupPatient"

const TitleAndInfo = ({
    onEdit,
    onClone,
    data,
    resource,
    t,
    testsLoadingComplete,
    requestTestSamplesCount,
    requestTestTestsCount,
    resourceReport,
    onPreviewClick,
    onCreateClick,
    onExportClick,
    onPrintClick,
    onReportSettingClick,
    testResults,
    generalSetting,
    isFastValidate,
    testResult,
    ...rest
}) => {
    const [loadingPrint, setLoadingPrint] = useState(false)
    const [loadingPreview, setLoadingPreview] = useState(false)
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const [isReport, setIsReport] = useState(true)
    const isIntegration = generalSetting && generalSetting?.find(item => item.fieldName === 'IntegratedApproval')?.fieldValue === 'Integration'
    const [settingModal, setSettingModal] = useState(false)
    const [groupModal, setGroupModal] = useState(false)
    const toggleSetting = () => {
        setSettingModal(prev => !prev)
    }
    const checkState = () => {
        let count_isShow = 0;
        let count_isReport = 0;
        let countValid = 0;
        let countSigned = 0;

        testResults.forEach(element => {
            if (element.type == TestRequest_Test_Type.TEST) {
                if (element.state == RESULT_STATE_Text.Signed) {
                    countSigned++;
                }
                if (element.isValid == true) {
                    countValid++
                    if (element.state < RESULT_STATE_Text.Validated || element.result == null || element.result == '') {
                        count_isReport++
                    }
                    if (!(element.result == null || element.result == '')) {
                        count_isShow++;
                    }
                }
            }
            if (element.children && element.children.length > 0) {
                element.children.forEach(parent => {
                    if (parent.type == TestRequest_Test_Type.TEST) {
                        if (parent.state == RESULT_STATE_Text.Signed) {
                            countSigned++;
                        }
                        if (parent.isValid == true) {
                            countValid++
                            if (parent.state < RESULT_STATE_Text.Validated || parent.result == null || parent.result == '') {
                                count_isReport++
                            }
                            if (!(parent.result == null || parent.result == '')) {
                                count_isShow++;
                            }
                        }
                    }
                    if (parent.children && parent.children.length > 0) {
                        parent.children.forEach(child => {
                            if (child.type == TestRequest_Test_Type.TEST) {
                                if (child.state == RESULT_STATE_Text.Signed) {
                                    countSigned++;
                                }
                                if (child.isValid == true) {
                                    countValid++
                                    if (parent.state < RESULT_STATE_Text.Validated || child.result == null || child.result == '') {
                                        count_isReport++
                                    }
                                    if (!(child.result == null || child.result == '')) {
                                        count_isShow++;
                                    }
                                }
                            }
                        });
                    }
                });
            }
        });

        setIsShow(countValid == 0 ? false : (count_isShow == 0))
        if (countSigned > 0) {
            setIsReport(false)
        }
    }

    useEffect(() => {
        checkState()
    }, [testResults])


    const setLoading = (name) => {
        switch (name) {
            case "Create":
                setLoadingCreate(true)
                setLoadingPreview(false)
                setLoadingPrint(false)
                setLoadingExport(false)
                break;
            case "Preview":
                setLoadingPreview(true)
                setLoadingCreate(false)
                setLoadingPrint(false)
                setLoadingExport(false)
                break;
            case "Export":
                setLoadingPreview(false)
                setLoadingCreate(false)
                setLoadingPrint(false)
                setLoadingExport(true)
                break;
            case "Print":
                setLoadingPreview(false)
                setLoadingCreate(false)
                setLoadingPrint(true)
                setLoadingExport(false)
                break;
        }
    }
    return (
        <>
            <TitleAndInformation
                onEdit={onEdit}
                onClone={onClone}
                resource={resource}
                {...rest}
                external={true}
                customButtons={() => (
                    <>
                        <Check permission={permissionType.R} resource={resource}>
                            <CustomButton
                                disabled={(isShow || !testResult.id)}
                                color={isFastValidate ? "warning" : "primary"}
                                isEdit={loadingPrint}
                                outline
                                onClick={() => {
                                    onPrintClick(ReportAction.Create)
                                    setLoading("Print")
                                }}>
                                {t("common:Print")}
                            </CustomButton>
                        </Check>

                        {/* <Check permission={ReportAction.Export} resource={resourceReport}>
                            <CustomButton disabled={isShow || !testResult.id} color="primary" isEdit={loadingExport} onClick={() => { onExportClick(ReportAction.Export), setLoading("Export") }} outline>
                                {t("common:Export")}
                            </CustomButton>
                        </Check> */}

                        <Check permission={ReportAction.Create} resource={resourceReport}>
                            <CustomButton disabled={isReport}
                                color={isIntegration ? "info" : "primary"}

                                isEdit={loadingCreate} onClick={() => { onCreateClick(ReportAction.Create), setLoading("Create") }} outline>
                                {t("common:Create Report")}
                            </CustomButton>
                        </Check>
                        <Check permission={ReportAction.Preview} resource={resourceReport}>
                            <CustomButton disabled={!testResult.id} color="primary" isEdit={loadingPreview} onClick={() => { onPreviewClick(ReportAction.Preview), setLoading("Preview") }} outline>
                                {t("common:Preview")}
                            </CustomButton>
                        </Check>
                        <Check permission={permissionType.U} resource={resource}>
                            <CustomButton
                                outline
                                text={t("testRequestPage:Patient Group")}
                                color="primary"
                                className="save-user button-width"
                                onClick={() => {
                                    setGroupModal(true)
                                }}
                            >
                                <i className="fas fa-upload px-1"></i>
                                {t("patientGroup:Load from file")}
                            </CustomButton>

                        </Check>
                        {/* <CustomButton color="primary" onClick={toggleSetting} outline>
                            <i className="fa fa-cog"></i>
                        </CustomButton> */}
                    </>
                )}
            >
            </TitleAndInformation>
            <SettingModal
                modal={settingModal}
                toggle={() => {
                    setSettingModal(false)
                }}
            />
            <GroupRequestModal
                RESOURCE={ModuleIds.PatientGroup}
                modal={groupModal}
                toggle={() => {
                    setGroupModal(false)
                }}
            />
        </>
    )
}

TitleAndInfo.propTypes = {
    onEdit: PropTypes.func,
    onClone: PropTypes.func,
    data: PropTypes.object,
    t: PropTypes.any
}

const mapStateToProps = ({ testRequest, testResult }) => ({
    testsLoadingComplete: testRequest.testsLoadingComplete,
    requestTestSamplesCount: testRequest.requestTestSamplesCount,
    requestTestTestsCount: testRequest.requestTestTestsCount,
    isFastValidate: testResult.isFastValidate,
    testResult: testResult.testResult
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testRequestPage", "testResultPage", "message", "common", "patientGroup"])(TitleAndInfo))