import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import TestModal from "./Modal/TestModal"
import TestTable from "./TestTable"

import {
    Check,
    ConfirmModal,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
} from "helpers/utilities"

import {
    addNewTest,
    deleteTestVendor,
    deleteTests,
    getFullTestSuccess,
    getTestDetail,
    getTests,
    resetSearchQuery,
    updateInfoTest,
    updateTest,
    updateTestVendor
} from "store/laboratory/tests/actions"


import { ModuleIds, RuleManagementType, permissionType } from "constant"
import { withTranslation } from "react-i18next"
import { getNormalRuleByTestCode, saveDisplayOrderTest, updateRuleManagement } from "store/actions"
import QuickAddModal from "./Modal/QuickAddModal"

const RESOURCE = ModuleIds.Test

const Tests = ({
    history,
    tests,
    paging,
    onGetTests,
    onAddNewTest,
    onUpdateTest,
    onDeleteTests,
    onResetTestSearchQuery,
    onGetTestDetail,
    test,
    loadingTests,
    updatedTestTime,
    onGetTestsSuccess,
    t,
    onUpdateRuleManagement,
    onGetNormalRuleByTestCode,
    onDisplayOrderChange,
    onDeleteTestVendor,
    updateInfoTest
}) => {
    useEffect(() => {
        onGetTestsSuccess([])
    }, [])
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmVendorModal, setConfirmVendorModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [isClone, setIsClone] = useState(false)
    const [row, setRow] = useState(false)
    const [rowDelete, setRowDelete] = useState({})
    const [vendorDelete, setVendorelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [warningVendorModal, setWarningVendorModal] = useState(false)
    const [selectQuickaddModal, setSelectQuickaddModal] = useState(false)
    const [selectedEditItem, setSelectedEditItem] = useState([])

    const formEl = useRef(null)

    const popStateChange = (event) => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetTestList(params)
        }
        else {
            fetchTests()
        }
    }

    const afterUpdate = () => {
        toggle()
        popStateChange()
    }

    const toggle = () => {
        setModal(prev => !prev)
    }

    const addTestClicks = () => {
        setIsEdit(false)
        setIsClone(false)
        toggle();
    }

    const onAddTestSend = () => {
        setSelectQuickaddModal(true)
        setSelectedEditItem(rows)
    }

    /**
    * Handling submit Test on form
    */

    const handleValidTestSubmit = (e, values) => {
        const type = RuleManagementType.normal
        values.expression = '1==1'
        const first = values?.testName.indexOf('[')
        const last = values?.testName.lastIndexOf(']')
        let customName = ''
        if (first !== -1 && last !== -1) {
            customName = values?.testName.slice(first + 1, last)
        }
        if (isEdit) {
            const updateTest = {
                ...values,
                id: values['id'],
                testCode: values["testCode"],
                testName: values["testName"].replace(`[${customName}]`, '').trimEnd(),
                shortName: values["shortName"],
                quickCode: values["quickCode"],
                sampleType: values["sampleType"],
                category: values["category"],
                reportType: values["reportType"],
                displayOrder: values["displayOrder"] || 0,
                tags: values["tags"],
                remark: values["remark"],
                inUse: values["inUse"],
                profiles: values["profiles"] || [],
                customName: customName
            }
            onUpdateTest({ test: updateTest, callback: afterUpdate })
            onUpdateRuleManagement({ rules: { rules: [values] }, type })
        } else {
            const newTest = {
                ...values,
                testCode: values["testCode"],
                testName: values["testName"].replace(`[${customName}]`, '').trimEnd(),
                shortName: values["shortName"],
                quickCode: values["quickCode"],
                sampleType: values["sampleType"],
                category: values["category"],
                reportType: values["reportType"],
                displayOrder: values["displayOrder"] || 0,
                tags: values["tags"],
                remark: values["remark"],
                inUse: values["inUse"],
                profiles: values["profiles"] || [],
                customName: customName
            }

            onAddNewTest({ test: newTest, history });
            if (values['unit'] !== "" || values['normalRange'] !== "" || values['lowerLimit'] !== "" || values['upperLimit'] !== "" || values['lowerAlert'] !== "" || values['upperAlert'] !== "") {
                onUpdateRuleManagement({ rules: { rules: [values] }, type });
            }
            toggle();
        }
    }

    const onCloneHandler = (param) => {
        const id = param.id || row?.id
        onGetNormalRuleByTestCode([param.testCode], (data) => {
            if (param) setRow({ ...data, ...param })
        })
        if (id) {
            setIsEdit(false);
            setIsClone(true);
            toggle();
        } else {
            setWarningModal(true);
        }

    }

    const onEditHandler = (e, testId, param) => {
        const id = testId || row?.id;
        if (id) {
            onGetNormalRuleByTestCode([param.testCode], () => {
                onGetTestDetail(id);
            });
            setIsEdit(true);
            toggle();
        } else {
            setWarningModal(true);
        }
    }

    const onDeleteVendor = (data) => {
        onDeleteTestVendor([data], () => {
            onRefreshHandler()
            setConfirmVendorModal(state => !state)
        })
    }

    const resetState = () => {
        setRow({})
        setRows([])
    }

    const onDeleteVendorToggleHandler = (test) => {
        setConfirmVendorModal(state => !state)
        setVendorelete(test)
    }

    const onDeleteToggleHandler = (e, test) => {
        onDeleteToggle({
            rows,
            row: test || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteTests({ tests: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowState => {
        onDeleteTests({
            tests: rowState,
            callback: () => {
                setRows(prev.filter(x => x.id != rowDelete.id))
            }
        })
        setRowDelete({});
        setRow({});
    }

    const onDeleteTestHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        // toggle modal
        setConfirmModal(false)
    }

    /**Get selected row and set to state
    *
    */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetTestList = (payload) => {
        insertUrlParam(payload)
        onGetTests(payload)
    }

    const fetchTests = () => {
        onGetTestList({ page: 1 })
    }

    const onRefreshHandler = () => {
        resetState();
        popStateChange();
    }

    const onSearch = searchText => {
        onGetTestList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetTestList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetTestList({ page })
    }

    const onSubmitFilter = values => {
        onGetTestList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetTestList({ page: 1, sort: sortString })
    }

    const onDisplayOrderHandler = (item, value) => {
        onDisplayOrderChange({ id: item.id, displayOrder: value }, () => {
            fetchTests()
        })
    }

    /** ---------CYCLE-------- **/
    useEffect(() => {
        onResetTestSearchQuery()
    }, [])

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetTestList(params)
        } else {
            fetchTests()
        }
    }, [])

    const onAddTestList = (data) => {
        setSelectQuickaddModal(true)
        setSelectedEditItem([data])
    }

    const onUpdateInfoTest = (item) => {
        updateInfoTest(item, () => {
            let params = getUrlParams()
            let paramPage = getUrlParamByKey("page");
            if (paramPage) {
                onGetTestList(params)
            } else {
                fetchTests()
            }
        })
    }

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <TestTable
                        tests={tests}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onDelete={onDeleteToggleHandler}
                        onClone={onCloneHandler}
                        loading={loadingTests}
                        updatedTime={updatedTestTime}
                        onAddTestList={onAddTestList}
                        onDisplayOrderChange={onDisplayOrderHandler}
                        onDeleteVendor={onDeleteVendorToggleHandler}
                        onUpdateTest={onUpdateTest}
                        updateInfoTest={onUpdateInfoTest}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <Check permission={permissionType.C} resource={RESOURCE}>
                        <CustomButton color="primary" onClick={addTestClicks} outline>
                            {t("testPage:Add Test")}
                        </CustomButton>
                        <CustomButton color="primary" onClick={onAddTestSend} outline>
                            {t("testPage:Add test send")}
                        </CustomButton>

                        <CustomButton
                            color="primary"
                            onClick={e => {
                                onDeleteToggleHandler(e, rows.id)
                            }}
                            outline
                        >
                            {t("common:Delete")}
                        </CustomButton>
                    </Check>
                )}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                onClone={onCloneHandler}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("Tests")}
                subtitle={t("Test List")}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <WarningModal
                modal={warningVendorModal}
                onToggle={() => {
                    setSelectQuickaddModal(true)
                    setSelectedEditItem(rows)
                    setWarningVendorModal(prev => !prev)
                }}
                message={t("Add Vendor Warning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Test")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteTestHandler}
            />
            <ConfirmModal
                modal={confirmVendorModal}
                title={`${t("common:Delete")}`}
                message={t("Delete test send")}
                onToggle={onDeleteVendorToggleHandler}
                onDelete={() => onDeleteVendor(vendorDelete)}
            />
            <TestModal
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                onValidSubmit={handleValidTestSubmit}
                toggle={toggle}
                data={!isEdit ? (isClone ? row : {}) : test}
            />
            <QuickAddModal
                modal={selectQuickaddModal}
                toggle={() => {
                    setSelectQuickaddModal(!selectQuickaddModal)
                    popStateChange()
                }}
                data={selectedEditItem}
            />
        </React.Fragment>
    )
}

Tests.propTypes = {
    tests: PropTypes.array,
    paging: PropTypes.object,
    onGetTests: PropTypes.func,
    onAddNewTest: PropTypes.func,
    onUpdateTest: PropTypes.func,
    onDeleteTests: PropTypes.func,
    onResetTestSearchQuery: PropTypes.func,
    onGetTestDetail: PropTypes.func,
    test: PropTypes.object,
    loadingTests: PropTypes.bool,
    updatedTestTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ test, RuleManagement }) => ({
    tests: test.tests,
    test: test.test,
    ruleTest: RuleManagement.ruleTest,
    paging: test.paging,
    loadingTests: test.loadingTests,
    updatedTestTime: test.updatedTime,
})

const mapDispatchToProps = dispatch => ({
    onGetTests: payload => dispatch(getTests(payload)),
    onAddNewTest: (test, history) => dispatch(addNewTest(test, history)),
    onUpdateTest: test => dispatch(updateTest(test)),
    onDeleteTests: test => dispatch(deleteTests(test)),
    onGetTestDetail: testId => dispatch(getTestDetail(testId)),
    onResetTestSearchQuery: () => dispatch(resetSearchQuery()),
    onGetTestsSuccess: payload => dispatch(getFullTestSuccess(payload)),
    onGetNormalRuleByTestCode: (payload, callback) => dispatch(getNormalRuleByTestCode(payload, callback)),
    onUpdateRuleManagement: (payload) => dispatch(updateRuleManagement(payload)),
    onDisplayOrderChange: (payload, callback) => dispatch(saveDisplayOrderTest(payload, callback)),
    onDeleteTestVendor: (test, callback) => dispatch(deleteTestVendor(test, callback)),
    updateInfoTest: (payload, callback) => dispatch(updateInfoTest(payload, callback))

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testPage", "testProfilePage", "message"])(Tests)))