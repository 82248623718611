import toastr from "toastr"
import "toastr/build/toastr.min.css"

const showToast = (message = "message") => {
  toastr.options = {
    positionClass: "toast-top-full-width",
    closeButton: true,
    showMethod: "slideDown",
    hideMethod: "slideUp",
  }

  toastr.success(message)
}

export { showToast }
