import { CustomButton, CustomSID } from "components/Common";
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync";
import { ModuleIds, ReportAction, ReportResource } from "constant";
import { getAuthorizePageByResourceName, getFilePrintResult, getPatientVisitWaitingList, pingToPrintService, sendDataPrint } from "helpers/app-backend";
import { getGeneralSetting } from "helpers/app-backend/general_setting_backend_helper";
import { getAllFullResults } from "helpers/app-backend/testResult_backend_helper";
import { GetDataUrlReportConfig, GetResourceReportIdConfig, NameToSlug, convertDateFormat, convertDateFormat_VN, getColorBookmark, indexCalculator, selectCheckboxHandler } from "helpers/utilities";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { ApprovedValidAndPublishResult, printSampleList } from "store/actions";

const RESOURCE = ModuleIds.PatientGroup;
let sizeWaitingGlobal = 100;
let dataSourceGlobal = [];
let stopPrint = false;
let modelGlobal = {}
const WatingPatient = ({
  t,
  onRowClick,
  onChangeRow,
  onApprovedValidAndPublishResult,
  model = {},
  loadingPrint,
  resetRow
}) => {
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState({})
  const [dataSource, setDataSource] = useState([])
  const [row, setRow] = useState({})
  const [rows, setRows] = useState([])
  const [spin, setSpin] = useState(false)
  const [reportInfos, setReportInfos] = useState([])
  const [sizeWaiting, setSizeWaiting] = useState(sizeWaitingGlobal)
  const [listPrint, setListPrint] = useState([])
  const [generalSetting, setGeneralSetting] = useState([])
  const isIntegration = generalSetting && generalSetting?.find(item => item.fieldName === 'IntegratedApproval')?.fieldValue === 'Integration'

  useEffect(() => {
    modelGlobal = model;
  }, [model])

  useEffect(() => {
    setRows(resetRow)
  }, [resetRow])

  const GetGeneralSettingList = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const res = await getGeneralSetting(userInfo.company)
    if (res && res.length > 0) {
      setGeneralSetting(res)
    }
  }

  useEffect(() => {
    fetchPatientWaitingList()
  }, [model])

  useEffect(() => {
    GetGeneralSettingList()
    loadReportInfo()
  }, [])

  const loadReportInfo = async () => {
    let res = await getAuthorizePageByResourceName("_" + ModuleIds.TestResult);
    if (res) {
      setReportInfos(res._ReportInfo)
    }
  }

  const io = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return;
      }
      if (dataSourceGlobal.length > sizeWaitingGlobal) {
        sizeWaitingGlobal += 100;
        setSizeWaiting(sizeWaitingGlobal)
      }
    });
  });


  let elEnd = document.getElementById(`watch_end_of_document_isWating`)

  useEffect(() => {
    if (elEnd) {
      io.observe(elEnd);
    }
  }, [elEnd])


  const columns = [
    {
      dataField: "tag",
      text: t(""),
      headerStyle: { width: "25px" },
      align: 'center',
      headerAlign: 'center',
      formatter: (cellContent, row, index) => {
        return <>
          {listPrint.includes(row.id) ?
            <span><i className={"fas fa-sync fa-spin"}></i></span>
            :
            <span><i style={{ color: `${getColorBookmark(row)}` }} className="fas fa-circle"></i></span>
          }</>
      },
    },
    {
      dataField: "",
      text: "#",
      formatter: (cellContent, item, index) => {
        if (item.id == -1) {
          return <span id={`watch_end_of_document_isWating`}></span>
        }
        else
          return indexCalculator(0, 0, index + 1)
      },
    },
    {
      dataField: "requestDate",
      text: t("patientVisitPage:Date"),
      formatter: (cellContent, item, index) => {
        return convertDateFormat(item.requestDate, "YYYY-MM-DD")
      },
    },
    {
      dataField: "sid",
      text: "SID",
      formatter: (cellContent, item, index) => {
        const data = item?.sampleIds ? JSON.parse(item?.sampleIds) : []
        const removeDuplicate = data.filter(
          (element, index) => index === data.findIndex(
            other => element.SID === other.SID
          ))
        return item.sampleIds &&
          removeDuplicate.map((x, postion) => <CustomSID
            key={`sampleType-${index}-${postion}`}
            keyToolTip={`sampleType-${index}-${postion}`}
            isToolTip={true}
            toolTip={x?.SampleTypeName}
            sid={x?.SID}
          // emergency={item?.emergency}
          />
          )
      },
    },
    {
      dataField: "patientName",
      text: t("Name"),
      formatter: (cellContent, item, index) => {
        return (
          <div style={{ color: "#3c3ce4", paddingTop: '2px' }}>{item.patientName}</div>
        )
      }
    },
    {
      dataField: "age",
      text: t("DOB"),
      formatter: (cellContent, item, index) => (
        item.dob && <div>{item.dob && <div>{convertDateFormat_VN(item.dob)}</div>}</div>
      ),
    },
  ]

  const rowClasses = (row, rowIndex) => {
    return row.id === selectedRow.id ? "patient-select" : ""
  }

  useEffect(() => {
    const interval = setInterval(() => {
      fetchPatientWaitingList(true)
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const fetchPatientWaitingList = async (autoRefresh = false) => {
    !autoRefresh && setRows([])
    if (modelGlobal.groupCode && modelGlobal.groupCode != '') {
      !autoRefresh && setSpin(true)
      !autoRefresh && setDataSource([])
      let res = {}
      res = await getAllFullResults({ size: 0, search: modelGlobal.searchText, ...modelGlobal })
      if (autoRefresh == false) {
        setSizeWaiting(100)
        sizeWaitingGlobal = 100
        setListPrint([])
        setSpin(false)
        setDataSource(res.data || [])
        dataSourceGlobal = res.data || []
      }
      else {
        let tmp = dataSourceGlobal || [];
        tmp.forEach(element => {
          const item = (res.data || []).find(x => x.id == element.id)
          if (item) {
            element.stateBookmark = item.stateBookmark
          }
        });
        setDataSource(JSON.parse(JSON.stringify((tmp || []))))
        dataSourceGlobal = tmp || []
      }
    } else {
      // showErrToast(
      //   `${t("message:PleaseSelectError", {
      //     field: t('Group'),
      //   })}`
      // )
    }
  }

  const onSelectCheckbox = (row, isSelected) => {
    // setIsSelectedRow(!isSelectedRow)
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    // setIsSelectedRow(!isSelectedRow)
    setRows(rows)
  }
  useEffect(() => {
    onChangeRow && onChangeRow(rows)
  }, [rows])

  useEffect(() => {
    if (stopPrint == true && (listPrint.length == 0 || (listPrint.length == 1 && listPrint.includes(-1)))) {
      stopPrint = false
      fetchPatientWaitingList()
    }
  }, [dataSource, listPrint])


  const PrintConfig = async () => {
    if (reportInfos.length > 0 && !isEmpty(reportInfos[0].uri)) {
      let prints = listPrint
      prints = rows.map(x => x.id)
      setListPrint(prints)
      let rowsTMP = rows.filter(x => x.resultId);
      let countDone = 0;
      rowsTMP.forEach(async (element, index) => {
        countDone++
        let dataTMP = dataSource;
        dataTMP = dataTMP.map(x => ({ ...x, isPrint: listPrint.includes(x.id) }))
        setDataSource([...dataTMP])
        let res = {}
        try {
          res = await getFilePrintResult({
            ...GetDataUrlReportConfig(reportInfos, ReportResource.TestResult, element.fileId),
            ResultId: `${element.resultId}`,
          })
        } catch (error) {

        }

        try {
          const getPingPrintService = await pingToPrintService();
          if (getPingPrintService.pingTime) {
            const dataSendPrint = await sendDataPrint({
              ...GetDataUrlReportConfig(reportInfos, ReportResource.TestResult, element.fileId),
              "filePath": `${res.reportUrl}`,
            })
          } else {
            window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
          }
        }
        catch {
          if (res?.reportUrl)
            window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
        if (countDone == rowsTMP.length) {
          stopPrint = true
        }
        prints = prints.filter(x => x != element.id)
        setListPrint(prints)
        dataTMP = dataTMP.map(x => ({ ...x, isPrint: !(x.id == element.id) }))
        setDataSource([...dataTMP])
      });

    }
  }

  const onCreateReport = async () => {
    let prints = listPrint
    prints = rows.map(x => x.id)
    setListPrint(prints)
    let rowsTMP = rows.filter(x => x.resultId);
    let countDone = 0;
    rowsTMP.forEach(async (element) => {
      let dataTMP = dataSource;
      dataTMP = dataTMP.map(x => ({ ...x, isPrint: listPrint.includes(x.id) }))
      setDataSource([...dataTMP])
      const params = {
        Module: ModuleIds.TestResult,
        ItemAction: ReportAction.Create,
        ItemValue: `${element.resultId}`,
        reportParameters: {
          ...GetDataUrlReportConfig(reportInfos, ReportResource.TestResult, element.fileId),
          ResultId: `${element.resultId}`,
        },
        orderCode: element.alternateId,
        medicalId: element.medicalId,
        patientName: NameToSlug(element.patientName)
      }
      const data = {
        ResultId: element.resultId,
        reportId: GetResourceReportIdConfig(reportInfos)
      }

      if (isIntegration) {
        onApprovedValidAndPublishResult({
          data: {
            data, params
          }, callback: (id) => {
            countDone++
            if (countDone == rowsTMP.length) {
              stopPrint = true
            }
            prints = prints.filter(x => x != element.id)
            setListPrint(prints)
            dataTMP = dataTMP.map(x => ({ ...x, isPrint: !(x.id == element.id) }))
            setDataSource([...dataTMP])
          }
        })
      } else {
        onAddNewFileResult({
          data: {
            data, params
          }, callback: () => {
            countDone++
            if (countDone == rowsTMP.length) {
              stopPrint = true
            }
            prints = prints.filter(x => x != element.id)
            setListPrint(prints)
            dataTMP = dataTMP.map(x => ({ ...x, isPrint: !(x.id == element.id) }))
            setDataSource([...dataTMP])
          }
        })
      }


    });
  }

  const getDataDisplay = () => {
    if (dataSource?.length > 0) {
      let res = [...dataSource].slice(0, sizeWaiting)
      res.push({ id: -1, testCode: '' })
      return res
    }
    else {
      let res = [{ id: -1, testCode: '' }]
      return res
    }
  }

  return (
    <div className={`table-patient-visit ${dataSource > 0 ? 'table-expand-custom table-rule-management' : ''}  `} style={{ height: '100%' }}>
      <CustomBootstrapTableAsync
        TableTitle={t('Patient List')}
        columns={columns}
        data={getDataDisplay()}
        keyField="id"
        onSelect={onSelectCheckbox}
        onSelectAll={onSelectAllCheckbox}
        onRowClick={(e, value) => {
          onRowClick(value)
          setSelectedRow(value)
        }}
        selectedRows={rows?.map(r => r.id)}
        selectRow
        paging={{ dataSize: dataSource.length, size: (sizeWaiting < dataSource.length) ? sizeWaiting : dataSource.length }}
        isEnableRefresh={false}
        isScrollable={true}
        rowClasses={rowClasses}
        resource={RESOURCE}
        style={{ height: '270px' }}
        customButtonTable={() => (
          <div className="button-items d-flex" style={{ textAlign: "right", marginBottom: 0 }}>
            <CustomButton className='mb-0' color="primary" disabled={loadingPrint} outline onClick={() => {
              dispatch(printSampleList(true))
            }}>
              {loadingPrint && <i style={{ marginRight: 5 }} className={"fas fa-sync fa-spin"}></i>}{t("Print")}
            </CustomButton>
            <CustomButton className='mb-0' color="primary" outline onClick={() => {
              fetchPatientWaitingList()
            }}>
              <i className={spin ? "fas fa-sync fa-spin" : "fas fa-sync-alt"}></i>

            </CustomButton>
            <UncontrolledDropdown>
              <DropdownToggle caret color="primary" outline>
                <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem disabled={(rows.length == 0 || listPrint.length > 0)} onClick={() => {
                  PrintConfig()
                }}>
                  {t("Print Result")}
                </DropdownItem>
                <DropdownItem disabled={(rows.length == 0 || listPrint.length > 0)} onClick={() => {
                  onCreateReport()
                }}>
                  {t("Send Result")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>)}

      />
    </div>
  )
}

const mapStateToProps = ({ testResult, testRequest }) => {
  return ({
    testResult: testResult.testResult,
    loadingDetail: testResult.loadingDetail || false,
    isFastValidate: testResult.isFastValidate,
    loadingPrint: testRequest.loadingPrintSampleList || false
  })
}


const mapDispatchToProps = dispatch => ({
  onApprovedValidAndPublishResult: (data, callback) => dispatch(ApprovedValidAndPublishResult(data, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation(["testRequestPage", "message", "patientVisitPage"])(WatingPatient)))