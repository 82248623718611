import { ConfirmModal, CustomButton } from "components/Common"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Card, CardBody, Collapse } from "reactstrap"
import WorkflowModal from "./Modal/WorkflowModal"
import { deleteWorkflowCustom, getWorkflowCustom } from "store/actions"
import { connect } from "react-redux"


const TreeViewBar_RulerManager = ({
  onChange,
  machines, t,
  workflows,
  onChooseCustomWorkflow,
  getCustomWorkflowList,
  onDeleteWorkflowCustom,
}) => {
  const [itemActive, setItemActive] = useState({
    Key: 1, Name: 'R_Normal Range', Result: 234,
    parent: { ID_Group: '1', GroupName: t('common:Rule management') }
  })
  const [IsOpenCollapse, SetOpenCollapse] = useState(true)
  const [isOpenCustomCollapse, setOpenCustomCollapse] = useState(true)
  const [isChildOpenCollapse, setChildOpenCollapse] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [warningDelete, setWarningDelete] = useState(false)
  const [workflow, setWorkflow] = useState({})
  const [onOver, setOnOver] = useState({});

  useEffect(() => {
    getCustomWorkflowList()
  }, [])
  
  const RulerTree = [
    {
      ID_Group: "1",
      GroupName: t("common:Rule management"),
      Children: [
        {
          Key: 1,
          Name: "R_Normal Range",
          Result: 234,
          onClick: () => {
          },
        },
        { Key: 2, Name: "R_Patient Range", Result: 20 },
        {
          Key: 3, Name: "R_Instrument Range", Result: 1,
          Children:
            machines.map((item, index) => ({ machineId: item.id, Key: 3, childrenKey: 100 + index, Name: item.name, Result: 1 }))
        },
        { Key: 4, Name: " R_Additional Info" },
        // { Key: 5, Name: "R_Result Time" },
        // { Key: 4, Name: "R_Ruler Based", Result: 2 },
        // { Key: 5, Name: "R_Result Time", Result: 3 },
        // { Key: 6, Name: "R_Instrument alert ", Result: 4 },
        // { Key: 7, Name: "R_Addtional", Result: 5 },
      ],
    },
  ]

  const addCustomWorkflow = () => {
    setModal(prev => !prev)
    setIsEdit(false)
  }

  const onEditWorkflow = (item) => {
    setWorkflow(item)
    setModal(prev => !prev)
    setIsEdit(!isEdit)
  }

  const deleteCustomWorkflow = () => {
    onDeleteWorkflowCustom({ id: workflow.id }, () => {
      onToggleDelete({})
      getCustomWorkflowList()
    })
  }

  const toggleWorkflow = () => {
    setModal(prev => !prev)
    setIsEdit(false)
  }

  const onToggleDelete = (item) => {
    setWorkflow(item)
    setWarningDelete(!warningDelete);
  }


  return (
    <React.Fragment>
      <Card className="filemanager-sidebar ">
        <CardBody>
          <div className="d-flex flex-column h-100">

            <CustomButton
              color="primary"
              outline
              onClick={addCustomWorkflow}
              className="mb-4"
            >
              {t("testConfig:Create New Workflow")}
            </CustomButton>


            <div className="mb-0">
              <div className="mb-3">
                <ul className="list-unstyled categories-list">
                  <li>
                    <div className="custom-accordion custom-tree-view">
                      {RulerTree.map((value, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Link
                              onClick={e => {
                                SetOpenCollapse(!IsOpenCollapse)
                                e.preventDefault()
                              }}
                              className="text-body fw-medium py-1 d-flex align-items-center"
                              to="#"
                            >
                              <span className="active" >
                                {value.GroupName}
                              </span>
                              <i
                                className={
                                  IsOpenCollapse
                                    ? "mdi mdi-chevron-down accor-down-icon ms-auto"
                                    : "mdi mdi-chevron-up accor-down-icon ms-auto"
                                }
                              />
                            </Link>

                            <Collapse isOpen={IsOpenCollapse}>
                              <div className="card border-0 shadow-none ps-2 mb-0">
                                <ul className="list-unstyled categories-list">
                                  {value.Children.map((child, index) => {
                                    return (
                                      <>
                                        <li key={index}>
                                          <a
                                            className={`${(child.Key == itemActive.Key && value.ID_Group == itemActive.parent?.ID_Group) ? "active" : ""} me-auto d-flex align-items-center treeViewReport`}
                                            onClick={() => {
                                              let tmp = child
                                              tmp.parent = value
                                              setItemActive(tmp)
                                              if (tmp.Key !== 3) {
                                                onChange(tmp)
                                              }
                                              onChooseCustomWorkflow(null)
                                            }}
                                          >
                                            {!child.Children && child.Name}
                                            {child.Children && <span onClick={(e) => {
                                              setChildOpenCollapse(!isChildOpenCollapse)
                                            }}>
                                              {child.Name}
                                            </span>}
                                            {child.Key === 3 && <i
                                              className={
                                                isChildOpenCollapse
                                                  ? "mdi mdi-chevron-down accor-down-icon ms-auto"
                                                  : "mdi mdi-chevron-up accor-down-icon ms-auto"
                                              }
                                            />}
                                          </a>
                                        </li>
                                        <li>
                                          {child.Children && <Collapse isOpen={isChildOpenCollapse}>
                                            <ul className="list-unstyled categories-list">
                                              {child.Children.map((child, index) => {
                                                return (
                                                  <li key={index}>
                                                    <a
                                                      className={`${(child.childrenKey == itemActive.childrenKey && value.ID_Group == itemActive.parent?.ID_Group) ? "active" : ""} me-auto d-flex align-items-center treeViewReport`}
                                                      onClick={() => {
                                                        let tmp = child
                                                        tmp.parent = value
                                                        setItemActive({ ...tmp })
                                                        onChange(tmp)
                                                        onChooseCustomWorkflow(null)
                                                      }}
                                                    >
                                                      {child.Name}
                                                    </a>
                                                  </li>
                                                )
                                              })}
                                            </ul>
                                          </Collapse>}
                                        </li>
                                      </>
                                    )
                                  })}
                                </ul>
                              </div>
                            </Collapse>
                                                  
                            </React.Fragment>
                        )
                      })}{" "}
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <ul className="list-unstyled categories-list">
                  <li>
                    <div className="custom-accordion custom-tree-view">
                      <Link
                        onClick={e => {
                          setOpenCustomCollapse(!isOpenCustomCollapse)

                        }}
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        to="#"
                      >
                        <span className="active" >
                          {t("common:Custom Workflow")}
                        </span>
                        <i
                          className={
                            isOpenCustomCollapse
                              ? "mdi mdi-chevron-down accor-down-icon ms-auto"
                              : "mdi mdi-chevron-up accor-down-icon ms-auto"
                          }
                        />
                      </Link>
                      <Collapse isOpen={isOpenCustomCollapse}>
                        <div className="card border-0 shadow-none ps-2 mb-0">
                          <ul className="list-unstyled categories-list">
                            {workflows?.map((item, index) => {
                              return (
                                <>
                                  <li key={index}>
                                    <a
                                      className={`${(item.id == itemActive.Key && itemActive.parent == undefined) ? "active" : ""} me-auto d-flex justify-content-sm-between`}
                                      onMouseOver={() => { setOnOver(item) }}
                                      onMouseOut={() => { setOnOver({}) }}
                                    >
                                      <span onClick={() => {
                                        onChooseCustomWorkflow(item)
                                        setItemActive({ Key: item.id })
                                       }}>
                                        {item.workflowName}
                                      </span>
                                      {(item.id == itemActive.Key || item.id == onOver.id) && <div className="d-flex">
                                        <div className="align-self-center mx-sm-2"
                                          onClick={e => {
                                            onEditWorkflow(item)
                                            onChooseCustomWorkflow(item)
                                          }}
                                        >
                                          <i className="mdi mdi-pencil font-size-16 text-success me-1" />
                                        </div>
                                        <div className="align-self-center"
                                          onClick={e => {
                                            onToggleDelete(item)
                                            onChooseCustomWorkflow(item)
                                          }}
                                        >
                                          <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
                                        </div>
                                      </div>}
                                    </a>
                                  </li>
                                </>
                              )
                            })}
                          </ul>
                        </div>
                      </Collapse>
                      <ConfirmModal
                        title="Delete Workflow"
                        modal={warningDelete}
                        onDelete={() => deleteCustomWorkflow()}
                        message={`${t("message:DeleteRecordConfirm", {
                          entity: `${t("Workflow")}`,
                        })}`}
                        onToggle={onToggleDelete}
                      />
                      <WorkflowModal
                        modal={modal}
                        toggle={toggleWorkflow}
                        isEdit={isEdit}
                        workflowId={workflow?.id}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

const mapStateToProps = ({ RuleManagement }) => ({
  workflows: RuleManagement.workflows || [],
})

const mapDispatchToProps = dispatch => ({
  getCustomWorkflowList: payload => dispatch(getWorkflowCustom(payload)),
  onDeleteWorkflowCustom: (payload, callback) => dispatch(deleteWorkflowCustom(payload, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message, common, testConfig"])(TreeViewBar_RulerManager))

