import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/profiles`

// Get All Test Profiles Method
const getAllTestProfiles = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

// Get TestProfile By Id Method
const getTestProfileById = profileId => {
    return get(`${BASE_API_URL}/${profileId}`)
}

// Add testProfile
const createTestProfile = req => {
    return post(`${BASE_API_URL}`, req)
}

// Update testProfile By Id Method
const updateTestProfileById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

// Delete TestProfile By Id Method
const deleteTestProfileById = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}

// Get TestProfile statistics
const getTestProfileStatisticsById = profileId => {
    return get(`${BASE_API_URL}/${profileId}/statistics`)
}

//get testprofile group
const getAllTestProfileGroups = profileId => {
    return get(`${BASE_API_URL}/${profileId}/groups`)
}

//update sub-profile
const updateTestProfileGroupsById = (profileId, req) => {
    return put(`${BASE_API_URL}/${profileId}/Groups`, req)
}

//get testprofile tests
const getAllTestProfileTests = profileId => {
    return get(`${BASE_API_URL}/${profileId}/tests`)
}

//update profile tests
const updateTestProfileTestsById = (profileId, req) => {
    return put(`${BASE_API_URL}/${profileId}/tests`, req)
}

// Get Full TestProfile By Id
const getFullTestProfileById = profileId => {
    return get(`${BASE_API_URL}/${profileId}/full`)
}

const updateDisplayOrderProfile = (req) => {
    return put(`${BASE_API_URL}/UpdateDisplayOrderProfile`, req)
}

const updateProfileVendor = (req) => {
    return post(`${BASE_API_URL}/UpdateProfileVendor`, req)
}

const deleteProfileVendor = (params) => {
    let ids = ""
    params.forEach(_param => (ids += `ids=${_param}&`))
    return del(`${BASE_API_URL}/DeleteProfileVendor?${ids}`)
}

const updateInfoProfile = (req) => {
    const { id } = req
    return put(`${BASE_API_URL}/UpdateInfoProfile/${id}`, req)
}

export {
    updateInfoProfile,
    getAllTestProfiles,
    getTestProfileById,
    createTestProfile,
    updateTestProfileById,
    deleteTestProfileById,
    getTestProfileStatisticsById,
    getAllTestProfileGroups,
    updateTestProfileGroupsById,
    getAllTestProfileTests,
    updateTestProfileTestsById,
    getFullTestProfileById,
    updateDisplayOrderProfile,
    updateProfileVendor,
    deleteProfileVendor
}
