import {
    ConfirmModal2,
    WarningModal
} from "components/Common"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
    compareSortArray
} from "helpers/utilities"

import {
    addCustomRuleManagement,
    deleteRuleCustom,
    getRuleCustom
} from "store/laboratory/ruleManagement/actions"

//i18n
import { ModuleIds } from "constant"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import CustomRuleTable from "./CustomRuleTable"
import RuleModal from "./Modal/RuleModal"

const RESOURCE = ModuleIds.RuleManagement

const CustomRule = ({
    rules,
    onGetRules,
    t,
    customRule,
    refresh,
    onDeleteCustomRule,
    onSelect
}) => {
    const [row, setRow] = useState({})
    const [ruleEdit, setRuleEdit] = useState({})
    const [warningModal, setWarningModal] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [sapXep, setSapXep] = useState({})
    const [warningDelete, setWarningDelete] = useState(false)
    const [modal, setModal] = useState(false)

    useEffect(() => {
        if (rules) {
            setDataSource([...rules])
        }
    }, [rules])

    useEffect(() => {
        if (!refresh) {
            onGetRuleList(customRule)
        }
    }, [refresh])

    const onGetRuleList = (payload) => {
        insertUrlParam(payload)
        onGetRules(payload)
    }

    const fetchRules = () => {
        onGetRuleList(customRule)
    }

    const onSearch = searchText => {
        onGetRuleList({ ...customRule, page: 1, search: searchText })
    }

    const onRefresh = () => {
        fetchRules();
    }

    const onSortHandler = (a, b) => {
        const filter = { order: b, dataField: a }
        if (
            sapXep.dataField === filter.dataField &&
            filter.order === sapXep.order
        ) {
            return;
        }

        const newData = JSON.parse(JSON.stringify(dataSource))
            .sort((a, b) => {
                return compareSortArray(a[filter.dataField], b[filter.dataField], filter.order)
            })
            .map((_item, _idx) => {
                _item.displayOrder = _idx
                return _item
            })
        setSapXep(filter)
        setDataSource([...newData])
    }

    const onDeleteHandler = (rule) => {
        setRow(rule)
        setWarningDelete(!warningDelete)
    }

    const onEditHandler = (value) => {
        setRuleEdit(value)
        toggle()
    }

    const onDeleteCustomRuleHandler = () => {
        onDeleteCustomRule({ id: [row.id] }, () => {
            fetchRules()
            onToggle()
        })
    }

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetRuleList(params)
        } else {
            fetchRules()
        }
    }, [])

    useEffect(() => {
        onGetRuleList(customRule)
    }, [customRule])

    const onToggle = () => {
        setWarningDelete(!warningDelete)
    }

    const toggle = () => {
        setModal(!modal)
        fetchRules()
    }

    return (
        <React.Fragment>
            <CustomRuleTable
                rules={dataSource}
                onRefresh={onRefresh}
                onSearch={onSearch}
                onSort={(a, b) => {
                    onSortHandler(a, b);
                }}
                onEditHandler={onEditHandler}
                onDeleteHandler={onDeleteHandler}
                paging={{ dataSize: dataSource.length }}
                onSelect={onSelect}
            />
            <ConfirmModal2
                title="Delete Rule"
                modal={warningDelete}
                onConfirm={onDeleteCustomRuleHandler}
                message={`${t("message:DeleteRecordConfirm", {
                    entity: `${t("Rule")}`,
                })}`}
                btnConfirmText={t("common:Delete")}
                onToggle={onToggle}
                isEdit
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <RuleModal
                modal={modal}
                toggle={toggle}
                fetchRules={fetchRules}
                data={ruleEdit}
            />
        </React.Fragment>
    )
}

CustomRule.propTypes = {
    rules: PropTypes.array,
    onGetRules: PropTypes.func,
    onAddNewRule: PropTypes.func,
    onUpdateRule: PropTypes.func,
    onDeleteRule: PropTypes.func,
    updatedRuleTime: PropTypes.any,
    t: PropTypes.any,
    //customRule: PropTypes.string,
    refresh: PropTypes.bool,
    onSelect: PropTypes.func,
}

const mapStateToProps = ({ RuleManagement }) => {
    return {
        rules: RuleManagement.rules,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetRules: payload => dispatch(getRuleCustom(payload)),
    onAddNewRule: (rule, history) =>
        dispatch(addCustomRuleManagement(rule, history)),
    onDeleteCustomRule: (payload, callback) =>
        dispatch(deleteRuleCustom(payload, callback)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testConfig", "message", "common"])(CustomRule)))
