import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import ParameterModal from "./Modal/ParameterModal"
import ParameterTable from "./ParameterTable"
import {
  Check,
  ConfirmModal,
  CustomButton,
  TitleAndTable,
  WarningModal,
} from "components/Common"

import {
  getUrlParamByKey,
  getUrlParams,
  insertUrlParam,
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import {
  getParameterDetail,
  getParameters,
  addNewParameter,
  updateParameter,
  deleteParameters,
  resetParameterSearchQuery,
} from "store/setting/parameter/actions"

//i18n
import { withTranslation } from "react-i18next"
import { ModuleIds, permissionType } from "constant"
import { saveLengthParameter } from "store/actions"

const RESOURCE = ModuleIds.Parameter

const Parameters = ({
  history,
  parameters,
  paging,
  onGetParameters,
  onAddNewParameter,
  onUpdateParameter,
  onDeleteParameter,
  onResetParameterSearchQuery,
  onGetParameterDetail,
  parameter,
  loadingParameters,
  updatedParameterTime,
  onLengthParameterChange,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])

  const [warningModal, setWarningModal] = useState(false)

  const formEl = useRef(null)

  useEffect(() => {
    window.addEventListener('popstate', (event) => popStateChange(event));
  }, []);

  const popStateChange = (event) => {
    let params = getUrlParams();
    let paramPage = getUrlParamByKey("page");
    if (paramPage) {
      onGetParameterList(params)
    }
  }

  useEffect(() => {
    let params = getUrlParams();
    let paramPage = getUrlParamByKey("page");
    if (paramPage) {
      onGetParameterList(params)
    } else {
      fetchParameters()
    }
  }, [])

  const onGetParameterList = (payload) => {
    insertUrlParam(payload)
    onGetParameters(payload)
  }

  const toggle = () => {
    setModal(prev => !prev)
  }

  const addParameterClicks = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
  }

  /**
   * Handling submit Parameter on Parameter form
   */
  const handleValidParameterSubmit = (e, values) => {
    if (isEdit) {
      onUpdateParameter({ parameter: values, callback: toggle })
    } else {
      const newParameter = {
        parameterKey: values["parameterKey"],
        name: values["name"],
        length: values["length"],
        description: values["description"],
        isSystem: !!values["isSystem"],
        type: values["type"],
      }
      // save new Parameter
      onAddNewParameter({ parameter: newParameter, history })
    }
  }

  const onCloneHandler = () => {
    const id = row?.id
    if (id) {
      // onGetParameterDetail(id)
      setIsEdit(false)
      setIsClone(true)
      toggle()
    } else setWarningModal(true)
  }

  const onEditHandler = (e, paramId) => {
    const id = paramId || row?.id
    if (id) {
      onGetParameterDetail(id)
      setIsEdit(true)
      toggle()
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, param) => {
    onDeleteToggle({
      rows,
      row: param || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteParameter({ parameters: rowsState, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteParameter({
      parameters: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteParameterHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const fetchParameters = () => {
    onGetParameterList({ page: 1 , sort : "createdDate:desc"})
  }

  /** Table methods */
  const onRefreshHandler = () => {
    resetState()
    popStateChange()
  }

  const onSearch = searchText => {
    onGetParameterList({ page: 1, search: searchText })
  }

  const onSizePerPageChange = size => {
    onGetParameterList({ page: 1, size })
  }

  const onPageChange = page => {
    onGetParameterList({ page })
  }

  const onSubmitFilter = values => {
    onGetParameterList({ page: 1, ...values })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetParameterList({ page: 1, sort: sortString })
  }

  const onLengthHandler = (item, value) => {
    onLengthParameterChange({ id: item.id, length: value }, () => {
      let params = getUrlParams();
      onGetParameterList(params)
    })
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetParameterSearchQuery()
  }, [])


  return (
    <React.Fragment>
      {/* Body */}
      <TitleAndTable
        table={() => (
          <ParameterTable
            parameters={parameters}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            loading={loadingParameters}
            updatedTime={updatedParameterTime}
            onLengthChange={onLengthHandler}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <Check permission={permissionType.C} resource={RESOURCE}>
            <CustomButton color="primary" onClick={addParameterClicks} outline>
              {t("parameterPage:Add Parameter")}
            </CustomButton>
          </Check>
        )}
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        onClone={onCloneHandler}
        title={t("Parameters")}
        subtitle={t("Parameter List")}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Parameter")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteParameterHandler}
      />
      <ParameterModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={handleValidParameterSubmit}
        toggle={toggle}
        data={!isEdit ? (isClone ? row : {}) : parameter}
      />
    </React.Fragment>
  )
}

Parameters.propTypes = {
  parameters: PropTypes.array,
  onGetParameterDetail: PropTypes.func,
  onGetParameters: PropTypes.func,
  onAddNewParameter: PropTypes.func,
  onUpdateParameter: PropTypes.func,
  onDeleteParameter: PropTypes.func,
  onResetParameterSearchQuery: PropTypes.func,
  paging: PropTypes.object,
  loadingParameters: PropTypes.bool,
  updatedParameterTime: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = ({ parameter }) => ({
  parameters: parameter.parameters,
  parameter: parameter.parameter,
  paging: parameter.paging,
  loadingParameters: parameter.loadingParameters,
  updatedParameterTime: parameter.updatedParameterTime,
})

const mapDispatchToProps = dispatch => ({
  onGetParameters: payload => dispatch(getParameters(payload)),
  onAddNewParameter: (parameter, history) =>
    dispatch(addNewParameter(parameter, history)),
  onUpdateParameter: parameter => dispatch(updateParameter(parameter)),
  onDeleteParameter: parameter => dispatch(deleteParameters(parameter)),
  onGetParameterDetail: paramId => dispatch(getParameterDetail(paramId)),
  onResetParameterSearchQuery: () => dispatch(resetParameterSearchQuery()),
  onDisplayOrderChange: payload => dispatch(onDisplayOrderChange(payload)),
  onLengthParameterChange: (payload, callback) => dispatch(saveLengthParameter(payload, callback))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["parameterPage", "message"])(Parameters)))
