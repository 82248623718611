import {
  ButtonDropdownGroup,
  Check,
  CustomButton
} from "components/Common"
import { permissionType, ReportAction } from "constant"
import PropTypes from "prop-types"

import {
  DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { useState } from "react"
const HeaderButtons = ({
  resource,
  onPrintClick,
  onPreviewClick,
  resourceReport,
  onExportClick,
  onReportSettingClick,
  onDeleteClick,
  onCreateClick,
  onEditClick,
  onAddClick,
  onCloneClick,
  t,
  disableCreateReport
}) => {
  const [loadingPrint, setLoadingPrint] = useState(false)
  const [loadingPreview, setLoadingPreview] = useState(false)
  const [loadingCreate, setLoadingCreate] = useState(false)

  const setLoading = (name) => {
    switch (name) {
      case "Create":
        setLoadingCreate(true)
        setLoadingPreview(false)
        setLoadingPrint(false)
        break;
      case "Preview":
        setLoadingPreview(true)
        setLoadingCreate(false)
        setLoadingPrint(false)
        break;
      case "Print":
        setLoadingPreview(false)
        setLoadingCreate(false)
        setLoadingPrint(true)
        break;
    }
  }
  return (
    <>
      {/* <Check permission={permissionType.C} resource={resource}>
        <CustomButton color="primary" onClick={() => onAddClick && onAddClick()} outline>
          {t("deliveryPage:Add Delivery")}
        </CustomButton>
      </Check> */}
      <ButtonDropdownGroup
        onDeleteClick={() => { }}
        onCloneClick={() => { }}
      >
        {/* <Check permission={ReportAction.Print} resource={resourceReport}>
          <CustomButton color="primary" isEdit={loadingPrint} outline onClick={() => { onPrintClick(ReportAction.Print), setLoading("Print") }}>
            {t("common:Print")}
          </CustomButton>
        </Check> */}
        {/* <Check permission={ReportAction.Create} resource={resourceReport}>
          <CustomButton color="primary" isEdit={loadingCreate} disabled={disableCreateReport} onClick={() => { onCreateClick(ReportAction.Create), setLoading("Create") }} outline>
            {t("Create Report")}
          </CustomButton>
        </Check> */}
        {/* <Check permission={ReportAction.Preview} resource={resourceReport}>
          <CustomButton color="primary" isEdit={loadingPreview} onClick={() => { onPreviewClick(ReportAction.Preview), setLoading("Preview") }} outline>
            {t("Preview")}
          </CustomButton>
        </Check> */}
        {/* <Check permission={ReportAction.Export} resource={resourceReport}>
                    <CustomButton color="primary" onClick={() => onExportClick(ReportAction.Export)} outline>
                        {t("Export")}
                    </CustomButton>
                </Check> */}
        {/* <Check permission={permissionType.E} resource={resource}>
                    <CustomButton color="primary" onClick={() => onEditClick && onEditClick()} outline>
                        {t("Edit")}
                    </CustomButton>
                </Check> */}
        {/* <Check permission={permissionType.C} resource={resource}>
                    <CustomButton color="primary" onClick={() => onCloneClick && onCloneClick()} outline>
                        {t("Clone")}
                    </CustomButton>
                </Check> */}
        <Check permission={permissionType.C} resource={resource}>
          <CustomButton color="primary" onClick={onDeleteClick} outline>
            {t("Delete")}
          </CustomButton>
        </Check>
        <Check
          permissions={[permissionType.U, permissionType.C, permissionType.E]}
          resource={resource}
        >
          <Check permission={permissionType.D} resource={resource}>
            <DropdownToggle caret color="primary" outline>
              <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem disabled>{t("Action")}</DropdownItem>
              {/* <Check permission={ReportAction.Export} resource={resourceReport}>
                <DropdownItem onClick={() => onExportClick(ReportAction.Export)}>
                  {t("Export")}
                </DropdownItem>
              </Check> */}
              {/* <Check permission={permissionType.E} resource={resource}>
                                <DropdownItem onClick={() => onEditClick && onEditClick()}>
                                    {t("Edit")}
                                </DropdownItem>
                            </Check> */}
              {/* <Check permission={permissionType.C} resource={resource}>
                                <DropdownItem onClick={() => onCloneClick && onCloneClick()}>
                                    {t("Clone")}
                                </DropdownItem>
                            </Check> */}
              {/* <Check permission={permissionType.D} resource={resource}>
                <DropdownItem onClick={onDeleteClick}>
                  {t("Delete")}
                </DropdownItem>
              </Check> */}
            </DropdownMenu>
          </Check>
        </Check>
      </ButtonDropdownGroup>
    </>
  )
}

HeaderButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  //onChangeSIDClick: PropTypes.func.isRequired,
  //onUpdateVALClick: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
  //onUpdateUPLClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  disableCreateReport: PropTypes.bool,
}

export default withTranslation(["common", "resultPage", "deliveryPage"])(HeaderButtons)
