import { InlineEditType, ModuleIds } from "constant"
import PropTypes from "prop-types"
import React, { forwardRef, useImperativeHandle, useState } from "react"
//i18n
import { AvForm } from "availity-reactstrap-validation"
import { CustomDatePicker, CustomSelectAsync, FormSwitch } from "components/Common"
import { isEmpty } from "lodash"
import * as moment from "moment"
import { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Col, Row } from "reactstrap"
import {
    ChangeRequestSampleTable, getTestRequestSample
} from "store/laboratory/testRequest/actions"
import { getGeneralSetting } from "store/setting/generalSetting/actions"
import SampleTabTable from "./SampleTabTable"

const RESOURCE = ModuleIds.TestRequest

const SampleTab = forwardRef(({
    t,
    testRequestSample,
    onGetTestRequestSample,
    activeTab,
    onChangeRequestSampleTableSuccess,
    testRequestTests,
    generalSetting,
    onGetGeneralSetting
}, ref) => {
    const [isCollected, setIsCollected] = useState(false);
    const [isReceived, setIsReceived] = useState(false);
    const [dataCollect, setDataCollect] = useState([]);
    const [dataReceive, setDataReceive] = useState([]);
    const userInfo = localStorage.getItem("userInfo")
    const userCollectedAndReceived = JSON.parse(localStorage.getItem("userInSesstion"));
    const { family_name, given_name, name, sub } = JSON.parse(userInfo)
    const [userCollect, setUserCollect] = useState(0)
    const [userReceive, setUserReceive] = useState(0)
    const [receiveDate, setReceiveDate] = useState(new Date())
    const [collectDate, setCollectDate] = useState(new Date())
    const [userCollectName, setUserCollectName] = useState('');
    const [userReceiveName, setUserReceiveName] = useState('');
    const { statusRequest } = useSelector(state => ({
        statusRequest: state.testRequest.testRequest?.state,
    }))
    useEffect(() => {
        if (isEmpty(generalSetting) && userInfo?.company) {
            onGetGeneralSetting(userInfo?.company);
        }
    }, [])
    useEffect(() => {
        if (activeTab == 3) {
            onGetTestRequestSample();
        }
    }, [activeTab])
    useImperativeHandle(ref, () => ({
        GetUserData: () => {
            let res = {}
            let displayName = '';
            let userid = 0
            if (userInfo) {
                displayName = family_name && given_name ? `${family_name} ${given_name}` : name
                userid = userCollectedAndReceived ? userCollectedAndReceived?.id :Number(sub)
            }
            if (isCollected) {
                if (!isEmpty(userCollectName)) {
                    res.userCollectName = userCollectName
                    res.userCollect = userCollect
                    res.isCollected = true
                    res.collectionTime = collectDate
                }
                else {
                    res.userCollectName = displayName
                    res.userCollect = userid
                    res.isCollected = true
                    res.collectionTime = collectDate
                }
            }
            else {
                res.userCollectName = ''
                res.userCollect = 0
                res.isCollected = false
            }
            if (isReceived) {
                if (!isEmpty(userReceiveName)) {
                    res.userReceiveName = userReceiveName
                    res.userReceive = userReceive
                    res.isReceived = true
                    res.receivedTime = receiveDate
                }
                else {
                    res.userCollectName = displayName
                    res.userCollect = userid
                    res.isReceived = true
                    res.receivedTime = receiveDate
                }
            }
            else {
                res.userReceiveName = ''
                res.userReceive = 0
                res.isReceived = false
            }
            return res;
        },
    }));
    useEffect(() => {
        if (testRequestTests.length == 0) {
            setIsCollected(false);
            setIsReceived(false);
        }
    }, [testRequestTests])
    useEffect(() => {
        if (userInfo) {
            const displayName = family_name && given_name ? `${family_name} ${given_name}` : name
            const res = [{ value: userCollectedAndReceived ? userCollectedAndReceived?.id :Number(sub), label: displayName }]
            setUserCollect(userCollectedAndReceived ? userCollectedAndReceived?.id :Number(sub))
            setUserCollectName(userCollectedAndReceived ? userCollectedAndReceived?.name : displayName)
            setUserReceive(Number(sub))
            setUserReceiveName(displayName)
            setDataCollect(res)
            setDataReceive(res)
        }
    }, [])
    useEffect(() => {
        // switch (statusRequest) {
        //     case TestRequest_State.Draft:
        //         setIsCollected(false);
        //         setIsReceived(false);
        //         onChangeRequestSampleTableSuccess([{ value: false, key: 'isCollected' }, { value: false, key: 'isReceived' }])

        //         break;
        //     case TestRequest_State.Collected:
        //         setIsCollected(true);
        //         setIsReceived(false);
        //         onChangeRequestSampleTableSuccess([{ value: true, key: 'isCollected' }, { value: false, key: 'isReceived' }])
        //         break;
        //     case TestRequest_State.Received:
        //         setIsCollected(true);
        //         setIsReceived(true);
        //         onChangeRequestSampleTableSuccess([{ value: true, key: 'isCollected' }, { value: true, key: 'isReceived' }])
        //         break;
        //     case TestRequest_State.Submitted:
        //         setIsCollected(false);
        //         setIsReceived(false);
        //         onChangeRequestSampleTableSuccess([{ value: false, key: 'isCollected' }, { value: false, key: 'isReceived' }])

        //         break;
        //     default:
        //         setIsCollected(false);
        //         setIsReceived(false);
        //         onChangeRequestSampleTableSuccess([{ value: false, key: 'isCollected' }, { value: false, key: 'isReceived' }])

        //         break;
        // }
    }, [statusRequest])

    const displayCollected = generalSetting.find(item => item.fieldName === 'Collected')?.fieldValue === 'True';
    const displayReceived = generalSetting.find(item => item.fieldName === 'Received')?.fieldValue === 'True';

    return (
        <React.Fragment>
            <AvForm
                // ref={formEl}
                id="generalForm"
            // onValidSubmit={onValidSubmit}
            // model={data}
            >
                <Row className="px-2 mt-2">
                    {displayCollected && <Col md={6}>
                        <FormSwitch label={t("testRequestPage:Collected?")}
                            value={isCollected}
                            onChangeSwitch={(e) => {
                                if (isCollected) {
                                    setIsReceived(false)
                                    onChangeRequestSampleTableSuccess([
                                        { value: !isCollected, key: 'isCollected' },
                                        { value: null, key: 'collectionTime' },
                                        { value: null, key: 'collectorUserId' },
                                        { value: '', key: 'collectorName' },
                                        { value: false, key: 'isReceived' },
                                        { value: null, key: 'receivedTime' },
                                        { value: null, key: 'receiverUserId' },
                                        { value: '', key: 'receiverName' },
                                        { value: ' ', key: 'quality' },
                                        { value: '', key: 'qualityName' },
                                    ])
                                }
                                else {
                                    let receiveDateString = moment(receiveDate).format("YYYY-MM-DDTHH:mm");
                                    onChangeRequestSampleTableSuccess([
                                        { value: !isCollected, key: 'isCollected' },
                                        { value: receiveDateString, key: 'collectionTime' },
                                        { value: userCollect, key: 'collectorUserId' },
                                        { value: userCollectName, key: 'collectorName' },
                                        { value: 'G', key: 'quality' },
                                        { value: t('testRequestPage:Good'), key: 'qualityName' },
                                    ])
                                }
                                setIsCollected(prev => !prev)
                            }}
                        />
                        <div className="mb-1">
                            <CustomDatePicker
                                name="collectDate"
                                label={''}
                                placeholder={"DD/MM/YYYY"}
                                format={"DD/MM/YYYY"}
                                value={collectDate || new Date()}
                                disabled={!isCollected}
                                enableTime={true}
                                dateFormat={'Y-m-d H:i'}
                                onChangeHandler={(e) => {
                                    let value = moment(e.time[0]).format("YYYY-MM-DDTHH:mm");
                                    setCollectDate(value)
                                    onChangeRequestSampleTableSuccess([{ value: value, key: 'collectionTime' }])
                                }}
                            />
                        </div>
                        <div className="mb-1">
                            <CustomSelectAsync
                                label={t("testRequestPage:Collector Name")}
                                name="collectName"
                                readOnly={!isCollected}
                                options={dataCollect}
                                value={userCollect || ""}
                                valueName={userCollectName || ""}
                                code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                                onChange={(e, values, name) => {
                                    setUserCollect(values[0] || null)
                                    setUserCollectName(name || '')
                                    onChangeRequestSampleTableSuccess([{ value: values[0], key: 'collectorUserId' }, { value: name, key: 'collectorName' }])
                                }}
                            />
                        </div>
                    </Col>}
                    {displayReceived && <Col md={6}>
                        <FormSwitch label={t("testRequestPage:Received?")}
                            value={isReceived}
                            // disabled={!isCollected}
                            onChangeSwitch={(e) => {
                                if (isReceived) {
                                    onChangeRequestSampleTableSuccess([
                                        { value: !isReceived, key: 'isReceived' },
                                        { value: null, key: 'receivedTime' },
                                        { value: null, key: 'receiverUserId' },
                                        { value: '', key: 'receiverName' },
                                        { value: ' ', key: 'quality' },
                                        { value: '', key: 'qualityName' },
                                    ])
                                }
                                else {
                                    let receiveDateString = moment(receiveDate).format("YYYY-MM-DDTHH:mm");
                                    onChangeRequestSampleTableSuccess([
                                        { value: !isReceived, key: 'isReceived' },
                                        { value: receiveDateString, key: 'receivedTime' },
                                        { value: userReceive, key: 'receiverUserId' },
                                        { value: userReceiveName, key: 'receiverName' },
                                        { value: 'G', key: 'quality' },
                                        { value: t('testRequestPage:Good'), key: 'qualityName' },
                                    ])
                                }
                                setIsReceived(prev => !prev)

                            }}
                        />
                        <div className="mb-1">
                            <CustomDatePicker
                                name="receiveDate"
                                label={''}
                                placeholder={"DD/MM/YYYY"}
                                format={"DD/MM/YYYY"}
                                disabled={!isReceived}
                                enableTime={true}
                                value={receiveDate || new Date()}
                                dateFormat={'Y-m-d H:i'}
                                onChangeHandler={(e) => {
                                    let value = moment(e.time[0]).format("YYYY-MM-DDTHH:mm");
                                    setReceiveDate(value)
                                    onChangeRequestSampleTableSuccess([{ value: value, key: 'receivedTime' }])
                                }}
                            />
                        </div>
                        <div className="mb-1">
                            <CustomSelectAsync
                                label={t("testRequestPage:Receiver Name")}
                                name="receiveName"
                                readOnly={!isReceived}
                                options={dataReceive}
                                value={userReceive || ""}
                                valueName={userCollectName || ""}
                                code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                                onChange={(e, values, name) => {
                                    setUserReceive(values[0] || null)
                                    setUserReceiveName(name || '')
                                    onChangeRequestSampleTableSuccess([{ value: values[0], key: 'receiverUserId' }, { value: name, key: 'receiverName' }])
                                }}
                            />
                        </div>
                    </Col>}
                </Row>
            </AvForm>
            <div className="sampleTabTable px-2 mt-3">
                <SampleTabTable
                    data={testRequestSample || []}
                    onSelect={() => { }}
                    onSelectAll={() => { }}
                    onDelete={() => { }}
                />
            </div>
        </React.Fragment>
    )
})

SampleTab.propTypes = {
    t: PropTypes.any,
    onGetTestRequestSample: PropTypes.func,
    testRequestSample: PropTypes.array,
}

SampleTab.defaultProps = {}

const mapStateToProps = ({ testRequest, generalSetting }) => ({
    testRequestSample: testRequest.testRequest.samples || [],
    testRequestTests: testRequest.testRequest.tests || [],
    generalSetting: generalSetting.generalSetting
})

const mapDispatchToProps = dispatch => ({
    onGetTestRequestSample: payload => dispatch(getTestRequestSample(payload)),
    onChangeRequestSampleTableSuccess: payload => dispatch(ChangeRequestSampleTable(payload)),
    onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload)),
})
SampleTab.displayName = 'SampleTab';
export default withTranslation(["message", "common", "testRequestPage"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(SampleTab))
