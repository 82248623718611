import { DonutChart } from "components/Common"
import { getSampleDetailTotal, getSampleGeneral, getSlowSample, getTatGeneral } from "helpers/fakebackend_helper"
import { Fragment, useEffect, useState } from "react"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"

const BodyLeft = ({ }) => {
  const [slowSample, setSlowSample] = useState([])
  const [sampleGeneral, setSampleGeneral] = useState([])
  const [sampleDetailTotal, setSampleDetailTotal] = useState({})
  const [tatGeneral, setTatGeneral] = useState([])

  const onFetchTatGeneral = async () => {
    await getTatGeneral().then(res => setTatGeneral(res))
  }
  console.log('tatGeneral', tatGeneral)
  const onFetchSlowSample = async () => {
    await getSlowSample().then(res => setSlowSample(res))
  }

  const onFetchSampleGeneral = async () => {
    await getSampleGeneral().then(res => setSampleGeneral(res))
  }

  const onFetchSampleDetailTotal = async () => {
    await getSampleDetailTotal().then(res => setSampleDetailTotal(res))
  }

  useEffect(() => {
    onFetchSlowSample(), onFetchSampleGeneral(), onFetchSampleDetailTotal(), onFetchTatGeneral()
  }, [])

  return (
    <Fragment>
      <Row>
        <Col lg="12" style={{ height: 'fit-content' }}>
          <Card className="mb-2">
            <CardHeader
              style={{
                textAlign: "center",
                background: "#fff",
                paddingBottom: 0,
              }}
            >
              <div style={{ fontSize: 15, fontWeight: 600 }}>Mẫu chậm trả</div>
            </CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <DonutChart
                data={slowSample}
                dataColor={["--bs-primary", "--bs-warning"]}
                height={170}
                offSetXLegend={-40}
                offSetYLegend={80}
              />
            </CardBody>
          </Card>
        </Col>
        {/* {sampleGeneral.map((item, index) => (
          <Col lg="12" key={index}>
            <Card className="mb-2" style={{ minHeight: "20%" }}>
              <CardHeader
                style={{
                  textAlign: "center",
                  background: "#fff",
                  paddingBottom: 0,
                }}
              >
                <div style={{ fontSize: 15, fontWeight: 600 }}>
                  {item.title}
                </div>
              </CardHeader>
              <CardBody style={{ textAlign: "center", paddingTop: 0 }}>
                <div style={{ fontSize: 24, fontWeight: "bold" }}>
                  {item.value}
                </div>
              </CardBody>
            </Card>
          </Col>
        ))} */}

        <div className="d-flex" style={{ gap: '10px' }}>
          {tatGeneral.map((item, index) => (
            <Col lg="6" key={index} style={{ width: '50%' }}>
              <Card className="mb-2" style={{ minHeight: "20%" }}>
                <CardHeader
                  style={{
                    textAlign: "center",
                    background: "#fff",
                    paddingBottom: 0,
                    paddingTop: '20px'
                  }}
                >
                  <div style={{ fontSize: 15, fontWeight: 600 }}>{item.title}</div>
                </CardHeader>
                <CardBody style={{ textAlign: "center", paddingTop: 0 }}>
                  <div style={{ fontSize: 24, fontWeight: "bold" }}>
                    {item.value} Min
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </div>

      </Row>
    </Fragment>
  )
}

export default BodyLeft
