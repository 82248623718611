import {
  CustomAvField,
  CustomDatePicker,
  CustomNav,
  CustomNavLink,
  CustomSelect,
  Header,
  TitleAndTable,
  showToast
} from "components/Common"
import {
  convertDateFormat
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  getDeliveries,
  resetDeliveriesSearchQuery
} from "store/actions"

import { AvForm } from "availity-reactstrap-validation"
import CustomSplitPaneLeft from "components/Common/CustomSplitPaneLeft"
import { ModuleIds } from "constant"
import { getAllOrganizations, getRequestSampleList } from "helpers/app-backend"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { Label } from "reactstrap"
import AddSampleDelivery from "./AddSampleDelivery"
import QuickAddDeliveryReceiptTable from "./QuickAddDeliveryReceiptTable"
import CustomSearchBar from "./SearchBar"

const RESOURCE = ModuleIds.DeliveryReceive

const QuickAddDeliveryReceipt = ({
  t,
}) => {
  const [rows, setRows] = useState([])
  const [model, setModel] = useState({
    dateTo: convertDateFormat(new Date(), "YYYY-MM-DD"),
    dateFrom: convertDateFormat(new Date(), "YYYY-MM-DD"),
    type: 'D',
    search: ''
  })
  const [isSpin, setIsSpin] = useState(false)
  const [sid, setSid] = useState([])
  const [enableBarcode, setEnableBarcode] = useState(false)
  const [oldSid, setOldSid] = useState(false)
  const [rowRemove, setRowRemove] = useState([])
  const [currentTab, setCurrentTab] = useState('D')
  const [serviceTypes, setServiceTypes] = useState([])
  const [sidTypeExclude, setSidTypeExclude] = useState([])

  useEffect(() => {
    getSID(model)
    getServiceType()
  }, [])

  useEffect(() => {
    getSID(model)
  }, [model.dateFrom, model.dateTo, model.type, model.groupCode])

  useEffect(() => {
    if (!isEmpty(rowRemove)) {
      const sampleData = rowRemove?.length > 0 ? rowRemove : [rowRemove]
      const data = rows.filter(item => !sampleData.find((element =>
        item.sampleId === element.sampleId &&
        item.patientId === element.patientId &&
        item.sampleType === element.sampleType &&
        item.requestId === element.requestId &&
        item.subSID === element.subSID
      )))
      setRows([...data])
    }
  }, [rowRemove])

  const onSearchSubmit = (e) => {
    e.preventDefault()
    getSID(model)
  }

  const sidTypeExcludeChange = (value) => {
    setSidTypeExclude(value)
  }

  const getSID = async (query) => {
    setIsSpin(true)
    const data = await getRequestSampleList(query)

    data.forEach((item, index) => {
      item.quality = item.quality || 'G'
      item.id = index + 1
    })
    setSid([...data])
    setIsSpin(false)
  }

  const onSelectCheckbox = (e, row, index) => {
    row.confirmedTime = convertDateFormat(new Date())
    rows.push(row)
    setRows([...rows])
  }

  const onClear = () => {
    model.search = ''
    setModel({ ...model })
    getSID(model)
  }

  const onDelete = (sample) => {
    const sampleData = sample?.length > 0 ? sample : [sample]
    setRowRemove([...sampleData])
  }

  const onDeliveryTypeChange = (data) => {
    setRows([])
    model.type = data
    setModel({ ...model })
  }
  const onBarCodeChange = (value) => {
    setEnableBarcode(value)
  }

  const requestDateOnChange = (date) => {
    model.dateFrom = convertDateFormat(date.time[0], "YYYY-MM-DD")
    model.dateTo = convertDateFormat(date.time[1], "YYYY-MM-DD")
    setModel({ ...model });
  }

  const onRefreshHandler = () => {
    getSID(model)
    setRows([])
  }

  const onRefreshSid = () => {
    getSID(model)
  }

  const onBarcode = (code) => {
    if (enableBarcode) {
      const data = sid.filter(item => (`${item.sid}${!!item.subSID ? item.subSID : ''}`).includes(code))
      if (data.length === 0) {
        showToast(
          `${t("Cannot find sample type")}`
        )
      } else {
        const sidExsistSelect = rows.filter(item => (`${item.sid}${!!item.subSID ? item.subSID : ''}`).includes(code))
        const result = data
          .filter(item => !sidExsistSelect.find(x => x.sid === item.sid && x.sampleType === item.sampleType && x.subSID === item.subSID)
            && !sidTypeExclude.includes(item.sampleType)
          )
        if (result?.length > 0) {
          result.forEach(item => item.confirmedTime = convertDateFormat(new Date()))
          setRows([...result, ...rows])
        } else {
          showToast(
            `${t("Sample type exsist")}`
          )
        }
      }
    }
  }

  const getServiceType = async () => {
    const query = { size: 0 }
    let res = await getAllOrganizations(query)
    let data = res?.data.map(_item => {
      _item.value = _item.organizationCode
      _item.label = `${_item.organizationCode} - ${_item.name}`
      return _item
    });
    setServiceTypes(data || [])
  }

  const sidDate = useMemo(() => [model.dateFrom, model.dateTo], [model.dateFrom, model.dateTo])

  const title = currentTab === 'D' ? 'deliveryPage:Sample Delivery' : (currentTab === 'C' ? 'Return Sample' : 'CancelSampleInfo')

  const minDate = new Date().setMonth(new Date().getMonth() - 3)
  const sampleList = sid.filter(item => !rows.find(element => element.sampleId === item.sampleId
    && element.patientId === item.patientId
    && element.sampleType === item.sampleType
    && element.requestId === item.requestId
    && element.subSID === item.subSID
  ) && item.numberOfTest > 0)

  return (
    <React.Fragment style={{ height: 'calc(100% - 10px)px' }}>
      <div className="p-1" style={{ background: 'white', overflow: 'hidden' }}>
        <div className="pl-1 pt-2 pb-1">
          <Header
            title={t("testRequestPage:Process management")}
            subtitle={t(title)}
            resource={RESOURCE}
          />
        </div>
        <div style={{ height: window.innerHeight >= 945 ? `${(window.innerHeight - window.innerHeight / 5.7)}px` : `${(window.innerHeight + 29)}px` }}>
          <CustomSplitPaneLeft
            LeftFrame={() => (
              <div className="pt-1 px-1" style={{ overflow: 'hidden' }}>
                <div className="mb-1">
                  <CustomNav
                    onToggle={value => {
                      setCurrentTab(value)
                      model.type = value
                      model.page = 1
                      model.size = 8
                      setModel({ ...model })
                      setRows([])
                    }}
                    defaultTab={currentTab}
                    tabs
                    className="nav-tabs-custom"
                  >
                    <CustomNavLink style={{ width: '33%', textAlign: 'center' }} tabId="D">
                      <span className="font-size-14">{t("deliveryPage:DeliveryAndReceipt")}</span>
                    </CustomNavLink>
                    <CustomNavLink style={{ width: '33%', textAlign: 'center' }} tabId="C">
                      <span className="font-size-14">{t("Return Sample")}</span>
                    </CustomNavLink>
                    <CustomNavLink style={{ width: '33%', textAlign: 'center' }} tabId="U">
                      <span className="font-size-14">{t("CancelSampleInfo")}</span>
                    </CustomNavLink>
                  </CustomNav>
                </div>

                <AvForm>
                  <div>
                    <div className="d-flex mb-1 gap-1">
                      <div className="col-xl-6">
                        <CustomSearchBar
                          width={"100%"}
                          className={'search-box mb-0'}
                          onChange={(e) => {
                            setModel({ ...model, search: e.target.value })
                          }}
                          style={{ height: '36px !important' }}
                          searchText={model.search}
                          onClear={onClear}
                          onSubmit={onSearchSubmit}
                          disabled={enableBarcode}
                        />
                      </div>
                      <div className="col-xl-6">
                        <CustomSelect
                          name="groupCode"
                          options={serviceTypes || []}
                          label={""}
                          value={model?.groupCode}
                          onChange={(e, vals, a, values) => {
                            setModel({ ...model, groupCode: vals[0] })
                          }}
                          placeholder={t("patientGroup:List Group")}
                        />
                      </div>
                    </div>
                    <div className="d-flex pb-1 gap-2">
                      <div className="col-xl-6">
                        <CustomDatePicker
                          style={{ width: '100%' }}
                          name="requestDate"
                          placeholder={t("Request Date")}
                          format={"d-m-Y"}
                          value={sidDate}
                          onChangeHandler={requestDateOnChange}
                          showIcon
                          mode='range'
                          maxDate={new Date()}
                          minDate={minDate}
                          disabled={!oldSid}
                          closeOnSelect
                        />
                      </div>
                      <div className="col-xl-3" style={{ placeSelf: 'center' }}>
                        <div className="d-flex gap-3">
                          <Label className="form-check-label" >{t('Old SID')}</Label>
                          <div className="form-check form-switch">
                            <CustomAvField
                              type="checkbox"
                              direction="down"
                              name='oldSid'
                              value={oldSid}
                              detected={false}
                              style={{
                                fontSize: 'larger'
                              }}
                              onChange={() => {
                                setOldSid(!oldSid)
                                if (oldSid) {
                                  model.dateFrom = convertDateFormat(new Date(), "YYYY-MM-DD")
                                  model.dateTo = convertDateFormat(new Date(), "YYYY-MM-DD")
                                  setModel({ ...model })
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AvForm>
                <div>
                  {isSpin &&
                    <div style={{
                      width: '99%',
                      position: 'absolute', zIndex: 1,
                      background: 'rgba(256,256,256,0.5)',
                      height: '560px'
                    }}>
                      <div className="w-100" >
                        <div className="text-center" style={{ marginTop: '180px' }}>
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <TitleAndTable
                    table={() => (
                      <QuickAddDeliveryReceiptTable
                        deliveries={sampleList}
                        onSelect={onSelectCheckbox}
                        onRefresh={onRefreshSid}
                        currentTab={model}
                      />
                    )}
                    resource={RESOURCE}
                  />
                </div>
              </div>
            )}
            RightFrame={() => (
              <div className="pt-1 pb-1" style={{ paddingLeft: '6px', overflow: 'hidden' }}>
                <AddSampleDelivery
                  samples={rows}
                  sampleList={sampleList}
                  onDelete={onDelete}
                  onDeliveryTypeChange={onDeliveryTypeChange}
                  onBarCodeChange={onBarCodeChange}
                  onRefreshHandler={onRefreshHandler}
                  onBarcode={onBarcode}
                  currentTab={currentTab}
                  onLoad={setIsSpin}
                  sidTypeExcludeChange={sidTypeExcludeChange}
                />
              </div>
            )}
            InitialSize={68}
            switchIcon={true}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

QuickAddDeliveryReceipt.propTypes = {
  deliveries: PropTypes.array,
  onGetDeliveryDetail: PropTypes.func,
  onGetDeliveries: PropTypes.func,
  onAddNewDelivery: PropTypes.func,
  onUpdateDelivery: PropTypes.func,
  onDeleteDeliveries: PropTypes.func,
  onResetDeliveriesSearchQuery: PropTypes.func,
  paging: PropTypes.object,
  loadingDeliveries: PropTypes.bool,
  updatedDeliveriesTime: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = ({ sampleDelivery }) => {
  return {
    deliveries: sampleDelivery.deliveries,
    delivery: sampleDelivery.delivery,
    paging: sampleDelivery.paging,
    loadingDeliveries: sampleDelivery.loadingDeliveries,
    updatedDeliveriesTime: sampleDelivery.updateDeliveriesTime,
    requestSamples: sampleDelivery.requestSamples,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetDeliveries: payload => dispatch(getDeliveries(payload)),
  onResetDeliveriesSearchQuery: () => dispatch(resetDeliveriesSearchQuery())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(withTranslation(["deliveryPage", "message", "sidebar", "testRequestPage"])(QuickAddDeliveryReceipt))
)
