import { CustomNav, CustomNavLink } from "components/Common"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Row, TabContent, TabPane } from "reactstrap"
import { getLanguageCodes } from "store/actions"
import ConnectorDepartment from "./Department"
import ConnectorPhysician from "./Physician"
import ConnectorHisCode from "./HisCode"
import ConnectorInsurance from "./Insurance"
import ConnectorInstrument from "./Instrument"
import TreeViewBar_Connector from "./TreeViewBar_Connector"
import { getUrlParamByKey, insertUrlParam } from "helpers/utilities"

const Connector = ({
  t, onGetLanguageCode, languages, hisConnector, itemActive
}) => {
  const [scale, setScale] = useState(false)
  const [isSpin, setIsSpin] = useState(false)
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
  useEffect(() => {
    setCurrentTab('1')
  }, [itemActive])

  const scaleScreen = () => {
    setScale(!scale)
  }
  useEffect(() => {
    if (languages.length == 0)
      onGetLanguageCode()
  }, [])

  useEffect(() => {
    setCurrentTab(`${getUrlParamByKey('tab')}`)
  }, [])

  useEffect(() => {
    if (getUrlParamByKey('tab') != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])
  useEffect(() => {
    setCurrentTab(getUrlParamByKey('tab'))
  }, [window.location.search])
  return (
    <React.Fragment>
      <Row className="connector-content" style={{ height: "calc(100% - 45px)", overflow: 'hidden', backgroundColor: '#fff', position: 'relative' }}>
        <div className={`col-md-3 col-lg-2 p-0 ${scale ? 'TATSettingLeft' : ''}`} style={{ maxHeight: '100%', overflowY: "auto", position: 'relative' }} >
          <TreeViewBar_Connector
            onChange={val => {
            }}
          />
        </div>
        <div className={`h-100 col-md-9 col-lg-10 ${scale ? 'TATSettingRight' : ''}`}>
          <div className={`resize-button-tat`}
            onClick={() => scaleScreen()}
          >
            <i className={`fa fa-angle-double-right ${scale ? 'resize-button-tat-icon' : ''}`}></i>
          </div>
          <div className="rule-management h-100 tat-setting-content position-relative">
            {isSpin &&
              <div className="h-100 w-100" style={{
                position: 'absolute', zIndex: 1,
                background: 'rgba(256,256,256,0.5)'
              }}>
                <div className="w-100 h-100">
                  <div className="text-center" style={{ marginTop: '50px' }}>
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            }
            <CustomNav
              onToggle={e => {
                setCurrentTab(e);
              }}
              defaultTab={currentTab}
              tabs
              className="nav-tabs-custom"
              tabContents={customActiveTab => (
                <TabContent
                  activeTab={customActiveTab}
                  className="py-3 text-muted"
                >
                  <TabPane tabId="1">
                    {currentTab == '1' &&
                      <div className="connector-hiscode">
                        <ConnectorHisCode t={t} onLoad={(value) => {
                          setIsSpin(value)
                        }} />
                      </div>
                    }
                  </TabPane>
                  <TabPane tabId="2">
                    {currentTab == '2' && hisConnector.insuranceMappingKey &&
                      <div className="connector-hiscode">
                        <ConnectorInsurance t={t} onLoad={(value) => {
                          setIsSpin(value)
                        }}
                        />
                      </div>
                    }
                  </TabPane>
                  <TabPane tabId="3">
                    {currentTab == '3' && hisConnector.departmentMappingKey &&
                      <div className="connector-hiscode">
                        <ConnectorDepartment t={t} onLoad={(value) => {
                          setIsSpin(value)
                        }}
                        />
                      </div>
                    }
                  </TabPane>
                  <TabPane tabId="4">
                    {currentTab == '4' && hisConnector.physicianMappingKey &&
                      <div className="connector-hiscode">
                        <ConnectorPhysician t={t} onLoad={(value) => {
                          setIsSpin(value)
                        }}
                        />
                      </div>
                    }
                  </TabPane>
                  <TabPane tabId="5">
                    {currentTab == '5' && hisConnector.instrumentMappingKey &&
                      <div className="connector-hiscode">
                        <ConnectorInstrument t={t} onLoad={(value) => {
                          setIsSpin(value)
                        }}
                        />
                      </div>
                    }
                  </TabPane>
                </TabContent>
              )}
            >
              <CustomNavLink tabId="1">
                <span className="">{t("HIS Code")}</span>
              </CustomNavLink>
              {hisConnector.insuranceMappingKey &&
                <CustomNavLink tabId="2">
                  <span className="">{t("Insurance")}</span>
                </CustomNavLink>
              }
              {hisConnector.departmentMappingKey &&
                <CustomNavLink tabId="3">
                  <span className="">{t("Department")}</span>
                </CustomNavLink>
              }
              {hisConnector.physicianMappingKey &&
                <CustomNavLink tabId="4">
                  <span className="">{t("Physician")}</span>
                </CustomNavLink>
              }
              {hisConnector.instrumentMappingKey &&
                <CustomNavLink tabId="5">
                  <span className="">{t("Instrument")}</span>
                </CustomNavLink>
              }
            </CustomNav>
          </div >
        </div >
      </Row >
    </React.Fragment >
  )
}

const mapStateToProps = ({ common, hisConnector }) => ({
  languages: common.languages || [],
  hisConnector: hisConnector.hisConnector || {},
  itemActive: hisConnector.itemActive
})

const mapDispatchToProps = dispatch => ({
  onGetLanguageCode: () => dispatch(getLanguageCodes()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["hisConnectorPage", "message, common, testConfig"])(Connector)))