import { Check, ConfirmModal, CustomMultiSelectAsync, TitleAndTable } from "components/Common"
import { ModuleIds } from "constant"

import PropTypes from "prop-types"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Col, Row } from "reactstrap"
import {
    deleteTestRequestTest,
    editTestRequestTestSampleType,
    getTestRequestProfileDetail,
    getTestRequestSample,
    getTestrequestSamplesDetail,
    getTestRequestTestDetail,
    getTestrequestTestsDetail,
    reloadStateTestRequestDetail,
    updateRequestTests
} from "store/laboratory/testRequest/actions"

import { AvForm } from "availity-reactstrap-validation"
import DetailTestsTabTable from "./DetailTestsTabTable"
import HeaderButtons from "./HeaderButtons"

const RESOURCE = ModuleIds.TestRequest

import { parameterCode, permissionType, TestRequest_State, TestRequest_Test_Type } from "constant/utility"

const DetailTestsTab = ({
    t,
    loadingRequestDetail,
    updateRequestTestDetailTime,

    onValidSubmit,
    formEl,

    onDeleteTestRequestTest,
    onEditSampleType,
    onGetProfileByCode,
    onGetTestByCode,
    onGetTestRequestSample,

    onRefreshDetail,
    onRefreshSamplesDetail,

    onUpdateRequestTests,
    testRequest,
    testRequestTests,
    isEdit,
    id,
    testsLoadingComplete,
    onGetTestRequestDetail,
    reloadSid,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [item, setItem] = useState({});
    const [selectedOptionDetail, setSelectedOptionDetail] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const userInSesstion = JSON.parse(localStorage.getItem("userInSesstion"));
    const RESOURCE = ModuleIds.TestRequest
    const onDeleteTestRequestHandler = () => {
        setIsSaveSample(false);
        onDeleteTestRequestTest(item);
        if (!isEdit) {
            onGetTestRequestSample()
        }
        setConfirmModal(false);
    }
    const removeItemFromArray = (array, itemToRemove) => {
        const index = array.indexOf(itemToRemove);
        if (index !== -1) {
            const newArray = [...array.slice(0, index), ...array.slice(index + 1)];
            setSelectedItem(newArray)
        } else {
            return selectedItem;
        }
    };

    const onDeleteToggle = (e, param) => {
        setItem(e);
        setConfirmModal(!confirmModal);
    }
    const [isSaveSample, setIsSaveSample] = useState(true);
    /** Table methods */

    const onRefreshHandler = () => {
        setIsLoading(true)
        setIsSaveSample(true)
        onRefreshDetail(id)
        onRefreshSamplesDetail(id, () => {
            setIsLoading(false)
        })
    }

    const onEditSampleTypeHandler = (item, sample) => {
        onEditSampleType({ item, sample })
        if (!isEdit) {
            onGetTestRequestSample()
        }
    }

    const onUpdateSampleCallback = () => {
        // if (!isEdit) {
        setIsSaveSample(false)
        onGetTestRequestSample()
        // }
    }

    const handleTestHandler = () => {
        onGetTestRequestSample({}, () => {
            submitUpdate()
        })
    }

    const submitUpdate = () => {
        let values = {};
        values.id = id;

        let tests = [];
        let profiles = [];
        testRequestTests.forEach(item => {

            //push test đơn
            if (item.type === TestRequest_Test_Type.TEST) {
                tests.push({
                    testCode: item.code,
                    testCategory: item.category,
                    sampleType: item.sampleType,
                    profileCode: item.parentCode,
                    sID: item?.sid || 0,
                    subID: item?.subID || '',
                });
            } else if (item.type === TestRequest_Test_Type.PROFILE_GROUP) {
                //push profile group vào list
                profiles.push({
                    profileCode: item.code,
                    profileCategory: item.category
                });

                if (item.children) {
                    //push tất cả child trực tiếp là test vào list
                    item.children.filter(x => x.type === TestRequest_Test_Type.TEST).map(test => {
                        tests.push({
                            testCode: test.code,
                            testCategory: test.category,
                            sampleType: test.sampleType,
                            profileCode: test.parentCode,
                            sID: test?.sid || 0,
                            subID: test?.subID || '',
                        });
                    })

                    //push tất cả child trực tiếp là profile vào list
                    item.children.filter(x => x.type === TestRequest_Test_Type.PROFILE).map(profile => {
                        profiles.push({
                            profileCode: profile.code,
                            profileCategory: profile.category
                        });
                        if (profile.children) {
                            //push tất cả test cháu vào list
                            profile.children.map(test => {
                                tests.push({
                                    testCode: test.code,
                                    testCategory: test.category,
                                    sampleType: test.sampleType,
                                    profileCode: test.parentCode,
                                    sID: test?.sid || 0,
                                    subID: test?.subID || '',
                                });
                            })
                        }
                    })
                }
            } else if (item.type === TestRequest_Test_Type.PROFILE) {
                //push profile bên ngoài
                profiles.push({
                    profileCode: item.code,
                    profileCategory: item.category
                });
                if (item.children) {

                    item.children.map(test => {
                        tests.push({
                            testCode: test.code,
                            testCategory: test.category,
                            sampleType: test.sampleType,
                            profileCode: test.parentCode,
                            sID: test?.sid || 0,
                            subID: test?.subID || '',
                        });
                    })
                }
                //push profile bên trong
            }
        })
        values.tests = tests;
        values.profiles = profiles;
        if (userInSesstion?.area) {
            values.area = userInSesstion?.area
        }
        onUpdateRequestTests({
            request: values, callback: () => {
                onRefreshHandler()
                onGetTestRequestDetail(id)
                reloadSid(id)
            }
        })
    }

    const onRenderCustomHeader = () => {
      return (
        <AvForm
          ref={formEl}
          id="testRequestTestTabForm"
          onValidSubmit={onValidSubmit}
          model={{}}
        >
          <Check permission={permissionType.U} resource={RESOURCE}>
            <Row>
              <Col xs="12" className="mt-2">
                <CustomMultiSelectAsync
                  readOnly={testRequest.state >= TestRequest_State.Collected}
                  name="profileAndTests"
                  value={removeItemFromArray(selectedItem, item?.code)}
                  label={""}
                  code={parameterCode.PROFILEANDTEST_CODE}
                  placeholder={t("testRequestPage:Select Test Or Profile")}
                  onChange={(e, values, label, value, data, unSelected) => {
                    let uncheckedItems = selectedOptionDetail.filter(
                      item => !data.includes(item)
                    )
                    if (uncheckedItems) {
                      if (uncheckedItems[0]?.isTestCode) {
                        if (uncheckedItems[0]?.categoryName) {
                          const arrCate =
                            uncheckedItems[0]?.categoryName.split(">")
                          uncheckedItems[0].categoryName =
                            arrCate[arrCate.length - 1]
                        }
                        uncheckedItems[0] = {
                          id: uncheckedItems[0].id,
                          sid: uncheckedItems[0]?.sid,
                          name: uncheckedItems[0].testName,
                          code: uncheckedItems[0].testCode,
                          sampleType:
                            uncheckedItems[0]?.sampleType ||
                            uncheckedItems[0].sampleType,
                          sampleTypeName:
                            uncheckedItems[0]?.sampleTypeName ||
                            uncheckedItems[0].sampleTypeName,
                          category: uncheckedItems[0].category,
                          categoryName: uncheckedItems[0]?.categoryName.trim(),
                          type: TestRequest_Test_Type.TEST,
                        }
                      }
                      onDeleteTestRequestHandler(uncheckedItems[0])
                    }
                    setSelectedOptionDetail(data)
                    setSelectedItem(values)
                    const dataTestCodeOrProfile = data[data.length - 1]?.value
                    if (
                      dataTestCodeOrProfile &&
                      data[data.length - 1].isTestCode
                    ) {
                      onGetTestByCode({
                        code: data[data.length - 1]?.value,
                        callback: onUpdateSampleCallback,
                      })
                    }
                    if (
                      dataTestCodeOrProfile &&
                      data[data.length - 1].isProfile
                    ) {
                      onGetProfileByCode({
                        code: dataTestCodeOrProfile,
                        callback: onUpdateSampleCallback,
                      })
                    }
                  }}
                  isMulti={false}
                  prevData={selectedOptionDetail}
                />
              </Col>
            </Row>
          </Check>
        </AvForm>
      )
    }

    return (
        <React.Fragment>

            {/* Table */}
            <div className="testsTabTable">
                <TitleAndTable
                    resource={RESOURCE}
                    buttons={() => (
                        <HeaderButtons
                            resource={RESOURCE}
                            onRefreshClick={onRefreshHandler}
                            onSaveClick={handleTestHandler}
                            isSaveSample={isSaveSample}
                            model={{}}
                            isLoading={isLoading}
                        />
                    )}
                    table={() => (
                        testsLoadingComplete && <DetailTestsTabTable
                            data={testRequestTests || []}
                            onSelect={() => { }}
                            onSelectAll={() => { }}
                            onDelete={onDeleteToggle}
                            onEditSampleType={onEditSampleTypeHandler}
                            loading={loadingRequestDetail}
                            model={{}}
                            updatedTime={updateRequestTestDetailTime}
                            testRequest={testRequest || {}}
                        />
                    )}
                    customComponent={onRenderCustomHeader}
                    external
                    subtitle={t("testRequestPage:Profile/Test Request")}
                    icon={false}
                />
                <ConfirmModal
                    modal={confirmModal}
                    title={`${t("common:Delete")} ${t("Test Request")}`}
                    message={t("message:DeleteConfirm")}
                    onToggle={onDeleteToggle}
                    onDelete={onDeleteTestRequestHandler}
                />

            </div>

        </React.Fragment>
    )
}

DetailTestsTab.propTypes = {
    onRefresh: PropTypes.func,
    t: PropTypes.any,

    loadingRequestDetail: PropTypes.bool,
    updateRequestTestDetailTime: PropTypes.any,

    onDeleteTestRequestTest: PropTypes.func,
    onEditSampleType: PropTypes.func,
    onGetTestRequestSample: PropTypes.func,

    onRefreshDetail: PropTypes.func,

    testRequest: PropTypes.object,
    testRequestSamples: PropTypes.array,
    testRequestTests: PropTypes.array,
}

DetailTestsTab.defaultProps = {}

const mapStateToProps = ({ testRequest }) => ({
    loadingRequestDetail: testRequest.loadingRequestDetail,
    updateRequestTestDetailTime: testRequest.updateRequestTestDetailTime,

    testRequest: testRequest.testRequest,
    testRequestSamples: testRequest.testRequest.samples || [],
    testRequestTests: testRequest.testRequest.tests || [],

    testsLoadingComplete: testRequest.testsLoadingComplete,
})

const mapDispatchToProps = dispatch => ({
    onDeleteTestRequestTest: payload => dispatch(deleteTestRequestTest(payload)),
    onEditSampleType: payload => dispatch(editTestRequestTestSampleType(payload)),
    onGetTestRequestDetail: requestId => dispatch(reloadStateTestRequestDetail(requestId)),
    onGetProfileByCode: (payload) => dispatch(getTestRequestProfileDetail(payload)),
    onGetTestByCode: (payload) => dispatch(getTestRequestTestDetail(payload)),

    onGetTestRequestSample: (payload, callback) => dispatch(getTestRequestSample(payload, callback)),

    onRefreshDetail: payload => dispatch(getTestrequestTestsDetail(payload)),
    onRefreshSamplesDetail: (payload, callback) => dispatch(getTestrequestSamplesDetail(payload, callback)),
    onUpdateRequestTests: ({ request, callback }) =>
        dispatch(updateRequestTests({ request, callback })),
    reloadSid: payload => dispatch(reloadStateTestRequestDetail(payload)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["message", "common", "testRequestPage"])(DetailTestsTab))
