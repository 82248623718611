import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import FilterForm from "./FilterForm"

import CustomBootstrapTable from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { DeliveryState, DeliveryStateText, ExportExtension, ModuleIds } from "constant/utility"

import { InlineAction } from "components/Common"
import { useSelector } from "react-redux"

import { convertDateFormat, indexCalculator } from "helpers/utilities"

//i18n
import BadgeLabel from "components/Common/BadgeLabel"
import CustomSIDHover from "components/Common/Input/CustomSIDHover"
import { getFileById } from "helpers/app-backend"
import { withTranslation } from "react-i18next"
import SampleDeliveryTableButtons from "./SampleDeliveryTableButtons"
const RESOURCE = ModuleIds.DeliveryManagement

const CorrectionRequestTable = ({
  onSelect,
  onSelectAll,
  deliveries,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  onClone,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    companyId: [],
    state: "",
  })

  const searchQuery = useSelector(state => state.sampleDelivery.searchQuery)

  const onResetHandler = () => {
    const initModel = {
      search: "",
      companyId: "",
      state: "",
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(deliveries)
  }, [deliveries])

  const download = async (item) => {
    if (item.fileId != null) {
      const fileData = await getFileById(item.fileId)
      let extension = ExportExtension.pdf;
      let tempFileName = item.deliveryDate
      let fileName = `${tempFileName}.${extension}`;

      const blob = new Blob([fileData], {
        type: 'application/octet-stream'
      })
      saveAs(blob, fileName)
    }
  }

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, delivery, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
    },
    {
      dataField: "deliveryTime",
      text: t("Date"),
      sort: true,
      style: { width: 190 },
      formatter: (cellContent, delivery, index) => {
        return (
          <Link to={`/DeliveryManagementCorrection/${delivery.id}/view?tab=1`}>{delivery.deliveryDate}</Link>
        )
      },
    },
    {
      dataField: "SID",
      text: t("SID"),
      formatter: (cellContent, testRequest, indexParent) => {
        return <CustomSIDHover data={testRequest.sampleIds} indexParent={indexParent} />
      },
    },
    {
      dataField: "targetUserName",
      text: t("Performer Name"),
      sort: true,
      formatter: (cellContent, delivery, index) => {
        return (
          <Link to={`/User/${delivery.targetUserId}/view?tab=1`} target="_blank">{cellContent}</Link>
        )
      },
    },
    {
      dataField: "updatedDate",
      text: t("Last Update"),
      sort: true,
      formatter: (cellContent, delivery, index) => {
        return convertDateFormat(
          delivery.updatedDate ? delivery.updatedDate : delivery.createdDate
        )
      },
    },
    {
      align: 'center',
      headerAlign: 'center',
      dataField: "testCount",
      text: t("Test Count"),
      formatter: (cellContent) => {
        return <span style={{ minWidth: '20px' }} className="badge badge-submitted">{cellContent}</span>
      }
    },
    {
      dataField: "state",
      text: t("State"),
      sort: true,
      align: '-webkit-center',
      headerAlign: 'center',
      formatter: (cellContent, delivery, index) => {
        return <BadgeLabel
          value={t(DeliveryState[delivery.state])}
          type={DeliveryState[delivery.state]}
          className="badge-width"
        />
      },
    },
    // {
    //   dataField: "report",
    //   text: t("Report File"),
    //   formatter: (cellContent, delivery, index) => {
    //     return <Link to={`#`} onClick={() => { download(delivery) }}>{DeliveryState[delivery.state] == "Reported" ? t("Download") : ""}</Link>
    //   },
    // },
    // {
    //   dataField: "action",
    //   text: "",
    //   isDummyField: true,
    //   style: { width: 50 },
    //   formatter: (cellContent, delivery, index) => (
    //     <InlineAction
    //       resource={RESOURCE}
    //       onEdit={e => onEdit(e, delivery.id)}
    //       disableEdit={
    //         delivery.state === DeliveryStateText.REPORTED ? true : false
    //       }
    //       onDelete={e => onDelete(e, delivery)}
    //       disableDelete={
    //         delivery.state === DeliveryStateText.REPORTED ? true : false
    //       }
    //       onClone={e => onClone(e, delivery)}
    //     />
    //   ),
    // },
  ]
  return (
    <div className="test-request-table-toolbar">
      <CustomBootstrapTable
        columns={columns}
        search
        data={data}
        paging={paging}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        onSearch={onSearch}
        onSort={onSort}
        onRefresh={onRefresh}
        filterForm={() => <FilterForm hideCompany model={model} />}
        onReset={onResetHandler}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        onSubmitFilter={onSubmitFilter}
        searchText={model.search}
        loading={loading}
        updatedTime={updatedTime}
        resource={RESOURCE}
        customButtonTable={() =>
          <SampleDeliveryTableButtons
            t={t}
            model={model}
            onChange={val => {
              setModel({ ...model, ...val })
            }}
            onSubmit={(val) => {
              onSubmitFilter(model)
            }}
          />
        }
      />
    </div>
  )
}

export default withTranslation(["deliveryPage", "common"])(CorrectionRequestTable)
