import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Row, TabContent, TabPane } from "reactstrap"
import TreeViewBar_TAT from "./TreeViewBar_TAT"
import { CustomNav, CustomNavLink } from "components/Common"
import TATConfigs from "./Settings"

const TATSettings = ({
  t,
}) => {

  const [currentTab, setCurrentTab] = useState('1')
  const [scale, setScale] = useState(false)
  const scaleScreen = () => {
    setScale(!scale)
  }
  return (
    <React.Fragment>
      <Row style={{ height: "calc(100% - 45px)", overflow: 'auto', backgroundColor: '#fff', position: 'relative' }}>
        <div className={`col-md-3 col-lg-2 p-0 ${scale ? 'TATSettingLeft' : ''}`} style={{ maxHeight: '100%', overflowY: "auto", position: 'relative' }} >
          <TreeViewBar_TAT
            onChange={val => {
              //setItemActive(val)
            }}
          />
        </div>
        <div className={`h-100 col-md-9 col-lg-10 ${scale ? 'TATSettingRight' : ''}`}>
          <div className="resize-button-tat"
            onClick={() => scaleScreen()}
          >
            <i className={`fa fa-angle-double-left ${scale ? 'resize-button-tat-icon' : ''}`}></i>
          </div>
          <div className="rule-management h-100 tat-setting-content">
            <CustomNav
              onToggle={e => {
                setCurrentTab(e);
              }}
              defaultTab={currentTab}
              tabs
              className="nav-tabs-custom"
              tabContents={customActiveTab => (
                <TabContent
                  activeTab={customActiveTab}
                  className="py-3 text-muted"
                >
                  <TabPane tabId="1">
                    <TATConfigs t={t} />
                  </TabPane>
                </TabContent>
              )}
            >
              <CustomNavLink tabId="1">
                <span className="">{t("common:Thiết lập")}</span>
              </CustomNavLink>
            </CustomNav>
          </div >
        </div >
      </Row >
    </React.Fragment >
  )
}

const mapStateToProps = ({ RuleManagement, machine }) => ({
  machines: machine.machines,
  tests: RuleManagement.tests,
  paging: RuleManagement.paging,
  updatedTime: RuleManagement.updatedTime
})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["message, common, testConfig"])(TATSettings)))