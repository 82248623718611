import { AvField, AvForm } from "availity-reactstrap-validation";
import { CustomAvField, CustomButton, CustomDatePicker, CustomSelect, CustomSelectAsync } from "components/Common";
import { parameterCode } from "constant";
import { getAllOrganizations } from "helpers/app-backend";
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Col, Label, Row } from "reactstrap";

const initModel = {
    dateType: 1,
    testCode: "",
    fromDate: new Date(),
    toDate: new Date(),
    fromSampleId: "",
    toSampleId: "",
}
const FilterForm = ({
    t,
    onChangeModel,
    formEl,
    data,
    isUpdate = false,
    onValidSubmit,
    onSearch,
    onExport,
    loadingDataExport,
}) => {
    const formRef = formEl ? formEl : useRef();
    const [model, setModel] = useState(data ? data : initModel);
    const [isWarning, setIsWarning] = useState(false);
    const [serviceTypes, setServiceTypes] = useState([])
    const colSpan = isUpdate ? 6 : 4;

    useEffect(() => {
        getServiceType()
    }, [])

    useEffect(() => {
        onChangeModel && onChangeModel(model)
    }, [model])
    // useEffect(() => {
    //     if (data)
    //         setModel(data || {})
    // }, [data])
    const onInputDebounce = (field, value) => {
        let newVal = { ...model }
        newVal[field] = value
        setModel(newVal)
    }


    const getServiceType = async () => {
        const query = { size: 0 }
        let res = await getAllOrganizations(query);
        let data = res?.data.map(_item => {
            _item.value = _item.organizationCode
            _item.label = `${_item.organizationCode} - ${_item.name}`
            return _item
        });
        setServiceTypes(data || [])
    }

    const date = useMemo(() => [model.fromDate, model.toDate], [model.fromDate, model.toDate])

    return (
        <AvForm ref={formRef} onValidSubmit={(e, vals) => {
            onValidSubmit && onValidSubmit(model)
        }}
            style={{ overflowX: 'hidden', }}
        >
            <Row className="px-2">
                <Col lg={colSpan} className="my-2">
                    <Row className="align-items-center">
                        <Col xs={3} className="label-group-relative position-relative">
                            <Label for="dateType" className="font-weight-semibold">
                                {t("Date Type")}
                                <span className="text-danger">*</span>
                            </Label>
                        </Col>
                        <Col xs={9}>
                            <CustomSelect
                                name="dateType"
                                value={model.dateType || 1}
                                code={parameterCode.DATE_TYPE_RESULT_INPUT_LIST}
                                // label={t("Date Type")}
                                required
                                errorMessage={getInvalidMessageI18n(t, "Date Type")}
                                validate={{
                                    required: { value: true },
                                }}
                                onChange={(e, values) => {
                                    setModel({ ...model, dateType: values[0] ? values[0] : '' });
                                }}
                                readOnly={isUpdate}
                            />
                        </Col>
                    </Row>
                </Col>
                {!isUpdate ?
                    <React.Fragment>
                        <Col lg={colSpan} className="my-2">
                            <Row className="align-items-center">
                                <Col xs={3} className="label-group-relative position-relative">
                                    <Label for="fromDate" className="font-weight-semibold">
                                        {t("Dates")}
                                        <span className="text-danger">*</span>
                                    </Label>
                                </Col>
                                <Col xs={9}>
                                    <CustomDatePicker
                                        name="requestDate"
                                        label={""}
                                        format={"d-m-Y"}
                                        value={date}
                                        closeOnSelect={true}
                                        onChangeHandler={date => {
                                            if (date.time.length == 2) {
                                                setModel({
                                                    ...model,
                                                    fromDate: convertDateFormat(
                                                        date.time[0],
                                                        "YYYY-MM-DD"
                                                    ),
                                                    toDate: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                                                })
                                            }
                                        }}
                                        mode="range"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Col lg={colSpan} className="my-2">
                            <Row className="align-items-center">
                                <Col xs={3} className="label-group-relative position-relative">
                                    <Label for="dateText" className="font-weight-semibold">
                                        {t("Dates")}
                                    </Label>
                                </Col>
                                <Col xs={9} className="field-worklist">
                                    <CustomAvField
                                        name={'dateText'}
                                        type="text"
                                        value={convertDateFormat(model.fromDate || new Date(), "YYYY-MM-DD HH:mm") + ' - ' + convertDateFormat(model.toDate || new Date(), "YYYY-MM-DD HH:mm")}
                                        // label={t("Dates")}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </React.Fragment>
                }

                {!isUpdate && <Col lg={colSpan} className="my-2">
                    <Row className="align-items-center">
                        <Col xs={3} className="label-group-relative position-relative">
                            <Label for="testCode" className="font-weight-semibold">
                                {t("State")}
                            </Label>
                        </Col>
                        <Col xs={9}>
                            <CustomSelectAsync
                                name="state"
                                value={model.state || ''}
                                options={[
                                    {
                                        label: t('Inprogress'),
                                        value: 7
                                    },
                                    {
                                        label: t('Valid'),
                                        value: 90
                                    }]}
                                onChange={(e, values) => {
                                    setModel({ ...model, state: values[0] ? values[0] : '' });
                                }}
                                code={parameterCode.TESTRESULT_STATE_KEY}
                            />
                        </Col>
                    </Row>
                </Col>}

                <Col lg={colSpan} className="my-2">
                    <Row className="align-items-center">
                        {isUpdate ?
                            <React.Fragment>
                                <Col xs={3} className="label-group-relative position-relative">
                                    <Label for="testName" className="font-weight-semibold">
                                        {t("Test Name")}
                                    </Label>
                                </Col>
                                <Col xs={9} className="field-worklist">
                                    <CustomAvField
                                        name={'testName'}
                                        type="text"
                                        value={model.testName || ''}
                                        // label={t("Test Name")}
                                        disabled={true}
                                    />
                                </Col>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Col xs={3} className="label-group-relative position-relative">
                                    <Label for="testCode" className="font-weight-semibold">
                                        {t("Test Name")}
                                        <span className="text-danger">*</span>
                                    </Label>
                                </Col>
                                <Col xs={9}>
                                    <CustomSelectAsync
                                        name="testCode"
                                        value={model.testCode}
                                        valueName={''}
                                        errorMessage={getInvalidMessageI18n(t, "Test Name")}
                                        required={true}
                                        code={parameterCode.LIS_MAPPING_KEY}
                                        // label={t("Test Name")}
                                        onChange={(e, values, name) => {
                                            setModel({ ...model, testCode: values[0] ? values[0] : '', testName: name });
                                        }}
                                        readOnly={isUpdate}
                                        isWarning={isWarning}
                                    />
                                </Col>
                            </React.Fragment>
                        }
                    </Row>
                </Col>

                {!isUpdate &&
                    <Col lg={colSpan} className="my-2">
                        <Row className="align-items-center">
                            <Col xs={3} className="label-group-relative position-relative">
                                <Label for="result" className="font-weight-semibold">
                                    {t("Result")}
                                </Label>
                            </Col>
                            <Col xs={9} className="field-worklist">
                                <CustomAvField
                                    name={'result'}
                                    type="text"
                                    value={model.result || ''}
                                    // label={t('Result')}
                                    onChange={(val) => {
                                        onInputDebounce('result', val)
                                    }}
                                    disabled={isUpdate}
                                />
                            </Col>
                        </Row>
                    </Col>
                }
                {isUpdate &&
                    <Col lg={colSpan} className="my-2">
                        <Row className="align-items-center">
                            <Col xs={3} className="label-group-relative position-relative">
                                <Label for="newResult" className="font-weight-semibold">
                                    {t("New Result")}
                                </Label>
                            </Col>
                            <Col xs={9} className="field-worklist">
                                <CustomAvField
                                    name={'newResult'}
                                    type="text"
                                    value={model.newResult || ''}
                                    // label={t('New Result')}
                                    onChange={(val) => {
                                        onInputDebounce('newResult', val)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                }
                {isUpdate &&
                    <Col lg={colSpan} className="my-2">
                        <Row className="align-items-center">
                            <Col xs={3} className="label-group-relative position-relative">
                                <Label for="newResultText" className="font-weight-semibold">
                                    {t("New Result Text")}
                                </Label>
                            </Col>
                            <Col xs={9} className="field-worklist">
                                <CustomAvField
                                    name={'newResultText'}
                                    type="text"
                                    value={model.newResultText || ''}
                                    // label={t('New Result')}
                                    onChange={(val) => {
                                        onInputDebounce('newResultText', val)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                }
                {!isUpdate && <Col lg={colSpan} className="my-2">
                    <Row className="align-items-center">
                        <Col xs={3} className="label-group-relative position-relative">
                            <Label for="newResult" className="font-weight-semibold">
                                {t("patientGroup:List Group")}
                            </Label>
                        </Col>
                        <Col xs={9} className="field-worklist">
                            <CustomSelect
                                name="groupCode"
                                options={serviceTypes || []}
                                label={""}
                                onChange={(e, vals, a, values) => {
                                    onInputDebounce('groupCode', vals[0])
                                }}
                                placeholder={t("patientGroup:List Group")}
                            />
                        </Col>
                    </Row>
                </Col>}
                {!isUpdate &&
                    <Col lg={8 + colSpan} className="align-items-center text-end my-2">
                        <CustomButton
                            color="success"
                            style={{ marginRight: 5 }}
                            onClick={() => {
                                onSearch && onSearch()
                            }}
                        >
                            <div className="d-flex">
                                <div><i className="fa fa-search"></i></div>
                                <div style={{ marginLeft: 10, marginRight: 10 }}>{t("common:Search")}</div>
                            </div>
                        </CustomButton>
                        <CustomButton
                            color="primary"
                            outline
                            disabled={loadingDataExport == true}
                            onClick={() => {
                                if (model.testCode === '') {
                                    setIsWarning(true)
                                }
                                onExport && onExport()
                            }}
                        >
                            <div className="d-flex">
                                {loadingDataExport == true &&
                                    <i style={{ lineHeight: '20px' }} className={`fas fa-sync-alt loading-spin`}></i>
                                }
                                <div style={{ marginLeft: 10, marginRight: 10 }}>{t("common:Export")}</div>
                            </div>
                        </CustomButton>
                    </Col>
                }
            </Row>
        </AvForm>
    )
}

export default withTranslation(["workListPage", "common"])(FilterForm)