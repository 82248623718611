import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CustomBootstrapTable } from "components/Common";
import FilterForm from "./FilterForm";
import { ModuleIds } from "constant";
import { getAllUsers } from "helpers/app-backend";
import { convertDateFormat } from "helpers/utilities";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const RESOURCE = ModuleIds.TestRequest;

const NotificationTable = ({
    notification,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    t,
    updatedTime,
}) => {
    const [data, setData] = useState([]);
    const [model, setModel] = useState({
        search: "",
        state: "",
    })
    const [user, setUser] = useState([])

    const searchQuery = useSelector(state => state.testRequest.searchQuery)
    const onResetHandler = () => {
        const initModel = {
            search: "",
            state: ""
        }
        setModel(initModel)
    }

    useEffect(() => {
        getUser()
    }, [])

    useEffect(() => {
        if (notification) {
            if (user.length === 0) {
                getUser(notification)
            } else {
                notification.forEach(item => {
                    item.createdByName = user.find(x => item.createdBy == x.id)?.label
                    item.senderUserName = user.find(x => item.senderUserId == x.id)?.label
                })
                setData(notification)
            }
        }
    }, [notification])

    const getUser = async (data = []) => {
        const query = { size: 0 }
        const res = await getAllUsers(query)
        const reuslt = res.data.map(_item => {
            _item.value = _item.id
            _item.label = _item.familyName + " " + _item.givenName
            return _item
        })
        setUser(reuslt)
        if (data.length > 0) {
            data.forEach(item => {
                item.createdByName = reuslt.find(x => item.createdBy == x.id)?.label
                item.senderUserName = reuslt.find(x => item.senderUserId == x.id)?.label
            })
            setData(data)
        }
        return reuslt
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent) => {
                return cellContent
            },
        },
        {
            dataField: "SEQ",
            text: "#",
            headerStyle: { width: "30px" },
            formatter: (cellContent, notification, index) => {
                return index + 1
            },
        },
        {
            dataField: "template",
            text: t("Content"),
            sort: true,
            formatter: (cellContent, row, index) => {
                let result = cellContent
                if (row.messageContent && row.messageContent !== '') {
                    const content = JSON.parse(row.messageContent)
                    const key = Object.keys(content)
                    key.forEach(item => {
                        result = result.replace(`@${item}`, content[item])
                    })
                }
                return <div dangerouslySetInnerHTML={{ __html: result }}></div>
            },
        },
        {
            dataField: "senderUserName",
            text: t("Sender Name"),
            formatter: (cellContent, row) => {
                return <Link to={`/User/${row.senderUserId}/view`} target="_blank">{`${row.senderUserName}`}</Link>
            },
        },
        {
            dataField: "sendTime",
            text: t("Send Time"),
            sort: true,
            formatter: (cellContent) => {
                return convertDateFormat(cellContent, "YYYY-MM-DD HH:mm:ss")
            },
        },
        {
            dataField: "createdByName",
            text: t("common:Created By"),
            formatter: (cellContent, row) => {
                return <Link to={`/User/${row.createdBy}/view`} target="_blank">{`${row.createdByName}`}</Link>
            },
        },
        {
            dataField: "createdDate",
            text: t("common:Created Date"),
            formatter: (cellContent) => {
                return convertDateFormat(cellContent, "YYYY-MM-DD hh:mm:ss")
            },
        }
    ]

    return (
        <CustomBootstrapTable
            columns={columns}
            search
            data={data}
            paging={paging}
            onSearch={onSearch}
            onSort={onSort}
            onRefresh={onRefresh}
            onReset={onResetHandler}
            onPageChange={onPageChange}
            filterForm={() => <FilterForm model={model} />}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            searchText={model.search}
            loading={loading}
            updatedTime={updatedTime}
            resource={RESOURCE}
            showSelectRow={false}
            isEnableExport={false}
            keyField="id"
        />
    )
}

export default withTranslation(["notificationPage", "common"])(NotificationTable)