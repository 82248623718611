import { CustomButton, CustomPrintButton, CustomSID } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { ReportResource } from "constant"
import { getFilePrint, pingToPrintService, sendDataPrint, sendDataPrintWithoutError } from "helpers/app-backend"
import { GetDataUrlReportConfig } from "helpers/utilities"
import { forEach, isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { connect, useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"

import ReactTooltip from "react-tooltip"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { loadingPrintSampleList, printSampleList, updateNumbOfLabels } from "store/actions"

let globalStop = false;
let countPrint = 0;
let lstSuccessGlobal = [];
let lstErrorGlobal = [];
let timeouts = []
let timeoutStop = []
const BarcodeFrame = ({
  t,
  samples,
  patientVisit,
  spin,
  fetchTestRequestSample,
  onUpdateNumberOfLabel,
  toggleBarcodeSetting,
  printList,
  handleClearSelected
}) => {
  const dispatch = useDispatch()
  const { reportInfos } = useSelector(state => {
    return ({
      reportInfos: state.Authorization.reportinfo || [],
    })
  })

  const [dataSource, setDataSource] = useState([])
  const [loadingPrint, setLoadingPrint] = useState(false)
  const [lstError, setLstError] = useState([])
  const [lstSuccess, setLstSuccess] = useState([])
  const [notSelected, setNotSelected] = useState(false)
  const [pausePrint, setPausePrint] = useState(false)


  useEffect(() => {
    setLstSuccess([])
    setLstError([])
    setDataSource(samples)
  }, [samples])

  useEffect(() => {
    if (lstError.length + lstSuccess.length == countPrint && countPrint > 0) {
      countPrint = 0;
      let tmp = JSON.parse(JSON.stringify(dataSource));
      tmp = tmp.map(x => {
        return { ...x, color: getColor(x) }
      })
      setDataSource([...tmp])
      setLoadingPrint(false)
    }
  }, [lstError, lstSuccess])

  const confirmElement = (
    <button type="submit"
      className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1"
    ><i className="mdi mdi-check"></i></button>
  );

  const cancelElement = (
    <button type="button"
      className="btn btn-outline-primary editable-cancel btn-sm me-3">
      <i className="mdi mdi-close"></i></button>
  );

  const submitUpdateNumberOfLabel = async (item, value) => {
    await onUpdateNumberOfLabel({
      request: {
        ...item,
        numberOfLabels: value
      }, callback: {}
    })
  }

  const PrintConfig = async () => {
    if (dataSource.length <= 0)
      return
    lstErrorGlobal = [];
    lstSuccessGlobal = []
    const print = JSON.parse(localStorage.getItem("printConfig"))
    let printColumns = print.columns
    let printPage = ['B1', 'B2', 'B3']
    if (reportInfos.length > 0 && !isEmpty(reportInfos[0].uri)) {
      //list request id
      const requestIds = dataSource.map(x => x.requestId) || [];
      const requestIdsDistinct = requestIds.filter((x, index) => !requestIds.includes(x, index + 1)) || []
      countPrint = requestIdsDistinct.length
      setLoadingPrint(true)
      let dataToPrint = [];
      for (let index = 0; index < requestIdsDistinct.length; index++) {
        const element = requestIdsDistinct[index];
        let samplePrints = dataSource.filter(x => x.requestId == element);
        let sampleType = samplePrints.map(x => x.sampleType)
        let SID = samplePrints.map(x => x.sid)
        let subSID = samplePrints.map(x => x.subSID)
        const formattedSampleType = sampleType?.map(item => `'${item}'`)
        const res = await getFilePrint({
          ...GetDataUrlReportConfig(reportInfos, ReportResource.RequestId, ""),
          SID: SID?.length > 1 ? `[${SID}]` : SID[0],
          SubSID: subSID?.length > 1 ? `[${subSID}]` : subSID[0],
          RequestDate: samplePrints[0].requestDate,
          SampleType: formattedSampleType?.length > 1 ? `[${formattedSampleType}]` : sampleType[0],
          Gender: patientVisit.gender,
          Page: printPage[printColumns - 1]
        })
        try {
          const getPingPrintService = await pingToPrintService();
          if (getPingPrintService.pingTime) {
            dataToPrint.push({
              requestId: element,
              data: {
                ...GetDataUrlReportConfig(reportInfos, ReportResource.RequestId, ""),
                "filePath": `${res.reportUrl}`,
              }
            })

          } else {
            window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
            let tmp = lstSuccessGlobal
            tmp.push(element)
            setLstSuccess([...tmp])
          }
        }
        catch (error) {
          console.log(error);
          let tmp = lstErrorGlobal
          tmp.push(element)
          setLstError([...tmp])
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      }
      Print(dataToPrint)
    }
    else {
      countPrint = 0;
      console.log('failed')
    }
  }

  const Print = (data) => {
    for (let index = 0; index < data.length; index++) {
      let to = setTimeout(async function () {
        if (!timeoutStop.includes(to)) {
          let element = data[index]
          try {
            await sendDataPrintWithoutError(element.data)
            let tmp = lstSuccessGlobal
            tmp.push(element.requestId)
            setLstSuccess([...tmp])
          }
          catch (error) {
            console.log(error);
            let tmp = lstErrorGlobal
            tmp.push(element.requestId)
            setLstError([...tmp])
          }
        }
      }, 1000 * index)
      timeouts.push(to)
    }

  }

  const handlePrintBarcode = () => {
    PrintConfig()
  }

  useEffect(() => {
    if (lstError.length + lstSuccess.length > 0) {
      let tmp = JSON.parse(JSON.stringify(dataSource));
      tmp = tmp.map(x => {
        return { ...x, color: getColor(x) }
      })
      setDataSource([...tmp])
    }
  }, [lstError, lstSuccess])

  const getColor = (item) => {
    let indexSuccess = lstSuccess.indexOf(item.requestId);
    if (indexSuccess >= 0)
      return 'green'
    let indexError = lstError.indexOf(item.requestId);
    if (indexError >= 0)
      return 'red'
    return 'white'
  }

  useEffect(() => {
    if (printList == true) {
      dispatch(printSampleList(false))
      PrintConfig()
    }
  }, [printList])

  useEffect(() => {
    dispatch(loadingPrintSampleList(loadingPrint))
  }, [loadingPrint])

  const PrintSingleBarcodeConfig = async (cell, item, index) => {
    const print = JSON.parse(localStorage.getItem("printConfig"))
    let printColumns = print.columns
    let printPage = ['B1', 'B2', 'B3']
    if (reportInfos.length > 0 && !isEmpty(reportInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportInfos, ReportResource.RequestId, ""),
        SID: item.sid,
        SubSID: item.subSID,
        RequestDate: item.requestDate,
        SampleType: item.sampleType,
        Gender: patientVisit.gender,
        Page: printPage[printColumns - 1]
      })
      try {
        const getPingPrintService = await pingToPrintService();
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportConfig(reportInfos, ReportResource.RequestId, ""),
            "filePath": `${res.reportUrl}`,
          })
        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      }
      catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    } else {
      console.log("failed")
    }
  }

  const handlePrintSingleBarcode = (cell, item, index) => {
    PrintSingleBarcodeConfig(cell, item, index)
  }

  const onClearAllSamples = () => {
    setDataSource([])
    setNotSelected(true)
    handleClearSelected(!notSelected)
  }

  // const handlePausePrint = () => {
  //   console.log('đã click')
  //   globalStop = true
  //   setLoadingPrint(false)
  //   setPausePrint(true)
  // }

  const columns = [
    {
      dataField: "sid2",
      text: t(""),
      style: { padding: "0px" },
      headerStyle: { padding: "0px" },
      formatter: (cellContent, item, index) => {
        return <div style={{ background: item.color, width: 3 }}>&nbsp;</div>
      },
    },
    {
      dataField: "id",
      text: t("Barcode"),
      // align: 'center',
      headerStyle: { width: "100px" },
      formatter: (cellContent, item, index) => (
        <CustomPrintButton
          name={"Barcode_Template_no4_Other_Report"}
          isPrint={true}
          sid={item.sid}
          subSID={item.subSID}
          sampleType={item.sampleType}
          requestDate={item.requestDate}
          reportResource={ReportResource.RequestId}
          fileId={''}
          reportInfos={reportInfos}
          gender={patientVisit.gender}
        />
      )
    },
    {
      dataField: "sampleType",
      text: t("Type"),
      headerStyle: { width: "100px" },
    },
    {
      dataField: "sid",
      text: "SID",
      headerStyle: { width: "110px" },
      formatter: (cellContent, item, index) => {
        return <CustomSID
          key={`sampleType-${index}`}
          keyToolTip={`sampleType-${index}`}
          isToolTip={true}
          toolTip={item.sampleTypeName}
          sid={`${item.sid}${item.subSID !== 0 ? '-' + item.subSID : ''}`}
        // emergency={item?.emergency}
        />
      },
    },
    // {
    //   dataField: "subSID",
    //   text: "Sub SID",
    //   headerStyle: { width: "100px" },
    //   formatter: (cellContent, item, index) => {
    //     return item.subSID
    //   },
    // },
    {
      dataField: "numberOfLabels",
      text: t("testRequestPage:Labels"),
      headerStyle: { width: "70px" },
      formatter: (cellContent, item, index) => {
        const idHTML = `requestSample${item.sampleType}`.replace(' ', '')
        return (<React.Fragment>
          <div id={idHTML + '_'} className={'requestSampleItemFocus'} tabIndex={9999}
            style={{ minWidth: 45, maxWidth: 150, width: "max-content", textAlign: "center", textDecoration: 'underline' }} onClick={(e) => {
              e.stopPropagation();
            }}>
            <Editable
              alwaysEditing={false}
              disabled={false}
              editText={`${item.numberOfLabels || '1'}`}
              initialValue={`${item.numberOfLabels || '1'}`}
              id={item.code}
              isValueClickable={false}
              mode="inline"
              placement="top"
              showText={false}
              type="textfield"
              renderConfirmElement={confirmElement}
              renderCancelElement={cancelElement}
              onSubmit={(value) => {
                submitUpdateNumberOfLabel(item, value)
              }}
            />
          </div>
        </React.Fragment>
        )
      },
    },
    {
      dataField: '',
      text: t("testRequestPage:Print"),
      headerStyle: { width: "70px" },
      formatter: (cellContent, item, index) => {
        return (
          <>
            <CustomButton className='mb-0' color="light" onClick={() => {
              // if (loadingPrint) {
              //   //stop
              //   globalStop = true
              // } else {
              //   // handlePrintSingleBarcode(cellContent, item, index)
              // }
              handlePrintSingleBarcode(cellContent, item, index)
            }}>
              <>
                <i className="fa fa-print"></i></>
            </CustomButton>
          </>
        )

      },
    }
  ]

  const removeSidType = (value) => {
    const newData = dataSource.filter(item => item.sampleType !== value)
    if (newData.length === 0) {
      handleClearSelected(newData)
      setNotSelected(true)
    }
    setDataSource([...newData])
  }

  const sampleTypeList = _.uniqBy(dataSource, (e) => {
    return e.sampleType
  })

  return (
    <div className="table-patient-barcode mt-1 mb-0 table-patient-visit">
      <CustomBootstrapTableAsync
        customButtonTable={() => (
          <div>
            <div className="button-items" style={{ textAlign: "right", display: 'flex' }}>
              <div className="delete-type">
                {sampleTypeList.length > 0 && <UncontrolledDropdown>
                  <DropdownToggle color="light" >
                    <i className="fa fa-ban"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    {sampleTypeList.map((item, index) => <DropdownItem key={index} onClick={() => {
                      removeSidType(item.sampleType)
                    }}>
                      {`${t('patientVisitPage:Delete Type')} ${t(item.sampleType)}`}
                    </DropdownItem>)}
                  </DropdownMenu>
                </UncontrolledDropdown>}
              </div>

              {/* <CustomButton className='mb-0' color="light" onClick={onClearAllSamples} disabled={(samples?.length < 1) ? true : false}>
                <i className="fa fa-ban"></i>
              </CustomButton> */}
              <CustomButton className='mb-0' color="light" onClick={() => {
                if (loadingPrint) {
                  //stop
                  globalStop = true
                  timeoutStop = timeouts
                  setLoadingPrint(false)
                } else {
                  handlePrintBarcode()
                }
              }} disabled={(samples?.length < 1) ? true : false}>
                {/* <>
                  {loadingPrint && <i style={{ marginRight: 5 }} className={"fas fa-sync fa-spin"}></i>}
                  <i className="fa fa-print"></i>
                </> */}
                {loadingPrint ?
                  <><i style={{ marginRight: 5 }} className={"fas fa-sync fa-spin"}></i> <i className="fa fa-pause"></i></>
                  :
                  <i className="fa fa-print"></i>
                }
              </CustomButton>
              {/* <CustomButton className='loading-spin-hover mb-0' color="light" onClick={toggleBarcodeSetting}>
                <i className="fa fa-cog loading-spin-hover-content"></i>
              </CustomButton> */}
            </div>
          </div>)}
        TableTitle={t('testRequestPage:Sample List')}
        columns={columns}
        data={dataSource || []}
        keyField="id"
        showSelectRow={false}
        style={{ margin: 0, maxHeight: '220px !important' }}
        onSizePerPageChange={() => { }}
        onSelect={() => { }}
        onSelectAll={() => { }}
        paging={{ dataSize: dataSource?.length || 0, size: dataSource?.length || 0 }}
        isSelected={notSelected}
      />
    </div>
  )
}

const mapStateToProps = ({ testResult, testRequest }) => {
  return ({
    testResult: testResult.testResult,
    loadingDetail: testResult.loadingDetail || false,
    isFastValidate: testResult.isFastValidate,
    printList: testRequest.printSampleList || false,
    samples: testRequest.testRequest.samples,
  })
}

const mapDispatchToProps = dispatch => ({
  onUpdateNumberOfLabel: payload => dispatch(updateNumbOfLabels(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation(["partyPage", "message", "common", "testRequestPage", "patientVisitPage"])(BarcodeFrame)))
